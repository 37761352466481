import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import DashboardSection from "../DashboardSection";
import SectionHeader from "../SectionHeader";
import UserContext, {
  isUserAdminOrSemiAdminWith,
  ServerOrgRoles,
} from "../../../services/UserContext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import CalendarRow, { useHeaderRow } from "./CalendarRow";
import GrowlContext from "../../../services/growlContext";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import axios from "axios";

export interface EventType {
  // TODO: Event time? seems like it might be important. Can be part of Date depending on serialization
  id: number;
  name: string;
  date: string;
  organization: string;
  url: string;
}

export const sharedStyles = {
  controls: {
    whiteSpace: "nowrap" as "nowrap",
  },
  iconButton: {
    width: "30px",
    margin: "5px",
  },
  inlineInput: {
    width: "100%",
  },
  cell: {
    verticalAlign: "top",
  },
};

const styles = {
  eventsTable: {
    margin: "20px 0",
    textAlign: "left" as "left",
    width: "100%",
    borderSpacing: "10px",
  },
};

type Props = { events: Array<EventType> };
const EventsCalendar: FunctionComponent<Props> = ({ events: inputEvents }) => {
  const userContext = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const [events, setEvents] = useState(inputEvents);
  const [newEventDate, setNewEventDate] = useState<Date>(new Date());
  const [newEventName, setNewEventName] = useState<string>("");
  const [newEventOrganization, setNewEventOrganization] = useState<string>("");
  const [newEventUrl, setNewEventUrl] = useState<string>("");

  React.useEffect(() => {
    setEvents(inputEvents);
  }, [inputEvents]);

  const addEvent = useCallback(
    async (name: string, date: Date, organization: string, url: string) => {
      await axios
        .post(
          getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
          {
            date,
            name,
            organization,
            url,
          },
          createRequestWithAuthHeaders(userContext)
        )
        .then((response) => {
          const newEvent = { ...response.data };
          setEvents([newEvent, ...events]);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error saving new event",
            detail: e,
          });
        });
    },
    []
  );

  const deleteEvent = useCallback(async (eventId: number) => {
    const request = {
      ...createRequestWithAuthHeaders(userContext),
      data: { id: eventId },
    };
    await axios
      .delete(
        getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
        request
      )
      .then(() => {
        const updatedEvents = events.filter((event) => event.id !== eventId);
        setEvents(updatedEvents);
      })
      .catch((e) => {
        console.error(e);
        growl.current.show({
          severity: "error",
          summary: "Error deleting event",
          detail: JSON.stringify(e),
        });
      });
  }, []);

  const editEvent = useCallback(
    async (eventId: number, editedEvent: EventType, events: EventType[]) => {
      await axios
        .put(
          getFullUrl("/api/user/event", { useDedicatedEnvironment: true }),
          {
            id: eventId,
            event: {
              date: editedEvent.date,
              name: editedEvent.name,
              organization: editedEvent.organization,
              url: editedEvent.url,
            },
          },
          createAuthenticatedRequest(userContext)
        )
        .then(() => {
          const updatedEvents = events.map((event) =>
            event.id === eventId ? editedEvent : event
          );
          setEvents(updatedEvents);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error editing event",
            detail: e,
          });
        });
    },
    []
  );

  const submitNewEvent = useCallback(
    async (newEventName, newEventDate, newEventOrganization, newEventUrl) => {
      await addEvent(
        newEventName,
        newEventDate,
        newEventOrganization,
        newEventUrl
      );
      setNewEventDate(new Date());
      setNewEventName("");
      setNewEventOrganization("");
      setNewEventUrl("");
    },
    []
  );

  const canEditEvents = isUserAdminOrSemiAdminWith(
    userContext,
    ServerOrgRoles.EditCalendarEvents
  );

  const canSubmitNewEvent = useMemo(
    () => newEventDate && newEventName && newEventOrganization && newEventUrl,
    [newEventDate, newEventName, newEventOrganization, newEventUrl]
  );

  return (
    <DashboardSection>
      <SectionHeader
        text={"Events Calendar"}
        icon={true}
        icontext={
          "The events calendar highlights events that USAID staff are participating in, or that are relevant to the USAID partnering market. We also include some events highlighting partnering opportunities with other international aid donors. Please message us if you are interested in having your event added to our calendar."
        }
      />

      <table style={styles.eventsTable}>
        <thead>{useHeaderRow()}</thead>
        <tbody>
          {events &&
            events
              .sort(
                (e1, e2) =>
                  new Date(e1.date).getTime() - new Date(e2.date).getTime()
              )
              .map((event, i) => (
                <CalendarRow
                  key={`${i}-${event.name}`}
                  event={event}
                  canEditEvents={canEditEvents}
                  editEvent={(id, event) => editEvent(id, event, events)}
                  deleteEvent={deleteEvent}
                />
              ))}
          {canEditEvents && (
            <tr>
              <td>
                <Calendar
                  placeholder="mm/dd/yyyy"
                  dateFormat="mm/dd/yy"
                  value={newEventDate}
                  onChange={(e) => setNewEventDate(e.value as Date)}
                />
              </td>
              <td>
                <InputText
                  placeholder="Event Name"
                  style={sharedStyles.inlineInput}
                  value={newEventName}
                  onChange={(e) => setNewEventName(e.currentTarget.value)}
                />
              </td>
              <td>
                <InputText
                  placeholder="Organization"
                  style={sharedStyles.inlineInput}
                  value={newEventOrganization}
                  onChange={(e) =>
                    setNewEventOrganization(e.currentTarget.value)
                  }
                />
              </td>
              <td>
                <InputText
                  placeholder="RSVP Link"
                  style={sharedStyles.inlineInput}
                  value={newEventUrl}
                  onChange={(e) => setNewEventUrl(e.currentTarget.value)}
                />
              </td>
              <td style={{ ...sharedStyles.cell, ...sharedStyles.controls }}>
                <Button
                  icon="pi pi-plus"
                  className="p-button-rounded aid-blu-btn"
                  style={sharedStyles.iconButton}
                  disabled={!canSubmitNewEvent}
                  onClick={() =>
                    submitNewEvent(
                      newEventName,
                      newEventDate,
                      newEventOrganization,
                      newEventUrl
                    )
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </DashboardSection>
  );
};

export default EventsCalendar;
