import React, {
  FC,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { DataTable } from "primereact/datatable";
import SectionHeader, {
  SubHeaders,
} from "../../components/Dashboard/SectionHeader";
import { Column } from "primereact/column";
import { useGridStyles } from "../../hooks/useGridStyles";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import "./Pipeline.css";
import { OverlayPanel } from "primereact/overlaypanel";
import GrowlContext from "../../services/growlContext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Sidebar as Sidebars } from "primereact/sidebar";
import Sidebar from "./PipelineFragments/SideBar";
import { goToUrlInNewTab } from "../../configs/resources";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MarketPreferencesBeta from "../../components/Dashboard/MarketPreferences/MarketPreferencesBeta";
import DebouncedButton from "./PipelineFragments/DebouncedButton";
import Chip from "../../components/Chip";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
const HeaderButton = styled.button({
  background: "#FAEACD",
  borderRadius: "26.5px",
  margin: "10px",
  height: "40px",
  padding: "10px",
  border: "none",
  left: "0px",
  ":focus": {
    background: "#ECAC37",
  },
});
const CustomLabel = styled.label({
  fontSize: "15px",
});
const Divider = styled.hr({
  width: "100%",
  border: "1px solid #D9D9D9",
});

const Div = styled.div({
  border: "5px solid #D9D9D9",
  borderRadius: "35px",
  height: "74px",
  boxSizing: "border-box",
  display: "flex",
  "& > button": {
    background: "#FAEACD",
    borderRadius: "26.5px",
    margin: "10px",
    height: "40px",
    padding: "8px 24px",
    border: "none",
    left: "0px",
    cursor: "pointer",
    ":focus": {
      background: "#ECAC37",
      border: "none",
      color: "#FFFFFF",
    },
  },
});
const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};

interface Item {
  name: string;
  code: string;
}
interface MarketPrefsItem {
  name: string;
  code: string;
}

type PipelineDto = {
  id: number;
  name: string;
  description: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: [];
  marketPreferences: [];
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
};

export interface Props {
  pipelines: PipelineDto[];
  headerTitle: string;
  deletePipeline(id: number): void;
  totalRecords: number;
  toggledColumns: boolean;
  currentTab: boolean;
}
enum PreferenceTypes {
  country = 1,
  technicalSector = 2,
}
type MarketPreference = {
  preferenceType: PreferenceTypes;
  name: string;
};

type User = {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  pipelineUserRole: string;
  email: string;
};
const Pipeline = () => {
  const context = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pipeline, setPipelines] = useState<PipelineDto[]>(
    Array<PipelineDto>()
  );
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalrecords] = React.useState(0);
  const [headerTitle, setSubHeader] = React.useState("All Pipelines");
  const [showAllpipelines, setshowAllpipelines] = React.useState(true);
  const [showMyPipelines, setshowMyPipelines] = React.useState(false);
  const [showShared, setShowShared] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const [controlRecord, setControlRecord] = useState<PipelineDto[]>(
    Array<PipelineDto>()
  );
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const [toggleColunms, setToggleColumns] = React.useState(false);

  const setSelectedTab = (
    type: "allPipelines" | "myPipelines" | "sharedPipelines"
  ) => {
    switch (type) {
      case "allPipelines":
        setToggleColumns(false);
        setshowAllpipelines(true);
        setSubHeader("All Pipelines");
        setPipelines(controlRecord);
        setshowMyPipelines(false);
        setShowShared(false);
        setTotalrecords(controlRecord.length);
        break;
      case "myPipelines":
        setToggleColumns(true);
        setSubHeader("My Pipelines");
        setTotalrecords(myPipelines.length);
        setshowMyPipelines(true);
        setshowAllpipelines(false);
        setShowShared(false);
        setPipelines(myPipelines);
        break;
      case "sharedPipelines":
        setSubHeader("Shared with me");
        setTotalrecords(sharedPipeline.length);
        setPipelines(sharedPipeline);
        setShowShared(true);
        setshowAllpipelines(false);
        setshowMyPipelines(false);
        setToggleColumns(true);
        break;
    }
  };

  const [myPipelines, setMyPipelines] = React.useState<PipelineDto[]>(
    Array<PipelineDto>()
  );
  const [sharedPipeline, setSharedPipeline] = React.useState<PipelineDto[]>(
    Array<PipelineDto>()
  );

  const loadPipelines = () => {
    axios
      .get(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        createRequestWithAuthHeaders(context)
      )
      .then((response) => {
        //  Reconstruct the pipeline data
        const pipelines: PipelineDto[] = (response.data as PipelineDto[]).map(
          (r) => ({
            id: r.id,
            name: r.name,
            users: r.users,
            description: r.description,
            createdOnUtc: r.createdOnUtc,
            pipelineUserRole: r.pipelineUserRole,
            marketPreferences: r.marketPreferences,
            businessForecastCustomColumnName: "",
            contractCustomColumnName: "",
            grantCustomColumnName: "",
            grantCount: r.grantCount,
            contractCount: r.grantCount,
            forecastCount: r.forecastCount,
            totalOpportunities:
              r.grantCount + r.forecastCount + r.contractCount,
          })
        );

        setPipelines(pipelines);
        setControlRecord(pipelines);
        const mypipelines = pipelines.filter(
          (pipeline) => pipeline.pipelineUserRole == "Admin"
        );
        const sharedWithMe = pipelines.filter(
          (pipeline) => pipeline.pipelineUserRole !== "Admin"
        );
        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);
        setTotalrecords(pipelines.length);

        setIsLoading(false);
      })
      .catch((message) => {
        console.error(message);
        setIsLoading(false);
        // growl.current.show({
        //   severity: "error",
        //   summary: "Error loading pipeline data",
        // });
      });
  };
  React.useEffect(() => {
    //  load pipeline data
    loadPipelines();
  }, []);

  const deletePipeline = (id: number) => {
    axios
      .delete(
        getFullUrl(`/api/pipeline/${id}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then(() => {
        const updatedPipelineList = pipeline.filter(
          (pipeline) => pipeline.id !== id
        );

        var mypipelines = updatedPipelineList.filter(
            (x) => x.pipelineUserRole == "Admin"
          ),
          sharedWithMe = updatedPipelineList.filter(
            (x) => x.pipelineUserRole !== "Admin"
          );

        setMyPipelines(mypipelines);
        setSharedPipeline(sharedWithMe);

        setPipelines(updatedPipelineList);
        setControlRecord(updatedPipelineList);
        setTotalrecords(updatedPipelineList.length);

        growl.current.show({
          severity: "success",
          summary: "Pipeline has been deleted",
        });
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error loading market preferences",
        });
      });
  };

  const defaultButton = useRef<HTMLButtonElement>(null);
  const myButton = useRef<HTMLButtonElement>(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isMy = urlParams.get("my");

  useEffect(() => {
    if (defaultButton && defaultButton.current && !isMy) {
      defaultButton.current.focus();
    }
  }, [defaultButton]);

  useEffect(() => {
    if (isMy) {
      setTimeout(() => {
        setSelectedTab("myPipelines");
        if (myButton.current) {
          myButton.current.focus();
        }
      }, 500);
    }
  }, []);

  return (
    <>
      <div className="p-col p-grid p-justify-center content-block">
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
          <Div>
            <button
              ref={defaultButton}
              onClick={() => setSelectedTab("allPipelines")}
            >
              <strong>All Pipelines</strong>
            </button>
            {isFree ? null : (
              <>
                <button
                  ref={myButton}
                  onClick={() => setSelectedTab("myPipelines")}
                >
                  <strong>My Pipelines</strong>
                </button>
                <button onClick={() => setSelectedTab("sharedPipelines")}>
                  <strong> Shared With Me</strong>
                </button>
              </>
            )}
          </Div>
        </div>
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
          <Div
            style={{
              display: "block",
              alignItems: "center",
              padding: "20px",
              minHeight: "80vh",
              height: "auto",
            }}
          >
            <div>
              {showAllpipelines ? (
                <PipelineTable
                  currentTab={true}
                  toggledColumns={toggleColunms}
                  totalRecords={totalRecords}
                  deletePipeline={deletePipeline}
                  headerTitle={headerTitle}
                  pipelines={pipeline}
                />
              ) : null}
              {isFree ? null : (
                <>
                  {showMyPipelines ? (
                    <PipelineTable
                      toggledColumns={toggleColunms}
                      totalRecords={totalRecords}
                      deletePipeline={deletePipeline}
                      currentTab={true}
                      headerTitle={headerTitle}
                      pipelines={myPipelines}
                    />
                  ) : null}
                  {showShared ? (
                    <PipelineTable
                      toggledColumns={toggleColunms}
                      totalRecords={totalRecords}
                      deletePipeline={deletePipeline}
                      currentTab={false}
                      headerTitle={headerTitle}
                      pipelines={sharedPipeline}
                    />
                  ) : null}
                </>
              )}
            </div>
          </Div>
        </div>
      </div>
    </>
  );
};

export default Pipeline;

const PipelineTable: FC<Props> = ({
  pipelines,
  headerTitle,
  deletePipeline,
  totalRecords,
  toggledColumns,
  currentTab,
}) => {
  const context = React.useContext(UserContext);
  const [keyword, setKeyword] = React.useState("");
  const header = createAuthenticatedRequest(context);
  const growl = React.useContext(GrowlContext);
  const [togglePrivacyTab, setTogglePrivacyTab] = React.useState(false);
  const [togglesidebarBtn, settogglesidebarBtn] = React.useState(false);
  const [siderBarHeader, setSidebarHeader] = React.useState("Add Pipeline");
  const styles = useGridStyles();
  const [first, setFirst] = React.useState(0);
  const history = useHistory();
  const { user } = context;

  const goTopipeline = (pipeline: PipelineDto) => {
    if (isFree) {
      growl.current.show({
        severity: "error",
        summary: (
          <p>
            You don't have access to this feature with your current subscription
            plan, <a href="/tiers"> Upgrade</a> to view pipeline details
          </p>
        ),
        life: 10000,
        closable: false,
      });
    } else {
      history.push(`pipeline/${pipeline.id}`, pipeline);
    }
  };

  const initialize = {} as PipelineDto;
  initialize.name = "";
  initialize.id = 0;
  initialize.users = [];
  initialize.businessForecastCustomColumnName = "";
  initialize.contractCustomColumnName = "";
  initialize.contractCustomColumnName = "";
  initialize.description = "";
  initialize.createdOnUtc = "";
  initialize.pipelineUserRole = "";
  initialize.marketPreferences = [];
  initialize.totalOpportunities = 0;
  initialize.grantCount = 0;
  initialize.contractCount = 0;
  initialize.forecastCount = 0;

  const [currentRowData, setCurrentRowData] =
    React.useState<PipelineDto>(initialize);

  //  sidebar
  const [title, setTitle] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [companyUsers, setCompanyUsers] = React.useState<User[]>([]);
  const [selected, setSelected] = React.useState({});
  const popupMenu = useRef<OverlayPanel>(null);
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const setFields = (field, value) => {
    setSelected({
      ...selected,
      [field]: value,
    });
  };
  // const {users} = currentRowData
  const [pipeusers, setPipelineUser] = React.useState<User[]>([]);
  const [selectedCountries, setSelectedCountires] = React.useState<
    MarketPrefsItem[]
  >(Array<MarketPrefsItem>());
  const [selectedSectors, setSelectedSectors] = React.useState<
    MarketPrefsItem[]
  >(Array<MarketPrefsItem>());

  const GetSelectedCountries = (data: any) => {
    setSelectedCountires(data);
  };
  const GetSelectedSectors = (data: any) => {
    setSelectedSectors(data);
  };

  React.useEffect(() => {
    // setPipelineUser(currentRowData.users)

    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { data } = response;
        const email = user.parsedIdToken?.["cognito:username"].split("|");
        if (email) {
          var id = email[0] + "@" + email[1];
          const orgUsers = data.filter((r: User) => r.email !== id);
          setCompanyUsers(orgUsers);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const saveNewPipeline = () => {
    // Convert UserID to uppercase
    const pipelineUsers = pipeusers.map((sa) => ({
      publicUserId: sa.publicUserId.toUpperCase(),
      role: "Member",
    }));
    if (title == "") {
      growl.current.show({
        severity: "error",
        summary: "Title required",
      });
      return;
    }
    axios
      .post(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
        },
        header
      )
      .then((res) => {
        const { id } = res.data;
        //  add  related market preferences
        if (selectedCountries.length > 0) {
          addSelectedCountries(id, "Add");
        }
        if (selectedSectors.length > 0) {
          addSelectedSectors(id, "Add");
        }
        growl.current.show({
          severity: "success",
          summary: "Pipeline has been created successfully",
        });
        context.refreshUserToken(true);
      })
      .catch(() => {
        growl.current.show({
          severity: "error",
          summary: "Error saving the pipeline",
        });
      });
  };

  const editPipeline = () => {
    const pipelineUsers = pipeusers.map((sa) => {
      if (Object.keys(selected).length === 0) {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role: "Member",
        };
      } else {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role: selected[sa.firstName],
        };
      }
    });

    if (title == "") {
      growl.current.show({
        severity: "error",
        summary: "The title is required",
      });
      return;
    }
    axios
      .put(
        getFullUrl(`/api/pipeline/${currentRowData.id}`, {
          useDedicatedEnvironment: true,
        }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
        },
        header
      )
      .then(() => {
        //  cascade and edit market preference as well
        addSelectedCountries(currentRowData.id, "Add");
        addSelectedSectors(currentRowData.id, "Add");
        growl.current.show({
          severity: "success",
          summary: "Pipeline has been edited",
        });
        context.refreshUserToken(true);
      })

      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };

  const selectUsers = useCallback(async (usersToAdd: User[]) => {
    setPipelineUser(usersToAdd);
  }, []);

  const userTemplate = (option) => {
    return (
      <div key={option.publicUserId}>
        {option.firstName} {option.lastName}
      </div>
    );
  };

  const RemoveUser = (userToRemove: User, Users: User[]) => {
    const updatedUsers = Users.filter(
      (user) => user.publicUserId !== userToRemove.publicUserId
    );
    setPipelineUser(updatedUsers);
    if (companyUsers.includes(userToRemove)) {
      return;
    } else {
      setCompanyUsers([userToRemove, ...companyUsers]);
    }
  };

  const addSelectedCountries = (id: number, method: string) => {
    let body = selectedCountries.map((r) => {
      return {
        PreferenceType: PreferenceTypes.country.toString(),
        Name: r.name,
        MethodType: method,
      };
    });
    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        body,
        header
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };
  const addSelectedSectors = (id: number, method: string) => {
    let body = selectedSectors.map((r) => ({
      PreferenceType: PreferenceTypes.technicalSector.toString(),
      Name: r.name,
      MethodType: method,
    }));
    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        body,
        header
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving info",
        });
      });
  };

  // row templates
  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-sm-12 p-md-6 p-lg-4 p-xl-6 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <InputText
          type="search"
          style={{
            color: "#979797",
            fontStyle: "italic",
            borderRadius: "20px",
          }}
          onChange={(e: any) => setKeyword(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <HeaderButton
          onClick={(e: React.SyntheticEvent<Element, Event>) => {
            popupMenu.current?.toggle(e);
          }}
        >
          + Add Pipeline
        </HeaderButton>
      </div>
    </div>
  );

  const dateStringTemplate = (rowdata) => {
    let strDate = rowdata.createdOnUtc.split("T")[0];
    return <div style={{ padding: 10 }}>{strDate}</div>;
  };

  const privacyTemplate = (rowdata: PipelineDto) => {
    const p = rowdata.users.filter((r: User) => r.pipelineUserRole !== "Admin");
    let privacy: string =
      rowdata.users.length > 1
        ? "Shared with " + (rowdata.users.length - 1) + " Member(s)"
        : "Private";
    return (
      <div
        className="center-cell-content"
        style={{
          padding: 10,
        }}
      >
        <p> {privacy}</p>
      </div>
    );
  };

  const ownerTemplate = (rowdata) => {
    let owner = rowdata.users.filter((sa) => sa.pipelineUserRole === "Admin");
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        {owner.map((r, i) => (
          <p key={i} id={r.publicUserId}>
            {r.firstName}
            {""}
            {r.lastName}
          </p>
        ))}
      </div>
    );
  };

  const OpportunitiesTemplate = (r: PipelineDto) => {
    return (
      <div className="center-cell-content">
        <p style={{ textAlign: "center" }}>{r.totalOpportunities}</p>
      </div>
    );
  };
  const countriesTemplate = (rowdata: PipelineDto) => {
    const selectedCount = rowdata.marketPreferences.filter(
      (refs: MarketPreference) => refs.preferenceType == 1
    );

    return (
      <div className="countries">
        {selectedCount.length > 3
          ? selectedCount.length + " Selected.."
          : selectedCount.map((sa: Item, i) => (
              <p
                style={{ margin: "0px" }}
                className="selected-countries"
                key={i}
              >
                {sa.name}
              </p>
            ))}
      </div>
    );
  };
  const sectorsTemplate = (rowdata: PipelineDto) => {
    const selectedSect = rowdata.marketPreferences.filter(
      (refs: MarketPreference) => refs.preferenceType == 2
    );

    return (
      <div className="countries">
        {selectedSect.length > 3
          ? selectedSect.length + " Selected.."
          : selectedSect.map((sa: Item, i) => (
              <p
                style={{ margin: "4px" }}
                className="selected-countries"
                key={i}
              >
                {sa.name}
              </p>
            ))}
      </div>
    );
  };
  const tableFooter = "Showing {first} to {last} of {totalRecords} entries";
  const paginatorTemplate =
    "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";
  function searchTable(pipelineRecords: PipelineDto[]) {
    return pipelineRecords.filter(
      (pipeline) =>
        // TO DO  convert  to debounce search.
        pipeline.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        pipeline.description.toLowerCase().indexOf(keyword.toLowerCase()) >
          -1 ||
        pipeline.createdOnUtc.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    );
  }

  const getFormattedCellFromBody = function (
    fieldNames: any,
    getValueFunc: any
  ) {
    return function (row: PipelineDto) {
      return <div>{getValueFunc(row)}</div>;
    };
  };
  function getDifference(c1: User[], c2: User[]) {
    return c1.filter((sa) => {
      return !c2.some((r) => {
        return sa.publicUserId === r.publicUserId.toLowerCase();
      });
    });
  }
  const selectedRow = (
    record: PipelineDto,
    e: React.SyntheticEvent<Element, Event>
  ) => {
    // set row data
    setCurrentRowData(record);
    setPipelineUser(record.users);
    setSidebarHeader(record.name);
    setTitle(record.name);
    setDesc(record.description);
    setTogglePrivacyTab(true);
    settogglesidebarBtn(true);

    //  remove already added users from options
    setCompanyUsers(getDifference(companyUsers, record.users));
    // toggle overlay sidebar
    if (popupMenu && popupMenu.current) {
      popupMenu.current.toggle(e);
    }
  };
  const adminActionsTemplate = (r: PipelineDto) => {
    return (
      <>
        {r.pipelineUserRole !== "Admin" ? (
          <p>Shared with you</p>
        ) : (
          <div className="admin-action-buttons">
            <Button
              style={{ cursor: "pointer" }}
              className="admin-action-button"
              onClick={(e: React.SyntheticEvent<Element, Event>) => {
                // e.preventDefault()
                selectedRow(r, e);
              }}
            >
              <span className="btn-lab">Edit</span>
            </Button>
            <p onClick={() => deletePipeline(r.id)}>Delete</p>
          </div>
        )}
      </>
    );
  };

  const handleOnhide = () => {
    setSidebarHeader("Add new pipeline");
    setCurrentRowData(initialize);
    setPipelineUser(initialize.users);
    setTitle("");
    setDesc("");
    settogglesidebarBtn(false);
    setTogglePrivacyTab(false);
  };

  function toggle(event) {
    var popup = document.getElementById("overlay_panel");
    if (popup?.style.display === "none") {
      popup.style.setProperty("display", "flex", "important");
    } else {
      popup?.style.setProperty("display", "none", "important");
    }
    event.stopPropagation();
  }

  return (
    <div>
      <OverlayPanel
        onHide={handleOnhide}
        ref={popupMenu}
        showCloseIcon
        id="overlay_panel"
        style={{
          left: "none",
          right: "0px !important",
          display: "flex !important",
        }}
      >
        <div className="side-panel-container">
          <div style={{ width: "400px", height: "107vh" }}>
            <div className="side-panel-header">
              <SubHeaders text={siderBarHeader} />
            </div>
            <div className="tabview">
              <div>
                <TabView>
                  <TabPanel header="Details">
                    <div className="p-grid text-labels">
                      <div className="p-col-12" style={{ textAlign: "left" }}>
                        <div className="p-col-12">
                          <CustomLabel htmlFor="Title">Title*</CustomLabel>
                          <InputText
                            placeholder="Title"
                            className="field-inputs"
                            defaultValue={title}
                            // value={}
                            onChange={(e) => {
                              e.preventDefault();
                              setTitle((e.target as HTMLInputElement).value);
                            }}
                          />
                        </div>
                        <div className="p-col-12">
                          <CustomLabel
                            className="field-titles"
                            htmlFor="Message"
                          >
                            Description
                          </CustomLabel>
                          <InputTextarea
                            value={desc || currentRowData.description}
                            placeholder="Pipeline Description"
                            rows={5}
                            cols={40}
                            onChange={(e) =>
                              setDesc((e.target as HTMLInputElement).value)
                            }
                          />
                        </div>

                        {isFree ? null : (
                          <div className="p-col-12">
                            <CustomLabel
                              className="field-titles"
                              htmlFor="users"
                            >
                              Share With
                            </CustomLabel>

                            <MultiSelect
                              optionLabel="firstName"
                              filter={true}
                              style={customStyle.multiSelect}
                              placeholder="share with"
                              fixedPlaceholder
                              value={pipeusers}
                              options={companyUsers}
                              itemTemplate={userTemplate}
                              onChange={(e) => selectUsers(e.value)}
                            />
                            <div style={customStyle.chipsContainer}>
                              {pipeusers
                                .filter((x) => x.pipelineUserRole !== "Admin")
                                .map((sa, i) => (
                                  <Chip
                                    key={i}
                                    label={sa.firstName + " " + sa.lastName}
                                    onIconClick={() =>
                                      RemoveUser(sa, pipeusers)
                                    }
                                  />
                                ))}
                            </div>
                          </div>
                        )}

                        <div className="p-col-12">
                          <MarketPreferencesBeta
                            pipelineId={currentRowData.id}
                            GetSelectedCountries={GetSelectedCountries}
                            GetSelectedSectors={GetSelectedSectors}
                            marketPreferences={currentRowData.marketPreferences}
                          />
                        </div>
                        <div className="p-col-12">
                          <div className="pipeline-save-button">
                            {!togglesidebarBtn === true ? (
                              <>
                                <DebouncedButton
                                  as={Button}
                                  delay={5000}
                                  className="modal-button"
                                  label="Add pipeline"
                                  onClick={saveNewPipeline}
                                />
                              </>
                            ) : (
                              <DebouncedButton
                                as={Button}
                                delay={5000}
                                className="modal-button"
                                label="Save edits"
                                onClick={editPipeline}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    header="Privacy"
                    contentStyle={{ textAlign: "left" }}
                  >
                    {!togglePrivacyTab ? (
                      <div className="info"> Only available on edit mode</div>
                    ) : (
                      <>
                        <div className="field-radiobutton text-labels">
                          <RadioButton
                            inputId="private"
                            name="private"
                            checked={currentRowData.users.length <= 1}
                            value="private"
                          />
                          <label
                            style={{ paddingLeft: "5px", fontWeight: 600 }}
                            htmlFor="private"
                          >
                            Private
                          </label>
                          <p className="radio-button-info">
                            Used for personal pipeline opportunities tracking
                          </p>
                        </div>
                        <div className="field-radiobutton text-labels">
                          <RadioButton
                            inputId="public"
                            name="public"
                            checked={currentRowData.users.length > 1}
                            value="public"
                          />
                          <label
                            style={{
                              fontSize: "16px",
                              paddingLeft: "5px",
                              fontWeight: 600,
                            }}
                            htmlFor="private"
                          >
                            Shared
                          </label>
                          <p className="radio-button-info">
                            {" "}
                            Multiple users can manage pipeline opportunities
                          </p>
                        </div>
                        <div className="pipeline-user-label">
                          <label
                            style={{ fontSize: "16px", marginTop: "10px" }}
                            className="field-titles"
                            htmlFor="pipeline members"
                          >
                            Pipeline Members
                          </label>
                          <div className="shared-with-total">
                            {"Shared with " +
                              (currentRowData.users.length - 1) +
                              " Member" +
                              (currentRowData.users.length > 2 ? "s" : "")}
                          </div>
                        </div>

                        <div className="p-grid text-labels">
                          {React.Children.toArray(
                            pipeusers.map((sa: User) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="pipeline-members">
                                    {sa.pipelineUserRole === "Admin" && (
                                      <p style={{ width: "200px" }}>
                                        <strong>
                                          {sa.firstName}
                                          {"."}
                                          {sa.lastName}
                                        </strong>{" "}
                                        (You){" "}
                                      </p>
                                    )}
                                    {sa.pipelineUserRole !== "Admin" && (
                                      <p>
                                        <strong>
                                          {sa.firstName}
                                          {"."}
                                          {sa.lastName}
                                        </strong>
                                      </p>
                                    )}
                                  </div>
                                  {/* {currentRowData.pipelineUserRole !=="Admin" && currentRowData.pipelineUserRole !== sa.pipelineUserRole &&( <> */}
                                  <div className="pipeline-members">
                                    {/* <p>.</p> */}
                                    {sa.pipelineUserRole === "Admin" && (
                                      <p style={{ textAlign: "end" }}>
                                        <strong>Owner</strong>
                                      </p>
                                    )}
                                    {sa.pipelineUserRole !== "Admin" && (
                                      <div
                                        style={{
                                          minWidth: "174px",
                                          textAlign: "center",
                                        }}
                                        className="shared-with-total"
                                      >
                                        {sa.pipelineUserRole === "Admin"
                                          ? "Can Manage"
                                          : "View Only"}
                                      </div>
                                    )}
                                  </div>
                                  {sa.pipelineUserRole === "Admin" ? null : (
                                    <div
                                      onClick={() => RemoveUser(sa, pipeusers)}
                                      className="member-close-button"
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                                <Divider />
                              </>
                            ))
                          )}
                        </div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <Button
                            style={{ marginRight: "10px" }}
                            className="modal-button"
                            label="Cancel"
                            onClick={() => popupMenu.current?.hide()}
                          ></Button>
                          <DebouncedButton
                            as={Button}
                            delay={5000}
                            className="modal-button"
                            label="Save"
                            onClick={editPipeline}
                          ></DebouncedButton>
                        </div>
                      </>
                    )}
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>

      <div style={{ marginBottom: "10px" }}>
        {" "}
        <SectionHeader text={headerTitle} />
      </div>

      <DataTable
        value={searchTable(pipelines)}
        globalFilter={keyword}
        // lazy={true}
        first={first}
        scrollable={true}
        scrollHeight="400px"
        totalRecords={pipelines.length}
        header={tableHeader}
        // onPage={(e)=> setFirst(e.first)}
        rows={10}
        resizableColumns
        columnResizeMode="expand"
        paginator={true}
        // loading={isLoading}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={tableFooter}
        rowsPerPageOptions={[5, 10, 20, 25, 30]}
        className="format-table-cells"
      >
        <Column
          headerStyle={styles.headerStyle(250)}
          style={styles.columnStyle(70)}
          bodyClassName="source"
          header="Title"
          field="name"
          className="justify-left-columns"
          exportable={true}
          body={getFormattedCellFromBody({}, (r: PipelineDto) => (
            <div style={{ display: "flex" }}>
              <p
                style={{
                  padding: "10px",
                  color: "#197AB5",
                  textDecoration: "underline",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentRowData(r);
                  goTopipeline(r);
                }}
              >
                {r.name}
              </p>
            </div>
          ))}
        />

        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(350)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Description"
            field="description"
            className="cell-data"
            exportable={true}
          />
        )}

        <Column
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          header="Country"
          field="country"
          body={countriesTemplate}
          exportable={true}
        />
        <Column
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          header="Sector"
          field="sector"
          body={sectorsTemplate}
          exportable={true}
        />
        <Column
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(50)}
          bodyClassName="source"
          header="Opportunities"
          field="totalOpportunities"
          body={OpportunitiesTemplate}
          exportable={true}
        />
        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Owner"
            field="source"
            body={ownerTemplate}
            exportable={true}
          />
        )}
        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Privacy"
            body={privacyTemplate}
            exportable={true}
          />
        )}

        {toggledColumns && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(30)}
            className="center-cell-content"
            bodyClassName="source"
            header="Created at"
            field="source"
            exportable={true}
            body={dateStringTemplate}
          />
        )}

        {!isFree && (
          <Column
            headerStyle={styles.headerStyle(150)}
            header="Actions"
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
            body={adminActionsTemplate}
          />
        )}
      </DataTable>
    </div>
  );
};
