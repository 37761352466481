export const userManagementData = [
  {
    id: "0",
    organization: "Quality Software",
    userName: "john32",
    email: "a@a.a",
    tier: "Free",
    expirationDate: new Date(),
    paymentType: "21312",
    emailPreferences: "Daily",
    lastLogin: new Date(),
    actions: "",
    passwordReset: "",
    confirmAccount: false,
    freezeAccount: false
  },
  {
    id: "1",
    organization: "Quality Software",
    userName: "john32",
    email: "a@a.a",
    tier: "Free",
    expirationDate: new Date(),
    paymentType: "21312",
    emailPreferences: "Daily",
    lastLogin: new Date(),
    actions: "",
    passwordReset: "",
    confirmAccount: false,
    freezeAccount: false
  },
  {
    id: "2",
    organization: "Quality Software",
    userName: "john32",
    email: "a@a.a",
    tier: "Free",
    expirationDate: new Date(),
    paymentType: "21312",
    emailPreferences: "Daily",
    lastLogin: new Date(),
    actions: "",
    passwordReset: "",
    confirmAccount: false,
    freezeAccount: false
  }
];

export const primePredictionsData = [
  {
    id: "0",
    country: "Ukraine",
    oppTitle: "",
    techSector: "",
    dateScriptLast: new Date(),
    predictions: "R Predictions",
    suggestedPrime: "",
    link: ""
  },
  {
    id: "1",
    country: "Ukraine",
    oppTitle: "",
    techSector: "",
    dateScriptLast: new Date(),
    predictions: "R Predictions",
    suggestedPrime: "",
    link: ""
  },
  {
    id: "2",
    country: "Ukraine",
    oppTitle: "",
    techSector: "",
    dateScriptLast: new Date(),
    predictions: "R Predictions",
    suggestedPrime: "",
    link: ""
  }
];

export const lcpSuggestionData = [
  {
    id: "0",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  },
  {
    id: "01",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  },
  {
    id: "2",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  }
];

export const ieeSuggestionData = [
  {
    id: "0",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  },
  {
    id: "01",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  },
  {
    id: "2",
    file: "",
    country: "",
    oppTitle: "",
    datePosted: new Date(),
    keywords: ""
  }
];

export const apiStatusData = [
  {
    id: "0",
    site: "Sam.Gov",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  },
  {
    id: "1",
    site: "Grants.Gov",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  },
  {
    id: "2",
    site: "Business Forecast",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  },
  {
    id: "3",
    site: "USA Spending",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  },
  {
    id: "4",
    site: "Foreign Aid Explorer",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  },
  {
    id: "5",
    site: "DEC",
    lasUpdated: new Date(),
    mostRecentChange: "",
    error: ""
  }
];
