import React, { FunctionComponent, useCallback, useState } from "react";
import DashboardSection from "../DashboardSection";
import SectionHeader from "../SectionHeader";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../Chip";
import "./MarketPreferences.css";
import { useSharedStyles } from "../useSharedStyles";
import { countries, technicalSectors } from "./market_preference_configs";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import GrowlContext from "../../../services/growlContext";
import axios from "axios";

interface Item {
  name: string;
  code: string;
}

const styles = {
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  multiSelectContainer: {
    margin: "20px 10px",
    
  },
};

enum PreferenceTypes {
  country = 1,
  technicalSector = 2,
}

type MarketPreference = {
  preferenceType: PreferenceTypes;
  name: string;
};

const MarketPreferences: FunctionComponent = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedCountries, setSelectedCountries] = useState<Item[]>([]);
  const [selectedTechnicalSectors, setSelectedTechnicalSectors] = useState<
    Item[]
  >([]);
  const sharedStyles = useSharedStyles();
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  // Initial load data
  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/user/marketpreference", {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        var r: Array<MarketPreference> = response.data;
        if (r) {
          const selectedCountries = r
            .filter((x) => x.preferenceType === PreferenceTypes.country)
            .map((x) => countries.find((r) => r.name === x.name))
            .filter((x) => x) as Array<Item>;
          setSelectedCountries(selectedCountries);

          const selectedSectors = r
            .filter((x) => x.preferenceType === PreferenceTypes.technicalSector)
            .map((x) => technicalSectors.find((r) => r.name === x.name))
            .filter((x) => x) as Array<Item>;
          setSelectedTechnicalSectors(selectedSectors);
        }

        setIsLoading(false);
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error loading market preferences",
        });
      });
  }, []);

  const selectCountries = useCallback(async (countriesToAdd: Item[]) => {
    let body = countriesToAdd.map((r) => ({
      PreferenceType: PreferenceTypes.country,
      Name: r.name,
    }));

    const sendToServer = () => {
      return axios
        .post(
          getFullUrl("/api/user/marketpreference", {
            useDedicatedEnvironment: true,
          }),
          body,
          createRequestWithAuthHeaders(context)
        )
        .then(() => {
          setSelectedCountries(countriesToAdd);
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error adding country",
          });
        });
    };

    await sendToServer();
  }, []);

  const unSelectCountry = useCallback(
    async (countryToRemove: Item, selectedCountries: Item[]) => {
      let request = {
        ...createRequestWithAuthHeaders(context),
        data: {
          PreferenceType: PreferenceTypes.country,
          Name: countryToRemove.name,
        },
      };

      const sendToServer = () => {
        return axios
          .delete(
            getFullUrl("/api/user/marketpreference", {
              useDedicatedEnvironment: true,
            }),
            request
          )
          .then(() => {
            const updatedCountries = selectedCountries.filter(
              (country) => country.code !== countryToRemove.code
            );
            setSelectedCountries(updatedCountries);
          })
          .catch((message) => {
            console.error(message);
            growl.current.show({
              severity: "error",
              summary: "Error removing country",
            });
          });
      };

      await sendToServer();
    },
    []
  );

  const selectSectors = useCallback(async (sectorsToAdd: Item[]) => {
    let body = sectorsToAdd.map((r) => ({
      PreferenceType: PreferenceTypes.technicalSector,
      Name: r.name,
    }));

    const sendToServer = () => {
      return axios
        .post(
          getFullUrl("/api/user/marketpreference", {
            useDedicatedEnvironment: true,
          }),
          body,
          createRequestWithAuthHeaders(context)
        )
        .then(() => {
          setSelectedTechnicalSectors(sectorsToAdd);
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error adding sector",
          });
        });
    };

    await sendToServer();
  }, []);

  const unSelectTechnicalSector = useCallback(
    async (techSectorToRemove: Item, selectedTechnicalSectors: Item[]) => {
      let request = {
        ...createRequestWithAuthHeaders(context),
        data: {
          PreferenceType: PreferenceTypes.technicalSector,
          Name: techSectorToRemove.name,
        },
      };

      const sendToServer = () => {
        return axios
          .delete(
            getFullUrl("/api/user/marketpreference", {
              useDedicatedEnvironment: true,
            }),
            request
          )
          .then(() => {
            const updatedSectors = selectedTechnicalSectors.filter(
              (techSector) => techSector.code !== techSectorToRemove.code
            );
            setSelectedTechnicalSectors(updatedSectors);
          })
          .catch((message) => {
            console.error(message);
            growl.current.show({
              severity: "error",
              summary: "Error removing sector",
            });
          });
      };

      await sendToServer();
    },
    []
  );

  return (
    <DashboardSection style={{ height: '54.5vh', overflowY:"auto"}}>
      <SectionHeader
        text="Big AidKonekt feature update for your team!"
      />
      <div className="announcement-inner">
        <p>Dear AidKonekt Subscribers,</p>
        <p>We have been diligently working to bring you new features to accelerate your USAID partnering and make your team happier! We listened to your feedback and focused on automating daily tasks, delivering you more actionable data points, and making it easier for your team to collaborate. As always, many of these features were added in direct response to the comments we received directly from you, our subscribers, so keep sending that along. </p>
          <p>We hope you love these new features, and we’re already busy at work on more features and updates that we’ll roll out throughout the year.</p>
          <p>Click <a className="link" href="http://releases.aidkonekt.com/" target="_blank">here</a> to review what’s new on AidKonekt.</p>
        <div className="no-spaces">
          <p>Sincerely,</p>
          <p>Mike</p>
          <p>AidKonekt CEO</p>
        </div>
      </div>
    </DashboardSection>
  );
};

export default MarketPreferences;
