import React, { useContext, useEffect, useState } from "react";
import "./FAQArticle.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory, useLocation } from "react-router-dom";
import { useGridStyles } from "../../../hooks/useGridStyles";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import { FAQTab } from "../FAQ";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
} from "../../../services/UserContext";
import { Button } from "primereact/button";
import ThemeContext from "../../../configs/theme";
import axios from "axios";

const useStyles = () => {
  const theme = React.useContext(ThemeContext);

  return {
    sendButton: {
      borderRadius: 65,
      font: theme.v2Fonts.link,
      backgroundColor: theme.v2.darkBlue,
      color: "white",
      padding: "20px 60px",
    },
  };
};

const FAQArticle: React.FC = (): JSX.Element => {
  const [FAQTab, setFAQTab] = useState<FAQTab>({
    id: 0,
    section: "",
    title: "",
    link: "",
    text: "",
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const buttonStyle = useStyles();
  const styles = useGridStyles();
  const context = useContext(UserContext);
  const currentRoute = useLocation();
  const history = useHistory();

  const fetchFAQTab = () => {
    axios
      .post(
        getFullUrl("/api/faq/article/read", { useDedicatedEnvironment: true }),
        { link: currentRoute.pathname.split("/")[2] },
        createRequestWithAuthHeaders(context)
      )
      .then((r) => {
        if (!r.data) {
          // error getting page - go back to faq
          history.push("/faq");
        }
        setFAQTab(r.data);
      });
  };

  const submitArticle = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    await axios
      .post(
        getFullUrl("/api/faq/article", { useDedicatedEnvironment: true }),
        { id: FAQTab.id, text: JSON.stringify(rawContentState) },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {});
  };

  useEffect(() => {
    fetchFAQTab();
  }, []);

  useEffect(() => {
    if (FAQTab.text !== "" && FAQTab.text !== null) {
      const contentState = convertFromRaw(JSON.parse(FAQTab.text));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [FAQTab]);

  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin);
  const { section, title } = FAQTab;
  return (
    <div className="faq-article-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>FAQ and Knowledge Base</span>
        </div>
        <span className="header-description">
          A knowledge base is a published collection of documentation that
          typically includes answers to frequently asked questions, how-to
          guides, and troubleshooting instructions. Its purpose is to make it
          easy for people to find solutions to their problems without having to
          ask for help.
        </span>
      </section>

      <section className="faq-article-content">
        <h3>{section}</h3>
        <h4>{title}</h4>
        <Editor
          wrapperClassName={!isAdmin ? "disabled" : ""}
          editorState={editorState}
          readOnly={!isAdmin}
          onEditorStateChange={(editorState) => setEditorState(editorState)}
        />
        {isAdmin && (
          <div className="send-btn">
            <Button
              label="Send"
              onClick={() => submitArticle()}
              style={buttonStyle.sendButton}
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default FAQArticle;
