import React, { CSSProperties } from "react";
import "./Header.css";
import { Link, NavLink, withRouter } from "react-router-dom";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import ThemeContext, { Theme, defaultTheme } from "../../configs/theme";
import useIsAuthenticated from "../../services/authCheck";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import axios from "axios";
import Badge from '@mui/material/Badge';
import aidkonektIcon from "../../assets/AidKonectLogo.png";

const navStyle = {
  paddingTop: "10px",
  paddingBottom: "10px",
  width: "175px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignSelf: "center",
  textDecoration: "none",
};

const unselectedNavStyle: any = (theme: Theme) => ({
  ...navStyle,
  backgroundColor: theme && theme.colors ? theme.colors.offwhite : "white",
});

const navTextStyle: React.CSSProperties = {
  textAlign: "left",
  font: defaultTheme.v2Fonts.menu,
  color: defaultTheme.v2.black,
  marginBottom: "0px",
  marginTop: "0px",
  borderBottom: "2px solid transparent",
};

const lockedLinkStyle: CSSProperties = { pointerEvents: "none" };
const lockedIconStyle: CSSProperties = { position: "sticky", paddingRight: 10 };

function useNavigationLink(
  path: string,
  name: string,
  theme: Theme,
  nest: number,
  lockLink: boolean,
  hideLockOverride?: boolean
): [React.Dispatch<boolean>, JSX.Element] {
  const [selected, setSelected] = React.useState(false);

  const selectedStyle = {
    ...navStyle,
    backgroundColor: theme.v2.lightBlue,
    borderRadius: "0 22px 22px 0",
  };

  const selectedTextStyle = {
    ...navTextStyle,
    font: theme.v2Fonts.menuSelected,
    color: theme.v2.darkBlue,
  };

  const actualNest = !nest ? 0 : nest;
  const textStyle = selected ? selectedTextStyle : navTextStyle;
  let s: any = { ...textStyle, paddingLeft: 15 + actualNest * 15 };

  let linkStyle: any = selected ? selectedStyle : unselectedNavStyle(theme);

  if (lockLink) {
    linkStyle = { ...linkStyle, ...lockedLinkStyle };
  }

  if (!nest) {
    s.font = theme.v2Fonts.menuRootNode;
  }


  const component = (
    <NavLink to={path} style={{ ...linkStyle }} className="aid-nav-link">
      <p style={s}>
        {lockLink && !hideLockOverride && (
          <span style={lockedIconStyle}>
            <i className="pi pi-lock" />
          </span>
        )}
        {name}
      </p>
    </NavLink>
  );

  return [setSelected, component];
}

// const icons = {
//   logo: require("../../assets/AidKonectLogo.png").default,
// };

type RedirectLinkProps = {
  path: string;
  name: string;
  theme: Theme;
  lockLink: boolean;
};
function RedirectLink({ path, name, theme, lockLink }: RedirectLinkProps) {
  const linkStyle: any = lockLink ? lockedLinkStyle : {};
  return (
    <NavLink
      to="#"
      style={{ ...unselectedNavStyle(theme), ...linkStyle }}
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        window.open(path, "_blank");
      }}
    >
      <p style={{ ...navTextStyle, paddingLeft: 30 }}>
        {lockLink && (
          <span style={lockedIconStyle}>
            <i className="pi pi-lock" />
          </span>
        )}
        {name}
      </p>
    </NavLink>
  );
}

type NavBarProps = { history: any; location: any };
function NavBar({ history, location }: NavBarProps) {
  const context = React.useContext(UserContext);
  const theme = React.useContext(ThemeContext);

  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);

  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();
  const isLoaded = context.isLoaded;

  const [setAdmin, Admin] = useNavigationLink(
    "/admin-panel",
    "Admin",
    theme,
    0,
    false
  );
  const [setDashboard, Dashboard] = useNavigationLink(
    "/",
    "Dashboard",
    theme,
    0,
    false
  );
  const [setMyBids, MyBids] = useNavigationLink(
    "/mybids",
    "MyBids",
    theme,
    0,
    false
  );
  const [setPipelines,  Pipeline] = useNavigationLink(
    "/pipeline",
    "Pipelines",
    theme,
    0,
    false
  );
  const [,  Pipelines] = useNavigationLink(
    "/forecast",
    "USAID Bid Search",
    theme,
    0,
    false
  );
  const [setForecast, Forecast] = useNavigationLink(
    "/forecast",
    "Forecast+",
    theme,
    1,
    false
  );
  const [setGrants, Grants] = useNavigationLink(
    "/grants",
    "Grants+",
    theme,
    1,
    false
  );
  const [setContracts, Contracts] = useNavigationLink(
    "/contracts",
    "Contracts+",
    theme,
    1,
    false
  );

  const [, Insights] = useNavigationLink(
    "/projects",
    "Capture Insight",
    theme,
    0,
    !isPro,
    true
  );
  const [setDataPlus, DataPlus] = useNavigationLink(
    "/decdata",
    "Data",
    theme,
    1,
    !isPro
  );
  const [setUsaid, UsAid] = useNavigationLink(
    "/projects",
    "Projects",
    theme,
    1,
    !isPro
  );

  const [setFAQ, FAQ] = useNavigationLink("/faq", "FAQ", theme, 0, false);
  const [setResources, Resources] = useNavigationLink(
    "/aidkonekt-resources",
    "Resources",
    theme,
    1,
    false
  );

  const [setUserManagement, UserManagement] = useNavigationLink(
    "/users",
    "Users",
    theme,
    0,
    false
  );

  function setSelected(path: string) {
    setAdmin(false);
    setDashboard(false);
    setForecast(false);
    setDataPlus(false);
    setMyBids(false);
    setUserManagement(false);
    setGrants(false);
    setContracts(false);
    setUsaid(false);
    setFAQ(false);
    setResources(false);
    setPipelines(false)

    switch (path) {
      case "/":
        setDashboard(true);
        break;
      case "/admin-panel":
        setAdmin(true);
        break;
      case "/projects":
        setUsaid(true);
        break;
      case "/decdata":
        setDataPlus(true);
        break;
      case "/forecast":
        setForecast(true);
        break;
      case "/mybids":
        setMyBids(true);
        break;
      case "/users":
        setUserManagement(true);
        break;
      case "/grants":
        setGrants(true);
        break;
      case "/contracts":
        setContracts(true);
        break;
      case "/faq":
        setFAQ(true);
        break;
      case "/pipeline":
        setPipelines(true);
        break;
      case "/aidkonekt-resources":
      case "/resources":
      case "/webinars":
      case "/cdcs-repository":
      case "/lcp-archives":
      case "/iee-archives":
      case "/qa-archives":
        setResources(true);
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    setSelected(location.pathname); // initialize
    return history.listen((l: any, a: any) => {
      setSelected(l.pathname);
    });
  }, []);

  const signOut = () => {
    axios
      .post(
        getFullUrl("/api/auth/signout", { useDedicatedEnvironment: true }),
        null,
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        context
          .updateCurrentUser({
            accessToken: "",
            jwt: "",
            idToken: undefined,
            parsedIdToken: undefined,
            refreshToken: "",
            expiresIn: 0,
            groups: [],
          })
          .then(() => history.push("/auth"));
      })
      .catch(() => console.log("error signing out..."));
  };
  const color = "#979797";
  return (
    <div id="header" className="p-col">
      {isLoaded && isAuthenticated && isAdmin && Admin}
      {isLoaded && isAuthenticated && Dashboard}
   
      {isLoaded &&
        isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
        (<Badge 
          sx={{
            "& .MuiBadge-root": {
              color: '#367FB6 !important',
            }
          }} 
          >
            <span id="bids"> {MyBids}</span>
         
        </Badge>) 
      }
      {isLoaded && isAuthenticated &&(
        <Badge 
          badgeContent={"New"} color="primary"
          sx={{
            "& .MuiBadge-badge": {
              color: "#367FB6",
              backgroundColor: "#FFFFFF",
              border:"2px solid #367FB6",
              marginLeft:"-10px",
              top: "13px",
              right: "30px"
            }
          }}
          >
            {Pipeline}
        </Badge>
      )}

      {isLoaded && isAuthenticated && Pipelines}
      {isLoaded && isAuthenticated && Forecast}
      {isLoaded &&
        isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
        Contracts}
      {isLoaded &&
        isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
        Grants}

      {isLoaded && isAuthenticated && Insights}
      {isLoaded && isUserInRoles(context, UserRoleGroups.plusOrHigher) && UsAid}
      {isLoaded &&
        isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
        DataPlus}
      {isLoaded &&
        isAuthenticated &&
        isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
        Resources}
      {isAdmin && UserManagement}
      {isLoaded && isAuthenticated && FAQ}

      {/* {isLoaded && isAuthenticated && (
        <div
          style={{ paddingLeft: "15px", display: "flex", cursor: "pointer" }}
          onClick={signOut}
        >
          <p
            className="sign-out-btn"
            style={{
              font: theme.v2Fonts.menu,
              color: theme.v2.darkBlue,
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="pi pi-sign-out" />
            <span>&nbsp;&nbsp;Sign Out</span>
          </p>
        </div>
      )} */}
    </div>
  );
}

export function Header() {
  return (
    <Link to="/">
      <img style={styles.logo} src={aidkonektIcon} alt="logo" />
    </Link>
  );
}

const styles = {
  logo: {
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 40,
  },
};

export default withRouter(NavBar);
