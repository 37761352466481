import * as React from "react";
import { transpile } from "typescript";
import ThemeContext from "../configs/theme";

export const useGridStyles = () => {
  const theme = React.useContext(ThemeContext);

  const gridHeaderBold: React.CSSProperties = {
    font: theme.v2Fonts.contextHeader,
    color: theme.v2.lightGrey,
  };

  const gridInput: React.CSSProperties = {
    font: theme.v2Fonts.inputHeader,
    borderRadius: 25,
  };

  const keywordSearch: React.CSSProperties = {
    borderRadius: 10,
    backgroundColor: theme.v2.lightGrey,
    font: theme.v2Fonts.inputHeader,
    color: theme.v2.black,
    maxWidth: 200,
  };

  const tableHeaderButton: React.CSSProperties = {
    backgroundColor: theme.v2.lightGrey,
    color: theme.v2.black,
    font: theme.v2Fonts.inputHeader,
    borderRadius: 25,
    boxShadow: "none",
  };

  const mySuggestionsOptions = (
    selected: boolean,
    color: "blue" | "yellow"
  ): React.CSSProperties => {
    const col = color === "blue" ? theme.v2.lightBlue : theme.v2.lightYellow;
    return {
      backgroundColor: selected ? col : "transparent",
      color: theme.v2.black,
      font: theme.v2Fonts.inputHeader,
      borderRadius: 5,
      boxShadow: "none",
      borderStyle: "solid",
      borderColor: col,
    };
  };

  const mySuggestionsBadge: React.CSSProperties = {
    position: "relative",
    top: -18,
    right: 12,
    padding: "2px 6px",
    borderRadius: "50%",
    background: theme.v2.darkBlue,
    color: "white",
    fontSize: 10,
  };

  const mySuggestionsRedBadge: React.CSSProperties = {
    position: "relative",
    top: -22,
    right: 18,
    padding: "2px 6px",
    borderRadius: "50%",
    background: "red",
    color: "white",
    fontSize: 10,
  };

  const mySuggestionsTransparent: React.CSSProperties = {
    position: "relative",
    top: -22,
    right: 18,
    padding: "2px 6px",
    borderRadius: "50%",
    background: "transparent",
    color: "white",
    fontSize: 10,
  };

  const headerStyle = (width: number): React.CSSProperties => ({
    backgroundColor: theme.v2.darkestGrey,
    width: width,
    font: theme.v2Fonts.contextHeader,
    color: theme.v2.lightGrey,
  });

  const columnStyle = (width: number): React.CSSProperties => ({
    width: width,
  });

  const addRemoveButtonStyle: React.CSSProperties = {
    width: 25,
    height: 25,
    padding: 0,
    borderRadius: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  const navigateToExternalSiteButtonStyle: React.CSSProperties = {
    width: 25,
    height: 25,
    padding: 0,
    borderRadius: "none",
    backgroundColor: "transparent",
  };

  const goToExternalVersionStyle: React.CSSProperties = {
    backgroundColor: theme.v2.darkBlue,
    color: "black",
    font: "bold 10px Montserrat",
    height: 19,
    width: 19,
    marginRight: 5,
  };

  const primeColumnStyle: React.CSSProperties = {
    border: `3px solid ${theme.v2.darkBlue}`,
  };

  // Page styles
  const pageHeader: React.CSSProperties = {
    font: theme.v2Fonts.largeHeader,
    color: theme.v2.black,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  };

  const icon: React.CSSProperties = {
    width: 30,
    height: 30,
    marginRight: 5,
  };

  const iconFile: React.CSSProperties = {
    width: 25,
    marginRight: icon.margin,
  };

  const pageButton: React.CSSProperties = {
    backgroundColor: theme.v2.darkBlue,
    color: theme.v2.darkYellow,
    font: theme.v2Fonts.inputHeader,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 25,
  };

  const sectionSubheader: React.CSSProperties = {
    font: theme.v2Fonts.context,
    color: theme.v2.black,
    textAlign: "left",
    marginBottom: 15,
  };

  const centeredSectionSubheader: React.CSSProperties = {
    font: theme.v2Fonts.context,
    color: theme.v2.black,
    margin: 10,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 25,
  };

  // filterbox styles
  const filterBox: React.CSSProperties = {
    borderColor: theme.v2.lightYellow,
    borderRadius: 25,
    borderStyle: "solid",
    borderWidth: 3,
    paddingLeft: 35,
    paddingRight: 35,
    marginRight: 35,
    marginTop: 15,
  };

  const notTopFilterRow: React.CSSProperties = {
    marginTop: 5,
  };

  const fontHeader: React.CSSProperties = {
    color: "black",
  };

  const panelTopRow: React.CSSProperties = {
    position: "relative",
    top: -60,
  };

  const searchButton: React.CSSProperties = {
    backgroundColor: theme.v2.darkYellow,
    borderRadius: 65,
  };

  const searchButtonText: React.CSSProperties = {
    color: "white",
    font: theme.v2Fonts.link,
    textTransform: "none",
    padding: "0px 30px",
  };

  const searchButtonContainer: React.CSSProperties = {
    textAlign: "left",
  };

  const inputTextLabel: React.CSSProperties = {
    paddingLeft: 10,
    paddingTop: 3,
    font: theme.v2Fonts.input,
  };

  const inputBox: React.CSSProperties = {
    borderRadius: 4,
    font: theme.v2Fonts.input,
    width: "100%",
    borderColor: "hsl(0, 0%, 80%)",
    borderStyle: "solid",
    borderWidth: 1,
    height: 38,
    display: "block",
  };

  return {
    // Grid styles
    gridHeaderBold,
    gridInput,
    keywordSearch,
    tableHeaderButton,
    mySuggestionsOptions,
    mySuggestionsBadge,
    mySuggestionsRedBadge,
    mySuggestionsTransparent,
    headerStyle,
    columnStyle,
    addRemoveButtonStyle,
    navigateToExternalSiteButtonStyle,
    goToExternalVersionStyle,
    primeColumnStyle,

    // Page styles
    pageHeader,
    aidkonektIcon: require("../assets/icons/v2/icon_Globe.png")?.default,
    icon,
    iconFile,
    pageButton,
    sectionSubheader,
    centeredSectionSubheader,

    filters: {
      panelTopRow,
      filterBox,
      notTopFilterRow,
      fontHeader,
      searchButton,
      searchButtonText,
      searchButtonContainer,
      inputTextLabel,
      inputBox,
    },
    theme: theme,
  };
};
