import { useState } from "react";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import { DataTable } from "primereact/datatable";
import React from "react";
import { getSortIfDescendingFirst } from "../../../services/functions";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";
import { Column } from "primereact/column";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { Button } from "primereact/button";
import { TableProperties } from "exceljs";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
} from "../../../services/exporter";
import { DateFormat, formatDate } from "../../../utils/conversion/date-converters";

export interface RequestLogTableRecord {
  id: number;
  cognitoId: string;
  method: string;
  path: string;
  body: string;
  queryParams: string;
  createdOnUtc: Date;
  email: string;
}

export interface Page {
  pageIndex: number;
  pageSizie: number;
  totalItems: number;
  data: RequestLogTableRecord[];
}

type RequestLogTableProps = {
  isExpanded: boolean;
  gridWidth: number;
  queryParams: URLSearchParams;
};

const RequestLogTable: React.FC<RequestLogTableProps> = ({
  isExpanded,
  gridWidth,
  queryParams
}): JSX.Element => {
  //Request Log table state
  const [first, setFirst] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortField, setSortField] = useState("createdOnUtc");
  const [sortOrder, setSortOrder] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState(Array<RequestLogTableRecord>());
  const context = React.useContext(UserContext);
  const styles = useGridStyles();
  const [filters, setFilters] = React.useState(
    Array<{
      [key: string]: {
        value: string;
      };
    }>()
  );

  const [disableExcel, setDisableExcel] = React.useState(false);
  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    if (isExpanded) {
      queryData({ first, rows: rowsPerPage, sortField, sortOrder });
    }
  }, [isExpanded, queryParams]);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Request Logs</div>
      </div>
      <div
        className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader"
        style={{ display: "flex" }}
      >
        <div className="button-export">
          <Button
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "RequestLogs",
                `Aidkonekt_request_logs_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (data: RequestLogTableRecord[]): TableProperties => {
    const columns = [
      { name: "Created On (Utc)" },
      { name: "Email" },
      { name: "Cognito Id" },
      { name: "Method" },
      { name: "Path" },
      { name: "Query Parameters" },
      { name: "Request Body" }
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          //formatDate(record.createdOnUtc, DateFormat.dd_MMMM_yyyy),
          record.createdOnUtc,
          record.email,
          record.cognitoId,
          record.method,
          record.path,
          record.queryParams,
          record.body
        ]);
      }
    }

    return buildExcelTable("RequestLogsExport", columns, rows);
  };

  var getExcelData = async (): Promise<RequestLogTableRecord[]> => {
    var base = "/api/utility/requestLog";
    var q = query;
    if (q) q.set("limit", "10000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<RequestLogTableRecord>();
    await axios
      .get(getFullUrl(qu, {
        useDedicatedEnvironment: true,
      }), createAuthenticatedRequest(context))
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
    return result;
  };

  const queryData = async (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
    keyword?: string;
  }) => {
    setRowsPerPage(event.rows);
    setIsLoading(true);
    setFirst(event.first);

    const url = "/api/utility/requestLog";
    const queryString = new URLSearchParams();

    const pageIndex = event.first / rowsPerPage;
    queryString.set("limit", rowsPerPage.toString());
    queryString.set("page", pageIndex.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    for (let p of queryParams){
      queryString.set(p[0], p[1]);
    }

    setQuery(queryString);

    await axios
      .get<Page>(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { totalItems, data: newData } = response.data;

        setTotalRecords(totalItems);
        setData(newData);
      });

    setIsLoading(false);
  };

  return isExpanded && !isLoading ? (
    <DataTable
      value={data}
      style={{ marginTop: 20, width: `${gridWidth - 60}px` }}
      paginator={true}
      rows={rowsPerPage}
      totalRecords={totalRecords}
      lazy={true}
      first={first}
      onPage={queryData}
      loading={isLoading}
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={sort}
      scrollable={true}
      scrollHeight="400px"
      header={tableHeader}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      rowsPerPageOptions={rowsPerPageOptionsStandard}
    >
      <Column
        header="Created On (Utc)"
        sortable={true}
        field={"createdOnUtc"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
        // filterMatchMode="custom"
        // filterField="createdOnUtc"
      />
      <Column
        header="Email"
        sortable={true}
        field={"email"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
      <Column
        header="Cognito Id"
        sortable={true}
        field={"cognitoId"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
      <Column
        header="Method"
        sortable={true}
        field={"method"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
      <Column
        header="Path"
        sortable={true}
        field={"path"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
      <Column
        header="Query Parameters"
        sortable={true}
        field={"queryParams"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
      <Column
        header="Request Body"
        sortable={true}
        field={"body"}
        style={styles.columnStyle(150)}
        headerStyle={styles.headerStyle(150)}
        // filter={true}
      />
    </DataTable>
  ) : (
    <></>
  );
};
export default RequestLogTable;

//https://www.freakyjolly.com/react-table-tutorial/
