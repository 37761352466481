import React from "react";

export const ShowMoreText: React.FunctionComponent<{ text: string }> = ({
  text,
}) => {
  const characterCount = 150;
  let minLength =
    text.length - 1 > characterCount ? characterCount : text.length - 1;

  // if there is an <a href> in the min text that would show, show 100 more characters to be safe
  const ahrefLocation = text.indexOf("a href");
  const closingTag = text.indexOf(">", ahrefLocation);
  if (
    ahrefLocation > -1 &&
    ahrefLocation < minLength &&
    closingTag + 150 < text.length
  ) {
    minLength = closingTag + 150;
  }

  const [truncated, setTruncated] = React.useState(true);
  const [tempText, setTempText] = React.useState(text.substring(0, minLength));

  const showMessage = text.length > characterCount;

  React.useEffect(() => {
    const length = truncated ? minLength : text.length;
    setTempText(text.substr(0, length));
  }, [truncated, minLength]);

  const more = "Show more";
  const less = "Show less";
  return (
    <div>
      <div>
        <span dangerouslySetInnerHTML={{ __html: tempText }}></span>
      </div>
      <br />
      {showMessage && (
        <div>
          {truncated && (
            <a href="#" onClick={() => setTruncated(false)}>
              {more}
            </a>
          )}
          {!truncated && (
            <a href="#" onClick={() => setTruncated(true)}>
              {less}
            </a>
          )}
        </div>
      )}
    </div>
  );
};
