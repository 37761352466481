import React, { useContext, useState } from "react";
import UserContext from "../../services/UserContext";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { parseISO } from "date-fns";

import {
  ApiName,
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";

import GrowlContext from "../../services/growlContext";
import "../DataTable.css";
import "../Forecast/Forecast.css";
import getWindowDimensions from "../../services/dimensions";
import { useHistory, useLocation } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { getFullDateString, getDateString } from "../../services/formatter";
import { tryCatchServerError, useDebounce } from "../../services/functions";
import { parse } from "query-string";
import { Popover, PopoverProps } from "../../components/Popover/Popover";
import { Button as PButton } from "primereact/components/button/Button";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import { useGridStyles } from "../../hooks/useGridStyles";
import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";
import * as ActionButtons from "../../components/DataTable/ActionButtons";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";
import IconTooltip from "../../components/IconTooltip";
import Swal from "sweetalert2";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import axios from "axios";
import { removeHtmlTags } from "../../services/dataParser";
import { geographicalCodesHeader } from "../Forecast/Forecast";
import { url } from "inspector";

enum businessForecastRecordType {
  default = 0,
  newRecord,
  modifiedRecord,
  finalRecord,
}

enum myListStatus {
  default = 0,
  bid,
  noBid,
  undecided,
}

interface ServerRecord {
  forecast: {
    id: string;
    rowId: number;
    country: string;
    sector: string;
    totalEstimatedCost: string;
    awardActionType: string;
    smallBusinessSetAside: string;
    anticipatedAwardDate: string;
    anticipatedSolicitationReleaseDate: string;
    createdOn: string;
    status: businessForecastRecordType;
    changedColumns: string;
    awardTitle: string;

    awardDescription: string;
    aaPlanId: string;
    fiscalYearofAction: string;
    aaSpecialist: string;
    awardLength: string;
    eligibilityCriteria: string;
    geographicalCodes: string;
    categoryManagementContractVehicle: string;
    solicitationNumber: string;
    coCreation: string;
    location: string;
  };
  enhancements: {
    primeOrIncumbent: string;
    quarterlyCallNotes: string;
  };
  userEdits: {
    myListStatus: myListStatus;
    isMyList: boolean;
    email: string;
    notes: string;
    customColumn: string;
  };
}

const options = [
  { label: "Undecided", value: myListStatus.undecided },
  { label: "No Bid", value: myListStatus.noBid },
  { label: "Bid", value: myListStatus.bid },
];

type BasicForecastRecordEvent = {
  id: string;
  recordType: businessForecastRecordType;
  changedColumns: string;
  isMyList: boolean;
  title: string;
  createdOnDate: string;
  description: string;
  primeOrIncumbent: string;
  quarterlyCallNotes: string;
  estimatedCostRange: string;
  projectType: string;
  anticipatedReleaseDate: string;
  anticipatedAwardDate: string;
  smallBusinessSetAside: string;
  sector: string;
  country: string;

  aaPlanId: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string;
  coCreation: string;
  location: string;
};

type MyViewForecastSearch = {
  sortField: string;
  sortOrder: number;
  rowsPerPage: number;
  pageIndex: number;
  keyword: string;
};

interface MyViewForecastRecord {
  id: string;
  recordType: businessForecastRecordType;
  changedColumns: string;
  isMyList: boolean;
  title: string;
  createdOnDate: string;
  description: string;
  primeOrIncumbent: string;
  quarterlyCallNotes: string;
  estimatedCostRange: string;
  projectType: string;
  anticipatedReleaseDate: string;
  anticipatedAwardDate: string;
  smallBusinessSetAside: string;
  sector: string;
  country: string;
  myListStatus: myListStatus;
  notes: string;
  customColumn: string;
  rawRecord: ServerRecord;

  aaPlanId: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string;
  coCreation: string;
  location: string;
}

function RecordExpandedView({
  record,
  popoverProps,
}: {
  record: MyViewForecastRecord;
  popoverProps: PopoverProps;
}) {
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  const [data, setData] = React.useState(Array<BasicForecastRecordEvent>());
  const [loading, setLoading] = React.useState(false);
  let dt = React.useRef<DataTable>(null);

  const getFormattedCellFromBody = function (
    fieldNames: string[],
    getValueFunc: (r: BasicForecastRecordEvent) => any
  ) {
    return function (row: BasicForecastRecordEvent) {
      const recordType = row.recordType;
      const updatedFields = row.changedColumns
        ? row.changedColumns.split(",").map((m) => m.trim())
        : [];

      let className;

      if (
        recordType === businessForecastRecordType.newRecord ||
        recordType === businessForecastRecordType.default
      ) {
        className = "new";
      } else if (recordType === businessForecastRecordType.finalRecord) {
        className = "final";
      } else if (
        recordType !== businessForecastRecordType.modifiedRecord ||
        !fieldNames.length
      ) {
        className = "";
      } else {
        // Only modified rows here - find if this field is a modified field - or if source field
        if (
          fieldNames.find((x) => x === "Source") ||
          updatedFields.find((m) => fieldNames.find((x) => x === m))
        ) {
          className = "modified";
        } else {
          className = "";
        }
      }

      return <div className={className}>{getValueFunc(row)}</div>;
    };
  };

  React.useEffect(() => {
    setLoading(true);
    const request = createRequestWithAuthHeaders(context);
    axios
      .post(
        getFullUrl(`/api/businessforecast/MyList/${record.id}/details`, {
          useDedicatedEnvironment: true,
        }),
        null,
        request
      )
      .then((r) => {
        const { data } = r.data;
        const records: BasicForecastRecordEvent[] = (
          data as ServerRecord[]
        ).map((d) => ({
          id: d.forecast.id,
          recordType: d.forecast.status,
          changedColumns: d.forecast.changedColumns,
          country: d.forecast.country,
          createdOnDate: d.forecast.createdOn,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          description: d.forecast.awardDescription,
          sector: d.forecast.sector,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
        }));
        setData(records);
        setLoading(false);
      })
      .catch(
        tryCatchServerError((m) => {
          growl.current.show({
            severity: "error",
            summary: "Error getting details",
            detail: `Error getting the Forecast+ details for '${record.title}'`,
          });
          setLoading(false);
        })
      );
  }, []);

  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible === "ForecastInside"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
      />
      <DataTable
        value={data}
        scrollHeight="600px"
        rowClassName={(r: BasicForecastRecordEvent) => {
          const { recordType } = r;
          const baseclassName =
            recordType === businessForecastRecordType.newRecord
              ? "new-record"
              : recordType === businessForecastRecordType.modifiedRecord
              ? "modified-record"
              : recordType === businessForecastRecordType.finalRecord
              ? "final-record"
              : "new-record";
          const classNames = [baseclassName];

          if (baseclassName === "modified-record") {
            // add class for each modified column
            classNames.splice(
              1,
              0,
              ...r.changedColumns
                .split(",")
                .map((m) => "changed-column-" + m.trim().toLowerCase())
            );
          }

          return classNames.reduce(
            (prev, curr) => ({ ...prev, [curr]: true }),
            {}
          );
        }}
        ref={dt}
        scrollable={true}
      >
        <Column
          headerStyle={styles.headerStyle(188)}
          style={styles.columnStyle(188)}
          header="Changes"
          field="changedColumns"
        />
        <Column
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="source"
          header="Source"
          body={getFormattedCellFromBody(["Source"], (x) => "Forecast+")}
        />
        <Column
          field="country"
          header="Country/Office"
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="country"
          body={getFormattedCellFromBody(
            ["Location", "OperatingUnit"],
            (x) => x.country
          )}
        />

        <Column
          field="title"
          header="Title"
          sortable={true}
          headerStyle={styles.headerStyle(350)}
          style={styles.columnStyle(350)}
          bodyClassName="awardtitle"
          body={getFormattedCellFromBody(["AwardTitle"], (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ))}
        />
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          sortable={true}
          body={getFormattedCellFromBody([], (r) =>
            getFullDateString(parseISO(r.createdOnDate))
          )}
        />
        <Column
          field="description"
          header="Description"
          sortable={true}
          body={getFormattedCellFromBody(["AwardDescription"], (r) => (
            <p
              className="primeOrIncumbent"
              data-title={r.title}
              data-full-abstract={r.description}
            >
              {r.description && r.description.length > 95
                ? `${removeHtmlTags(r.description).substring(0, 95)}...`
                : r.description}
            </p>
          ))}
          headerStyle={styles.headerStyle(550)}
          style={{ ...styles.columnStyle(550), ...styles.primeColumnStyle }}
          bodyClassName="awarddescription"
        />
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          body={getFormattedCellFromBody(["Sector"], (x) => x.sector)}
          headerStyle={styles.headerStyle(250)}
          style={styles.columnStyle(250)}
          bodyClassName="sector"
        />
        <Column
          field="primeOrIncumbent"
          header="Suggested Primes/Partners"
          sortable={true}
          body={getFormattedCellFromBody(
            [],
            (r) =>
              r.primeOrIncumbent && (
                <p
                  className="primeOrIncumbent"
                  data-full-abstract={r.primeOrIncumbent}
                >
                  {r.primeOrIncumbent && r.primeOrIncumbent.length > 95
                    ? `${removeHtmlTags(r.primeOrIncumbent).substring(
                        0,
                        95
                      )}...`
                    : r.primeOrIncumbent}
                </p>
              )
          )}
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650), ...styles.primeColumnStyle }}
          bodyClassName="primeorincumbent"
        />
        <Column
          field="quarterlyCallNotes"
          header="Quarterly Forecast Q&A and Notes"
          sortable={true}
          body={getFormattedCellFromBody(
            [],
            (r) =>
              r.quarterlyCallNotes && (
                <p
                  className="primeOrIncumbent"
                  data-full-abstract={r.quarterlyCallNotes}
                >
                  {r.quarterlyCallNotes && r.quarterlyCallNotes.length > 95
                    ? `${removeHtmlTags(r.quarterlyCallNotes).substring(
                        0,
                        95
                      )}...`
                    : r.quarterlyCallNotes}
                </p>
              )
          )}
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650), ...styles.primeColumnStyle }}
          bodyClassName="primeorincumbent"
        />

        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          body={getFormattedCellFromBody(["AAPlanId"], (x) => x.aaPlanId)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaplanid"
          exportable={true}
        />

        {/**Incumbent - cannot find */}

        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          body={getFormattedCellFromBody(
            ["CategoryManagementContractVehicle"],
            (x) => x.categoryManagementContractVehicle
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          body={getFormattedCellFromBody(
            ["TotalEstimatedCost"],
            (x) => x.estimatedCostRange
          )}
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          bodyClassName="totalestimatedcost"
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AwardActionType"],
            (x) => x.projectType
          )}
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(200)}
          bodyClassName="awardactiontype"
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={getFormattedCellFromBody(["GeographicalCodes"], (x) => (
            <span
              dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}
            ></span>
          ))}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />

        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={getFormattedCellFromBody(["CoCreation"], (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.coCreation }}></span>
          ))}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AnticipatedSolicitationReleaseDate"],
            (r) => getFullDateString(parseISO(r.anticipatedReleaseDate))
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="anticipatedsolicitationreleasedate"
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={getFormattedCellFromBody(["AnticipatedAwardDate"], (r) =>
            getFullDateString(parseISO(r.anticipatedAwardDate))
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="anticipatedawarddate"
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          body={getFormattedCellFromBody(["AwardLength"], (x) => x.awardLength)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          body={getFormattedCellFromBody(
            ["SmallBusinessSetAside"],
            (x) => x.smallBusinessSetAside
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="smallbusinesssetaside"
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          body={getFormattedCellFromBody(
            ["EligibilityCriteria"],
            (x) => x.eligibilityCriteria
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          body={getFormattedCellFromBody(
            ["SolicitationNumber"],
            (x) => x.solicitationNumber
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AASpecialist"],
            (x) => x.aaSpecialist
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          body={getFormattedCellFromBody(
            ["FiscalYearofAction"],
            (x) => x.fiscalYearofAction
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          body={getFormattedCellFromBody(["Location"], (x) => x.location)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>
    </div>
  );
}

const statusRenderer = (record: MyViewForecastRecord) => {
  const match = options.find((o) => o.value === record.myListStatus);
  return match ? match.label : "";
};

const StatusDropdown = ({ rowData }: { rowData: MyViewForecastRecord }) => {
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  const handleChange = async (status: myListStatus) => {
    // set value, try to update, roll back if need be
    rowData.myListStatus = status;

    await axios
      .post(
        getFullUrl("/api/businessforecast/mylist/itemstatus", {
          useDedicatedEnvironment: true,
        }),
        {
          businessForecastId: rowData.id,
          status: status,
        },
        createRequestWithAuthHeaders(context)
      )
      .then((r) => {
        // success
        if (growl)
          growl.current.show({
            severity: "success",
            summary: "Status Updated",
            detail: `Updated '${rowData.title}' to ${statusRenderer(rowData)}`,
          });
      })
      .catch((r) => {
        // error - roll back
        rowData.myListStatus = status;
        console.error(r);
        if (growl)
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: `Error updating status`,
          });
      });
  };

  const dropdown = React.useRef<Dropdown>();

  return (
    <div>
      <Dropdown
        style={{ width: "100%" }}
        className="mybids-forecast-dropdown"
        ref={(e) => {
          if (e) dropdown.current = e;
        }}
        onFocus={(e) => {
          if (dropdown.current) {
            (dropdown.current as any).showOverlay();
          }
        }}
        options={options}
        onChange={(e) => handleChange(e.value)}
        value={rowData.myListStatus}
      />
    </div>
  );
};

export function MyBids({id, isPipelineScreen}) {
  const context = useContext(UserContext);
  const styles = useGridStyles();

  const history = useHistory();
  const location = useLocation();

  let initialFilterTxt = "";
  if (location && location.search) {
    initialFilterTxt = (parse(location.search).initialFilter as string) || "";
  }
  const path = history.location.pathname
  const [data, setData] = useState(Array<MyViewForecastRecord>());
  // const [allData, setAllData] = useState(Array<MyViewForecastRecord>());
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [keyword, setKeyword] = React.useState(initialFilterTxt);

  const [expandedRows, setExpandedRows] = React.useState(Array<any>());
  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: string; note: string }>()
  );
  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: string; customColumn: string }>()
  );
  const [baseUrl,setBaseUrl] = React.useState("")  
  const [notesCollapsed, setNotesCollapsed] = React.useState(true);
  const [customColumnCollapsed, setCustomColumnCollapsed] =
    React.useState(true);
  const [customColumnName, setCustomColumnName] = React.useState("");
  const growl = React.useContext(GrowlContext);
  const [gridWidth, setGridWidth] = React.useState(0);

  React.useEffect(() => {
    queryData({ first: first, rows: rowsPerPage, keyword });

    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const debouncedSearch = useDebounce(keyword, 750);

  React.useEffect(() => {
    queryData({
      first,
      rows: rowsPerPage,
      sort: { sortField, sortOrder },
      keyword,
    });
  }, [debouncedSearch]);

  const [queryDto, setQueryDto] = React.useState<MyViewForecastSearch>();

  function sort(event: { sortField: string; sortOrder: number }) {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    queryData({ first, rows: rowsPerPage, sort: { ...event }, keyword });
  }

  const queryData = (event: {
    first: number;
    rows: number;
    sort?: { sortField: string; sortOrder: number };
    keyword: string;
  }) => {
    setIsLoading(true);

    // Since this table is being reused in my bids 
    // We switch url depending on the current path.

    let url = "/api/businessforecast/mylist";

    switch (path) {
      case `/pipeline/${id}`:

      url=`/api/pipeline/${id}/forecast`
      setBaseUrl(url)
        break
      case '/mybids':
        setBaseUrl(url)
        break
      default:
        break;
    } 
    setRowsPerPage(event.rows);
    setFirst(event.first);

    const dto: MyViewForecastSearch = {
      sortField: event.sort ? event.sort.sortField : sortField,
      sortOrder: event.sort ? event.sort.sortOrder : sortOrder,
      rowsPerPage: event.rows, // need to send rows since rowsPerPage may not be updated yet
      pageIndex: event.first / event.rows,
      keyword: event.keyword,
    };
    const queryString = new URLSearchParams();

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sort?.sortField || sortField);
    queryString.set("sortOrder", (event.sort?.sortOrder || sortOrder).toString());

    const filterToUse = event.keyword !== undefined ? event.keyword : keyword;
    if (filterToUse) {
      queryString.set("filter", filterToUse);
    }

    setQueryDto(dto);

    //  Temporary  pending migration
    //  TO  DO : remove 
     switch (path) {
      case `/pipeline/${id}`:
        axios
        .get(
          getFullUrl(`${url}?${queryString.toString()}`, {
            useDedicatedEnvironment: true,
          }),
          // dto,
          createRequestWithAuthHeaders(context)
        )
        .then((response) => {
         
          var r: {
            lastUpdatedUtc: string;
            totalItems: number;
            data: ServerRecord[];
            attributes: any;
          } = response.data;
          const { lastUpdatedUtc, data, totalItems, attributes } = r;
          setTotalRecords(totalItems);
          setCustomColumnName(attributes ? attributes.CustomColumnName : "");
          setLastUpdated(
            lastUpdated
              ? `Last updated ${new Date(lastUpdated + "Z").toLocaleString()}`
              : ""
          );
          setOriginalNotes(
            data.map((r) => ({ id: r.forecast.id, note: r.userEdits.notes }))
          );
          setOriginalColumns(
            data.map((r) => ({
              id: r.forecast.id,
              customColumn: r.userEdits.customColumn,
            }))
          );
          const records: MyViewForecastRecord[] = data.map((d) => ({
            id: d.forecast.id,
            recordType: d.forecast.status,
            changedColumns: d.forecast.changedColumns,
            isMyList: d.userEdits.isMyList,
            title: d.forecast.awardTitle,
            createdOnDate: d.forecast.createdOn,
            description: d.forecast.awardDescription,
            primeOrIncumbent: d.enhancements.primeOrIncumbent,
            quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
            estimatedCostRange: d.forecast.totalEstimatedCost,
            projectType: d.forecast.awardActionType,
            anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
            anticipatedAwardDate: d.forecast.anticipatedAwardDate,
            smallBusinessSetAside: d.forecast.smallBusinessSetAside,
            sector: d.forecast.sector,
            country: d.forecast.country,
            myListStatus: d.userEdits.myListStatus,
            notes: d.userEdits.notes || "",
            customColumn: d.userEdits.customColumn || "",
            rawRecord: d,
  
            aaPlanId: d.forecast.aaPlanId,
            fiscalYearofAction: d.forecast.fiscalYearofAction,
            aaSpecialist: d.forecast.aaSpecialist,
            awardLength: d.forecast.awardLength,
            eligibilityCriteria: d.forecast.eligibilityCriteria,
            geographicalCodes: d.forecast.geographicalCodes,
            categoryManagementContractVehicle:
              d.forecast.categoryManagementContractVehicle,
            solicitationNumber: d.forecast.solicitationNumber,
            coCreation: d.forecast.coCreation,
            location: d.forecast.location,
          }));
          setData(records);
          // setAllData(records);
          setIsLoading(false);
        })
        .catch(
          tryCatchServerError((m) => {
            growl.current.show({
              severity: "error",
              sticky: true,
              summary: "Export quota reached",
              detail: m,
            });
            setIsLoading(false);
          })
        );
        break
      default:
        axios
        .post(
          getFullUrl(url, {
            useDedicatedEnvironment: true,
          }),
          dto,
          createRequestWithAuthHeaders(context)
        )
        .then((response) => {
         
          var r: {
            lastUpdatedUtc: string;
            totalItems: number;
            data: ServerRecord[];
            attributes: any;
          } = response.data;
          const { lastUpdatedUtc, data, totalItems, attributes } = r;
          setTotalRecords(totalItems);
          setCustomColumnName(attributes ? attributes.CustomColumnName : "");
          setLastUpdated(
            lastUpdated
              ? `Last updated ${new Date(lastUpdated + "Z").toLocaleString()}`
              : ""
          );
          setOriginalNotes(
            data.map((r) => ({ id: r.forecast.id, note: r.userEdits.notes }))
          );
          setOriginalColumns(
            data.map((r) => ({
              id: r.forecast.id,
              customColumn: r.userEdits.customColumn,
            }))
          );
          const records: MyViewForecastRecord[] = data.map((d) => ({
            id: d.forecast.id,
            recordType: d.forecast.status,
            changedColumns: d.forecast.changedColumns,
            isMyList: d.userEdits.isMyList,
            title: d.forecast.awardTitle,
            createdOnDate: d.forecast.createdOn,
            description: d.forecast.awardDescription,
            primeOrIncumbent: d.enhancements.primeOrIncumbent,
            quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
            estimatedCostRange: d.forecast.totalEstimatedCost,
            projectType: d.forecast.awardActionType,
            anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
            anticipatedAwardDate: d.forecast.anticipatedAwardDate,
            smallBusinessSetAside: d.forecast.smallBusinessSetAside,
            sector: d.forecast.sector,
            country: d.forecast.country,
            myListStatus: d.userEdits.myListStatus,
            notes: d.userEdits.notes || "",
            customColumn: d.userEdits.customColumn || "",
            rawRecord: d,
  
            aaPlanId: d.forecast.aaPlanId,
            fiscalYearofAction: d.forecast.fiscalYearofAction,
            aaSpecialist: d.forecast.aaSpecialist,
            awardLength: d.forecast.awardLength,
            eligibilityCriteria: d.forecast.eligibilityCriteria,
            geographicalCodes: d.forecast.geographicalCodes,
            categoryManagementContractVehicle:
              d.forecast.categoryManagementContractVehicle,
            solicitationNumber: d.forecast.solicitationNumber,
            coCreation: d.forecast.coCreation,
            location: d.forecast.location,
          }));
          setData(records);
          // setAllData(records);
          setIsLoading(false);
        })
        .catch(
          tryCatchServerError((m) => {
            growl.current.show({
              severity: "error",
              sticky: true,
              summary: "Export quota reached",
              detail: m,
            });
            setIsLoading(false);
          })
        );
        break;
    } 
  };

  let dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const [exportVisible, setExportVisible] = React.useState(false);

  const [disableExcel, setDisableExcel] = React.useState(false);

  var getExcelData = async (): Promise<MyViewForecastRecord[]> => {
    var base = baseUrl
    var q = queryDto;
   
    if (q) q.rowsPerPage = 1000; 

    var qu = q ? base + "?" + "rowsPerPage=" + q.rowsPerPage : base;
    var result = Array<MyViewForecastRecord>();

    // TO DO : Clean after full migration
    switch (path) {
      case `/pipeline/${id}`:
      await axios
      .get(
        getFullUrl(qu, {
          useDedicatedEnvironment: true,
        }),
        // q, TO DO requires post for the end point.
        createRequestWithAuthHeaders(context)
      )
      .then((response) => {
        var data = response.data.data;
        var records: MyViewForecastRecord[] = data.map((d) => ({
          id: d.forecast.id,
          recordType: d.forecast.status,
          changedColumns: d.forecast.changedColumns,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          createdOnDate: d.forecast.createdOn,
          description: d.forecast.awardDescription,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          sector: d.forecast.sector,
          country: d.forecast.country,
          myListStatus: d.userEdits.myListStatus,
          notes: d.userEdits.notes || "",
          customColumn: d.userEdits.customColumn || "",
          rawRecord: d,
          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
        }));
        result = records;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
      return result;
      default:
        await axios
        .post(
          getFullUrl(base, {
            useDedicatedEnvironment: true,
          }),
          q,// TO DO requires post for the end point.
          createRequestWithAuthHeaders(context)
        )
        .then((response) => {
          var data = response.data.data;
          var records: MyViewForecastRecord[] = data.map((d) => ({
            id: d.forecast.id,
            recordType: d.forecast.status,
            changedColumns: d.forecast.changedColumns,
            isMyList: d.userEdits.isMyList,
            title: d.forecast.awardTitle,
            createdOnDate: d.forecast.createdOn,
            description: d.forecast.awardDescription,
            primeOrIncumbent: d.enhancements.primeOrIncumbent,
            quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
            estimatedCostRange: d.forecast.totalEstimatedCost,
            projectType: d.forecast.awardActionType,
            anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
            anticipatedAwardDate: d.forecast.anticipatedAwardDate,
            smallBusinessSetAside: d.forecast.smallBusinessSetAside,
            sector: d.forecast.sector,
            country: d.forecast.country,
            myListStatus: d.userEdits.myListStatus,
            notes: d.userEdits.notes || "",
            customColumn: d.userEdits.customColumn || "",
            rawRecord: d,
            aaPlanId: d.forecast.aaPlanId,
            fiscalYearofAction: d.forecast.fiscalYearofAction,
            aaSpecialist: d.forecast.aaSpecialist,
            awardLength: d.forecast.awardLength,
            eligibilityCriteria: d.forecast.eligibilityCriteria,
            geographicalCodes: d.forecast.geographicalCodes,
            categoryManagementContractVehicle:
              d.forecast.categoryManagementContractVehicle,
            solicitationNumber: d.forecast.solicitationNumber,
            coCreation: d.forecast.coCreation,
            location: d.forecast.location,
          }));
          result = records;
        })
        .catch((error) => {
          console.log(error);
          alert("error fetching data");
        });
        return result;
    }
  };

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col topLeftColumnHeader topRightColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div className="p-col"></div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <div style={{ font: "14px Open Sans", verticalAlign: "middle" }}>
          <IconTooltip
            tooltipText={
              "Opportunities are automatically sorted by “Last Updated Date,” so you can quickly identify changes and when they were made."
            }
            className="tooltip-fixed"
          />
        </div>
        <div className="button-select">
          <PButton
            type="button"
            icon="pi pi-file"
            iconPos="left"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            label="All Forecasts"
            onClick={() => history.push("/forecast")}
          />
        </div>
        <div className="search-bar">
          <InputText
            type="search"
            style={{ ...styles.keywordSearch }}
            value={keyword}
            onChange={(e) => setKeyword((e.target as any).value)}
            placeholder="Global Search"
          />
        </div>
        <div className="button-export">
          {exportVisible && (
            <div className="button-export-popup">
              <button
                className="export-popup-option"
                onClick={() => {
                  if (dt.current) {
                    dt.current.exportCSV();
                  }
                  setExportVisible(false);
                }}
              >
                Export Current Page
              </button>
              <button
                className="export-popup-option"
                // onClick={() => {
                //   if (dt.current) {
                //     dt.current.exportCSV();
                //   }
                //   setExportVisible(false);
                // }}
                onClick={async () => {
                  setDisableExcel(true);
                  generateExcel(
                    "MyBids+",
                    `Aidkonekt_my_bids_plus_${new Date().getFullYear()}`,
                    getExportableDataTable(
                      getExcelData ? await getExcelData() : data
                    )
                  ).then(() => {
                    setDisableExcel(false);
                  });
                }}
              >
                Export Entire Pipeline
              </button>
            </div>
          )}
          <PButton
            type="button"
            icon="pi pi-file-o"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            tooltip="CSV"
            tooltipOptions={{ position: "top" }}
            onClick={() => {
              setExportVisible(!exportVisible);
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "MyBids+",
                `Aidkonekt_my_bids_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            className="aid-blu-btn"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{ ...styles.tableHeaderButton, height: "auto" }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (
    data: MyViewForecastRecord[]
  ): TableProperties => {
    const columns = [
      { name: "Notes" },
      { name: "Status" },
      { name: "Country/Office" },
      { name: "Name" },
      { name: "Type" },
      { name: "Date Record Created/Changed" },
      { name: "Sector" },
      { name: "Suggested Primes/Partners" },
      { name: "Quarterly Forecast Q&A and Notes" },
      { name: "ID" },
      { name: "Category Management Contract Vehicle" },
      { name: "Cost" },
      { name: "Award/Action Type" },
      { name: "Geographical Codes" },
      { name: "Co-creation" },
      { name: "Anticipated Release Date" },
      { name: "Anticipated Award Date" },
      { name: "Award Length" },
      { name: "Small Business Set-Aside" },
      { name: "Eligibility Criteria" },
      { name: "Solicitation Number" },
      { name: "A&A Specialist" },
      { name: "Fiscal Year of Action" },
      { name: "Location" },
      { name: "My Bids+" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.notes ? record.notes.replace(/(<([^>]+)>)/gi, "") : record.notes,
          myListStatus[record.myListStatus],
          record.country,
          record.title,
          businessForecastRecordType[record.recordType],
          record.createdOnDate,
          record.sector,
          record.primeOrIncumbent ? record.primeOrIncumbent.replace(/(<([^>]+)>)/gi, "") : record.primeOrIncumbent,
          record.quarterlyCallNotes ? record.quarterlyCallNotes.replace(/(<([^>]+)>)/gi, "") : record.quarterlyCallNotes,
          record.aaPlanId,
          record.categoryManagementContractVehicle,
          record.estimatedCostRange,
          record.projectType,
          record.geographicalCodes? record.geographicalCodes.replace(/(<([^>]+)>)/gi, "") : record.geographicalCodes,
          record.coCreation ? record.coCreation.replace(/(<([^>]+)>)/gi, "") : record.coCreation,
          record.anticipatedReleaseDate,
          record.anticipatedAwardDate,
          record.awardLength,
          record.smallBusinessSetAside,
          record.eligibilityCriteria,
          record.solicitationNumber,
          record.aaSpecialist,
          record.fiscalYearofAction,
          record.location,
          record.isMyList,
        ]);
      }
    }

    return buildExcelTable("MyBidsExport", columns, rows);
  };

  const removeRecordFromMyList = (record: MyViewForecastRecord) => {
    let request = createAuthenticatedRequest(context);
    // `/api/businessforecast/mylist/${record.id
    axios
      .delete(
        getFullUrl(`/api/pipeline/${id}/forecast/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then((r) => {
        // re-set my list status for all items with this id
        const newData = [...data.filter((r) => r.id !== record.id)];
        setTotalRecords(newData.length)
        setData(newData);
        if (growl)
          growl.current.show({
            severity: "success",
            summary: "Removed",
            detail: `Removed '${record.title}' from my list`,
          });
      })
      .catch((r) => {
        console.error(r);
        if (growl)
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: `Error removing '${record.title}' from my list`,
          });
      });
  };

  const NotesEditor = (props: any) => {
    const { rowIndex } = props;

    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].notes = value;
      setData(updatedData);
    }

    return (
      <textarea
        autoFocus
        value={data[rowIndex].notes}
        className="notesTextarea"
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const ColumnEditor = (props: any) => {
    const { rowIndex } = props;
    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].customColumn = value;
      setData(updatedData);
    }

    return (
      <InputText
        type="text"
        value={data[rowIndex].customColumn || ""}
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const submitNote = (o: any) => {
    const { rowData }: { rowIndex: number; rowData: MyViewForecastRecord } =
      o.columnProps;

    // If the note has not changed, don't re-save
    const baseNote = originalNotes.find((r) => r.id === rowData.id);
    const isNoteUnmodified = baseNote && baseNote.note === rowData.notes;
    if (isNoteUnmodified) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl("/api/businessforecast/MyList/Note", {
          useDedicatedEnvironment: true,
        }),
        { businessForecastId: rowData.id, note: rowData.notes },
        createRequestWithAuthHeaders(context)
      )
      .then((r) => {
        // Update in-memory notes to new note
        if (baseNote) {
          const updatedNotes = [...originalNotes];
          const updatedNote = updatedNotes.find((r) => r.id === baseNote.id);
          if (updatedNote) updatedNote.note = rowData.notes;
          setOriginalNotes(updatedNotes);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Note Updated",
            detail: `Finished updating notes for '${rowData.title}'`,
          });
      })
      .catch((e) => {
        console.error(`Error updating note`, e);
        if (growl && growl.current)
          growl.current.show({
            severity: "error",
            summary: "Error updating note",
            detail: `Unable to update note for '${rowData.title}'`,
          });

        // Revert note back to original note
        cancelNote(o);
      });
  };

  const submitColumn = (o: any) => {
    const { rowData }: { rowIndex: number; rowData: MyViewForecastRecord } =
      o.columnProps;

    // If the note has not changed, don't re-save
    const baseColumn = originalColumns.find((r) => r.id === rowData.id);

    const isColumnUnmodified =
      baseColumn && baseColumn.customColumn === rowData.customColumn;
    if (isColumnUnmodified) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl("/api/businessForecast/mylist/customColumn", {
          useDedicatedEnvironment: true,
        }),
        {
          businessForecastId: rowData.id,
          customColumn: rowData.customColumn,
        },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // Update in-memory notes to new note
        if (baseColumn) {
          const updatedColumns = [...originalColumns];
          const updatedColumn = updatedColumns.find(
            (r) => r.id === baseColumn.id
          );
          if (updatedColumn) updatedColumn.customColumn = rowData.customColumn;
          setOriginalColumns(updatedColumns);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Custom Column Updated",
            detail: `Finished updating custom column for '${rowData.title}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelCustomColumn(o);
        })
      );
  };

  const cancelNote = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseNote =
      (originalNotes.find((r) => r.id === rowData.id) || {}).note || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].notes = baseNote;
    setData(updatedData);
  };

  const cancelColumn = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const cancelCustomColumn = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const ActionsButtonColumn = ({
    record,
  }: {
    record: MyViewForecastRecord;
  }) => {
    // MySuggestion page
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>{remove}</div>
    );
  };

  const showEditCustomColumnName = async () => {
    const { value: formValues } = await Swal.fire({
      title: `Edit Column Name`,
      html: `<label htmlFor="columnName">Date</label>
          <input id="columnName" class="swal2-input" value="${customColumnName}"></input>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [(document.getElementById("columnName") as any).value];
      },
    });

    if (!formValues || !formValues.length) return;

    const [newColumnName] = formValues;
    await axios
      .put(
        getFullUrl("/api/utility/columnName", {
          useDedicatedEnvironment: true,
        }),
        { columnName: newColumnName, type: "businessforecast" },
        createAuthenticatedRequest(context)
      )
      .then(() => {
        setCustomColumnName(newColumnName);
      })
      .catch((e) => {
        console.error("error setting contract custom column name", e);
        growl.current.show({
          severity: "error",
          summary: "Error setting column name",
        });
      });
  };

  const notesHeader = (
    <div>
      {notesCollapsed && (
        <div>
          <Button
            icon="pi pi-chevron-right"
            onClick={() => setNotesCollapsed(false)}
          />
        </div>
      )}
      {!notesCollapsed && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ alignSelf: "center", marginRight: 10 }}>Notes</div>
          <Button
            icon="pi pi-chevron-left"
            onClick={() => setNotesCollapsed(true)}
          />
        </div>
      )}
    </div>
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");
  const [dialogExpendedVisible, setDialogExpendedVisible] =
    React.useState(false);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (
        !event.target.matches(".suggestions_description") &&
        !event.target.matches(".primeOrIncumbent") &&
        !event.target.matches(".quarterlyCallNotes")
      ) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      if (!container) {
        return;
      }

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      if (event.target.matches(".primeOrIncumbent")) {
        setDialogHeader("Suggested Primes/Partners");
      }

      if (event.target.matches(".quarterlyCallNotes")) {
        setDialogHeader("Quarterly Forecast Q&A and Notes");
      }

      if (event.target.matches(".suggestions_description")) {
        setDialogHeader(container.attributes["data-title"].value);
      }

      setDialogText(dialogText);
      if (dialogVisible !== "Forecast") {
        setDialogVisible("Forecast");
      }
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setDialogVisible("");
    };
  }, []);

  const suggestionsDescriptionColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="suggestions_description"
      data-title={r.title}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.solicitationNumber);
        setDialogText(r.description);
        setDialogVisible("Forecast");
      }}
    ></p>
  );

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible === "Forecast"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
      />

      <DataTable
        value={data}
        globalFilter={keyword}
        style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        ref={dt}
        header={tableHeader}
        exportFilename={`Aidkonekt_forecast_plus_${new Date().getFullYear()}`}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={(d: MyViewForecastRecord) => (
          <RecordExpandedView
            record={d}
            popoverProps={{
              dialogHeader,
              dialogText,
              isVisible: dialogExpendedVisible,
              setDialogVisible: setDialogExpendedVisible,
            }}
          />
        )}
      >
        <Column
          headerStyle={styles.headerStyle(40)}
          style={styles.columnStyle(40)}
          expander
        />

        {isPipelineScreen && <Column
          field="isMyList"
          header="Actions"
          sortable={true}
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          body={(r: MyViewForecastRecord) => <ActionsButtonColumn record={r} />}
        />}

        <Column
          field={notesCollapsed ? "" : "notes"}
          header={notesHeader}
          headerStyle={{
            ...styles.headerStyle(notesCollapsed ? 50 : 350),
            ...styles.primeColumnStyle,
          }}
          style={styles.columnStyle(notesCollapsed ? 50 : 350)}
          editor={notesCollapsed ? undefined : NotesEditor}
          onEditorSubmit={submitNote}
          onEditorCancel={cancelNote}
        />
        <Column
          field={customColumnCollapsed ? "" : "customColumn"}
          header={
            <div>
              {customColumnCollapsed && (
                <div>
                  <Button
                    icon="pi pi-chevron-right"
                    onClick={() => setCustomColumnCollapsed(false)}
                  />
                </div>
              )}
              {!customColumnCollapsed && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ alignSelf: "center", marginRight: 10 }}>
                    {customColumnName}
                  </div>
                  <Button
                    icon="pi pi-chevron-left"
                    style={{ marginRight: 10 }}
                    onClick={() => setCustomColumnCollapsed(true)}
                  />
                  <Button
                    icon="pi pi-pencil"
                    onClick={showEditCustomColumnName}
                  />
                </div>
              )}
            </div>
          }
          headerStyle={{
            ...styles.headerStyle(customColumnCollapsed ? 50 : 350),
            ...styles.primeColumnStyle,
          }}
          style={styles.columnStyle(customColumnCollapsed ? 50 : 350)}
          editor={customColumnCollapsed ? undefined : ColumnEditor}
          onEditorSubmit={submitColumn}
          onEditorCancel={cancelColumn}
        />
        <Column
          field="myListStatus"
          header="Status (*)"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) => statusRenderer(r)}
          editor={(r) => <StatusDropdown {...r} />}
        />
        <Column
          field="country"
          header="Country/Office"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          350,
          (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ),
          () => "Name",
          "Title",
          250,
          true,
          "title"
        )}
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.createdOnDate + "Z"))
          }
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsDescriptionColumnBody,
          (x) => x.title,
          "Description",
          250,
          true,
          "description"
        )}
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
        />
        <Column
          field="primeOrIncumbent"
          header="Suggested Primes/Partners"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.primeOrIncumbent && (
              <p
                className="primeOrIncumbent"
                data-full-abstract={r.primeOrIncumbent}
              >
                {r.primeOrIncumbent && r.primeOrIncumbent.length > 95
                  ? `${removeHtmlTags(r.primeOrIncumbent).substring(0, 95)}...`
                  : r.primeOrIncumbent}
              </p>
            )
          }
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
          className="primeOrIncumbent"
        />
        <Column
          field="quarterlyCallNotes"
          header="Quarterly Forecast Q&A and Notes"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.quarterlyCallNotes && (
              <p
                className="quarterlyCallNotes"
                data-full-abstract={r.quarterlyCallNotes}
              >
                {r.quarterlyCallNotes && r.quarterlyCallNotes.length > 100
                  ? `${removeHtmlTags(r.quarterlyCallNotes).substring(
                      0,
                      100
                    )}...`
                  : r.quarterlyCallNotes}
              </p>
            )
          }
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
          className="quarterlyCallNotes"
        />

        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaplanid"
          exportable={true}
        />
        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          headerStyle={styles.headerStyle(100)}
          style={{ ...styles.columnStyle(100) }}
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          headerStyle={styles.headerStyle(200)}
          style={{ ...styles.columnStyle(200) }}
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={(x) => (
            <span
              dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}
            ></span>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />
        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={(x) => (
            <span dangerouslySetInnerHTML={{ __html: x.coCreation }}></span>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedReleaseDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedAwardDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>
    </div>
  );
}

export function MySuggestionsBusinessForecast({decrement,isRemove,id,isPipelineScreen}: {
  decrement: () => void;
  isRemove: boolean;
  id:number
  isPipelineScreen?:boolean
}) {
  const context = useContext(UserContext);
  const styles = useGridStyles();
  const  history = useHistory()
  const [data, setData] = useState(Array<MyViewForecastRecord>());
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [baseUrl,setBaseUrl] = React.useState("")
  const growl = React.useContext(GrowlContext);
  const [gridWidth, setGridWidth] = React.useState(0);
 
  const path = history.location.pathname

  React.useEffect(() => {
   
    queryData({ first: first, rows: rowsPerPage });

    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    queryData({ first, rows: rowsPerPage, sort: { ...event } });
  }
  
  const queryData = (event: {
    first: number;
    rows: number;
    sort?: { sortField: string; sortOrder: number };
  }) => {
    setIsLoading(true);
    //  switch paths depending on where the tables is rendered.

    let url = "/api/businessforecast/mysuggestions";
    switch (path) {
      case `/pipeline/${id}`:
      url=`/api/pipeline/${id}/forecast/suggestion`
        break;
      case `/mybids`:
      setBaseUrl(url);
      break  
      default:
        break;
    } 
    setRowsPerPage(event.rows);
    setFirst(event.first);
    // 
    axios
      .post(
        // TO DO : 
        // "/api/businessforecast/mysuggestions"
        // `/api/pipeline/${id}/forecast/suggestion`
        getFullUrl(url, {
          useDedicatedEnvironment: true,
        }),
        {
          sortField: event.sort ? event.sort.sortField : sortField,
          sortOrder: event.sort ? event.sort.sortOrder : sortOrder,
          rowsPerPage: event.rows, // need to send rows since rowsPerPage may not be updated yet
          pageIndex: event.first / event.rows,
        },
        createRequestWithAuthHeaders(context)
      )
      .then((response) => {
        var r: {
          lastUpdatedUtc: string;
          totalItems: number;
          data: ServerRecord[];
        } = response.data;
        const { data, totalItems } = r;
        setTotalRecords(totalItems);

        const records: MyViewForecastRecord[] = data.map((d) => ({
          id: d.forecast.id,
          recordType: d.forecast.status,
          changedColumns: d.forecast.changedColumns,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          createdOnDate: d.forecast.createdOn,
          description: d.forecast.awardDescription,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          sector: d.forecast.sector,
          country: d.forecast.country,
          myListStatus: myListStatus.default, // dont include in my suggestions
          notes: "", // dont include in my suggestions
          customColumn: "", // dont include in my suggestions
          rawRecord: d,

          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
        }));
        setData(records);
        setIsLoading(false);
      })
      .catch(
        tryCatchServerError((m) => {
          growl.current.show({
            severity: "error",
            sticky: true,
            summary: "Export quota reached",
            detail: m,
          });
          setIsLoading(false);
        })
      );
  };

  let dt = React.useRef<DataTable>(null);

  const addMySuggestion = (record: MyViewForecastRecord) => {
    let request = createRequestWithAuthHeaders(context);
    axios
      .post(
        getFullUrl(
          `/api/businessforecast/mylist/${record.rawRecord.forecast.id}`,
          { useDedicatedEnvironment: true }
        ),
        null,
        request
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added forecast '${record.title}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  React.useEffect(() => {
    if (isRemove) {
      removeAllMySuggestions();
    }
  }, [isRemove]);

  const removeAllMySuggestions = () => {
    let request = createAuthenticatedRequest(context);
    axios
      .delete(
        getFullUrl(id ? `/api/pipeline/${id}/forecast/suggestion` : `/api/businessforecast/mysuggestions`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then(() => {
        setData([]);
        setTotalRecords(0);
        setRowsPerPage(0);
        decrement();
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const removeMySuggestion = (record: MyViewForecastRecord) => {
    let request = createAuthenticatedRequest(context);
    axios
      .delete(
        getFullUrl(
          `/api/businessforecast/mysuggestions/${record.rawRecord.forecast.id}`,
          {
            useDedicatedEnvironment: true,
          }
        ),
        request
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        growl.current.show({
          severity: "success",
          summary: "Ignored",
          detail: `Ignored forecast '${record.title}'`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const isMyListColumnHeader = (
    <>
      <IconTooltip
        tooltipText={
          "Click “+” to add the opportunity to your relevant pipeline below. The MyBids page will regularly check for updates " +
          "and will note changes to your opportunities in your AidKonekt email. If you click “-” to ignore, " +
          "the opportunity will be removed from this queue, but you will still be able to search for it under the relevant “Pipelines” subsection."
        }
      />
      &nbsp;
      <span>Add/Ignore</span>
    </>
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  React.useEffect(() => {
    function onDescriptionClick(event: any) {
      if (!event.target.matches(".forecast_description")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    }
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  const suggestionsDescriptionColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.description);
        setDialogVisible(true);
      }}
    ></p>
  );

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />

      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 60}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        ref={dt}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
      >
        {isPipelineScreen && <Column
          field="isMyList"
          header={isMyListColumnHeader}
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(100)}
          body={(r: MyViewForecastRecord) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButtons.AddToMyListButton 
              add={() => addMySuggestion(r)} />
              <ActionButtons.RemoveFromMyListButton
                remove={() => removeMySuggestion(r)}
              />
            </div>
          )}
        />}
        <Column
          field="country"
          header="Country/Office"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150), wordBreak: "break-word" }}
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          350,
          (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ),
          () => "Name",
          "Title",
          250,
          true,
          "title"
        )}
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.createdOnDate + "Z"))
          }
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsDescriptionColumnBody,
          (x) => x.title,
          "Description",
          250,
          true,
          "description"
        )}
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
        />
        <Column
          field="primeOrIncumbent"
          header="Suggested Primes/Partners"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.primeOrIncumbent && (
              <span
                dangerouslySetInnerHTML={{ __html: r.primeOrIncumbent }}
              ></span>
            )
          }
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
        />
        <Column
          field="quarterlyCallNotes"
          header="Quarterly Forecast Q&A and Notes"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.quarterlyCallNotes && (
              <span
                dangerouslySetInnerHTML={{ __html: r.quarterlyCallNotes }}
              ></span>
            )
          }
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
        />

        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaplanid"
          exportable={true}
        />
        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          headerStyle={styles.headerStyle(100)}
          style={{ ...styles.columnStyle(100) }}
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          headerStyle={styles.headerStyle(200)}
          style={{ ...styles.columnStyle(200) }}
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={(x) => (
            <p dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}></p>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />
        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={(x) => (
            <p dangerouslySetInnerHTML={{ __html: x.coCreation }}></p>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedReleaseDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedAwardDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>
    </div>
  );
}
