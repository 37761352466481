import * as React from "react";
import UserContext, {
  convertRoleToFriendlyName,
  ServerOrgRoles,
  UserContextType,
} from "../../services/UserContext";
import getWindowDimensions from "../../services/dimensions";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";

import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getEmailerFullUrl,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { useDebounce } from "../../services/functions";

import "./UserManagementScreen.css";
import { ProgressSpinner } from "primereact/progressspinner";
import GrowlContext from "../../services/growlContext";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import axios from "axios";

const headerStyle = (width: number) => ({
  backgroundColor: "rgb(216, 216, 216)",
  width: `${width}px`,
});
const columnStyle = (width: number) => ({ width: `${width}px` });

type GroupDto = {
  name: ServerOrgRoles;
  expiration?: Date;
};

type GroupState = { name: ServerOrgRoles; expiration: string };

type UserDto = {
  email: string;
  username: string;
  enabled: boolean;
  createdUtc: Date;
  updatedUtc: Date;
  groups: GroupDto[];
};

const groupOptions = [
  ServerOrgRoles.free,
  ServerOrgRoles.plus,
  ServerOrgRoles.pro,
  ServerOrgRoles.semiAdmin,
  ServerOrgRoles.EditPrimesAndQuarterlyCallInfo,
  ServerOrgRoles.EditUserNotifications,
  ServerOrgRoles.EditCalendarEvents,
  ServerOrgRoles.admin,
].map((r) => ({
  value: r,
  label: convertRoleToFriendlyName(r),
}));

const UserRowExpanded = ({
  user,
  context,
}: {
  user: UserDto;
  context: UserContextType;
}) => {
  const [groups, setGroups] = React.useState(Array<GroupState>());
  const [loading, setLoading] = React.useState(true);
  const [enabled, setEnabled] = React.useState(user.enabled);

  const growl = React.useContext(GrowlContext);

  React.useEffect(() => {
    const url = `/api/admin/user?email=${encodeURIComponent(user.email)}`;
    let request = createAuthenticatedRequest(context);
    axios
      .get(getFullUrl(url, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        var { groups }: { groups: Array<GroupDto> } = response.data;
        console.log(response);

        // parse groups dates out
        const formattedGroups: Array<GroupState> = groups.map((g) => {
          let expiration: string = "";
          if (g.expiration) {
            let expiry = new Date(g.expiration).toISOString();
            expiration = `${expiry.substring(5, 7)}/${expiry.substring(
              8,
              10
            )}/${expiry.substring(0, 4)}`;
          }
          return {
            name: g.name,
            expiration: expiration,
          };
        });

        // Add new record to group
        formattedGroups.push({
          name: "" as any,
          expiration: "",
        });

        setGroups(formattedGroups);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setLoading(false);
      });
  }, []);

  const saveRecord = () => {
    const groupsToSave = groups.filter((g) => g.name);

    // Safety check on expiration dates first
    const invalidGroup = groupsToSave.find((g) => {
      // plus/pro check date is valid
      if (g.name === ServerOrgRoles.plus || g.name === ServerOrgRoles.pro) {
        const date = new Date(g.expiration).toString();
        if (date === "Invalid Date") {
          return true;
        }
      }

      // other roles (admin, semi admin) no expiration
      return false;
    });

    if (invalidGroup) {
      if (growl && growl.current)
        growl.current.show({
          severity: "error",
          summary: `Unable to set expiration for ${invalidGroup.name}`,
          detail: `please enter a valid expiration date for group '${invalidGroup.name}'`,
        });
      return;
    }

    // can send save
    const url = "/api/admin/user";
    let request = createAuthenticatedRequest(context);

    setLoading(true);
    axios
      .post(
        getFullUrl(url, { useDedicatedEnvironment: true }),
        {
          email: user.email,
          groups: groupsToSave,
          enabled: enabled,
        },
        request
      )
      .then((r) => {
        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Saved user!",
            detail: "successfully saved user changes",
          });
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        if (growl && growl.current)
          growl.current.show({
            severity: "error",
            summary: "Error saving user",
            detail: "error saving user changes",
          });
        setLoading(false);
      });
  };

  const sendEmail = async () => {
    setLoading(true);
    const request = createRequestWithAuthHeaders(context);
    axios
      .post(
        getEmailerFullUrl("sendWeeklyForecast"),
        { user: user.email, sender: context.user.parsedIdToken?.email },
        request
      )
      .then((r) => {
        setLoading(false);
        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Weekly forecast sent!",
          });
      })
      .catch((e) => {
        console.error(e);
        if (growl && growl.current)
          growl.current.show({
            severity: "error",
            summary: "Error sending weekly forecast",
          });
        setLoading(false);
      });
  };

  return loading ? (
    <ProgressSpinner />
  ) : (
    <div className="p-fluid" style={{ textAlign: "left" }}>
      <div className="p-field">
        <label htmlFor="username">Username</label>
        <InputText
          id="username"
          type="text"
          value={user.username}
          readOnly={true}
        />
      </div>
      <div className="p-field">
        <label htmlFor="email">Email</label>
        <InputText id="email" type="text" value={user.email} readOnly={true} />
      </div>
      <div className="p-field">
        <label htmlFor="enabled">Enabled</label>
        <ToggleButton
          style={{ display: "block" }}
          checked={enabled}
          onChange={(e) => setEnabled(e.value)}
        />
      </div>
      <div className="p-field">
        <label htmlFor="sendWFEmail">Actions</label>
        <div>
          <Button
            className="no-max-width-col"
            label="Send Weekly Forecast Email"
            onClick={sendEmail}
          />
        </div>
      </div>
      <div className="p-grid" style={{ marginTop: 30 }}>
        <div className="p-col-3">Group</div>
        <div className="p-col-3">Expiration</div>
      </div>
      {groups.map((group, index) => {
        const changeExpiration = ({
          target: { value },
        }: {
          target: { value: string };
        }) => {
          const newGroups = [...groups];
          const thisGroup = newGroups.find((g) => g.name === group.name);
          if (thisGroup) {
            thisGroup.expiration = value;
          }
          setGroups(newGroups);
        };

        const changeGroup = ({ value }: { value: ServerOrgRoles }) => {
          const newGroups = [...groups];
          const thisGroup = newGroups.find((g) => g.name === group.name);
          if (thisGroup) {
            thisGroup.name = value;

            // If this is the bottom group, add another row
            if (
              newGroups.findIndex((g) => g === thisGroup) ===
              newGroups.length - 1
            ) {
              newGroups.push({
                name: "" as any,
                expiration: "",
              });
            }
          }

          setGroups(newGroups);
        };

        const deleteGroup = () => {
          const newGroups = [...groups];
          const index = newGroups.findIndex((g) => g.name === group.name);
          newGroups.splice(index, 1);
          setGroups(newGroups);
        };

        const isLastRecord = index === groups.length - 1;

        return (
          <div className="p-grid p-align-center" key={group.name}>
            {/* <div className="p-col-3">{group.name}</div> */}
            <div className="p-col-3">
              <Dropdown
                value={group.name}
                options={groupOptions}
                onChange={changeGroup}
                placeholder="Select a Group"
              />
            </div>
            <div className="p-col-3">
              <InputMask
                mask="99/99/9999"
                placeholder="99/99/9999"
                slotChar="mm/dd/yyyy"
                value={group.expiration}
                onChange={changeExpiration}
              />
            </div>
            <div className="p-col-3">
              <>
                {!isLastRecord && (
                  <Button
                    style={{ padding: "3px", height: "auto" }}
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-secondary"
                    onClick={deleteGroup}
                  ></Button>
                )}
              </>
            </div>
          </div>
        );
      })}
      <div className="p-grid">
        <div className="p-col">
          <Button
            label="Save"
            className="p-button-primary"
            onClick={saveRecord}
          />
        </div>
      </div>
    </div>
  );
};

function UserManagementScreen() {
  const context = React.useContext(UserContext);
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // State
  const [data, setData] = React.useState(Array<UserDto>());
  const [isLoading, setIsLoading] = React.useState(true);
  const [expandedRows, setExpandedRows] = React.useState(Array<any>());

  // Filters
  const [email, setEmail] = React.useState("");

  const debouncedSearch = useDebounce(email, 750);

  // Load table on page load
  React.useEffect(() => {
    // Reset page on search
    setData([]);
    queryData();
  }, [debouncedSearch]);

  function queryData() {
    let promise: Promise<any>;
    const url = "/api/admin/users";
    let request = createAuthenticatedRequest(context);
    setIsLoading(true);

    // If previous page is loaded, get the next token and use that to get the next page of data
    promise = axios.get(
      getFullUrl(`${url}?email=${email}`, { useDedicatedEnvironment: true }),
      request
    );

    promise
      .then((response) => {
        const { users }: { users: UserDto[] } = response.data;
        setData(users);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid p-align-center">
        <h2 className="p-col-8 sectionHeader">User Management</h2>
        <div className="p-col-4">
          <label htmlFor="email">Email Search</label>
          <InputText
            id="email"
            style={{ minWidth: 250 }}
            value={email}
            onChange={(e) => setEmail((e.target as any).value)}
          ></InputText>
        </div>
      </div>

      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
        rows={30}
        lazy={true}
        footer={<div className="p-grid"></div>}
        loading={isLoading}
        scrollable={true}
        scrollHeight="800px"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(d: UserDto) => (
          <UserRowExpanded user={d} context={context} />
        )}
      >
        <Column
          expander
          headerStyle={headerStyle(40)}
          style={columnStyle(40)}
        />
        <Column
          field="email"
          header="Email"
          headerStyle={headerStyle(250)}
          style={columnStyle(250)}
        />
        <Column
          field="username"
          header="Username"
          headerStyle={headerStyle(150)}
          style={columnStyle(150)}
        />
        <Column
          field="enabled"
          header="Enabled"
          body={(r: UserDto) => (r.enabled ? "Yes" : "No")}
          headerStyle={headerStyle(80)}
          style={columnStyle(80)}
        />
        <Column
          field="createdUtc"
          header="Created"
          body={(r: UserDto) => new Date(r.createdUtc).toLocaleString()}
          headerStyle={headerStyle(150)}
          style={columnStyle(150)}
        />
        <Column
          field="updatedUtc"
          header="Updated"
          body={(r: UserDto) => new Date(r.updatedUtc).toLocaleString()}
          headerStyle={headerStyle(150)}
          style={columnStyle(150)}
        />
      </DataTable>
    </div>
  );
}

export default UserManagementScreen;
