import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { withRouter } from "react-router-dom";

import AidkonektUpdates from "../components/Dashboard/AidkonektUpdates";

import UserContext from "../services/UserContext";

import { NotificationTypeEnum } from "../services/enums";

import {
  getLocalTimeZone,
} from "../services/timezone";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import GrowlContext from "../services/growlContext";
import { Weekday } from "../utils/constants/time-constants";
import {
  NotificationRecipientBackend,
} from "../utils/constants/notification-recipients";
import accountInformationReducer, {
  AccountInformationContext,
  createInitialAccountInformationState,
} from "../components/Dashboard/DashboardManagement/AccountInformation/reducer";
import emailNotificationsReducer, {
  createInitialEmailNotificationState,
} from "../components/Dashboard/DashboardManagement/EmailNotifications/reducer";
import MarketPreferences from "../components/Dashboard/MarketPreferences/MarketPreferences";
import EventsCalendar, {
  EventType,
} from "../components/Dashboard/EventsCalendar/EventsCalendar";
import axios from "axios";
import { AskAQuestionUrl, goToUrlInNewTab } from "../configs/resources";
import UserFeedback from "../components/Dashboard/UserFeedback";

type ProfileResponse = {
  events: Array<EventType>;
};
const Dashboard = () => {
  // Context
  const userContext = useContext(UserContext);
  const growl = useContext(GrowlContext);

  const [events, setEvents] = React.useState(Array<EventType>());

  const email = userContext.user.parsedIdToken?.email ?? "";

  // State
  const [accountInformationState, accountInfoDispatch] = useReducer(
    accountInformationReducer,
    createInitialAccountInformationState(
      email,
      "",
      "",
      email,
      getLocalTimeZone()
    )
  );
  // TODO: Combine reducers -> "Dashboard reducer"
  const request = createAuthenticatedRequest(userContext);

  const loadDataAsync = useCallback(() => {
    axios
      .get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        request
      )
      .then((response) => {
        // Hook back to profile info
        const {
          events,
        }: ProfileResponse = response.data;
        setEvents(events);

      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load user profile",
          });
        }
      });
  }, []);

  useEffect(loadDataAsync, [userContext]);



  const accountInformationContextValue = useMemo(() => {
    return {
      accountInformationState: accountInformationState,
      accountInformationDispatch: accountInfoDispatch,
    };
  }, [accountInformationState, accountInfoDispatch]);

 
  return (
    <AccountInformationContext.Provider value={accountInformationContextValue}>
    
       <div className="p-grid" style={{ marginRight: "20px" }}>
          <div className="p-col-12 p-md-12 p-lg-12 p-xl-7">
              <AidkonektUpdates />
          </div>
          <div className="p-col-12 p-md-12 p-lg-12 p-xl-5">
             <MarketPreferences />
             <UserFeedback />
          </div>
         
          <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
             <EventsCalendar events={events} />
          </div>
       </div>
  
    </AccountInformationContext.Provider>
  );
};

export default withRouter(Dashboard);
