import React, { useState } from "react";
import "./CDCSRepository.css";
import { useStyles } from "../../hooks/useStyles";
import UserContext, { doesUserHaveRole, ServerOrgRoles } from "../../services/UserContext";
import { createRequestWithAuthHeaders, getFullUrl } from "../../configs/axios-export.custom";
import axios from "axios";
import GrowlContext from "../../services/growlContext";
import { ProgressSpinner } from "primereact/progressspinner";

interface CDCSList {
  continent: string
  country: {
    id: number
    name: string
    link: string
  }[]
}

const CDCSRepository: React.FC = (): JSX.Element => {
  const user = React.useContext(UserContext);

  const [cdcsList, setCdcsList] = useState<CDCSList[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [newPostTitle, setNewPostTitle] = useState("");
  const [newPostDate, setNewPostDate] = useState("");
  const [newPostLink, setNewPostLink] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [linkError, setLinkError] = useState('');
  const [newPlantDate, setNewPlanDate] = useState("");
  const [newPlanLink, setNewPlanLink] = useState("");
  const [editable, setEditable] = useState(false);

  const [editCurrentPost, setEditCurrentPost] = useState({
    current: '',
    continent: '',
    name: '',
    link: ''
  })

  const [newPostNameError, setNewPostNameError] = useState(false)
  const [newPostLinkError, setNewPostLinkError] = useState('')

  const [editContinent, setEditContinent] = useState({
    current: '',
    continent: ''
  })
  const [continentError, setContinentError] = useState(false)

  const styles = useStyles();

  const [isLoading, setIsLoading] = React.useState(false)

  const isAdmin = doesUserHaveRole(user, ServerOrgRoles.admin);

  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  React.useEffect(() => {
    getPosts()
    setEditable(false);
    setNewPostTitle("");
    setNewPostDate("");
    setNewPostLink("");
  }, [])

  const getPosts = async () => {
    setIsLoading(true)
    await axios
      .get(
        getFullUrl("/api/utility/CdcResource", {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context)
      ).then((response) => {
        let mappedArray: CDCSList[] = []
        response.data.map((item) => {
          const isSameTitle = mappedArray.find((mItem) => mItem.continent === item.title)
          const isSameTitleIndex = mappedArray.findIndex((mItem) => mItem.continent === item.title)
          if (isSameTitle) {
            const isSameDescription = isSameTitle.country.find((sItem) => sItem.name === item.name)
            if (!isSameDescription) {
              mappedArray[isSameTitleIndex] = {continent: mappedArray[isSameTitleIndex].continent, country: [
                  ...mappedArray[isSameTitleIndex].country, {id: item.id, name: item.name, link: item.link}
                ]}
            }
          } else {
            mappedArray = [...mappedArray,  { continent: item.title, country: [{ id: item.id, name: item.name, link: item.link }]}]
          }
        })
        setCdcsList(mappedArray);
        setIsLoading(false)
      }).catch((e) => {
        growl.current.show({
          severity: "error",
          summary: "Error getting posts",
          detail: e,
        });
      })
  }

  const addPost = async () => {

    if (!newPostTitle) {
      setTitleError(true)
    }

    if (!newPostDate) {
      setNameError(true)
    }

    if (!newPostLink) {
      setLinkError('No link provided')
    }

    if (titleError || nameError || linkError) {
      return
    }

    if (newPostLink.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
    await axios
      .post(
        getFullUrl("/api/utility/CdcResource", {
          useDedicatedEnvironment: true,
        }),
        { title: newPostTitle, name: newPostDate, link: newPostLink },
        createRequestWithAuthHeaders(context)
      )
      .then(async () => {
        await getPosts()
        setEditable(false);
        setNewPostTitle("");
        setNewPostDate("");
        setNewPostLink("");
      }).catch((e) => {
        growl.current.show({
          severity: "error",
          summary: "Error adding new post",
          detail: e,
        });
      })
    } else {
      setLinkError('Incorrect link format')
    }
  };

  const removePost = async (recordId: number) => {
    await axios
      .delete(
        getFullUrl(`/api/utility/CdcResource/${recordId}`, {
          useDedicatedEnvironment: true,
        }),
        createRequestWithAuthHeaders(context)
      )
      .then(async () => {
        await getPosts()
        setEditable(false);
        setNewPostTitle("");
        setNewPostDate("");
        setNewPostLink("");
        setEditCurrentPost({
          current: '',
          continent: '',
          name: '',
          link: ''
        })
      }).catch((e) => {
        growl.current.show({
          severity: "error",
          summary: "Error adding new post",
          detail: e,
        });
      })
  };

  const updatePost = async (recordId: number) => {
    if (!editCurrentPost.name) {
      setNewPostNameError(true)
      return
    }

    if (!editCurrentPost.link) {
      setNewPostLinkError('No link provided')
      return
    }

    if (editCurrentPost.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
      await axios
      .put(
        getFullUrl(`/api/utility/CdcResource/${recordId}`, {
          useDedicatedEnvironment: true,
        }),
        {
          id: recordId,
          title: editCurrentPost.continent,
          name: editCurrentPost.name,
          link: editCurrentPost.link
        },
        createRequestWithAuthHeaders(context)
      )
      .then(async () => {
        await getPosts()
        setEditable(false);
        setNewPostTitle("");
        setNewPostDate("");
        setNewPostLink("");
        setEditCurrentPost({
          current: '',
          continent: '',
          name: '',
          link: ''
        })
      }).catch((e) => {
        growl.current.show({
          severity: "error",
          summary: "Error updating post",
          detail: e,
        });
      })
    } else {
      setNewPostLinkError('Incorrect link format')
      return
    }
  };

  const updateContinent = (continent: string) => {
    if (editContinent.continent) {
      const continentItems = cdcsList.find((list) => list.continent === continent);
      if (continentItems) {
        continentItems.country.forEach((country) => {
          axios
            .put(
              getFullUrl(`/api/utility/CdcResource/${country.id}`, {
                useDedicatedEnvironment: true
              }),
              {
                id: country.id,
                title: editContinent.continent,
                name: country.name,
                link: country.link
              },
              createRequestWithAuthHeaders(context)
            )
            .then(async () => {
              await getPosts();
              setEditable(false);
              setNewPostTitle("");
              setNewPostDate("");
              setNewPostLink("");
              setEditCurrentPost({
                current: "",
                continent: "",
                name: "",
                link: ""
              });
            }).catch((e) => {
            growl.current.show({
              severity: "error",
              summary: "Error updating post",
              detail: e
            });
          });
        });
        setEditContinent({
          current: "",
          continent: ""
        });
      }
    } else {
      setContinentError(true)
    }
  }

  const removeContinent = (continent: string) => {
    const continentItems = cdcsList.find((list) => list.continent === continent)
    if (continentItems) {
      continentItems.country.forEach((country) => {
        axios
          .delete(
            getFullUrl(`/api/utility/CdcResource/${country.id}`, {
              useDedicatedEnvironment: true,
            }),
            createRequestWithAuthHeaders(context)
          )
          .then(async () => {
            await getPosts()
            setEditable(false);
            setNewPostTitle("");
            setNewPostDate("");
            setNewPostLink("");
            setEditContinent({
              continent: '',
              current: ''
            })
            setEditCurrentPost({
              current: '',
              continent: '',
              name: '',
              link: ''
            })
          }).catch((e) => {
            growl.current.show({
              severity: "error",
              summary: "Error adding new post",
              detail: e,
            });
          })
      })
    }
  }

  return (
    <div className="resources-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>CDCS Repository</span>
        </div>
        <span className="header-subtitle">
          USAID Country Development Cooperation Strategy (CDCS) Repositor
        </span>
        <span className="header-description">
          “A Country Development Cooperation Strategy (CDCS) defines how a
          Mission will further the goal of ending the need for foreign
          assistance in a given country by articulating how and where it will
          build self-reliance with the country partner. Typically covering five
          years, the CDCS is essentially the strategic roadmap for how USAID
          will design and implement its projects and activities in a given
          country. The CDCS development process centers around a Mission’s
          dialogue with USAID/Washington and with key local stakeholders,
          resulting in a series of milestones and deliverables. The Mission
          disseminates a public version of the strategy on USAID’s public
          website.
          <br />
          <br />
          Listed below, please find the current list of approved CDCSs. Some
          CDCSs have been extended beyond the timeline noted on the cover sheet.
          A fact sheet with all dates is available here. The Agency anticipates
          most CDCSs will be completed on or about December 2020. Answers to
          frequently asked CDCS questions are here.” - USAID
          <br />
          <br />
          Updated Jan, 2021
        </span>
      </section>

      <section className="cdcs-list-section">
        <div className="cdcs-search-block">
          <input
            type="text"
            placeholder="Country"
            onChange={(e) => setSearchValue(e.currentTarget.value)}
          />
          <button type="button" className="card-btn aid-blu-btn">
            Search
          </button>
        </div>
        {isAdmin &&
          (editable ? (
            <div className="add-new-post">
              <input
                type="text"
                className={`new-post-header ${titleError ? 'input-error' : ''}`}
                onChange={(e) => {
                  if (titleError) {
                    setTitleError(false)
                  }
                  setNewPostTitle(e.currentTarget.value);
                }}
              />
              <div className="new-post-content">
                <div className="new-post-date-container">
                  <span className="new-post-date-helper">date:</span>
                  <input
                    type="text"
                    className={`new-post-date ${nameError ? 'input-error' : ''}`}
                    onChange={(e) => {
                      if (nameError) {
                        setNameError(false)
                      }
                      setNewPostDate(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="new-post-link-container">
                  <span className="new-post-link-helper">link:</span>
                  <input
                    type="text"
                    className={`new-post-link ${linkError ? 'input-error' : ''}`}
                    onChange={(e) => {
                      if (linkError) {
                        setLinkError('')
                      }
                      setNewPostLink(e.currentTarget.value);
                    }}
                  />
                  <span className="link-error-message">{linkError}</span>
                </div>
              </div>
              <button
                type="button"
                className="card-btn aid-blu-btn"
                onClick={() => addPost()}
              >
                Save
              </button>
            </div>
          ) : (
            <div
              className="add-btn-container"
              onClick={() => setEditable(true)}
            >
              <button type="button" className="add-btn aid-blu-btn">
                +
              </button>
              <span className="add-btn-title">Add new post</span>
            </div>
          ))}
        <div className="country-list">
          {isLoading ? <ProgressSpinner /> : cdcsList.sort((a, b) => a.continent.localeCompare(b.continent)).map((item) => (
            <React.Fragment key={item.continent}>
              <div className="list-title-block">
                {isAdmin && editContinent.current === item.continent
                  ? <input
                      type="text"
                      value={editContinent.continent}
                      className={`edit-current-continent ${continentError ? 'input-error' : ''}`}
                      onChange={(e) => {
                        if (continentError) {
                          setContinentError(false)
                        }
                        setEditContinent({
                          ...editContinent,
                          continent: e.currentTarget.value
                        });
                      }}
                    />
                  : <h3 className="list-title">{item.continent}</h3>}
                {isAdmin ? editContinent.current === item.continent
                  ? <img
                    src={styles.checkIcon}
                    style={styles.icon}
                    alt="edit"
                    className="editIcon"
                    onClick={() => updateContinent(item.continent)}
                  />
                  : <img
                    src={styles.editIcon}
                    style={styles.icon}
                    alt="edit"
                    className="editIcon"
                    onClick={() => setEditContinent({
                      continent: item.continent,
                      current: item.continent
                    })}
                  /> : <></>
                }
                {isAdmin && <img
                  src={styles.removeIcon}
                  style={styles.icon}
                  alt="remove"
                  className="removeeIcon"
                  onClick={() => removeContinent(item.continent)}
                />}
              </div>
              <ul>
                {item.country.sort((a, b) => a.name.localeCompare(b.name)).map(
                  (country) =>
                    country.name
                      .toLowerCase()
                      .indexOf(searchValue.toLowerCase()) !== -1 && (
                      <li
                        key={country.name}
                        className="list-item"
                      >
                        <div className="top-li">
                          {isAdmin && editCurrentPost.current === country.name
                            ? <input
                                type="text"
                                value={editCurrentPost.name}
                                className={`edit-current-name ${newPostNameError ? 'input-error' : ''}`}
                                onChange={(e) => {
                                  if (newPostNameError) {
                                    setNewPostNameError(false)
                                  }
                                  setEditCurrentPost({
                                    ...editCurrentPost,
                                    name: e.currentTarget.value
                                  });
                                }}
                              />
                            : <span onClick={() => window.open(country.link, "_blank")}>{country.name}</span>}
                          {isAdmin ? editCurrentPost.current === country.name
                            ? <img
                                src={styles.checkIcon}
                                style={styles.icon}
                                alt="edit"
                                className="editIcon"
                                onClick={() => updatePost(country.id)}
                              />
                            : <img
                                src={styles.editIcon}
                                style={styles.icon}
                                alt="edit"
                                className="editIcon"
                                onClick={() => {
                                  setNewPostNameError(false)
                                  setNewPostLinkError('')
                                  setEditCurrentPost({
                                    current: country.name,
                                    continent: item.continent,
                                    name: country.name,
                                    link: country.link
                                  });
                                }}
                              /> : <></>
                          }
                          {isAdmin && <img
                            src={styles.removeIcon}
                            style={styles.icon}
                            alt="remove"
                            className="removeeIcon"
                            onClick={() => removePost(country.id)}
                          />}
                        </div>
                        {editCurrentPost.current === country.name && <div className="bottom-li">
                          <input
                            type="text"
                            value={editCurrentPost.link}
                            className={`edit-current-link ${newPostLinkError ? 'input-error' : ''}`}
                            onChange={(e) => {
                              if (newPostLinkError) {
                                setNewPostLinkError('')
                              }
                              setEditCurrentPost({
                                ...editCurrentPost,
                                link: e.currentTarget.value
                              });
                            }}
                          />
                          {newPostLinkError && <span className="bottom-li-error-text">{newPostLinkError}</span>}
                        </div>}
                      </li>
                    )
                )}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CDCSRepository;
