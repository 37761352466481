export default function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    if (document === null) {
        return {width: null, height: null, headerWidth: null, viewWidth: null};
    }

    const headerWidth = (document.querySelector("#header") || {}).clientWidth
    const viewWidth = (document.querySelector("#view") || {}).clientWidth
    return {
      width: width - 40,
      nestedWidth: width - 40 - 150,
      height,
      headerWidth,
      viewWidth
    };
  }