import * as React from "react";

import "./MyItems.css";
import "../DataTable.css";

import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import { PanelHeader } from "../../components/DataTable/PanelHeader";

import { MyBids, MySuggestionsBusinessForecast } from "../MyBids/MyBids";
import {
  ContractsTableOnly,
  MySuggestionsContracts,
} from "../ContractTableScreen/ContractTableScreen";
import {
  GrantsTableOnly,
  MySuggestionsGrants,
} from "../GrantsTableScreen/GrantsTableScreen";
import { Button } from "primereact/button";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";

import UserContext from "../../services/UserContext";
import GrowlContext from "../../services/growlContext";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SectionHeader from "../../components/Dashboard/SectionHeader";
import mybidsInfoIcon from "../../assets/icons/mybids-info-icon.png";
const icons = {
  notification: require("../../assets/icons/v2/icon_Notfication.png")?.default,
};

export const MyItems: React.FunctionComponent = () => {
  const styles = useGridStyles();
  const user = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  // Open section from email if exists
  let initialSection: "" | "businessforecast" | "contract" | "grant" = "";
  const location = useLocation();
  if (location && location.search) {
    initialSection = (parse(location.search).section as string) || ("" as any);
  }

  // Grid-specific state
  const [isMySuggestionsCollapsed, setIsMySuggestionsCollapsed] =
    React.useState(true);
  const [isMyForecastsCollapsed, setIsMyForecastsCollapsed] = React.useState(
    initialSection !== "businessforecast"
  );
  const [isMyContractsCollapsed, setIsMyContractsCollapsed] = React.useState(
    initialSection !== "contract"
  );
  const [isMyGrantsCollapsed, setIsMyGrantsCollapsed] = React.useState(
    initialSection !== "grant"
  );

  // MySuggestions state
  
  const [myForecastSelected, setMyForecastSelected] = React.useState(true);
  const [myGrantSelected, setMyGrantSelected] = React.useState(false);
  const [myContractSelected, setMyContractSelected] = React.useState(false);
  const [mySuggestionOppCount, setMySuggestionOppCount] = React.useState(0);
  const [myForecastOppCount, setMyForecastOppCount] = React.useState(0);
  const [myGrantOppCount, setMyGrantOppCount] = React.useState(0);
  const [myContractOppCount, setMyContractOppCount] = React.useState(0);
  const [isActive, setIsActive] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/user/MySuggestions", {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(user)
      )
      .then((response) => {
        try {
          const {
            myForecastSuggestionCount,
            myGrantSuggestionCount,
            myContractSuggestionCount,
          } = response.data;
          setMySuggestionOppCount(
            myForecastSuggestionCount +
              myGrantSuggestionCount +
              myContractSuggestionCount
          );
          setMyForecastOppCount(myForecastSuggestionCount);
          setMyGrantOppCount(myGrantSuggestionCount);
          setMyContractOppCount(myContractSuggestionCount);
        } catch (e) {
          growl.current.show({
            severity: "error",
            summary: "Error Loading MySuggestions",
            sticky: true,
          });
          console.error("unable to load", { error: e, response });
        }
      });
  }, []);

  const setSelected = (type: "forecast" | "grant" | "contract") => {
    switch (type) {
      case "forecast":
        setMyContractSelected(false);
        setMyGrantSelected(false);
        setMyForecastSelected(true);
        break;
      case "grant":
        setMyContractSelected(false);
        setMyGrantSelected(true);
        setMyForecastSelected(false);
        break;
      case "contract":
        setMyContractSelected(true);
        setMyGrantSelected(false);
        setMyForecastSelected(false);
        break;
    }
  };

  const notificationIcon = (
    <div style={{ paddingTop: 10, marginRight: "-16px" }}>
      <img src={icons.notification} style={styles.icon}></img>
      {mySuggestionOppCount > 0
        ? (
        <span style={styles.mySuggestionsRedBadge}>{mySuggestionOppCount}</span>
          )
        : <span style={styles.mySuggestionsTransparent} />}
    </div>
  );

  const [removeAll, setRemoveAll] = React.useState(false);

  return (
    <div className="p-grid p-dir-col p-col-12">
      <div className="p-col-12">
          <SectionHeader text={"My Bids"}/>
      </div>
      <div  className={isActive ? 'mybids-container': "hide-info-page"} >
          <div className="icon">
            <img src={mybidsInfoIcon} alt="" width={26} height={46}/>
          </div>
       
          <div className="container-group">
          <p><strong> We have moved My Bids under <a className="links-bids" href="/pipeline">Pipelines!</a> </strong></p>
            <p className="content-section">As we're helping your organization follow and track opportunities,
              AiKonekt now gives you the opportunity to organize and collaborate on the opportunities with your team.
              You can create customized pipelines and start adding and sharing opportunities with other team members.</p>
            <p className="content-section">Don't worry, nothing has been deleted, you still can access your bids saved in the pipeline “Default Pipeline” available under <a className="links-bids" href="/pipeline?my=true"><strong>My Bids Pipeline</strong></a></p>
            <div className="bottom-footer">
              <p onClick={()=> setIsActive(false) }>Dismiss</p>  
            </div>
          </div>
      </div>
      {/* <div className="p-grid p-col-12" style={styles.pageHeader}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        MyBids
      </div> */}

      {/* <div
        className="p-col-10"
        style={{ ...styles.sectionSubheader, paddingBottom: 0 }}
      >
        Welcome to your USAID capture phase (pre-solicitation) pipeline monitor.
        This page allows you to quickly monitor changes to anticipated USAID
        procurements you want to track. You can also manage your Bid and No Bid
        (Go/No Go) decisions here, and track your partnering process in the
        notes section. Also, click on the arrow to the left of any anticipate
        opportunity and you will see the list of historical changes made to that
        opportunity on the Forecast. This is a great way to see how USAID's
        thinking has evolved for each opportunity.
      </div> */}
      <div className="p-col-2"></div>

      <div className="p-col-12">
        <Panel
          style={{
            ...styles.filters.filterBox,
            marginTop: 0,
            borderColor: styles.theme.v2.lightBlue,
          }}
          collapsed={isMySuggestionsCollapsed}
          onToggle={(e) => setIsMySuggestionsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <div className="suggestions-header">
              <PanelHeader
                title="MySuggestions"
                isCollapsed={isMySuggestionsCollapsed}
                setIsCollapsed={setIsMySuggestionsCollapsed}
                collapserIcon={notificationIcon}
                expanderIcon={notificationIcon}
              />
            </div>
          }
        >
          <div className="p-grid p-justify-start">
            <div className="p-col-12" style={{ textAlign: "start" }}>
              <Button
                className="p-button bordered p-no-hover"
                style={styles.mySuggestionsOptions(myForecastSelected, "blue")}
                onClick={() => setSelected("forecast")}
              >
                New Forecast Opps
              </Button>
              {myForecastOppCount > 0 ? (
                <span style={styles.mySuggestionsBadge}>
                  {myForecastOppCount}
                </span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={styles.mySuggestionsOptions(myContractSelected, "blue")}
                className="p-no-hover"
                onClick={() => setSelected("contract")}
              >
                New Contracts Opps
              </Button>
              {myContractOppCount > 0
                ? (
                <span style={styles.mySuggestionsBadge}>
                  {myContractOppCount}
                </span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={styles.mySuggestionsOptions(myGrantSelected, "blue")}
                className="p-no-hover"
                onClick={() => setSelected("grant")}
              >
                New Grants Opps
              </Button>
              {myGrantOppCount > 0 ? (
                <span style={styles.mySuggestionsBadge}>{myGrantOppCount}</span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={{
                  ...styles.mySuggestionsOptions(false, "blue"),
                  backgroundColor: "#FF0000",
                  borderColor: "#FF0000",
                  paddingRight: 12,
                }}
                className="clear-btn"
                onClick={async () => {
                  setRemoveAll(true);
                  setTimeout(() => {
                    axios
                      .get(
                        getFullUrl("/api/user/MySuggestions", {
                          useDedicatedEnvironment: true,
                        }),
                        createAuthenticatedRequest(user)
                      )
                      .then((response) => {
                        setRemoveAll(false)
                        try {
                          const {
                            myForecastSuggestionCount,
                            myGrantSuggestionCount,
                            myContractSuggestionCount,
                          } = response.data;
                          setMySuggestionOppCount(
                            myForecastSuggestionCount +
                            myGrantSuggestionCount +
                            myContractSuggestionCount
                          );
                          setMyForecastOppCount(myForecastSuggestionCount);
                          setMyGrantOppCount(myGrantSuggestionCount);
                          setMyContractOppCount(myContractSuggestionCount);
                        } catch (e) {
                          growl.current.show({
                            severity: "error",
                            summary: "Error Loading MySuggestions",
                            sticky: true,
                          });
                          console.error("unable to load", { error: e, response });
                        }
                      });
                  },1000)

                }}
              >
                Clear All
              </Button>
            </div>
            <div className="p-col-12">
              {myForecastSelected && (
                <MySuggestionsBusinessForecast
                  id={0}  // TO DO temp fix
                  isRemove={removeAll}
                  decrement={() => {
                    setMyForecastOppCount(myForecastOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
              {myGrantSelected && (
                <MySuggestionsGrants
                  id={0} // TO DO : remove when migration to pipeline is complete
                  isRemove={removeAll}
                  decrement={() => {
                    setMyGrantOppCount(myGrantOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
              {myContractSelected && (
                <MySuggestionsContracts
                  pipelineId={0} // TO DO : temp fix
                  isRemove={removeAll}
                  decrement={() => {
                    setMyContractOppCount(myContractOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
            </div>
          </div>
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 }}
          collapsed={isMyForecastsCollapsed}
          onToggle={(e) => setIsMyForecastsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyForecast"
              isCollapsed={isMyForecastsCollapsed}
              setIsCollapsed={setIsMyForecastsCollapsed}
            />
          }
        >
          {/* id heres is a temporary fix pending full pipeline migration */}
          <MyBids id={0} isPipelineScreen={false} />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 }}
          collapsed={isMyContractsCollapsed}
          onToggle={(e) => setIsMyContractsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyContracts"
              isCollapsed={isMyContractsCollapsed}
              setIsCollapsed={setIsMyContractsCollapsed}
            />
          }
        >
          {/* temporary fix pending pipeline migration */}
          <ContractsTableOnly id={0} /> 
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 }}
          collapsed={isMyGrantsCollapsed}
          onToggle={(e) => setIsMyGrantsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyGrants"
              isCollapsed={isMyGrantsCollapsed}
              setIsCollapsed={setIsMyGrantsCollapsed}
            />
          }
        >
          {/* temporary */}
          <GrantsTableOnly id={0} />
        </Panel>
      </div>
    </div>
  );
};
