import * as React from 'react';

function useEnterListener(action: () => void) {
    const callback = (ev: KeyboardEvent) => {
        if (ev.key === "Enter") {
            return action();
        }
    };

    React.useEffect(() => {
        window.addEventListener('keyup', callback);
        return ()=> window.removeEventListener('keyup', callback);
    }, [action]);
}

export { useEnterListener };