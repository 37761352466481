import React, { FunctionComponent } from "react";
import ThemeContext from "../configs/theme";

const useStyle = () => {
    const theme = React.useContext(ThemeContext);

    return {
        chip: {
            display: "flex",
            alignItems: "center",
            margin: "2px",
            padding: "5px",
            fontSize: "14px",
            textAlign: "left" as "left",
            backgroundColor: theme.v2.lightBlue,
            borderRadius: "1em"
        },
        icon: {
            fontSize: ".9em",
            cursor: "pointer"
        }
    };
};

interface ChipProps {
    label: string,
    onIconClick: React.Dispatch<any>
}

const Chip: FunctionComponent<ChipProps> = ({label, onIconClick}) => {

    const style = useStyle();

    return (
        <div style={style.chip}>
            <span>{label}</span> &nbsp; <i style={style.icon} className="pi pi-times" onClick={onIconClick} />
        </div>
    );
};

export default Chip;
