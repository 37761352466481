import React, { ChangeEvent, useState } from "react";
import { useStyles } from "../../hooks/useStyles";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext from "../../services/UserContext";

type ConfirmationEmailParams = {
  email: string;
};

function ConfirmationEmail() {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as ConfirmationEmailParams;
  const context = React.useContext(UserContext);
  const [state, setState] = useState({
    email: locationState ? locationState.email : "",
    authCode: "",
    errorMessage: "",
    message: "",
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.id;
    const value = event.currentTarget.value;

    setState((state) => ({ ...state, [key]: value }));
  };

  const resendVerification = async () => {
    let url = getFullUrl("/api/auth/confirmation/resend", {
      useDedicatedEnvironment: true,
    });
    await axios
      .post(url, { email: state.email }, createRequestWithAuthHeaders(context))
      .then(() => {
        setState((state) => ({
          ...state,
          errorMessage: "",
          message: "Confirmation email sent",
        }));
      })
      .catch((err) => {
        console.log("error confirming signing up: ", err);
        setState((state) => ({ ...state, errorMessage: err.message }));
      });
  };

  const verifyCode = async () => {
    if (!state.authCode) {
      setState((state) => ({
        ...state,
        errorMessage: "Please enter an verification code",
      }));
      return;
    }

    let url = getFullUrl("/api/auth/verify", { useDedicatedEnvironment: true });
    await axios
      .post(
        url,
        {
          email: state.email,
          authCode: state.authCode,
        },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        history.push("/auth");
      })
      .catch((err) => {
        console.log("error confirming signing up: ", err);
        setState((state) => ({ ...state, errorMessage: err.message }));
      });
  };

  return (
    <div style={styles.innerContainer}>
      <div style={styles.header}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        Email Confirmation
      </div>
      <div style={styles.errorDiv}>
        <p>{state.errorMessage}</p>
      </div>
      <div style={styles.messageDiv}>
        <p>{state.message}</p>
      </div>
      <>
        <label htmlFor="authCode" style={styles.inputLabel}>
          Confirmation Code
        </label>
        <input id="authCode" onChange={onChange} style={styles.inputStyle} />
        <div style={styles.buttonStyle} onClick={verifyCode}>
          <p style={styles.buttonTextStyle}>Confirm</p>
        </div>
        {state.email && ( // only let resend if email in state
          <div style={styles.buttonStyle} onClick={resendVerification}>
            <p style={styles.buttonTextStyle}>Resend Verification</p>
          </div>
        )}
      </>
    </div>
  );
}

export default ConfirmationEmail;
