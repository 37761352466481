import React, { useState } from "react";
import { css } from "glamor";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { DateFormat, formatDate } from "../utils/conversion/date-converters";
import { useSharedStyles } from "./Dashboard/useSharedStyles";
import { ShowMoreText } from "./ShowMoreText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const styles = {
  update: {
    textAlign: "left",
    // fontSize: "0.75em",
    backgroundColor: "#FEE4B8",
    margin: "10px 0",
    padding: "15px",
    borderRadius: "30px",
    whiteSpace: "pre-wrap",
  },
  buttonContainer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export type UpdateType = {
  id: number;
  message: string;
  createdOn?: Date | null;
};

type UpdateProps = {
  update: UpdateType;
  canEditUpdates: boolean;
  editUpdate: (updateId: number, editedUpdateText: string) => void;
  deleteUpdate: (updateId: number) => void;
};

const Update = ({
  update,
  canEditUpdates,
  editUpdate,
  deleteUpdate,
}: UpdateProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedUpdateText, setEditedUpdateText] = useState<string>(
    update.message
  );
  const sharedStyles = useSharedStyles();

  const handleSave = () => {
    setIsEditing(false);
    editUpdate(update.id, editedUpdateText);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
    setEditedUpdateText(update.message);
  };

  return (
    <div {...css(styles.update)}>
      {isEditing ? (
        <div className="p-inputgroup">
          <ReactQuill
            style={{ width: "100%", height: "100%" }}
            theme="snow"
            value={editedUpdateText}
            onChange={(e) => setEditedUpdateText(e)}
          />
        </div>
      ) : (
        <div style={sharedStyles.potentiallyBlurred(sharedStyles.isFree)}>
          {update.createdOn ? (
            <div style={{ fontWeight: "bold", marginBottom: "15px" }}>
              {formatDate(update.createdOn.toString(), DateFormat.dd_MMMM_yyyy)}
            </div>
          ) : (
            <></>
          )}
          <ShowMoreText text={update.message} />
        </div>
      )}
      {canEditUpdates && (
        <div {...css(styles.buttonContainer)} className="p-inputgroup">
          {isEditing && (
            <Button
              icon="pi pi-save"
              label="Save"
              className="aid-blu-btn"
              style={{ marginRight: "5px" }}
              disabled={!isEditing}
              onClick={() => handleSave()}
            />
          )}
          <Button
            icon={isEditing ? "pi pi-times" : "pi pi-pencil"}
            label={isEditing ? "Cancel" : "Edit"}
            className="aid-blu-btn"
            style={{ marginRight: "5px" }}
            onClick={() => toggleEditing()}
          />
          <Button
            icon="pi pi-trash"
            label="Delete"
            className="p-button-danger"
            disabled={isEditing}
            onClick={() => deleteUpdate(update.id)}
          />
        </div>
      )}
    </div>
  );
};

export default Update;
