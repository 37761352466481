import { initializeSettingValue, saveSettingValue, ServerSetting, updateSettingValue } from "../../reducers/DashboardReducer";
import { Type, Action } from "./actions"
import React from "react";

export interface AccountInformationState {
    username: string,
    firstName: ServerSetting<string>,
    lastName: ServerSetting<string>,
    email: string,
    timeZone: ServerSetting<string>
}

export const createInitialAccountInformationState = (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    timeZone: string
): AccountInformationState => ({
    username: username,
    firstName: initializeSettingValue(firstName),
    lastName: initializeSettingValue(lastName),
    email: email,
    timeZone: initializeSettingValue(timeZone)
});

const reducer = (state: AccountInformationState, action: Action): AccountInformationState => {
    switch(action.type) {
        case Type.SET_FIRST_NAME: return {
            ...state,
            firstName: updateSettingValue(state.firstName, action.value)
        };
        case Type.SAVE_FIRST_NAME: return {
            ...state,
            firstName: saveSettingValue(state.firstName)
        };
        case Type.SET_LAST_NAME: return {
            ...state,
            lastName: updateSettingValue(state.lastName, action.value)
        };
        case Type.SAVE_LAST_NAME: return {
            ...state,
            lastName: saveSettingValue(state.lastName)
        };
        case Type.SET_TIME_ZONE: return {
            ...state,
            timeZone: updateSettingValue(state.timeZone, action.value)
        };
        case Type.SAVE_TIME_ZONE: return {
            ...state,
            timeZone: saveSettingValue(state.timeZone)
        };
        default: return state; // TODO: Or throw error?
    }
};

type AccountInformationContextType = {
    accountInformationState: AccountInformationState,
    accountInformationDispatch: React.Dispatch<Action>
}

export const AccountInformationContext = React.createContext<AccountInformationContextType>({} as AccountInformationContextType);

export default reducer;
