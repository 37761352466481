import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import "./Popover.css";
import { Button } from "primereact/button";

export interface PopoverProps {
  dialogHeader: string;
  isVisible: boolean;
  dialogText: string | JSX.Element;
  setDialogVisible?: (x: boolean) => void;
  setMultiDialogVisible?: (x: string) => void;
  multi?: boolean;
  colunmType?: string
}

export const Popover = ({
  dialogHeader,
  isVisible,
  dialogText,
  setDialogVisible,
  setMultiDialogVisible,
  colunmType,
  multi,
}: PopoverProps) => {
 
  let dialogtextContent: Array<string>  = dialogText.toString().split(',');
  const header = (
    <div>
        <p>{dialogHeader}</p>
    </div>
);
    
  return (
    <>
    {colunmType === "pipeline-popover" ? 
    
    <Dialog
      closeOnEscape={true}
      dismissableMask={true}
      header={header}
      visible={isVisible}
      style={{
        width: "30vw",
      }}
      className="pipeline-column-popover"
      onHide={() => {
        if (setMultiDialogVisible && multi) {
          setMultiDialogVisible("");
        }
        if (setDialogVisible) {
          setDialogVisible(false);
        }
      }}
      id="pipe-diolog"
    >
      <div className="pipeline-column-popover-content">
        <ul className="pipeline-column-ul">
          {dialogtextContent.map((i,j)=><li key={j}>{i}</li>)}
        </ul>
      </div>
      {/* to do */}
      {/* <div className="bottom-arrow"></div> */} 
    </Dialog>
    
     :
    
    <>
    <Dialog
      closeOnEscape={true}
      dismissableMask={true}
      header={dialogHeader}
      visible={isVisible}
      style={{
        width: "50vw",
        fontSize: 32,
        background: "#000000",
        borderRadius: 10,
      }}
      onHide={() => {
        if (setMultiDialogVisible && multi) {
          setMultiDialogVisible("");
        }
        if (setDialogVisible) {
          setDialogVisible(false);
        }
      }}
    >
      <div
        style={{ fontSize: 32,color:"#FFFFFF" }}
        dangerouslySetInnerHTML={{ __html: dialogText.toString()}}

      >
      </div>
     
    </Dialog>
    </>}
    </>
    
    
  );
};
