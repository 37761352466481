import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import "./AdminScreen.css";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import Button from "@material-ui/core/Button";
import UserManagementTable, {
  TableRecord,
} from "./AdminTables/UserManagementTable";
import PrimePredictionsTable, {
  TableRecord as PredictionsTableRecord,
} from "./AdminTables/PrimePredictionsTable";
import LCPSuggestionsTable, {
  TableRecord as LCPSuggestionsTableRecord,
} from "./AdminTables/LCPSuggestionsTable";
import IEESuggestionsTable, {
  TableRecord as IEESuggestionsTableRecord,
} from "./AdminTables/IEESuggestionsTable";
import ApiStatusTable, {
  TableRecord as ApiStatusTableRecord,
} from "./AdminTables/ApiStatusTable";
import RequestLogTable from "./AdminTables/RequestLogTable";
import getWindowDimensions from "../../services/dimensions";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import {
  apiStatusData,
  userManagementData,
  primePredictionsData,
} from "./AdminTables/mock-tables-data";
import UserContext from "../../services/UserContext";
import Swal from "sweetalert2";
import GrowlContext from "../../services/growlContext";
import axios, { AxiosRequestConfig } from "axios";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AdminScreen: React.FC = (): JSX.Element => {
  const styles = useGridStyles();
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  const [isCollapsedManagement, setIsCollapsedManagement] = useState(true);
  const [isCollapsedPredictions, setIsCollapsedPredictions] = useState(true);
  const [isCollapsedLCPSuggestions, setIsCollapsedLCPSuggestions] =
    useState(true);
  const [isCollapsedIEESuggestions, setIsCollapsedIEESuggestions] =
    useState(true);
  const [isCollapsedApiStatus, setIsCollapsedApiStatus] = useState(true);
  const [isCollapsedRequestLogStatus, setIsCollapsedRequestLogStatus] =
    useState(true);

  const [organizationValue, setOrganizationValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const [includeExpiring, setIncludeExpiring] = useState(false);
  const [includePro, setIncludePro] = useState(false);
  const [includePlus, setIncludePlus] = useState(false);
  const [includeFree, setIncludeFree] = useState(false);

  //User Management table state
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortField, setSortField] = useState("expirationDate");
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [data, setData] = useState(Array<TableRecord>());
  const [gridWidth, setGridWidth] = useState(0);
  const [originalNotes, setOriginalNotes] = useState(
    Array<{ id: number; note: string }>()
  );

  //Prime Predictions table state
  const [isLoadingPredictions, setIsLoadingPredictions] = useState(false);
  const [rowsPerPagePredictions, setRowsPerPagePredictions] = useState(20);
  const [sortFieldPredictions, setSortFieldPredictions] =
    useState("dateScriptLast");
  const [sortOrderPredictions, setSortOrderPredictions] = useState(-1);
  const [firstPredictions, setFirstPredictions] = useState(-1);
  const [totalRecordsPredictions, setTotalRecordsPredictions] = useState(0);
  const [lastUpdatedPredictions, setLastUpdatedPredictions] = useState("");
  const [dataPredictions, setDataPredictions] = useState(
    Array<PredictionsTableRecord>()
  );
  const [gridWidthPredictions, setGridWidthPredictions] = useState(0);
  const [originalNotesPredictions, setOriginalNotesPredictions] = useState(
    Array<{ id: number; note: string }>()
  );

  //LCP Suggestions table state
  const [isLoadingLCPSuggestions, setIsLoadingLCPSuggestions] = useState(false);
  const [rowsPerPageLCPSuggestions, setRowsPerPageLCPSuggestions] =
    useState(20);
  const [sortFieldLCPSuggestions, setSortFieldLCPSuggestions] =
    useState("expirationDate");
  const [sortOrderLCPSuggestions, setSortOrderLCPSuggestions] = useState(-1);
  const [firstLCPSuggestions, setFirstLCPSuggestions] = useState(0);
  const [totalRecordsLCPSuggestions, setTotalRecordsLCPSuggestions] =
    useState(0);
  const [lastUpdatedLCPSuggestions, setLastUpdatedLCPSuggestions] =
    useState("");
  const [dataLCPSuggestions, setDataLCPSuggestions] = useState(
    Array<LCPSuggestionsTableRecord>()
  );
  const [gridWidthLCPSuggestions, setGridWidthLCPSuggestions] = useState(0);
  const [originalNotesLCPSuggestions, setOriginalNotesLCPSuggestions] =
    useState(Array<{ id: number; note: string }>());

  //IEE Suggestions table state
  const [isLoadingIEESuggestions, setIsLoadingIEESuggestions] = useState(false);
  const [rowsPerPageIEESuggestions, setRowsPerPageIEESuggestions] =
    useState(20);
  const [sortFieldIEESuggestions, setSortFieldIEESuggestions] =
    useState("expirationDate");
  const [sortOrderIEESuggestions, setSortOrderIEESuggestions] = useState(-1);
  const [firstIEESuggestions, setFirstIEESuggestions] = useState(0);
  const [totalRecordsIEESuggestions, setTotalRecordsIEESuggestions] =
    useState(0);
  const [lastUpdatedIEESuggestions, setLastUpdatedIEESuggestions] =
    useState("");
  const [dataIEESuggestions, setDataIEESuggestions] = useState(
    Array<IEESuggestionsTableRecord>()
  );
  const [gridWidthIEESuggestions, setGridWidthIEESuggestions] = useState(0);
  const [originalNotesIEESuggestions, setOriginalNotesIEESuggestions] =
    useState(Array<{ id: number; note: string }>());

  //Api Status table state
  const [isLoadingApiStatus, setIsLoadingApiStatus] = useState(false);
  const [rowsPerPageApiStatus, setRowsPerPageApiStatus] = useState(20);
  const [sortFieldApiStatus, setSortFieldApiStatus] =
    useState("expirationDate");
  const [sortOrderApiStatus, setSortOrderApiStatus] = useState(-1);
  const [firstApiStatus, setFirstApiStatus] = useState(-1);
  const [totalRecordsApiStatus, setTotalRecordsApiStatus] = useState(0);
  const [lastUpdatedApiStatus, setLastUpdatedApiStatus] = useState("");
  const [dataApiStatus, setDataApiStatus] = useState(
    Array<ApiStatusTableRecord>()
  );
  const [gridWidthApiStatus, setGridWidthApiStatus] = useState(0);
  const [originalNotesApiStatus, setOriginalNotesApiStatus] = useState(
    Array<{ id: number; note: string }>()
  );

  //Request Logs table state
  const [requestLogsEmail, setRequestLogsEmail] = useState("");
  const [requestLogsCognitoId, setRequestLogsCognitoId] = useState("");
  const [requestLogsPath, setRequestLogsPath] = useState("");
  const [requestLogsMethod, setRequestLogsMethod] = useState("");
  const [requestLogsQueryParameters, setRequestLogsQueryParameters] =
    useState("");
  const [requestLogsRequestBody, setRequestLogsRequestBody] = useState("");
  const [requestLogsStartDate, setRequestLogsStartDate] = useState<Date | null>(
    null
  );
  const [requestLogsEndDate, setRequestLogsEndDate] = useState<Date | null>(
    null
  );
  const [requestLogsTableQueryString, setRequestLogsTableQueryString] =
    useState(new URLSearchParams());
  const requestLogsMethodOptions = [
    { value: "", label: "Method" },
    { value: "get", label: "GET" },
    { value: "post", label: "POST" },
    { value: "put", label: "PUT" },
    { value: "delete", label: "DELETE" },
  ];

  const getPotentialResources = (
    type: "iee" | "lcp",
    pageSize: number,
    pageIndex: number
  ) => {
    return axios
      .get(
        getFullUrl(
          `/api/admin/potentialresource/${type}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
          { useDedicatedEnvironment: true }
        ),
        createAuthenticatedRequest(context)
      )
      .then((r) => ({ data: r.data.data, totalItems: r.data.totalItems }))
      .catch((e) => {
        console.error(`error getting potential ${type} resource data`, e);
        return null;
      });
  };

  const removeData = (type: "iee" | "lcp", id: number) => {
    if (type === "iee") {
      const data = dataIEESuggestions.filter((r) => r.id !== id);
      setDataIEESuggestions(data);
      setTotalRecordsIEESuggestions(totalRecordsIEESuggestions - 1);
    } else {
      const data = dataLCPSuggestions.filter((r) => r.id !== id);
      setDataLCPSuggestions(data);
      setTotalRecordsLCPSuggestions(totalRecordsLCPSuggestions - 1);
    }
  };

  const addPotentialResource = async (
    type: "iee" | "lcp",
    props: { id: number; attachmentDate: Date; country: string }
  ) => {
    const { value: formValues } = await Swal.fire({
      title: `Add ${type.toUpperCase()} Resource`,
      html: `<label htmlFor="date">Date</label>
                <input id="date" class="swal2-input" value="${props.attachmentDate}"></input>
                <label htmlFor="date">Country</label>
                <input id="country" class="swal2-input" value="${props.country}"></input>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [
          (document.getElementById("date") as any).value,
          (document.getElementById("country") as any).value,
        ];
      },
    });

    if (!formValues || !formValues[0] || !formValues[1]) {
      growl.current.show({
        severity: "error",
        summary:
          "Canceled submit or did not provide valid values - add resource aborted",
      });
      return;
    }

    const [date, country] = formValues;

    await axios
      .post(
        getFullUrl("/api/admin/potentialresource", {
          useDedicatedEnvironment: true,
        }),
        { id: props.id, date, country },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        removeData(type, props.id);
      })
      .catch((e) => {
        console.error(`error adding ${type} resource`, e);
        growl.current.show({
          severity: "error",
          summary: `Error adding record - see console for more info`,
        });
      });
  };

  const removePotentialResource = async (
    type: "iee" | "lcp",
    props: { id: number }
  ) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(context),
      data: { id: props.id },
    };

    await axios
      .delete(
        getFullUrl("/api/admin/potentialresource", {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then(() => {
        removeData(type, props.id);
      })
      .catch((e) => console.error(`error adding ${type} resource`, e));
  };

  type QueryDataProperties = { first: number; rows: number };

  const queryData = () => setData(userManagementData);
  const queryDataPredictions = () => setDataPredictions(primePredictionsData);

  const queryDataLCPSuggestions = async (props: QueryDataProperties) => {
    setFirstLCPSuggestions(props.first);
    const pageIndex = props.first / rowsPerPageLCPSuggestions;
    const result = await getPotentialResources("lcp", props.rows, pageIndex);
    if (result) {
      const { data, totalItems } = result;
      setDataLCPSuggestions(data);
      setTotalRecordsLCPSuggestions(totalItems);
    }
  };

  const queryDataIEESuggestions = async (props: QueryDataProperties) => {
    setFirstIEESuggestions(props.first);
    const pageIndex = props.first / rowsPerPageIEESuggestions;
    const result = await getPotentialResources("iee", props.rows, pageIndex);
    if (result) {
      const { data, totalItems } = result;
      setDataIEESuggestions(data);
      setTotalRecordsIEESuggestions(totalItems);
    }
  };

  const queryDataApiStatus = () => setDataApiStatus(apiStatusData);

  const queryDataRequestLogs = () => {
    const queryString = new URLSearchParams();

    if (requestLogsCognitoId) {
      queryString.set("cognitoId", requestLogsCognitoId);
    }

    if (requestLogsEmail) {
      queryString.set("email", requestLogsEmail);
    }

    if (requestLogsPath) {
      queryString.set("path", requestLogsPath);
    }

    if (requestLogsMethod) {
      queryString.set("method", requestLogsMethod);
    }

    if (requestLogsQueryParameters) {
      queryString.set("queryParams", requestLogsQueryParameters);
    }

    if (requestLogsRequestBody) {
      queryString.set("body", requestLogsRequestBody);
    }

    if (requestLogsStartDate) {
      queryString.set("startDate", requestLogsStartDate.toISOString());
    }

    if (requestLogsEndDate) {
      queryString.set("endDate", requestLogsEndDate.toISOString());
    }

    setRequestLogsTableQueryString(queryString);
  };

  useEffect(() => {
    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
        setGridWidthPredictions(viewWidth);
        setGridWidthLCPSuggestions(viewWidth);
        setGridWidthIEESuggestions(viewWidth);
        setGridWidthApiStatus(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setData(userManagementData);
    setDataPredictions(primePredictionsData);
    queryDataLCPSuggestions({
      rows: rowsPerPageLCPSuggestions,
      first: firstLCPSuggestions,
    });
    queryDataIEESuggestions({
      rows: rowsPerPageIEESuggestions,
      first: firstIEESuggestions,
    });
    setDataApiStatus(apiStatusData);
  }, []);

  return (
    <div className="admin-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>Admin</span>
        </div>
      </section>

      <section className="data-section">
        <div className="data-block">
          <h3>User Metrics</h3>
          <div className="row-data-container">
            <div className="row-data">
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">New members</span>
              </div>
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">Expiring this month</span>
              </div>
            </div>
            <div className="row-data">
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">Avg User Time</span>
              </div>
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">Click Throughs</span>
              </div>
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">Emails Opened</span>
              </div>
            </div>
          </div>
        </div>
        <div className="data-block">
          <h3>Forecast Metrics</h3>
          <div className="row-data-container">
            <div className="row-data">
              <div className="data-content">
                <span className="data-content-count">56</span>
                <span className="data-content-title">Changes</span>
              </div>
              <div className="data-content">
                <span className="data-content-count">56%</span>
                <span className="data-content-title">
                  Change from Last Week
                </span>
              </div>
            </div>
            <div className="row-data">
              <div className="data-content">
                <span className="data-content-count">$56</span>
                <span className="data-content-title">Value of New Opps</span>
              </div>
              <div className="data-content">
                <span className="data-content-count">Ukraine</span>
                <span className="data-content-title">Most Active Mission</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="user-management-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedManagement ? 20 : 0,
          }}
          collapsed={isCollapsedManagement}
          onToggle={(e) => setIsCollapsedManagement(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="User Management"
              isCollapsed={isCollapsedManagement}
              setIsCollapsed={setIsCollapsedManagement}
            />
          }
        >
          <div>
            <div
              className="p-grid p-align-center"
              style={styles.filters.notTopFilterRow}
            >
              <div
                className="p-col"
                style={{ textAlign: "left", display: "block" }}
              >
                <InputText
                  style={styles.filters.inputBox}
                  id="organization"
                  type="search"
                  value={organizationValue}
                  onChange={(e: any) =>
                    setOrganizationValue((e.target as any).value)
                  }
                  placeholder="Organization"
                />
              </div>
            </div>
            <div
              className="p-grid p-align-center"
              style={styles.filters.notTopFilterRow}
            >
              <div
                className="p-col"
                style={{ textAlign: "left", display: "block" }}
              >
                <InputText
                  style={styles.filters.inputBox}
                  id="email"
                  type="search"
                  value={emailValue}
                  onChange={(e: any) => setEmailValue((e.target as any).value)}
                  placeholder="Email"
                />
              </div>
            </div>
            <div
              className="p-grid p-align-center"
              style={styles.filters.notTopFilterRow}
            >
              <div
                className="p-col"
                style={{ textAlign: "left", display: "block" }}
              >
                <InputText
                  style={styles.filters.inputBox}
                  id="keyword"
                  type="search"
                  value={keywordValue}
                  onChange={(e: any) =>
                    setKeywordValue((e.target as any).value)
                  }
                  placeholder="Keyword"
                />
              </div>
            </div>
            <div className="header-checkboxes-block">
              <div
                className="header-checkbox-container"
                style={{ textAlign: "left" }}
              >
                <Checkbox
                  inputId="cb1"
                  value="Expiring"
                  onChange={(e) => setIncludeExpiring(e.checked)}
                  checked={includeExpiring}
                />
                <label
                  htmlFor="cb1"
                  className="p-checkbox-label"
                  style={styles.filters.inputTextLabel}
                >
                  Expiring in the next 60 days
                </label>
              </div>
              <div
                className="header-checkbox-container"
                style={{ textAlign: "left" }}
              >
                <Checkbox
                  inputId="cb2"
                  value="Pro Users"
                  onChange={(e) => setIncludePro(e.checked)}
                  checked={includePro}
                />
                <label
                  htmlFor="cb2"
                  className="p-checkbox-label"
                  style={styles.filters.inputTextLabel}
                >
                  Pro Users
                </label>
              </div>
              <div
                className="header-checkbox-container"
                style={{ textAlign: "left" }}
              >
                <Checkbox
                  inputId="cb3"
                  value="Plus Users"
                  onChange={(e) => setIncludePlus(e.checked)}
                  checked={includePlus}
                />
                <label
                  htmlFor="cb3"
                  className="p-checkbox-label"
                  style={styles.filters.inputTextLabel}
                >
                  Plus Users
                </label>
              </div>
              <div
                className="header-checkbox-container"
                style={{ textAlign: "left" }}
              >
                <Checkbox
                  inputId="swa"
                  value="Free Users"
                  onChange={(e) => setIncludeFree(e.checked)}
                  checked={includeFree}
                />
                <label
                  htmlFor="swa"
                  className="p-checkbox-label"
                  style={styles.filters.inputTextLabel}
                >
                  Free Users
                </label>
              </div>
            </div>
            <div className="search-btn-container">
              <Button style={styles.filters.searchButton} onClick={() => null}>
                <div style={styles.filters.searchButtonText}>Search</div>
              </Button>
            </div>
            <UserManagementTable
              isLoading={isLoading}
              rowsPerPage={rowsPerPage}
              sortField={sortField}
              setSortField={setSortField}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              first={first}
              totalRecords={totalRecords}
              data={data}
              setData={setData}
              lastUpdated={lastUpdated}
              gridWidth={gridWidth}
              originalNotes={originalNotes}
              setOriginalNotes={setOriginalNotes}
              queryData={queryData}
            />
          </div>
        </Panel>
      </section>

      <section className="prime-predictions-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedPredictions ? 20 : 0,
          }}
          collapsed={isCollapsedPredictions}
          onToggle={(e) => setIsCollapsedPredictions(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="Prime Predictions"
              isCollapsed={isCollapsedPredictions}
              setIsCollapsed={setIsCollapsedPredictions}
            />
          }
        >
          <PrimePredictionsTable
            isLoading={isLoadingPredictions}
            rowsPerPage={rowsPerPagePredictions}
            sortField={sortFieldPredictions}
            setSortField={setSortFieldPredictions}
            sortOrder={sortOrderPredictions}
            setSortOrder={setSortOrderPredictions}
            first={firstPredictions}
            totalRecords={totalRecordsPredictions}
            data={dataPredictions}
            setData={setDataPredictions}
            lastUpdated={lastUpdatedPredictions}
            gridWidth={gridWidthPredictions}
            originalNotes={originalNotesPredictions}
            setOriginalNotes={setOriginalNotesPredictions}
            queryData={queryDataPredictions}
          />
        </Panel>
      </section>

      <section className="lcp-suggestions-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedLCPSuggestions ? 20 : 0,
          }}
          collapsed={isCollapsedLCPSuggestions}
          onToggle={(e) => setIsCollapsedLCPSuggestions(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="LCP Archive Suggestions"
              isCollapsed={isCollapsedLCPSuggestions}
              setIsCollapsed={setIsCollapsedLCPSuggestions}
            />
          }
        >
          <LCPSuggestionsTable
            isLoading={isLoadingLCPSuggestions}
            rowsPerPage={rowsPerPageLCPSuggestions}
            sortField={sortFieldLCPSuggestions}
            setSortField={setSortFieldLCPSuggestions}
            sortOrder={sortOrderLCPSuggestions}
            setSortOrder={setSortOrderLCPSuggestions}
            first={firstLCPSuggestions}
            totalRecords={totalRecordsLCPSuggestions}
            data={dataLCPSuggestions}
            setData={setDataLCPSuggestions}
            lastUpdated={lastUpdatedLCPSuggestions}
            gridWidth={gridWidthLCPSuggestions}
            originalNotes={originalNotesLCPSuggestions}
            setOriginalNotes={setOriginalNotesLCPSuggestions}
            addRecord={(r) => addPotentialResource("lcp", r)}
            removeRecord={(r) => removePotentialResource("lcp", r)}
            queryData={queryDataLCPSuggestions}
          />
        </Panel>
      </section>

      <section className="iee-suggestions-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedIEESuggestions ? 20 : 0,
          }}
          collapsed={isCollapsedIEESuggestions}
          onToggle={(e) => setIsCollapsedIEESuggestions(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="IEE Archive Suggestions"
              isCollapsed={isCollapsedIEESuggestions}
              setIsCollapsed={setIsCollapsedIEESuggestions}
            />
          }
        >
          <IEESuggestionsTable
            isLoading={isLoadingIEESuggestions}
            rowsPerPage={rowsPerPageIEESuggestions}
            sortField={sortFieldIEESuggestions}
            setSortField={setSortFieldIEESuggestions}
            sortOrder={sortOrderIEESuggestions}
            setSortOrder={setSortOrderIEESuggestions}
            first={firstIEESuggestions}
            totalRecords={totalRecordsIEESuggestions}
            data={dataIEESuggestions}
            setData={setDataIEESuggestions}
            lastUpdated={lastUpdatedIEESuggestions}
            gridWidth={gridWidthIEESuggestions}
            originalNotes={originalNotesIEESuggestions}
            setOriginalNotes={setOriginalNotesIEESuggestions}
            addRecord={(r) => addPotentialResource("iee", r)}
            removeRecord={(r) => removePotentialResource("iee", r)}
            queryData={queryDataIEESuggestions}
          />
        </Panel>
      </section>

      <section className="api-status-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedApiStatus ? 20 : 0,
          }}
          collapsed={isCollapsedApiStatus}
          onToggle={(e) => setIsCollapsedApiStatus(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="API Status"
              isCollapsed={isCollapsedApiStatus}
              setIsCollapsed={setIsCollapsedApiStatus}
            />
          }
        >
          <ApiStatusTable
            isLoading={isLoadingApiStatus}
            rowsPerPage={rowsPerPageApiStatus}
            sortField={sortFieldApiStatus}
            setSortField={setSortFieldApiStatus}
            sortOrder={sortOrderApiStatus}
            setSortOrder={setSortOrderApiStatus}
            first={firstApiStatus}
            totalRecords={totalRecordsApiStatus}
            data={dataApiStatus}
            setData={setDataApiStatus}
            lastUpdated={lastUpdatedApiStatus}
            gridWidth={gridWidthApiStatus}
            originalNotes={originalNotesApiStatus}
            setOriginalNotes={setOriginalNotesApiStatus}
            queryData={queryDataApiStatus}
          />
        </Panel>
      </section>

      <section className="request-log-section">
        <Panel
          style={{
            ...styles.filters.filterBox,
            paddingBottom: !isCollapsedRequestLogStatus ? 20 : 0,
          }}
          collapsed={isCollapsedRequestLogStatus}
          onToggle={(e) => setIsCollapsedRequestLogStatus(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="Request Logs"
              isCollapsed={isCollapsedRequestLogStatus}
              setIsCollapsed={setIsCollapsedRequestLogStatus}
            />
          }
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "flex" }}
                >
                  <label
                    style={{
                      alignSelf: "center",
                      ...styles.filters.inputTextLabel,
                    }}
                  >
                    Show request logs created between{" "}
                  </label>
                  <KeyboardDateTimePicker
                    disableToolbar
                    disableFuture={true}
                    ampm={false}
                    variant="inline"
                    format="MM/dd/yyyy HH:mm"
                    value={requestLogsStartDate}
                    onChange={(date) => setRequestLogsStartDate(date)}
                    style={{ marginLeft: 10 }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isLoading}
                  />
                  <label
                    style={{
                      alignSelf: "center",
                      ...styles.filters.inputTextLabel,
                    }}
                  >
                    {" "}
                    and{" "}
                  </label>
                  <KeyboardDateTimePicker
                    disableToolbar
                    disableFuture={true}
                    ampm={false}
                    variant="inline"
                    format="MM/dd/yyyy HH:mm"
                    value={requestLogsEndDate}
                    onChange={(date) => setRequestLogsEndDate(date)}
                    style={{ marginLeft: 10 }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <InputText
                    style={styles.filters.inputBox}
                    id="requestLogsEmail"
                    type="search"
                    value={requestLogsEmail}
                    onChange={(e: any) =>
                      setRequestLogsEmail((e.target as any).value)
                    }
                    placeholder="Email"
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <InputText
                    style={styles.filters.inputBox}
                    id="requestLogsCognitoId"
                    type="search"
                    value={requestLogsCognitoId}
                    onChange={(e: any) =>
                      setRequestLogsCognitoId((e.target as any).value)
                    }
                    placeholder="Cognito Id"
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <ReactSelect
                    isDisabled={false}
                    placeholder="Method"
                    isMulti={false}
                    onChange={(opt) => {
                      let newVal = opt?.value ?? "";
                      setRequestLogsMethod(newVal);
                    }}
                    options={requestLogsMethodOptions}
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <InputText
                    style={styles.filters.inputBox}
                    id="requestLogsPath"
                    type="search"
                    value={requestLogsPath}
                    onChange={(e: any) =>
                      setRequestLogsPath((e.target as any).value)
                    }
                    placeholder="Path"
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <InputText
                    style={styles.filters.inputBox}
                    id="requestLogsQueryParameters"
                    type="search"
                    value={requestLogsQueryParameters}
                    onChange={(e: any) =>
                      setRequestLogsQueryParameters((e.target as any).value)
                    }
                    placeholder="Query Parameters"
                  />
                </div>
              </div>
              <div
                className="p-grid p-align-center"
                style={styles.filters.notTopFilterRow}
              >
                <div
                  className="p-col"
                  style={{ textAlign: "left", display: "block" }}
                >
                  <InputText
                    style={styles.filters.inputBox}
                    id="requestLogsRequestBody"
                    type="search"
                    value={requestLogsRequestBody}
                    onChange={(e: any) =>
                      setRequestLogsRequestBody((e.target as any).value)
                    }
                    placeholder="Request Body"
                  />
                </div>
              </div>
              <div className="search-btn-container">
                <Button
                  style={styles.filters.searchButton}
                  onClick={queryDataRequestLogs}
                >
                  <div style={styles.filters.searchButtonText}>Search</div>
                </Button>
              </div>
            </div>
          </MuiPickersUtilsProvider>
          <RequestLogTable
            isExpanded={!isCollapsedRequestLogStatus}
            gridWidth={gridWidth}
            queryParams={requestLogsTableQueryString}
          />
        </Panel>
      </section>
    </div>
  );
};

export default AdminScreen;
