import React from "react";
import UserContext from "../../services/UserContext";

import "date-fns";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import getWindowDimensions from "../../services/dimensions";

import CustomSearchSelect from "../../components/CustomSearchSelect/CustomSearchSelect";
import "../DataTable.css";
import "./USAid.css";

import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";
import "datejs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { DataTable } from "primereact/datatable";
import { Button as PButton } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import * as ActionButtons from "../../components/DataTable/ActionButtons";

import { getDateString } from "../../services/formatter";
import GrowlContext from "../../services/growlContext";
import {
  tryCatchServerError,
  getSortIfDescendingFirst,
} from "../../services/functions";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { Popover } from "../../components/Popover/Popover";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
  getChunksFromString,
  MaxCharsAllowed,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { useEnterListener } from "../../hooks/useEnterListener";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";
import {
  rowsPerPageOptionsHighIncrement,
  rowsPerPageOptionsStandard,
} from "../../utils/constants/gridsOptions";
import axios, { AxiosRequestConfig } from "axios";
import { fetchDataNewEnvironment } from "../../services/dropdownOptionListProvider";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";

const icons = {
  location: require("../../assets/icons/v2/icon_location.png")?.default,
  helmet: require("../../assets/icons/v2/icon_helmet.png")?.default,
  number: require("../../assets/icons/v2/icon_number.png")?.default,
  file: require("../../assets/icons/v2/icon_file.png")?.default,
  eligible: require("../../assets/icons/v2/icon_checklist.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
  hands: require("../../assets/icons/Implementer_icon.png")?.default,
  arrowUp: require("../../assets/icons/v2/icon_up_arrow.png")?.default,
  arrowDown: require("../../assets/icons/v2/icon_down_arrow.png")?.default,
};

interface UsAidRecord {
  isMyList: boolean;
  activityId: number;
  activityProjectNumber: string;
  country: string;
  projectName: string;
  projectDescription: string;
  implementingPartner: string;
  technicalSector: string[];
  region: string;
  subagency: string;
  activityStartDate: string;
  activityEndDate: string;
  obligations: number | null;
  disbursements: number | null;
}

interface UsaSpendingAwardDto {
  isMyProject: boolean;
  country: string;
  uniqueAwardId: string; // used in url
  projectNumber: string; // ID
  projectName: string;
  projectDescription: string;
  implementer: string;
  subawardRecipients: string;
  awardMechanism: string;
  awardingAgencyName: string;
  awardingOffice: string;
  parentIDVAgencyName: string;
  naicsCode: string;
  startDate: Date | null;
  currentEndDate: Date | null;
  potentialEndDate: Date | null;
  obligatedAmount: number | null;
  currentAmount: number | null;
  potentialAmount: number | null;
  recipientAddress: string;
  createdOnUtc: Date;
  setAside: string;
}

interface FilteredDate {
  start: {
    from: Date | null;
    to: Date | null;
  };
  close: {
    from: Date | null;
    to: Date | null;
  };
}

// Initialize date to today-1 year
let todayMinusYears = new Date();
todayMinusYears.setFullYear(todayMinusYears.getFullYear() - 1);

let todayDate = Date.today();

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currencyFormatterNoDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function USAidScreen() {
  const [projectSource, setProjectSource] = React.useState({
    fae: false,
    usaspending: true,
  });
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useEffect(() => {
    // when source toggled, collapse filters
    setIsCollapsed(true);
  }, [projectSource]);

  React.useEffect(() => {
    setIsCollapsed(false); // expand on first load
  }, []);

  // Initial prarams
  let onlyMyRecordsArgument = false;
  const styles = useGridStyles();

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid p-justify-start">
        <div className="p-col-12" style={{ textAlign: "start" }}>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(
                projectSource.usaspending,
                "yellow"
              ),
              marginRight: 20,
            }}
            onClick={() => setProjectSource({ fae: false, usaspending: true })}
          >
            USA Spending
          </PButton>
          <PButton
            style={{
              ...styles.mySuggestionsOptions(projectSource.fae, "yellow"),
            }}
            onClick={() => setProjectSource({ fae: true, usaspending: false })}
          >
            Foreign Aid Explorer
          </PButton>
        </div>
      </div>

      {projectSource.fae && (
        <GetUsAidView
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          onlyMyRecordsArgument={onlyMyRecordsArgument}
        />
      )}
      {projectSource.usaspending && (
        <GetUsaSpendingView
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          onlyMyRecordsArgument={onlyMyRecordsArgument}
        />
      )}
    </div>
  );
}

export default USAidScreen;

const GetUsAidView = ({
  setIsCollapsed,
  onlyMyRecordsArgument,
  isCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (x: boolean) => void;
  onlyMyRecordsArgument: boolean;
}) => {
  const context = React.useContext(UserContext);

  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);

  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [data, setData] = React.useState(Array<UsAidRecord>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("lastUpdatedDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [lastUpdated, setLastUpdated] = React.useState("");

  //Available Options
  const [aidCounryNamesOptions, setAidCountryNamesOptions] = React.useState(
    Array<string>()
  );
  const [aidImplementorsOptions, setAidImplementorsOptions] = React.useState(
    Array<string>()
  );
  const [technicalSectorsOptions, setTechnicalSectorsOptions] = React.useState(
    Array<string>()
  );
  const [regionNamesOptions, setRegionNamesOptions] = React.useState(
    Array<string>()
  );
  const [subagencyNamesOptions, setSubagencyNamesOptions] = React.useState(
    Array<string>()
  );

  React.useEffect(() => {
    let apiUrl = "/api/usaid/dropdownoptions/";
    let aidCounryNamesUrl = apiUrl + "aidCountry";
    let aidImplementorsUrl = apiUrl + "aidImplementor";
    let technicalSectorsUrl = apiUrl + "technicalSector";
    let regionNamesUrl = apiUrl + "region";
    let subagencyNamesUrl = apiUrl + "subagency";

    let baseMessage = "error fetching available ";
    let aidCounryNamesMessage = baseMessage + "aid country names";
    let aidImplementorsMessage = baseMessage + "aid implementors";
    let technicalSectorsMessage = baseMessage + "technical sectors";
    let regionNamesMessage = baseMessage + "region names";
    let subagencyNamesMessage = baseMessage + "subagency names";

    fetchDataNewEnvironment(
      aidCounryNamesUrl,
      setAidCountryNamesOptions,
      context,
      aidCounryNamesMessage
    );
    fetchDataNewEnvironment(
      aidImplementorsUrl,
      setAidImplementorsOptions,
      context,
      aidImplementorsMessage,
      true
    );
    fetchDataNewEnvironment(
      technicalSectorsUrl,
      setTechnicalSectorsOptions,
      context,
      technicalSectorsMessage,
      true
    );
    fetchDataNewEnvironment(
      regionNamesUrl,
      setRegionNamesOptions,
      context,
      regionNamesMessage,
      true
    );
    fetchDataNewEnvironment(
      subagencyNamesUrl,
      setSubagencyNamesOptions,
      context,
      subagencyNamesMessage,
      true
    );
  }, []);

  // Filters
  const [closingStartDate, setClosingStartDate] = React.useState<Date | null>(
    todayMinusYears
  );
  const [closingEndDate, setClosingEndDate] = React.useState<Date | null>(null);
  const [updatedStartingDateFilterError, setUpdatedStartedDateFilterError] =
    React.useState(false);
  const [updatedClosingDateFilterError, setUpdatedClosingDateFilterError] =
    React.useState(false);
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>()
  );
  const [selectedTechnicalSector, setTechnicalSector] = React.useState(
    Array<string>()
  );
  const [selectedImplementingPartner, setSelectedImplementingAgency] =
    React.useState(Array<string>());
  const [keyword, setKeyword] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  const [regionName, setRegionName] = React.useState(Array<string>());
  const [subagencyName, setSubagencyName] = React.useState(Array<string>());
  const [activityProjectNumber, setActivityProjectNumber] = React.useState("");

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  const depList = [
    closingStartDate,
    closingEndDate,
    selectedCountryFilter,
    selectedTechnicalSector,
    selectedImplementingPartner,
    keyword,
    onlyMyList,
    regionName,
    subagencyName,
    activityProjectNumber,
    rowsPerPage,
    sortField,
    sortOrder,
    first,
    totalRecords,
  ];

  const queryCallback = React.useCallback(
    () =>
      queryData({
        first,
        rows: rowsPerPage,
        sortField,
        sortOrder,
      }),
    depList
  );
  useEnterListener(queryCallback);

  const dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setFirst(event.first);
    setRowsPerPage(event.rows);
    let request = createAuthenticatedRequest(context);
    setIsLoading(true);
    const url = "/api/usaid";
    const queryString = new URLSearchParams();

    if (activityProjectNumber) {
      queryString.set("activityProjectNumber", activityProjectNumber);
    }

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (onlyMyList) {
      queryString.set("onlyMyList", onlyMyList.toString());
    }

    if (selectedTechnicalSector.length) {
      queryString.set("sector", selectedTechnicalSector.join("|"));
    }

    if (selectedImplementingPartner.length) {
      queryString.set(
        "implementingPartner",
        selectedImplementingPartner.join("|")
      );
    }

    if (closingStartDate && !updatedStartingDateFilterError) {
      queryString.set(
        "closingStartDate",
        closingStartDate.toISOString().substring(0, 10)
      );
    }

    if (closingEndDate && !updatedClosingDateFilterError) {
      queryString.set(
        "closingEndDate",
        closingEndDate.toISOString().substring(0, 10)
      );
    }

    if (regionName.length) {
      queryString.set("regionName", regionName.join("|"));
    }

    if (subagencyName.length) {
      queryString.set("closingEndDate", subagencyName.join("|"));
    }

    if (keyword) {
      queryString.set("filter", keyword);
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<UsAidRecord>;
        setData(d);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: USAID Foreign Aid Explorer</div>
        <div>{lastUpdated}</div>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Projects+",
                `Aidkonekt_projects_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (data: UsAidRecord[]): TableProperties => {
    const columns = [
      { name: "Country" },
      { name: "Project Number" },
      { name: "Project Name" },
      { name: "Project Description" },
      { name: "Implementer" },
      { name: "Subawards" },
      { name: "Technical Sector" },
      { name: "Region" },
      { name: "Subagency" },
      { name: "Activity Start Date" },
      { name: "Activity End Date" },
      { name: "Obligations" },
      { name: "Disbursements" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.country,
          record.activityProjectNumber,
          record.projectName,
          record.projectDescription,
          record.implementingPartner,
          record.technicalSector.join("\n"),
          record.region,
          record.subagency,
          record.activityStartDate,
          record.activityEndDate,
          record.obligations,
          record.disbursements,
        ]);
      }
    }

    return buildExcelTable("ProjectsExport", columns, rows);
  };

  const addRecordToMyList = (record: UsAidRecord) => {
    axios
      .post(
        getFullUrl("/api/usaid/mylist", { useDedicatedEnvironment: true }),
        { activityId: record.activityId },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter((r: any) => r.activityId === record.activityId)
          .forEach((r: UsAidRecord) => (r.isMyList = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added grant '${record.activityProjectNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const removeRecordFromMyList = (record: UsAidRecord) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(context),
      data: { activityId: record.activityId },
    };

    axios
      .delete(
        getFullUrl("/api/usaid/mylist", { useDedicatedEnvironment: true }),
        request
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter((r: any) => r.activityId === record.activityId)
          .forEach((r: UsAidRecord) => (r.isMyList = false));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.activityProjectNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const ActionsButtonColumn = ({ record }: { record: UsAidRecord }) => {
    const add = (
      <ActionButtons.AddToMyListButton add={() => addRecordToMyList(record)} />
    );
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {record.isMyList ? remove : add}
      </div>
    );
  };

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!aidCounryNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Country"
                setSelectedOption={setSelectedCountryFilter}
                allOptions={aidCounryNamesOptions}
                disabled={isLoading || !aidCounryNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!technicalSectorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Technical Sector"
                setSelectedOption={setTechnicalSector}
                allOptions={technicalSectorsOptions}
                disabled={isLoading || !technicalSectorsOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!aidImplementorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Implementers"
                setSelectedOption={setSelectedImplementingAgency}
                allOptions={aidImplementorsOptions}
                disabled={isLoading || !aidImplementorsOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!regionNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Region"
                setSelectedOption={setRegionName}
                allOptions={regionNamesOptions}
                disabled={isLoading || !regionNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.file} alt="map" style={styles.iconFile} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {subagencyNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Subagency"
                setSelectedOption={setSubagencyName}
                allOptions={subagencyNamesOptions}
                disabled={isLoading || !subagencyNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="activityProjectNumber"
              type="search"
              value={activityProjectNumber}
              onChange={(e: any) =>
                setActivityProjectNumber((e.target as any).value)
              }
              placeholder="Opportunity Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show projects closing between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={closingStartDate}
              onError={(e) => setUpdatedStartedDateFilterError(!!e)}
              onChange={(date) => setClosingStartDate(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={closingEndDate}
              onError={(e) => setUpdatedClosingDateFilterError(!!e)}
              onChange={(date) => setClosingEndDate(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <PButton
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({
                  first,
                  rows: rowsPerPage,
                  sortField,
                  sortOrder,
                })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </PButton>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<UsAidRecord[]> => {
    var base = "/api/usaid";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    let request = createAuthenticatedRequest(context);
    var result = Array<UsAidRecord>();
    await axios
      .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
    return result;
  };

  return (
    <>
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="p-col-12" style={styles.pageHeader}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Projects Enhanced USAID Project Data
        </div>

        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          A database of USAID projects that allows you to quickly sort USAID
          project activity by closing date for your target countries,
          competitors, and technical sectors. This tool is designed to deliver
          rapid, landscape snapshots of USAID projects, but is not meant to be
          comprehensive of every USAID project. It is based on USAID project
          data from Foreign Aid Explorer and USA Spending.
        </div>
      </div>

      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Panel
            style={styles.filters.filterBox}
            collapsed={isCollapsed}
            onToggle={(e) => setIsCollapsed(e.value)}
            toggleable
            expandIcon=""
            collapseIcon=""
            header={
              <PanelHeader
                search={() =>
                  queryData({ first, rows: rowsPerPage, sortField, sortOrder })
                }
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                expandedTitle={
                  <div
                    className="p-grid p-justify-start"
                    style={{ ...styles.filters.fontHeader }}
                  >
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="allprojects"
                        value="0"
                        name="allprojects"
                        onChange={() => setOnlyMyList(false)}
                        checked={!onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="allprojects">
                        Show all Projects
                      </label>
                    </div>
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="myprojects"
                        value="1"
                        name="myprojects"
                        onChange={() => setOnlyMyList(true)}
                        checked={onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="myprojects">
                        Show my Projects
                      </label>
                    </div>
                  </div>
                }
              />
            }
          >
            {renderSearch()}
          </Panel>
        </MuiPickersUtilsProvider>

        <Popover
          dialogHeader={dialogHeader}
          isVisible={dialogVisible}
          dialogText={dialogText}
          setDialogVisible={setDialogVisible}
        />

        <DataTable
          value={data}
          style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
          paginator={true}
          rows={rowsPerPage}
          totalRecords={totalRecords}
          lazy={true}
          first={first}
          onPage={queryData}
          loading={isLoading}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={sort}
          scrollable={true}
          scrollHeight="400px"
          ref={dt}
          header={tableHeader}
          exportFilename={`Aidkonekt_projects_plus_${new Date().getFullYear()}`}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={rowsPerPageOptionsStandard}
        >
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => (
              // <ActionsButtonColumn record={x} />
              <></>
            ),
            "Add to My Projects",
            "activityProjectNumber",
            { sortable: false }
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.country,
            "Country",
            "country"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.activityProjectNumber,
            "Project Number",
            "activityProjectNumber"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.projectName,
            "Project Name",
            "projectName"
          )}
          {GetCustomColumnShortened<UsAidRecord>(
            styles,
            150,
            (x) => x.projectDescription,
            (x) => x.activityProjectNumber,
            "Project Description",
            150,
            true,
            "projectDescription"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.implementingPartner,
            "Implementer",
            "implementingPartner"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.technicalSector.map((x, i) => <p key={i}>{x}</p>),
            "Technical Sector",
            "technicalSector"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.region,
            "Region",
            "region"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => x.subagency,
            "Subagency",
            "subagency"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => formatDate(x.activityStartDate, DateFormat.dd_MMMM_yyyy),
            "Activity Start Date",
            "activityStartDate"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) => formatDate(x.activityEndDate, DateFormat.dd_MMMM_yyyy),
            "Activity End Date",
            "activityEndDate"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) =>
              x.obligations ? currencyFormatter.format(x.obligations) : "",
            "Obligations",
            "obligations"
          )}
          {GetCustomColumnBody<UsAidRecord>(
            styles,
            150,
            (x) =>
              x.disbursements ? currencyFormatter.format(x.disbursements) : "",
            "Disbursements",
            "disbursements"
          )}
        </DataTable>
      </>
    </>
  );
};

const GetUsaSpendingView = ({
  setIsCollapsed,
  onlyMyRecordsArgument,
  isCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (x: boolean) => void;
  onlyMyRecordsArgument: boolean;
}) => {
  const context = React.useContext(UserContext);

  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [data, setData] = React.useState(Array<UsaSpendingAwardDto>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("lastUpdatedDate");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [lastUpdated, setLastUpdated] = React.useState("");

  //Available Options
  const [usaspendingCountryNamesOptions, setUsaspendingCountryNamesOptions] =
    React.useState(Array<string>());
  const [usaspendingImplementorsOptions, setUsaspendingImplementorsOptions] =
    React.useState(Array<string>());
  const [usaSubawardeesOptions, setUsaSubawardeesOptions] = React.useState(
    Array<string>()
  );
  const [mechanismsOptions, setMechanismsOptions] = React.useState(
    Array<{ key: string; value: string }>()
  );
  const [naicsesOptions, setNaicsesOptions] = React.useState(Array<string>());
  const [setAsidesOptions, setSetAsidesOptions] = React.useState(
    Array<string>()
  );

  React.useEffect(() => {
    let apiUrl = "/api/usaspending/dropdownoptions/";
    let usaspendingCountryNamesUrl = apiUrl + "country";
    let usaspendingImplementorsUrl = apiUrl + "implementor";
    let usaSubawardsUrl = apiUrl + "subawards";
    let mechanismsUrl = apiUrl + "mechanism";
    let naicsesUrl = apiUrl + "naics";
    let setAsidesUrl = apiUrl + "setAside";

    let baseMessage = "error fetching available ";
    let usaspendingCountryNamesMessage =
      baseMessage + "usaspending country names";
    let usaspendingImplementorsMessage =
      baseMessage + "usaspending implementors";
    let usaSubawardsUrlMessage = baseMessage + "usaspending subwards";
    let mechanismsMessage = baseMessage + "mechanisms";
    let naicsesMessage = baseMessage + "naicses";
    let setAsidesMessage = baseMessage + "set asides";

    fetchDataNewEnvironment(
      usaspendingCountryNamesUrl,
      setUsaspendingCountryNamesOptions,
      context,
      usaspendingCountryNamesMessage,
      true
    );
    fetchDataNewEnvironment(
      usaspendingImplementorsUrl,
      setUsaspendingImplementorsOptions,
      context,
      usaspendingImplementorsMessage,
      true
    );
    fetchDataNewEnvironment(
      usaSubawardsUrl,
      setUsaSubawardeesOptions,
      context,
      usaSubawardsUrlMessage,
      true
    );
    fetchDataNewEnvironment(
      mechanismsUrl,
      setMechanismsOptions,
      context,
      mechanismsMessage,
      false,
      true
    );
    fetchDataNewEnvironment(
      naicsesUrl,
      setNaicsesOptions,
      context,
      naicsesMessage,
      true
    );
    fetchDataNewEnvironment(
      setAsidesUrl,
      setSetAsidesOptions,
      context,
      setAsidesMessage,
      true
    );
  }, []);

  // Filters
  const [updatedDateFilter, setUpdatedDateFilter] =
    React.useState<FilteredDate>({
      start: {
        from: null,
        to: null,
      },
      close: {
        from: todayDate,
        to: null,
      },
    });
  const [updatedStartFromDateFilterError, setUpdatedStartFromDateFilterError] =
    React.useState(false);
  const [updatedStartToDateFilterError, setUpdatedStartToDateFilterError] =
    React.useState(false);
  const [updatedCloseFromDateFilterError, setUpdatedCloseFromDateFilterError] =
    React.useState(false);
  const [updatedCloseToDateFilterError, setUpdatedCloseToDateFilterError] =
    React.useState(false);
  const [recentlyAwardedProjects, setRecentlyAwardedProjects] =
    React.useState<boolean>(false);
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>()
  );
  const [selectedImplementingPartner, setSelectedImplementingAgency] =
    React.useState(Array<string>());
  const [selectedSubawardees, setSelectedSubawardees] = React.useState(
    Array<string>()
  );
  const [keyword, setKeyword] = React.useState("");
  const [projectNumberFilter, setProjectNumberFilter] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  const [mechanism, setMechanism] = React.useState(Array<string>());
  const [naics, setNaics] = React.useState(Array<string>());
  const [setAside, setSetAside] = React.useState(Array<string>());
  const [withMinimumAmount, setWithMinimumAmount] = React.useState(true);
  const [minimumAmount, setMinimumAmount] = React.useState(500000);

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    if (recentlyAwardedProjects) {
      setUpdatedDateFilter({
        start: {
          from: new Date(new Date().setDate(new Date().getDate() - 90)),
          to: todayDate,
        },
        close: {
          from: null,
          to: null,
        },
      });
    } else {
      setUpdatedDateFilter({
        start: {
          from: null,
          to: null,
        },
        close: {
          from: todayDate,
          to: null,
        },
      });
    }
  }, [recentlyAwardedProjects]);

  const depList = [
    updatedDateFilter.start.from,
    updatedDateFilter.start.to,
    updatedDateFilter.close.from,
    updatedDateFilter.close.to,
    recentlyAwardedProjects,
    selectedCountryFilter,
    selectedImplementingPartner,
    selectedSubawardees,
    keyword,
    projectNumberFilter,
    onlyMyList,
    mechanism,
    naics,
    setAside,
    rowsPerPage,
    sortField,
    sortOrder,
    first,
    totalRecords,
  ];

  const queryCallback = React.useCallback(
    () =>
      queryData({
        first,
        rows: rowsPerPage,
        sortField,
        sortOrder,
      }),
    depList
  );
  useEnterListener(queryCallback);

  const dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setFirst(event.first);
    setRowsPerPage(event.rows);
    let request = createAuthenticatedRequest(context);
    setIsLoading(true);
    const url = "/api/usaspending";
    const queryString = new URLSearchParams();

    if (
      updatedDateFilter.close.from &&
      updatedDateFilter.close.to &&
      !updatedCloseFromDateFilterError
    ) {
      queryString.set(
        "dateCloseFrom",
        updatedDateFilter.close.from.toISOString().substring(0, 10)
      );
    }

    if (
      updatedDateFilter.close.from &&
      updatedDateFilter.close.to &&
      !updatedCloseToDateFilterError
    ) {
      queryString.set(
        "dateCloseTo",
        updatedDateFilter.close.to.toISOString().substring(0, 10)
      );
    }

    if (updatedDateFilter.start.from && !updatedStartFromDateFilterError) {
      queryString.set(
        "dateStartFrom",
        updatedDateFilter.start.from.toISOString().substring(0, 10)
      );
    }

    if (updatedDateFilter.start.to && !updatedStartToDateFilterError) {
      queryString.set(
        "dateStartTo",
        updatedDateFilter.start.to.toISOString().substring(0, 10)
      );
    }

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (selectedImplementingPartner.length) {
      queryString.set("implementer", selectedImplementingPartner.join("|"));
    }

    if (selectedSubawardees.length) {
      queryString.set("subawardRecipient", selectedSubawardees.join("|"));
    }

    if (keyword) {
      queryString.set("keyword", keyword);
    }

    if (projectNumberFilter.length) {
      queryString.set("projectNumber", projectNumberFilter);
    }

    if (mechanism.length) {
      var keys: string[] = [];
      mechanism.forEach((m) => {
        var s = mechanismsOptions
          ? mechanismsOptions.find((x) => x.value == m)
          : null;
        if (s) {
          keys.push(s.key);
        }
      });
      queryString.set("mechanism", keys.join("|"));
    }

    if (naics.length) {
      queryString.set("naics", naics.join("|"));
    }

    if (setAside.length) {
      queryString.set("setAside", setAside.join("|"));
    }

    if (onlyMyList) {
      queryString.set("isMyList", onlyMyList.toString());
    }

    if (withMinimumAmount && minimumAmount > 0) {
      queryString.set("minimumAmount", minimumAmount.toString());
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<UsaSpendingAwardDto>;
        setData(d);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: USA Spending </div>
        <div>{lastUpdated}</div>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "USA Spending",
                `Aidkonekt_usa_spending_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (
    data: UsaSpendingAwardDto[]
  ): TableProperties => {
    const columns = [
      { name: "Country" },
      { name: "Project Number" },
      { name: "Project Name" },
      { name: "Project Description" },
      { name: "Implementer" },
      { name: "Subawards" },
      { name: "Award Mechanism" },
      { name: "Awarding Agency Name" },
      { name: "Awarding Office" },
      { name: "Parent IDV Agency Name" },
      { name: "Set-Aside Type" },
      { name: "NAICS Code" },
      { name: "Start Date" },
      { name: "Current End Date" },
      { name: "Potential End Date" },
      { name: "Obligated Amount" },
      { name: "Current Amount" },
      { name: "Potential Amount" },
      { name: "Recipient Address" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        const chunkDescription = getChunksFromString(
          record.projectDescription,
          MaxCharsAllowed
        );

        rows.push([
          record.country,
          record.projectNumber,
          record.projectName,
          chunkDescription[0] || "",
          record.implementer,
          record.subawardRecipients ?? "",
          getDispayForMechanism(record.awardMechanism),
          record.awardingAgencyName,
          record.awardingOffice,
          record.parentIDVAgencyName,
          record.setAside,
          record.naicsCode,
          formatDate(record.startDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.currentEndDate, DateFormat.dd_MMMM_yyyy),
          formatDate(record.potentialEndDate, DateFormat.dd_MMMM_yyyy),
          record.obligatedAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.obligatedAmount)
              )
            : "",
          record.currentAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.currentAmount)
              )
            : "",
          record.potentialAmount !== null
            ? currencyFormatterNoDecimals.format(
                Math.round(record.potentialAmount)
              )
            : "",
          record.recipientAddress,
        ]);

        for (let j = 1; j < chunkDescription.length; j++) {
          let emptyRow = genEmptyRow(columns.length);
          emptyRow[3] = chunkDescription[j];
          rows.push(emptyRow);
        }
      }
    }

    return buildExcelTable("USASpending", columns, rows);
  };

  const addRecordToMyList = (record: UsaSpendingAwardDto) => {
    axios
      .post(
        getFullUrl("/api/usaspending/mylist", {
          useDedicatedEnvironment: true,
        }),
        { uniqueAwardId: record.uniqueAwardId },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: Partial<UsaSpendingAwardDto>) =>
              r.uniqueAwardId === record.uniqueAwardId
          )
          .forEach((r: UsaSpendingAwardDto) => (r.isMyProject = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added USA Record '${record.projectNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const removeRecordFromMyList = (record: UsaSpendingAwardDto) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(context),
      data: { uniqueAwardId: record.uniqueAwardId },
    };
    axios
      .delete(
        getFullUrl("/api/usaspending/mylist", {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter(
            (r: Partial<UsaSpendingAwardDto>) =>
              r.uniqueAwardId === record.uniqueAwardId
          )
          .forEach((r: UsaSpendingAwardDto) => (r.isMyProject = false));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.projectNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const ActionsButtonColumn = ({ record }: { record: UsaSpendingAwardDto }) => {
    const add = (
      <ActionButtons.AddToMyListButton add={() => addRecordToMyList(record)} />
    );
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {record.isMyProject ? remove : add}
      </div>
    );
  };

  function getDispayForMechanism(awardMechanism: string): any {
    var mech = mechanismsOptions
      ? mechanismsOptions.find((m) => m.key == awardMechanism)
      : null;
    return mech ? mech.value : "";
  }

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaspendingCountryNamesOptions.length ? (
              <CustomSearchSelect
                placeholder="Country"
                setSelectedOption={setSelectedCountryFilter}
                allOptions={usaspendingCountryNamesOptions}
                disabled={isLoading || !usaspendingCountryNamesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaspendingImplementorsOptions.length ? (
              <CustomSearchSelect
                placeholder="Implementer"
                setSelectedOption={setSelectedImplementingAgency}
                allOptions={usaspendingImplementorsOptions}
                disabled={isLoading || !usaspendingImplementorsOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.hands} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!usaSubawardeesOptions.length ? (
              <CustomSearchSelect
                placeholder="Subawardees"
                setSelectedOption={setSelectedSubawardees}
                allOptions={usaSubawardeesOptions}
                disabled={isLoading || !usaSubawardeesOptions}
                addAllFilteredResults={true}
                useContainsFilter={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="projectNumber"
              type="search"
              value={projectNumberFilter}
              onChange={(e: any) =>
                setProjectNumberFilter((e.target as any).value)
              }
              placeholder="Project Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.file} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!mechanismsOptions?.length ? (
              <CustomSearchSelect
                placeholder="Award Mechanism"
                setSelectedOption={setMechanism}
                allOptions={
                  mechanismsOptions
                    ? mechanismsOptions.map((x) => x.value)
                    : null
                }
                disabled={isLoading || !mechanismsOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!naicsesOptions.length ? (
              <CustomSearchSelect
                placeholder="NAICS Code"
                setSelectedOption={setNaics}
                allOptions={naicsesOptions}
                disabled={isLoading || !naicsesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!setAsidesOptions.length ? (
              <CustomSearchSelect
                placeholder="Set-Aside Type"
                setSelectedOption={setSetAside}
                allOptions={setAsidesOptions}
                disabled={isLoading || !setAsidesOptions}
                addAllFilteredResults={true}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show projects closing between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.from}
              onError={(e) => setUpdatedCloseFromDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: date,
                    to: updatedDateFilter.close.to,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.close.to}
              onError={(e) => setUpdatedCloseToDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: updatedDateFilter.start,
                  close: {
                    from: updatedDateFilter.close.from,
                    to: date,
                  },
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <Checkbox
              inputId="withMimimumAmountCB"
              value="withMinimumAmount"
              onChange={(e) => setWithMinimumAmount(e.checked)}
              checked={withMinimumAmount}
            />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Only show Projects over{" "}
            </label>
            <InputNumber
              style={styles.filters.inputTextLabel}
              id="minimumAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              maxFractionDigits={0}
              value={minimumAmount}
              onChange={(e: any) => setMinimumAmount(e.value)}
              placeholder="Minimum Amount"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={{ ...styles.filters.notTopFilterRow, paddingTop: 7 }}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show project starting between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.from}
              onError={(e) => setUpdatedStartFromDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: date,
                    to: updatedDateFilter.start.to,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter.start.to}
              onError={(e) => setUpdatedStartToDateFilterError(!!e)}
              onChange={(date) =>
                setUpdatedDateFilter({
                  start: {
                    from: updatedDateFilter.start.from,
                    to: date,
                  },
                  close: updatedDateFilter.close,
                })
              }
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading || recentlyAwardedProjects}
            />
          </div>
        </div>

        <div
          className="p-col-12 p-md-6 p-lg-12"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 7,
            marginBottom: 10,
            marginLeft: 5,
            textAlign: "left",
          }}
        >
          <Checkbox
            inputId="swa"
            value="showAwardsOnly"
            onChange={(e) => setRecentlyAwardedProjects(e.checked)}
            checked={recentlyAwardedProjects}
          />
          <label
            htmlFor="swa"
            className="p-checkbox-label"
            style={{
              ...styles.filters.inputTextLabel,
              marginLeft: 20,
              marginBottom: 3,
            }}
          >
            Show recently awarded projects (last 90 days)
          </label>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <PButton
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({
                  first,
                  rows: rowsPerPage,
                  sortField,
                  sortOrder,
                })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </PButton>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<UsaSpendingAwardDto[]> => {
    var base = "/api/usaspending";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    let request = createAuthenticatedRequest(context);
    var result = Array<UsaSpendingAwardDto>();
    await axios
      .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
    return result;
  };

  return (
    <>
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="p-col-12" style={styles.pageHeader}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Projects Enhanced USA Spending Project Data
        </div>

        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          A database of USAID projects that allows you to quickly sort USAID
          project activity by closing date for your target countries,
          competitors, and technical sectors. This tool is designed to deliver
          rapid, landscape snapshots of USAID projects, but is not meant to be
          comprehensive of every USAID project. It is based on USAID project
          data from USA Spending.
        </div>
      </div>

      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Panel
            style={styles.filters.filterBox}
            collapsed={isCollapsed}
            onToggle={(e) => setIsCollapsed(e.value)}
            toggleable
            expandIcon=""
            collapseIcon=""
            header={
              <PanelHeader
                search={() =>
                  queryData({ first, rows: rowsPerPage, sortField, sortOrder })
                }
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                expandedTitle={
                  <div
                    className="p-grid p-justify-start"
                    style={{ ...styles.filters.fontHeader }}
                  >
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="allprojects"
                        value="0"
                        name="allprojects"
                        onChange={() => setOnlyMyList(false)}
                        checked={!onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="allprojects">
                        Show all Projects
                      </label>
                    </div>
                    <div
                      className="p-col-12 p-md-6 p-lg-4"
                      style={{ textAlign: "left" }}
                    >
                      <RadioButton
                        id="myprojects"
                        value="1"
                        name="myprojects"
                        onChange={() => setOnlyMyList(true)}
                        checked={onlyMyList}
                      />
                      <label style={{ marginLeft: 5 }} htmlFor="myprojects">
                        Show my Projects
                      </label>
                    </div>
                  </div>
                }
              />
            }
          >
            {renderSearch()}
          </Panel>
        </MuiPickersUtilsProvider>

        <Popover
          dialogHeader={dialogHeader}
          isVisible={dialogVisible}
          dialogText={dialogText}
          setDialogVisible={setDialogVisible}
        />

        <DataTable
          value={data}
          style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
          paginator={true}
          rows={rowsPerPage}
          totalRecords={totalRecords}
          lazy={true}
          first={first}
          onPage={queryData}
          loading={isLoading}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={sort}
          scrollable={true}
          scrollHeight="400px"
          ref={dt}
          header={tableHeader}
          exportFilename={`Aidkonekt_projects_plus_${new Date().getFullYear()}`}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={rowsPerPageOptionsHighIncrement}
        >
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => (
              <div className="actions-column">
                {/* <NavigateToButton
                  openPage={() => window.open("/project-details")}
                /> */}
                <ActionsButtonColumn record={x} />
              </div>
            ),
            "Add to My Projects",
            "activityProjectNumber",
            { sortable: false }
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.country,
            "Country",
            "country"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.projectNumber,
            "Project Number",
            "projectNumber"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.projectName,
            "Project Name",
            "projectName"
          )}
          {GetCustomColumnShortened<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.projectDescription,
            (x) => x.projectNumber,
            "Description",
            150,
            true,
            "projectDescription"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.implementer,
            "Implementer",
            "implementer"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.subawardRecipients ?? "",
            "Subawards",
            "subawards"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => getDispayForMechanism(x.awardMechanism),
            "Award Mechanism",
            "awardMechanism"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.awardingAgencyName,
            "Awarding Agency Name",
            "awardingAgencyName"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.awardingOffice,
            "Awarding Office",
            "awardingOffice"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.parentIDVAgencyName,
            "Parent IDV Agency Name",
            "parentIDVAgencyName"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.setAside,
            "Set-Aside Type",
            "setAside"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.naicsCode,
            "NAICS Code",
            "naicsCode"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => formatDate(x.startDate, DateFormat.dd_MMMM_yyyy),
            "Start Date",
            "startDate"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => formatDate(x.currentEndDate, DateFormat.dd_MMMM_yyyy),
            "Current End Date",
            "currentEndDate"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => formatDate(x.potentialEndDate, DateFormat.dd_MMMM_yyyy),
            "Potential End Date",
            "potentialEndDate"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) =>
              x.obligatedAmount !== null
                ? currencyFormatterNoDecimals.format(
                    Math.round(x.obligatedAmount)
                  )
                : "",
            "Obligated Amount",
            "obligatedAmount"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) =>
              x.currentAmount !== null
                ? currencyFormatterNoDecimals.format(
                    Math.round(x.currentAmount)
                  )
                : "",
            "Current Amount",
            "currentAmount"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) =>
              x.potentialAmount !== null
                ? currencyFormatterNoDecimals.format(
                    Math.round(x.potentialAmount)
                  )
                : "",
            "Potential Amount",
            "potentialAmount"
          )}
          {GetCustomColumnBody<UsaSpendingAwardDto>(
            styles,
            150,
            (x) => x.recipientAddress,
            "Recipient Address",
            "recipientAddress"
          )}
        </DataTable>
      </>
    </>
  );
};
