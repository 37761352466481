import { any } from 'glamor';
import * as React from 'react';
import { useGridStyles } from '../../hooks/useGridStyles';

const icons = {
    add: require('../../assets/icons/v2/icon-11.png')?.default,
    remove: require('../../assets/icons/v2/icon_Subtract.png')?.default,
    goTo: require('../../assets/icons/v2/icon_Link-out.png')?.default
};

export const AddToMyListButton: React.FunctionComponent<{ add: () => void }> = ({ add }) => {
    const styles = useGridStyles();
    return (
        <img src={icons.add}
            style={styles.addRemoveButtonStyle}
            onClick={add}>
        </img>
    )
};

export const RemoveFromMyListButton: React.FunctionComponent<{ remove: () => void }> = ({ remove }) => {
    const styles = useGridStyles();
    return (
        <img src={icons.remove}
            style={styles.addRemoveButtonStyle}
            onClick={remove}>
        </img>
    );
}

export const NavigateToButton: React.FunctionComponent<{
    disabled?: any,
    openPage: () => void
}> = ({ openPage, disabled }) => {
    const styles = useGridStyles();
    return (
        <img src={icons.goTo}
            style={{ ...styles.navigateToExternalSiteButtonStyle, cursor: disabled ? "default" : "pointer" }}
            onClick={disabled ? () => { } : openPage}>
        </img>
    );
}

let gridStyle: string | null;
let gridBodyStyle: string | null;
let eventListener: any;


export const MaximizeGrid = (element: HTMLElement, backdropClicked: () => void, fixed?: boolean): void => {
    const grid = element.closest('.p-datatable-scrollable');

    if (grid) {
        eventListener = (e: Event) => backdropClick(e, backdropClicked, grid as HTMLElement);
        grid.addEventListener('click', eventListener, false);

        gridStyle = grid.getAttribute('style');
        const fullScreenGridStyle = `position: ${fixed ? 'fixed' : 'absolute'};` +
            'left: 50%;' +
            'transform: translate(-50%, -50%);' +
            'background-color: rgba(0,0,0,0.7);' +
            'width: 100%;' +
            'height: 100%;' +
            'z-index: 100000;' +
            'top: 50%;' +
            'margin-top: 0px;' +
            'padding: 2%;';

        grid.setAttribute('style', fullScreenGridStyle);

        const gridHeader = grid.querySelector('.p-datatable-header');
        const gridFooter = grid.querySelector('.p-paginator-bottom');

        const gridHeaderHeight = getElementHeight(gridHeader as HTMLElement);
        const gridFooterHeight = getElementHeight(gridFooter as HTMLElement);
        const mainHeight = document.documentElement.clientHeight;

        const bodySizeCalculation = (mainHeight * 0.8) - gridHeaderHeight - gridFooterHeight;
        const gridBody = grid.querySelector('.p-datatable-scrollable-body');

        if(gridBody){
            gridBodyStyle = gridBody.getAttribute('style');
            const fullScreenGridBodyStyle = 'overflow-y: scroll;' +
            `max-height: ${bodySizeCalculation}px;`;

            gridBody.setAttribute('style', fullScreenGridBodyStyle);
        }
    }
}

export const MinimizeGrid = (element: HTMLElement): void => {
    const grid = element.closest('.p-datatable-scrollable');

    if (grid) {
        grid.removeEventListener('click', eventListener, false);

        grid.setAttribute('style', gridStyle || '');
        gridStyle = null;

        const gridBody = grid.querySelector('.p-datatable-scrollable-body');

        if(gridBody){
            gridBody.setAttribute('style', gridBodyStyle || '');
            gridBodyStyle = null;
        }
    }
}

const backdropClick = (event: Event, callback: () => void, grid: HTMLElement): void => {
    if(grid == event.target){
        event.preventDefault();
        MinimizeGrid(grid);
        callback();
    }
}

const getElementHeight = (element: HTMLElement): number => {
    if (!element) return 0;

    const rect = element.getBoundingClientRect();
    return rect ? rect.height : 0;
}
