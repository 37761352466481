import { Button } from "primereact/button";
import React, { FC, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { SubHeaders } from "../../../components/Dashboard/SectionHeader";
import "../Pipeline.css";
import { PipelineItems } from "./PipelineItems";
import AidkonektIcon from "../../../assets/icons/v2/icon_Globe.png";
import { OverlayPanel } from "primereact/overlaypanel";
import Details from "./SideBar";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
type PipelineDto = {
  id: number;
  name: string;
  description: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: [];
  marketPreferences: [];
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
};
const PipelineDetail: React.FunctionComponent = () => {
  const context = React.useContext(UserContext);
  const location = useLocation();
  const [pipeline, setPipeline] = React.useState(location.state as PipelineDto);
  const [isPipelineOwner, setIspipelineOwner] = React.useState(false);
  const sp = useRef<OverlayPanel>(null);
  const [togglePrivacyTab, setTogglePrivacyTab] = React.useState(false);
  const [toggleView, setToggleViewMode] = React.useState(false);

  // extract pipeline id from path.
  const path = window.location.pathname.split("/");
  const pipelineId = parseInt(path[path.length - 1]);

  React.useEffect(() => {
    const loadPipeline = (pipelineId: number) => {
      axios
        .get(
          getFullUrl(`/api/pipeline/${pipelineId}`, {
            useDedicatedEnvironment: true,
          }),
          createRequestWithAuthHeaders(context)
        )
        .then((res) => {
          const data: PipelineDto = res.data as PipelineDto;
          let pipelineOwner = data.pipelineUserRole === "Admin";
          setIspipelineOwner(pipelineOwner);
          setPipeline(data);
        })
        .catch(() => {});
    };
    if (pipeline == null) {
      loadPipeline(pipelineId);
    } else {
      let pipelineOwner = pipeline.pipelineUserRole === "Admin";
      setIspipelineOwner(pipelineOwner);
    }
  }, [context, pipeline, pipelineId]);

  return (
    <div className="pipeline-details-main">
      <div className="pipeline-details-pipelines">
        <Link to={"/pipeline"}>
          <i className="pi pi-arrow-left"></i>View All Pipelines
        </Link>
      </div>
      <div className="pipeline-detail-container">
        <div className="pipeline-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={AidkonektIcon}
              alt="Aidkonekt Icon"
              style={{ height: "25px", width: "auto" }}
            />
            &nbsp;
            <span className="pipeline-title">{pipeline?.name}</span>
            <span>
              {/* <Dropdown optionLabel="name" value={city} options={pipeline} onChange={(e) => setCity(e.value)} placeholder="Select a City"/> */}
            </span>
          </div>
          <p className="header-pipeline">{pipeline?.description}</p>
        </div>
        <div className="pipeline-buttons">
          {isPipelineOwner && (
            <>
              <Button
                onClick={(e: React.SyntheticEvent<Element, Event>) => {
                  sp.current?.toggle(e);
                }}
                style={{ marginRight: "6px", fontSize: "13px" }}
                className="modal-button"
                label="Share Pipeline"
              />
              {/* <Button style={{fontSize:"13px"}} onClick={(e:React.SyntheticEvent<Element,Event>)=>{sp.current?.toggle(e)}}  className="modal-button" label="Edit Pipeline"/> */}
            </>
          )}
        </div>
      </div>
      <PipelineItems id={pipelineId} />
      <OverlayPanel
        // onHide={handleOnhide }
        ref={sp}
        showCloseIcon
        id="overlay_panel"
        style={{ left: "none", right: "0px !important" }}
      >
        <div className="side-panel-container">
          <div style={{ width: "400px", height: "107vh" }}>
            <div className="side-panel-header">
              <SubHeaders text={pipeline?.name} />
            </div>
            <div className="tabview">
              <div>
                <Details
                  divRef={sp}
                  pipelines={pipeline}
                  toggleEdits={togglePrivacyTab}
                  toggleViewMode={toggleView}
                />
              </div>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default PipelineDetail;
