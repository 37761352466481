import * as React from "react";
import "../../DataTable.css";
import "../Pipeline.css"
import { useGridStyles } from "../../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import { PanelHeader } from "../../../components/DataTable/PanelHeader";

import { MyBids, MySuggestionsBusinessForecast } from "../../MyBids/MyBids";

import {
  ContractsTableOnly,
  MySuggestionsContracts,
} from "../../ContractTableScreen/ContractTableScreen";
import {
  GrantsTableOnly,
  MySuggestionsGrants,
} from "../../GrantsTableScreen/GrantsTableScreen";
import { Button } from "primereact/button";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";

import UserContext from "../../../services/UserContext";
import GrowlContext from "../../../services/growlContext";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { id } from "date-fns/locale";
const icons = {
  notification: require("../../../assets/icons/v2/icon_Notfication.png")?.default,
};

type pipelineID = {
  id:number 
}
export const PipelineItems: React.FunctionComponent<pipelineID> = ({id}) => {
  const styles = useGridStyles();
  const user = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  // Open section from email if exists
  let initialSection: "" | "businessforecast" | "contract" | "grant" = "";
  const location = useLocation();
  if (location && location.search) {
    initialSection = (parse(location.search).section as string) || ("" as any);
  }

  // Grid-specific state
  const [isMySuggestionsCollapsed, setIsMySuggestionsCollapsed] =
    React.useState(true);
  const [isMyForecastsCollapsed, setIsMyForecastsCollapsed] = React.useState(
    initialSection !== "businessforecast"
  );
  const [isMyContractsCollapsed, setIsMyContractsCollapsed] = React.useState(
    initialSection !== "contract"
  );
  const [isMyGrantsCollapsed, setIsMyGrantsCollapsed] = React.useState(
    initialSection !== "grant"
  );

  // MySuggestions state
  
  const [myForecastSelected, setMyForecastSelected] = React.useState(true);
  const [myGrantSelected, setMyGrantSelected] = React.useState(false);
  const [myContractSelected, setMyContractSelected] = React.useState(false);
  const [mySuggestionOppCount, setMySuggestionOppCount] = React.useState(0);
  const [myForecastOppCount, setMyForecastOppCount] = React.useState(0);
  const [myGrantOppCount, setMyGrantOppCount] = React.useState(0);
  const [myContractOppCount, setMyContractOppCount] = React.useState(0);

  React.useEffect(() => {
    //  contracts
    axios
    .get(
      getFullUrl(`/api/pipeline/${id}/contract/suggestion`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(user)
    )
    .then((response) => {
      const {totalItems} = response.data
      setMyContractOppCount(totalItems)
    });

    //  forecast
    axios
    .post(
      getFullUrl(`/api/pipeline/${id}/forecast/suggestion`, {
        useDedicatedEnvironment: true,
      }),{
        // TO DO : useState
        first:0,
        rows: 20,
        sort: { sortField:'', sortOrder:0 },
        keyword:'',
      },
      createAuthenticatedRequest(user)
    )
    .then((response) => {
      const {totalItems} = response.data
      setMyForecastOppCount(totalItems)
    });

    //  grants 
    axios
    .get(
      getFullUrl(`/api/pipeline/${id}/grant/suggestion`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(user)
    )
    .then((response) => {
      
      const {totalItems} = response.data
    
      setMyGrantOppCount(totalItems)
    });
  },[]);

  const setSelected = (type: "forecast" | "grant" | "contract") => {
    switch (type) {
      case "forecast":
        setMyContractSelected(false);
        setMyGrantSelected(false);
        setMyForecastSelected(true);
        break;
      case "grant":
        setMyContractSelected(false);
        setMyGrantSelected(true);
        setMyForecastSelected(false);
        break;
      case "contract":
        setMyContractSelected(true);
        setMyGrantSelected(false);
        setMyForecastSelected(false);
        break;
    }
  };

  const notificationIcon = (
    <div style={{ paddingTop: 10, marginRight: "-16px" }}>
      <img src={icons.notification} style={styles.icon}></img>
      {/* TO DO */}
      {myForecastOppCount+myGrantOppCount+myContractOppCount > 0
        ? (
        <span style={styles.mySuggestionsRedBadge}>{
          myForecastOppCount+myGrantOppCount+myContractOppCount
          }
          </span>
          )
        : <span style={styles.mySuggestionsTransparent} />}
    </div>
  );

  const [removeAll, setRemoveAll] = React.useState(false);

  return (
    <div className="p-grid p-dir-col p-col-12">
      <div className="p-col-2"></div>
      <div className="p-col-12">
        <Panel
          style={{
            ...styles.filters.filterBox,
            marginTop: 0,
            borderColor: styles.theme.v2.lightBlue,
          }}
          collapsed={isMySuggestionsCollapsed}
          onToggle={(e) => setIsMySuggestionsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <div className="suggestions-header">
              <PanelHeader
                title="MySuggestions"
                isCollapsed={isMySuggestionsCollapsed}
                setIsCollapsed={setIsMySuggestionsCollapsed}
                collapserIcon={notificationIcon}
                expanderIcon={notificationIcon}
              />
            </div>
          }
        >
          <div className="p-grid p-justify-start">
            <div className="p-col-12" style={{ textAlign: "start" }}>
              <Button
                className="p-button bordered p-no-hover"
                style={styles.mySuggestionsOptions(myForecastSelected, "blue")}
                onClick={() => setSelected("forecast")}
              >
                New Forecast Opps
              </Button>
              {myForecastOppCount > 0 ? (
                <span style={styles.mySuggestionsBadge}>
                  {myForecastOppCount}
                </span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={styles.mySuggestionsOptions(myContractSelected, "blue")}
                className="p-no-hover"
                onClick={() => setSelected("contract")}
              >
                New Contracts Opps
              </Button>
              {myContractOppCount > 0
                ? (
                <span style={styles.mySuggestionsBadge}>
                  {myContractOppCount}
                </span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={styles.mySuggestionsOptions(myGrantSelected, "blue")}
                className="p-no-hover"
                onClick={() => setSelected("grant")}
              >
                New Grants Opps
              </Button>
              {myGrantOppCount > 0 ? (
                <span style={styles.mySuggestionsBadge}>{myGrantOppCount}</span>
              ) :
                <span style={styles.mySuggestionsTransparent} />
              }
              <Button
                style={{
                  ...styles.mySuggestionsOptions(false, "blue"),
                  backgroundColor: "#FF0000",
                  borderColor: "#FF0000",
                  paddingRight: 12,
                }}
                className="clear-btn"
                onClick={async () => {
                  setRemoveAll(true);
                  setTimeout(() => {
                    axios
                      .get(
                        getFullUrl(`/api/pipeline/${id}/contract/suggestion`, {
                          useDedicatedEnvironment: true,
                        }),
                        createAuthenticatedRequest(user)
                      )
                      .then((response) => {
                        const {totalItems} = response.data
                        setMyContractOppCount(totalItems)
                      });
                    //  forecast
                    axios
                      .get(
                        getFullUrl(`/api/pipeline/${id}/forecast/suggestion`, {
                          useDedicatedEnvironment: true,
                        }),
                        createAuthenticatedRequest(user)
                      )
                      .then((response) => {
                        // forecast no data
                        const {totalItems} = response.data
                        setMyForecastOppCount(totalItems)
                      });
                    //  grants
                    axios
                      .get(
                        getFullUrl(`/api/pipeline/${id}/grant/suggestion`, {
                          useDedicatedEnvironment: true,
                        }),
                        createAuthenticatedRequest(user)
                      )
                      .then((response) => {
                        const {totalItems} = response.data
                        setMyGrantOppCount(totalItems)
                      });
                    axios
                      .get(
                        getFullUrl("/api/user/MySuggestions", {
                          useDedicatedEnvironment: true,
                        }),
                        createAuthenticatedRequest(user)
                      )
                      .then((response) => {
                        try {
                          const {
                            myForecastSuggestionCount,
                            myGrantSuggestionCount,
                            myContractSuggestionCount,
                          } = response.data;
                          setMySuggestionOppCount(
                            myForecastSuggestionCount +
                            myGrantSuggestionCount +
                            myContractSuggestionCount
                          );
                          //setMyForecastOppCount(myForecastSuggestionCount);
                          // setMyGrantOppCount(myGrantSuggestionCount);
                          // setMyContractOppCount(myContractSuggestionCount);
                        } catch (e) {
                          growl.current.show({
                            severity: "error",
                            summary: "Error Loading MySuggestions",
                            sticky: true,
                          });
                          console.error("unable to load", { error: e, response });
                        }
                      });
                  })}}
              >
                Clear All
              </Button>
            </div>
            <div className="p-col-12">
              {myForecastSelected && (
                <MySuggestionsBusinessForecast
                id={id}
                  isRemove={removeAll}
                isPipelineScreen={true}
                decrement={() => {
                    setMyForecastOppCount(myForecastOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
              {myGrantSelected && (
                <MySuggestionsGrants
                  isRemove={removeAll}
                  id={id}
                  decrement={() => {
                    setMyGrantOppCount(myGrantOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
              {myContractSelected && (
                <MySuggestionsContracts
                  isRemove={removeAll}
                  pipelineId={id}
                  decrement={() => {
                    setMyContractOppCount(myContractOppCount - 1);
                    setMySuggestionOppCount(mySuggestionOppCount - 1);
                  }}
                />
              )}
            </div>
          </div>
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 }}
          collapsed={isMyForecastsCollapsed}
          onToggle={(e) => setIsMyForecastsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyForecast"
              isCollapsed={isMyForecastsCollapsed}
              setIsCollapsed={setIsMyForecastsCollapsed}
            />
          }
        >
          <MyBids id={id} isPipelineScreen={true} />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 ,paddingLeft:"21px"}}
          collapsed={isMyContractsCollapsed}
          onToggle={(e) => setIsMyContractsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyContracts"
              isCollapsed={isMyContractsCollapsed}
              setIsCollapsed={setIsMyContractsCollapsed}
            />
          }
        >
          <ContractsTableOnly id={id} isPipelineScreen={true}  />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          style={{ ...styles.filters.filterBox, marginTop: 0 }}
          collapsed={isMyGrantsCollapsed}
          onToggle={(e) => setIsMyGrantsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              title="MyGrants"
              isCollapsed={isMyGrantsCollapsed}
              setIsCollapsed={setIsMyGrantsCollapsed}
            />
          }
        >
          <GrantsTableOnly id={id} isPipelineScreen={true} />
        </Panel>
      </div>
    </div>
  );
};
