import styled from '@emotion/styled';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react'
import { useGridStyles } from '../../../../hooks/useGridStyles';
import UserContext, { doesUserHaveRole, isUserInRoles, ServerOrgRoles, UserRoleGroups } from '../../../../services/UserContext';
import SectionHeader, { SubHeaders } from '../../SectionHeader';
import { OverlayPanel } from 'primereact/overlaypanel';
import "./organizationInfo.css"
import { createAuthenticatedRequest, getFullUrl } from '../../../../configs/axios-export.custom';
import axios from "axios"
import GrowlContext from '../../../../services/growlContext';
import { css } from 'glamor';
import { useSharedStyles } from '../../useSharedStyles';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

const HeaderButton = styled.button({
  background:"#ECAC37 !important",
  borderRadius:"26.5px",
  margin:"10px",
  height:"40px",
  padding:"10px",
  border:"none",
  left:"0px",
  color:"#FFFFFF !important",
  ':focus':{
    background:"#ECAC37"
  }
})


const Panel = styled.div({
  zIndex:10000,
  top:"1px !important",
  right:"0px"
})

interface IUsers {
  FirstName: string;
  LastName: string;
  email:string;
}
interface EnumIUsers extends Array<IUsers>{}

type UserProfileType = {
  firstName: string,
  lastName: string,
  email: string,
  userRole:string
  password:string
  message:string;
  pipelines:[]
};
const defaultUserSetting:UserProfileType = {
  firstName:"",
  lastName:"",
  email:"",
  userRole:"",
  password:"",
  message:"",
  pipelines:[],
}
const Team =()=> {
 
  const context = React.useContext(UserContext);
  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin)
  const growl = useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);
  const [first, setFirst] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");
  const [totalRecords,setTotalrecords] = React.useState(0)
  const [toggleInviteBtn,setToggleButton] = React.useState(false)
  const [toggleViewMode,setToggleViewMode] = React.useState(false)
  const [companyUsers, setCompanyUsers] = useState<EnumIUsers[]>([]);
  const [headerText, setHeaderText]  =  React.useState("Add team member")
  const isProOrPlusAdmin = isUserInRoles(context, UserRoleGroups.plusOrHigher); 
  const [userSettings,setUserSettings] = React.useState<UserProfileType>(defaultUserSetting)
  const [rowState,setRowState] = React.useState<UserProfileType>()
  const sharedStyles = useSharedStyles();
  const [serverValues, setServerValues] = React.useState<UserProfileType>()

  const pl =React.useRef<OverlayPanel>(null);
  const styles = useGridStyles();
  const [isLoading,setisloading]= React.useState(true)

    // Data table row state
    // const [rowState,setRowState] = React.useState<UserProfileType>({
    //   firstName:"",lastName:"",email:"",userRole:"",password:"",pipelines:[]
    // })

    //  update state with the new added user
    const updateUsers = (data: any) => {
      const newUser = [data, ...companyUsers];
      setCompanyUsers(newUser)
    }
  const loadUsers = () => {
    axios
    .get(
      getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
      header
    )
    .then((response) => {
      setCompanyUsers(response.data)
      setTotalrecords(companyUsers.length)
      setisloading(false)
    })
    .catch((e) => {
      console.error(e);
      if (growl.current) {
        growl.current.show({
          severity: "error",
          summary: "Unable to load users",
        });
      }
    });
  }

  useEffect(loadUsers,[context]) 

  const deleteUser = () => {
    axios
    .delete(
      getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
      header
    )
    .then((response) => {
      setCompanyUsers(response.data)
    })
    .catch((e) => {
      console.error(e);
      if (growl.current) {
        growl.current.show({
          severity: "error",
          summary: "Error deleting data",
        });
      }
    });
  }
  const  showSidePanel = (e: React.SyntheticEvent<Element, Event>)=>{
      if(pl && pl.current){
        pl.current.toggle(e)
      }
  }

  const canSaveUsr = userSettings.firstName !== serverValues?.firstName
  || userSettings.lastName !== serverValues?.lastName  
  || userSettings.email !== serverValues?.email  
  || userSettings.userRole !== serverValues?.userRole  
  || userSettings.password !== serverValues?.password  
  || userSettings.pipelines !== serverValues?.pipelines  
  
    // validate email 
    function validateEmail(email:string) 
    {
        var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return re.test(email);
    }

    //  save user
    const saveNewUser = ()=>{
      const r = userSettings.userRole === "Organization Admin" ? "OrganizationAdmin" : "Member"
      //  TO DO : Covert to form validation
      if(userSettings.firstName == ""){
        growl.current.show({
          severity: "error",
          summary: "First name is required."

        });
        return
      }else if(userSettings.lastName == ""){
        growl.current.show({
          severity: "error",
          summary: "Last name is required."
        });
        return
      }else if(userSettings.email == ""){
        growl.current.show({
          severity: "error",
          summary: "Email  is required."
        });
      }else if(!validateEmail(userSettings.email)){
        growl.current.show({
          severity: "error",
          summary: "Invalid email address. e.g. example@email.com"
        });
        return 
      }else if(userSettings.userRole == ""){
        growl.current.show({
          severity: "error",
          summary: "Role is required"
        });
        return
      }else if(userSettings.password == ""){
        growl.current.show({
          severity: "error",
          summary: "Password is required"
        });
      }

      axios
      .post(
        getFullUrl("/api/organization/user", {
          useDedicatedEnvironment: true,
        }),
        { FirstName:userSettings.firstName,
          LastName:userSettings.lastName,
          Email:userSettings.email,
          Password:userSettings.password,
          Pipelines:userSettings.pipelines,
          userRole:r
        },
        header
      )
      .then((res) => {
        // console.log(res.data)
        growl.current.show({
          severity: "success",
          summary: "The new member has been added successfully",
        });
        // update state 
        updateUsers(res.data)
      })
      .catch((error)=>{
        // console.log(error)

        if(error.response){
          console.log(error.response.status);
        }
        if (growl.current) {
 
          if(error.response.status === 409){ // TO DO get actual error response from trace stack
            growl.current.show({
              severity: "error",
              summary: "User already exists"
            });
            return
          }
          if(error.response.status === 500){
            growl.current.show({
              severity: "error",
              summary: "Invalid email address"
            });
            return
          }
        }
      });
      
    }

    const onUserSettingsChange =<O extends keyof UserProfileType> (prop:O, value:UserProfileType[O])=>{
      setUserSettings({...userSettings, [prop]:value})
    }
  //   Reset state when overlay is hidden

  const handleOnhide =()=>{
    // reset sidebar  state on hide
    setHeaderText("Add team member")
    setRowState(defaultUserSetting)
    setToggleViewMode(false)
    setToggleButton(false)
  } 

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div className="p-sm-12 p-md-6 p-lg-4 p-xl-6 topLeftColumnHeader" style={{ textAlign: "left" }}>
          
          <div className="search-bar">
              <InputText
                type="search"
                style={{color:"#979797",fontStyle:"italic",borderRadius:"20px" }}
                onChange={(e: any) => setKeyword(e.target.value)}
                placeholder="Search..."
              />
          </div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        {/* <Button tooltip="A Disabled Button" disabled>Import Members</Button> */}
        <HeaderButton onClick={showSidePanel}>+ Add Member</HeaderButton> 
      </div>
    </div>
  );
    //  Get the current cell/row data
    const getFormattedCellFromBody = function (
      fieldNames: any,
      getValueFunc: any
    ) {
      return function (row: UserProfileType) {
        return (
          <div>
            {getValueFunc(row)}
          </div>
        );
      };
    };
    
  //  combine First name and Last name 

    const Concat = (rowdata) =>{
      return(
        <p style={{textAlign:"left", textTransform:"capitalize",paddingLeft:"5px"}}> {rowdata.firstName}{" "}{rowdata.lastName}</p>
      )
    }
 
    function searchTable(Users: any[]){
      return Users.filter((user)=>
      user.email.toLowerCase().indexOf(keyword.toLowerCase())>-1 ||
      user.firstName.toLowerCase().indexOf(keyword.toLowerCase())>-1 ||
      user.lastName.toLowerCase().indexOf(keyword.toLowerCase())>-1 
      )
    }
    //  paginator
    const tableFooter = "Showing {first} to {last} of {totalRecords} entries";
    const paginatorTemplate = "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";

    const StatusTemplate = (rowdata) =>{
      let isEnable = true
      return(
        <div>
          {isEnable? <button className='user-status-button-active'>Active</button>
           :<button className='user-status-button-pending'>Pending</button>}
        </div>
      )
    } 
    const roleTemplate = (r:UserProfileType)=>{
      const rolename =r.userRole.substring(0, 12) + " " + r.userRole.substring(12, r.userRole.length);
      return(
        <p>{rolename}</p>
      )
    }
    const userRoles : Array<string> = ["Member","Organization Admin"]
  return (
    <div>
       <div style={{marginBottom:"10px"}}> <SectionHeader text={"Teams"}/></div>
       <Panel style={{paddingLeft:"50px",paddingRight:"50px"}}>
        <OverlayPanel  onHide={handleOnhide} ref={pl} className="side-panel">
          <div style={{width:"335px",height:"auto"}}>
            <div className='side-panel-header'><SubHeaders  text={headerText} /></div>
            <div className="p-grid">
            <div className="p-col-12" style={{textAlign:"left"}}>
                <div className="p-col-12">
                    <label className='field-titles' htmlFor="firstName">
                      First Name *
                    </label>

                    {toggleViewMode ?  <>
                      <div style={{backgroundColor:"#e7e7e7", fontSize:"14px",padding:"5px 15px"}}
                       id="firstName"
                        {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
                           >
                         {userSettings.firstName || rowState?.firstName}
                            </div>
                       </> :  <InputText 
                        placeholder=" First Name"
                        className='field-inputs'
                        defaultValue={userSettings.firstName}
                        // value={fname|| user.firstName }
                        onChange={(e)=>{onUserSettingsChange("firstName",e.currentTarget.value)}} 
                    />}
                   
                </div>
                <div className="p-col-12">
                    <label className='field-titles' htmlFor="LastName">
                      Last  Name *
                    </label>
                    {
                      toggleViewMode ? <>
                          <div style={{backgroundColor:"#e7e7e7", fontSize:"14px",padding:"5px 15px"}}
                              id="email"
                                {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
                                >
                                {userSettings.lastName || rowState?.lastName}
                          </div>
                          </> :
                           <InputText
                           type='text'
                           placeholder="last name"
                           className='field-inputs'
                           defaultValue={userSettings.lastName}
                          //  value={user.lastName || lname}
                          onChange={(e)=>{onUserSettingsChange("lastName",e.currentTarget.value)}} 
                       />
                    }
                   
                </div>
                <div className="p-col-12">
                    <label className='field-titles' htmlFor="email">
                      Email*
                    </label>
                    {toggleViewMode ? 
                    <>
                      <div style={{backgroundColor:"#e7e7e7", fontSize:"14px", padding:"5px 15px"}}
                          id="email"
                            {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
                            >
                            {userSettings.email || rowState?.email}
                      </div>
                    </> :
                      <InputText 
                       placeholder="email"
                      className='field-inputs'
                      defaultValue={userSettings.email}
                      // value={user.email || email}
                      onChange={(e)=>{onUserSettingsChange("email",e.currentTarget.value)}}
                  />
                    
                    }
                    
                </div>
                <div className="p-col-12">
                    <label  className='field-titles' htmlFor="email">
                     Password
                    </label>
                    {toggleViewMode ? 
                    <>
                      <div  style={{backgroundColor:"#e7e7e7", fontSize:"14px", padding:"5px 15px"}}
                          id="Password"
                            {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
                            >
                            {"*********"}
                      </div>
                    </> :
                      <InputText 
                      disabled={toggleViewMode}
                      required
                      placeholder="password"
                      className='field-inputs'
                      defaultValue={userSettings.password}
                      // value={user.password || password}
                      onChange={(e) =>  {onUserSettingsChange("password",e.currentTarget.value)}}
                  />
                    
                    }
                    
                </div>
                <div className="p-col-12">
                    <label className='field-titles' htmlFor="email">
                      Role
                    </label>
                    {toggleViewMode ? 
                    <>
                      <div  style={{backgroundColor:"#e7e7e7", fontSize:"14px", padding:"5px 15px"}}
                          id="userrole"
                            {...css({ ...sharedStyles.text, ...sharedStyles.radiusBordered })}
                            >
                            {userSettings.userRole || rowState?.userRole}
                      </div>
                    </>: 
                    <Dropdown className='field-inputs' value={userSettings.userRole} options={userRoles} onChange={(e) => {onUserSettingsChange("userRole",e.target.value)}} placeholder="Select role" />
                  }
                </div>
                
                {/* <div className="p-col-12">
                 <span>
                      <span className="field-checkbox" style={{padding:"10px"}}>
                     
                        <Checkbox  disabled inputId="binary" checked={false} />
                       
                      </span>
                      Able to  create team pipeline
                  </span>
                </div> */}
                {/* <div className="p-col-12">
                    <label className='field-titles' htmlFor="email">
                      Pipelines*
                    </label>
                    <Dropdown disabled className='field-inputs' value={""}  optionLabel="Roles" editable />
                </div> */}

                <div className="p-col-12">
                   {toggleViewMode ? null:<>
                    <label className='field-titles' htmlFor="Message">
                        Message
                    </label>
                    <InputTextarea 
                        value={userSettings.message}
                        placeholder="Message Text"
                        // className='field-inputs'
                        rows={5} cols={37}
                        onChange={(e) =>  {onUserSettingsChange("message",e.currentTarget.value)}}
                    />
                   </> }
                   
                </div>
                <div className="p-col-12">
                   <div className='button-elements'>
                      {toggleInviteBtn ? null: <button disabled={!canSaveUsr} onClick={saveNewUser} className="edit-user-button">Invite</button>}
                   </div>
                </div>
            </div>
            
         </div>
          </div>
        </OverlayPanel>   
    </Panel>

       <DataTable
        value={searchTable(companyUsers)}
        globalFilter={keyword}
        // lazy={true}
        loading={isLoading}
        scrollable={true}
        scrollHeight="400px"
        header={tableHeader}
        rows={10}
        resizableColumns
        columnResizeMode="expand"
        paginator={true}
        totalRecords={totalRecords}
        first={first}
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={tableFooter}
        rowsPerPageOptions={[5,10,20,25,30]}
      >

          <Column
            headerStyle={styles.headerStyle(70)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            header="Name"
            field="firstName"
            className='cell-data'
            body={Concat}
            exportable={true}   
          />

 
        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          className='cell-data'
          header="Email"
          field="email"         
          exportable={true}
         
        />
        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
         
          header="Roles"
          // field="userRole"
          className='cell-data'
          exportable={true}
          field="userRole"
          body={roleTemplate}
        />  
       {/* <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          header="Pipeline"
          field="source"
          exportable={true}
         
        /> */}
        <Column
          headerStyle={styles.headerStyle(70)}
          style={styles.columnStyle(30)}
          bodyClassName="source"
          header="Status"
         body={StatusTemplate}
          exportable={true}
          
        />
      
          <Column
            header="Actions"
            headerStyle={styles.headerStyle(70)}
            style={styles.columnStyle(30)}
            bodyClassName="source"
            body={getFormattedCellFromBody({}, (r: UserProfileType) => (
                <div style={{display:"flex", justifyContent:"center"}}>
                <Button
                      className="admin-action-button"
                      onClick={(e: React.SyntheticEvent<Element, Event>)=>{
                        setRowState(r)
                        setToggleButton(true)
                        setToggleViewMode(true)
                        setHeaderText("View team member")
                        pl.current?.toggle(e)}}>View</Button>

                    <Button 
                      // tooltip={"+ click"} 
                        disabled
                      className="admin-action-button"
                        onClick={(e: React.SyntheticEvent<Element, Event>)=>{
                        setRowState(r)
                        setToggleButton(false)
                        setToggleViewMode(false)
                        setHeaderText("Edit member details")
                        pl.current?.toggle(e)}}
                    >Edit</Button>

                    <Button 
                    disabled
                    // tooltip={"+ click"} 
                      // // style={{color:"#367FB6 !important", background:"none !important"}}
                      className="admin-action-button"
                      onClick={()=> deleteUser()}
                    >Delete</Button>
                </div>
            ))}
          />        
      </DataTable>
    </div>
  )
}

export default Team