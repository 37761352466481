enum subscriptionTypes {
    free=1,
    consultantMonthly,
    consultantYearly,
    plusMonthly,
    plusYearly,
    proMonthly,
    proYearly
};

enum subscriptionGroups {
    free = 1,
    consultant,
    plus,
    pro
};

export {subscriptionTypes, subscriptionGroups};