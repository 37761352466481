import React from "react";
import ThemeContext from '../configs/theme';

export const useStyles = () => {
    const theme = React.useContext(ThemeContext);
    const inputStyle: React.CSSProperties = {
      height: 40,
      width: "100%",
      marginBottom: '40px',
      border: 'none',
      outline: 'none',
      borderBottom: '2px solid #ffb102',
      font: theme.v2Fonts.input
    }

    const inputLabel: React.CSSProperties = {
      width: '100%',
      textAlign: 'left',
      font: theme.v2Fonts.inputHeader,
      color: theme.v2.darkGrey
    }

    const buttonStyle: React.CSSProperties = {
      padding: '10px 40px',
      backgroundColor: theme.v2.lightBlue,
      marginTop: 10,
      marginBottom: 10,
      cursor: 'pointer',
      borderRadius: '30px',
    }

    const buttonTextStyle: React.CSSProperties = {
      margin: 0,
      color: theme.v2.darkBlue,
      font: theme.v2Fonts.menuSelected
    };

    const formContainerStyle: React.CSSProperties = {
      padding: 20,
      width: 550,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 20,
      border: `${theme.v2.lightYellow} solid 5px`
    };

    const buttonContainer: React.CSSProperties = {
      display: 'flex',
      justifyContent: 'center'
    };

    const container: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: 50
    };

    const header: React.CSSProperties = {
      alignSelf: 'flex-start',
      margin: '0px 0px 0px',
      font: theme.v2Fonts.largeHeader,
      display: "flex"
    };

    const linkContainer: React.CSSProperties = { marginTop: 30 };

    const toggle: React.CSSProperties = {
      paddingBottom: '10px',
      cursor: 'pointer',
      marginTop: 10,
      marginBottom: 0,
      borderBottom: '2px solid transparent',
      font: theme.v2Fonts.input
    }

    const errorDiv: React.CSSProperties = {
      font: theme.v2Fonts.contextHeader,
      color: theme.v2.darkBlue,
      alignSelf: 'flex-start',
      paddingTop: 20,
      paddingBottom: 20,
    }

    const messageDiv: React.CSSProperties = {
      font: theme.v2Fonts.contextHeader,
      color: theme.v2.darkBlue,
      alignSelf: 'flex-start',
      paddingTop: 20,
      paddingBottom: 20,
    }

    const icon: React.CSSProperties = {
      width: 30,
    }

    const innerContainer: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center' }

    const styles = {
      inputStyle,
      inputLabel,
      buttonStyle,
      buttonTextStyle,
      formContainerStyle,
      container,
      buttonContainer,
      linkContainer,
      toggle,
      innerContainer,
      header,
      errorDiv,
      messageDiv,
      icon,
      aidkonektIcon: require('../assets/icons/v2/icon_Globe.png')?.default,
      editIcon: require('../assets/icons/v2/edit-icon.png')?.default,
      removeIcon: require('../assets/icons/v2/remove-icon.png')?.default,
      checkIcon: require('../assets/icons/v2/check-icon.png')?.default
    }

    return styles;
  }
