import { NotificationRecipient } from "../../../utils/constants/notification-recipients";
import { Weekday } from "../../../utils/constants/time-constants";
import { AccountInformationState } from "../DashboardManagement/AccountInformation/reducer";

export interface ServerSetting<T> {
    currentValue: T,
    serverValue: T
}

interface EmailNotificationSettingsState {
    daily: {
        enabled: ServerSetting<boolean>,
        time: ServerSetting<string>,
        recipients: ServerSetting<NotificationRecipient>
    },
    weekly: {
        enabled: ServerSetting<boolean>,
        weekday: ServerSetting<Weekday>,
        time: ServerSetting<string>,
        recipients: ServerSetting<NotificationRecipient>
    }
}

interface DashboardState {
    accountInformation: AccountInformationState,
    emailNotifications: EmailNotificationSettingsState,
    organization: {
        companyEmails: ServerSetting<string[]>
    }
}

export const initializeSettingValue = <T>(initialValue: T): ServerSetting<T> => ({
    currentValue: initialValue,
    serverValue: initialValue
});

export const updateSettingValue = <T>(setting: ServerSetting<T>, newValue: T) : ServerSetting<T> => ({
    ...setting,
    currentValue: newValue
});

export const saveSettingValue = <T>(setting: ServerSetting<T>) : ServerSetting<T> => ({
    ...setting,
    serverValue: setting.currentValue
});

const dashboardReducer = () => {

};

export default dashboardReducer;