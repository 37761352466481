export enum NotificationRecipient {
    ALL_TEAM = "All Team",
    ONLY_ME = "Only Me"
};

export enum NotificationRecipientBackend {
    ALL_TEAM = "AllTeam",
    ONLY_ME = "OnlyMe"
}

