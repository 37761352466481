export const rowsPerPageOptionsStandard = [
    20,
    50,
    100,
    1000
];

export const rowsPerPageOptionsHighIncrement = [
    20,
    250,
    1000
];