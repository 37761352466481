import React from "react";
import { useGridStyles } from "../../../hooks/useGridStyles";
import { getSortIfDescendingFirst } from "../../../services/functions";
import { DataTable } from "primereact/datatable";
import { GetCustomColumnBody } from "../../../components/DataTable/Columns";
import { DateFormat, formatDate } from "../../../utils/conversion/date-converters";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { NavigateToButton } from "../../../components/DataTable/ActionButtons";
import * as ActionButtons from "../../../components/DataTable/ActionButtons";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";

export interface TableRecord {
    id: string,
    country: string;
    oppTitle: string;
    techSector: string;
    dateScriptLast: Date;
    predictions: string;
    suggestedPrime: string;
    link: string;
}

type PrimePredictionsTableProps = {
    isLoading: boolean

    // Filtering/sorting/querying
    rowsPerPage: number,
    sortField: string
    setSortField: React.Dispatch<string>
    sortOrder: number
    setSortOrder: React.Dispatch<number>
    first: number
    totalRecords: number,

    // Grid state
    data: Array<TableRecord>
    setData: React.Dispatch<Array<TableRecord>>
    lastUpdated: string
    gridWidth: number

    // Notes info
    originalNotes: Array<{id: number, note: string}>
    setOriginalNotes: React.Dispatch<Array<{id: number, note: string}>>

    isMyContracts?: boolean
    decrement?: () => void

    queryData: (event: {first: number, rows: number, sortField: string, sortOrder: number}) => void

};

const PrimePredictionsTable : React.FC<PrimePredictionsTableProps> = ({
      isLoading,
      rowsPerPage,
      sortField,
      setSortField,
      sortOrder,
      setSortOrder,
      first,
      totalRecords,
      data,
      gridWidth,
      queryData
  }): JSX.Element => {
    const styles = useGridStyles();

    const [expandedRows, setExpandedRows] = React.useState(Array<TableRecord>());

    const sort = (event: {sortField: string, sortOrder: number}) => {
        const newSortOrder = getSortIfDescendingFirst(event, sortField);
        setSortField(event.sortField);
        setSortOrder(newSortOrder);
        queryData({first, rows: rowsPerPage, sortField: event.sortField, sortOrder: newSortOrder});
    }

    const ActionsButtonColumn = ({record}: {record: TableRecord}) => {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <ActionButtons.AddToMyListButton add={() => null} />
                <span style={{ marginRight: 20 }} />
                <ActionButtons.RemoveFromMyListButton remove={() => null} />
            </div>);
    }

    const RecordExpandedView = ({record}: {record: TableRecord}) => {
        const styles = useGridStyles();

        return (
            <DataTable
                value={data}
                scrollHeight="600px"
                scrollable={true}>
                {GetCustomColumnBody<TableRecord>(styles, 150, x => x.predictions, 'Predictions','predictions', {sortable: false})}
                {GetCustomColumnBody<TableRecord>(styles, 100, x => <ActionsButtonColumn record={x} />, 'Add/Ignore', 'lastLogin', {sortable: false})}
                {GetCustomColumnBody<TableRecord>(styles, 350, x => x.suggestedPrime, 'Suggested Prime', 'suggestedPrime', {sortable: false})}
                {GetCustomColumnBody<TableRecord>(styles, 350, x => x.link, 'Ad/Project Link', 'link', {sortable: false})}
            </DataTable>
        )
    }

    return (
        <DataTable
            value={data}
            style={{marginTop: 20, width: `${gridWidth+20}px` }}
            paginator={true}
            rows={rowsPerPage}
            totalRecords={totalRecords}
            lazy={true}
            first={first}
            onPage={queryData}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={sort}
            header={<div />}
            scrollable={true}
            scrollHeight="400px"
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            rowExpansionTemplate={(d: TableRecord) => <RecordExpandedView record={d} />}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rowsPerPageOptions={rowsPerPageOptionsStandard}>
            <Column headerStyle={styles.headerStyle(40)} style={styles.columnStyle(40)} expander />
            {GetCustomColumnBody<TableRecord>(styles, 250, x => x.id, 'Forecast ID Number','id', {sortable: false})}
            {GetCustomColumnBody<TableRecord>(styles, 250, x => x.country, 'Country', 'country', {sortable: false})}
            {GetCustomColumnBody<TableRecord>(styles, 250, x => x.oppTitle, 'Opp Title', 'oppTitle', {sortable: false})}
            {GetCustomColumnBody<TableRecord>(styles, 150, x => x.techSector, 'Opp TitleTech Sector', 'techSector', {sortable: false})}
            {GetCustomColumnBody<TableRecord>(styles, 150, x => formatDate(x.dateScriptLast, DateFormat.dd_MMMM_yyyy), 'Date Script Was Last Run', 'dateScriptLast')}
        </DataTable>
    )
}

export default PrimePredictionsTable
