import React from "react";
import "./Resources.css";
import { useGridStyles } from "../../hooks/useGridStyles";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles, ServerOrgRoles,
  UserRoleGroups
} from "../../services/UserContext";
import { Redirect } from "react-router";
import GrowlContext from "../../services/growlContext";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { tryCatchServerError } from "../../services/functions";
import { ProgressSpinner } from "primereact/progressspinner";
import axios, { AxiosRequestConfig } from "axios";

const Resources: React.FC = (): JSX.Element => {
  const context = React.useContext(UserContext);
  const styles = useGridStyles();

  const growl = React.useContext(GrowlContext);
  const [loading, setLoading] = React.useState(false);
  const [records, fetchRecords] = React.useState(
    Array<{
      id: number;
      title: string;
      description: string;
      fileName: string;
    }>()
  );
  const [reloader, reload] = React.useState(false);
  const [selectedFileName, setSlectedFileName] = React.useState("");
  const [newPostTitle, setNewPostTitle] = React.useState("");
  const [newPostDescription, setNewPostDescription] = React.useState("");
  const [editable, setEditable] = React.useState(false);

  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin)

  const upload = async () => {
    let request: AxiosRequestConfig = {
      ...createAuthenticatedRequest(context, {
        additionalHeaders: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
        },
      }),
    };

    await axios
      .post(
        getFullUrl("/api/utility/upload", {
          useDedicatedEnvironment: true,
        }),
        {
          title: newPostTitle,
          description: newPostDescription,
          fileName: selectedFileName,
        },
        request
      )
      .catch((e) => {
        alert("Error adding file " + e.message);
      });
    setEditable(false);
    reload(!reloader);
  };

  const UploadFile = () => {
    return editable ? (
      <section>
        <div className="add-btn-container" onClick={() => setEditable(false)}>
          <button type="button" className="add-btn aid-blu-btn">
            -
          </button>
          <span className="add-btn-title">Hide</span>
        </div>
        <div
          className="new-post-date-container"
          style={{ paddingLeft: "20px" }}
        >
          <input
            type="text"
            placeholder="Title"
            className="new-post-date-small"
            onChange={(e) => setNewPostTitle(e.currentTarget.value)}
          />
          <textarea
            className="new-post-date-big"
            placeholder="Resource description"
            onChange={(e) => setNewPostDescription(e.currentTarget.value)}
          />
          <input
            type="text"
            placeholder="Url"
            className="new-post-date-small"
            onChange={(e) => setSlectedFileName(e.currentTarget.value)}
          />
          <button
            className="card-btn aid-blu-btn"
            disabled={!selectedFileName || !newPostDescription || !newPostTitle}
            onClick={upload}
          >
            Upload
          </button>
        </div>
      </section>
    ) : (
      <div className="add-btn-container" onClick={() => setEditable(true)}>
        <button type="button" className="add-btn aid-blu-btn">
          +
        </button>
        <span className="add-btn-title">Add new resource</span>
      </div>
    );
  };

  if (!isUserInRoles(context, UserRoleGroups.plusOrHigher)) {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    setLoading(true);
    let request = createAuthenticatedRequest(context);

    axios
      .get(
        getFullUrl("/api/utility", { useDedicatedEnvironment: true }),
        request
      )
      .then((r) => {
        fetchRecords(r.data);
        setLoading(false);
      })
      .catch(
        tryCatchServerError(() => {
          growl.current.show({
            severity: "error",
            summary: "Error getting utility resources",
            detail: "Error getting utility resources",
          });
          setLoading(false);
        })
      );
  }, [reloader]);

  if (loading) {
    return <ProgressSpinner />;
  }

  return (
    <div className="resources-screen resources">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>USAID Resources</span>
        </div>
        <span className="header-subtitle">
          Curated Key Resources for working with USAID
        </span>
        <span className="header-description">
          As a company, we have more than half a decade of experience supporting
          our clients’ USAID proposal development, and collectively, our core
          network of professionals has centuries of experience in all aspects of
          working with USAID, from proposals to implementation to closeout and
          follow-on activities. With this depth and breadth of experience
          working with USAID, we would like to share the following resources. We
          hope you find them useful as you and your team partner with USAID to
          implement their important work.
        </span>
      </section>

      {isAdmin && UploadFile()}

      <section className="resources-cards-block">
        {records.map((r) => (
          <div className="card-container">
            <h3 className="card-title">{r.title}</h3>
            <div className="card-content">
              <span>{r.description}</span>
            </div>
            <button
              type="button"
              className="card-btn aid-blu-btn"
              onClick={() => {
                let extractedFileName = r.fileName.substring(
                  r.fileName.lastIndexOf("/") + 1
                );
                saveAs(r.fileName, extractedFileName);
              }}
            >
              Download
            </button>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Resources;
