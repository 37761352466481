import { Column } from 'primereact/column';
import * as React from 'react';



export function GetCustomColumnBody<T>(
    {headerStyle, columnStyle}: { headerStyle: (width: number) => React.CSSProperties, columnStyle: (width: number) => React.CSSProperties },
    width: number,
    body:((x: T)=>any),
    header: any,
    fieldName: keyof T | string,
    props?: {bodyClassName?: string, sortable?: boolean, additionalHeaderStyles?: React.CSSProperties}) {
        const additionalHeader = props && props.additionalHeaderStyles ? props.additionalHeaderStyles : {};
        return (
            <Column
                header={header}
                sortable={props ? props.sortable : true}
                field={fieldName as string}
                bodyClassName={props && props.bodyClassName ? props.bodyClassName : ""}
                headerStyle={{...headerStyle(width), ...additionalHeader}}
                style={columnStyle(width)}
                body={body} />
        );
}

export function GetCustomColumnShortened<T>(
    {headerStyle, columnStyle}: { headerStyle: (width: number) => React.CSSProperties, columnStyle: (width: number) => React.CSSProperties },
    width: number,
    body: ((x: T) => any),
    title: ((x: T) => string),
    header: string,
    textLength: number,
    sortable: boolean,
    fieldName: keyof T | string) {
      return GetCustomColumnBody(
          {headerStyle, columnStyle},
          width,
          x => {
            const bodyElement = body(x);
            const textTitle = title(x);

            if (typeof bodyElement === 'string' || bodyElement instanceof String){
                return (
                    <p data-full-abstract={bodyElement} data-title={textTitle} className="abstract">
                        {bodyElement && bodyElement.length > textLength ? `${bodyElement.substring(0,textLength)}...` : bodyElement}
                    </p>);
            } else {
                return bodyElement;
            }

          },
          header,
          fieldName,
          {bodyClassName: "abstract", sortable});
  }
