import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { withRouter } from "react-router-dom";


import UserContext from "../services/UserContext";

import { NotificationTypeEnum } from "../services/enums";

import {
  getLocalTimeZone,
  newyorkCountryName,
  washingtonCountryName,
} from "../services/timezone";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../configs/axios-export.custom";
import { tryCatchServerError } from "../services/functions";
import GrowlContext from "../services/growlContext";
import { Weekday } from "../utils/constants/time-constants";
import {
  NotificationRecipient,
  NotificationRecipientBackend,
} from "../utils/constants/notification-recipients";
import accountInformationReducer, {
  AccountInformationContext,
  createInitialAccountInformationState,
} from "../components/Dashboard/DashboardManagement/AccountInformation/reducer";
import {
  saveFirstName,
  saveLastName,
  saveTimeZone,
  setFirstName,
  setLastName,
  setTimeZone,
} from "../components/Dashboard/DashboardManagement/AccountInformation/actions";
import emailNotificationsReducer, {
  createInitialEmailNotificationState,
  EmailNotificationsContext,
} from "../components/Dashboard/DashboardManagement/EmailNotifications/reducer";
import {
  saveDailyEmailEnabled,
  saveDailyEmailRecipient,
  saveDailyEmailTime,
  saveForecastAlertEnabled,
  saveForecastAlertRecipient,
  saveWeeklyEmailDay,
  saveWeeklyEmailEnabled,
  saveWeeklyEmailRecipient,
  saveWeeklyEmailTime,
  setDailyEmailEnabled,
  setDailyEmailRecipient,
  setDailyEmailTime,
  setForecastAlertEnabled,
  setForecastAlertRecipient,
  setWeeklyEmailDay,
  setWeeklyEmailEnabled,
  setWeeklyEmailRecipient,
  setWeeklyEmailTime,
} from "../components/Dashboard/DashboardManagement/EmailNotifications/actions";
import {
  EventType,
} from "../components/Dashboard/EventsCalendar/EventsCalendar";
import axios from "axios";
import ProfileOverview from "../components/Dashboard/DashboardManagement/Users/user components/ProfileOverview";
import DashboardManagementBeta from "../components/Dashboard/DashboardManagement/DashboardManagementBeta"
import ChangePassword from "../components/Dashboard/DashboardManagement/Users/user components/ChangePassword";
import styled from "@emotion/styled";

interface NotificationResponse {
  notificationTime: string;
  notificationDay: Weekday;
  notificationType: NotificationTypeEnum;
  recipient: NotificationRecipientBackend;
}

interface CompanyEmailResponse {
  emailAddress: string;
}

type BusinessForecastLiveUpdateResponse = {
  recipient: NotificationRecipientBackend | undefined;
};

type ProfileResponse = {
  firstName: string;
  lastName: string;
  timezone: string;
  notifications: Array<NotificationResponse>;
  companyEmails: Array<CompanyEmailResponse>;
  businessForecastLiveUpdateDto: BusinessForecastLiveUpdateResponse;
  events: Array<EventType>;
  organization: string;
};
const Div = styled.div({
  border:"5px solid #D9D9D9",
  borderRadius:"35px",
  height:"70px",
  boxSizing:"border-box",
  display:"flex",
  '& button':{
    background:"#FAEACD",
    borderRadius:"26.5px",
    margin:"10px",
    height:"40px",
    padding:"8px 24px",
    border:"none",
    left:"0px",
    cursor:"pointer",
    ':focus':{
      background:"#ECAC37",
      border:"0px",
      color:"#FFFFFF"
    }
  }

})
const UserDashboard = () => {
  // Context
  const userContext = useContext(UserContext);
  const growl = useContext(GrowlContext);

  const [events, setEvents] = React.useState(Array<EventType>());
  const [organization, setOrganization] = React.useState("");
  const email = userContext.user.parsedIdToken?.email ?? "";
  const[baseString,setBaseString] =  React.useState("")
  // State

  const [accountInformationState, accountInfoDispatch] = useReducer(
    accountInformationReducer,
    createInitialAccountInformationState(
      email,
      "",
      "",
      email,
      getLocalTimeZone()
    )
  );

  const [emailNotificationsState, emailNotificationsDispatch] = useReducer(
    emailNotificationsReducer,
    createInitialEmailNotificationState(
      false,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      false,
      undefined
    )
  ); // TODO: Combine reducers -> "Dashboard reducer"

  const [companyEmails, setCompanyEmails] = useState(Array<string>());

  const request = createAuthenticatedRequest(userContext);

  const loadDataAsync = useCallback(() => {
    axios
      .get(
        getFullUrl("/api/user/profile", { useDedicatedEnvironment: true }),
        request
      )
      .then((response) => {
        const {
          firstName,
          lastName,
          timezone,
          notifications,
          companyEmails,
          businessForecastLiveUpdateDto,
          events,
          organization,
        }: ProfileResponse = response.data;

        
        
        // if timezone is new york, show DC
        const localTimezone =
          timezone === newyorkCountryName ? washingtonCountryName : timezone;

        //TODO: This is really inelegant... see below with notifications as well. Would be nicer to add initialize actions perhaps
        accountInfoDispatch(setFirstName(firstName || ""));
        accountInfoDispatch(saveFirstName());
        accountInfoDispatch(setLastName(lastName || ""));
        accountInfoDispatch(saveLastName());
        accountInfoDispatch(setTimeZone(localTimezone));
        accountInfoDispatch(saveTimeZone());
        setOrganization(organization);

        setEvents(events);

        // Set back into state
        const dailyNotification = notifications.find(
          (r) => r.notificationType === NotificationTypeEnum.Daily
        );
        if (dailyNotification) {
          const dailyEmailEnabled = true;
          const dailyEmailRecipient =
            dailyNotification.recipient ===
            NotificationRecipientBackend.ALL_TEAM
              ? NotificationRecipient.ALL_TEAM
              : NotificationRecipient.ONLY_ME;
          const dailyEmailTime = dailyNotification.notificationTime;

          emailNotificationsDispatch(setDailyEmailEnabled(dailyEmailEnabled));
          emailNotificationsDispatch(saveDailyEmailEnabled());

          emailNotificationsDispatch(
            setDailyEmailRecipient(dailyEmailRecipient)
          );
          emailNotificationsDispatch(saveDailyEmailRecipient());

          emailNotificationsDispatch(setDailyEmailTime(dailyEmailTime));
          emailNotificationsDispatch(saveDailyEmailTime());
        }

        const weeklyNotification = notifications.find(
          (r) => r.notificationType === NotificationTypeEnum.Weekly
        );
        if (weeklyNotification) {
          const weeklyEmailEnabled = true;
          const weeklyEmailRecipient =
            weeklyNotification.recipient ===
            NotificationRecipientBackend.ALL_TEAM
              ? NotificationRecipient.ALL_TEAM
              : NotificationRecipient.ONLY_ME;
          const weeklyEmailTime = weeklyNotification.notificationTime;
          const weeklyDay = weeklyNotification.notificationDay;

          emailNotificationsDispatch(setWeeklyEmailEnabled(weeklyEmailEnabled));
          emailNotificationsDispatch(saveWeeklyEmailEnabled());

          emailNotificationsDispatch(
            setWeeklyEmailRecipient(weeklyEmailRecipient)
          );
          emailNotificationsDispatch(saveWeeklyEmailRecipient());

          emailNotificationsDispatch(setWeeklyEmailTime(weeklyEmailTime));
          emailNotificationsDispatch(saveWeeklyEmailTime());

          emailNotificationsDispatch(setWeeklyEmailDay(weeklyDay));
          emailNotificationsDispatch(saveWeeklyEmailDay());
        }

        if (companyEmails) {
          const emails = companyEmails.map((r) => r.emailAddress);
          setCompanyEmails(emails);
        }

        if (
          businessForecastLiveUpdateDto &&
          businessForecastLiveUpdateDto.recipient
        ) {
          const recip =
            businessForecastLiveUpdateDto.recipient ===
            NotificationRecipientBackend.ALL_TEAM
              ? NotificationRecipient.ALL_TEAM
              : NotificationRecipient.ONLY_ME;
          emailNotificationsDispatch(setForecastAlertEnabled(true));
          emailNotificationsDispatch(saveForecastAlertEnabled());
          emailNotificationsDispatch(setForecastAlertRecipient(recip));
          emailNotificationsDispatch(saveForecastAlertRecipient());
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load user profile",
          });
        }
      });

      //  pull the user profile image as well 

      axios
        .get(
          getFullUrl("/api/user/profile/picture", { useDedicatedEnvironment: true }),
          request
        )
        .then((response) => {
          let bstr = response.data 
          let base64ToString = Buffer.from(bstr, "base64").toString()
          setBaseString(base64ToString)

        })
        .catch((e) => {
          console.error(e);
          if (growl.current) {
            growl.current.show({
              severity: "error",
              summary: "Unable to load profile image",
            });
          }
        });

  }, []);

  // Reload the data when user data changes

  useEffect(loadDataAsync, [userContext]);


  const accountInformationContextValue = useMemo(() => {
    return {
      accountInformationState: accountInformationState,
      accountInformationDispatch: accountInfoDispatch,
    };
  }, [accountInformationState, accountInfoDispatch]);

  const emailNotificationsContextValue = useMemo(() => {
    return {
      emailNotificationsState: emailNotificationsState,
      emailNotificationsDispatch: emailNotificationsDispatch,
    };
  }, [emailNotificationsState, emailNotificationsDispatch]);

    // toggles tabs

    const [showAccountsSettings,setshowAccountsSettings] = React.useState(true);
    const [logginSettings, setlogginSettings] = React.useState(false);

    const showAccounts = () =>{ setshowAccountsSettings(true); setlogginSettings(false)};
    const showLogging = () => {setlogginSettings(true);setshowAccountsSettings(false)};

    // update the image with newly uploaded image
    const updateBase64String = (data) =>{
          setBaseString(data)
    }
  
  const defaultButton  = useRef<HTMLButtonElement>(null);
  
  useEffect(()=>{
    if(defaultButton && defaultButton.current){
      defaultButton.current.focus()
    }
  },[defaultButton]) // permanent focus

  
  return (
    <AccountInformationContext.Provider value={accountInformationContextValue}>
      <EmailNotificationsContext.Provider value={emailNotificationsContextValue}>
      <div className="p-col p-grid p-justify-center content-block">
             <div className="p-col-12 p-md-12 p-lg-12 p-xl-8">
             <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
            <Div>
              <button ref={defaultButton} onClick={showAccounts}><strong>Accounts Settings</strong></button>
              <button  onClick={showLogging}><strong>Login Settings</strong></button>
              {/* <button onClick={showSubscriptions} style={{display:"none"}}> Subscription</button> */}
            </Div>
          </div>
             <div>

               {showAccountsSettings ? <DashboardManagementBeta updateBase64String={updateBase64String}  /> :null 
               }
                {logginSettings ? <ChangePassword user={accountInformationState.username}/> :null 
               }
             </div>      
            </div>
            <div className="p-col-12 p-md-12 p-lg-12 p-xl-4" style={{marginTop:"8px"}}>
              {/* To do rename and reorganize the content to reflect user profile overview */}
              <ProfileOverview organization={organization} profile={baseString} />
               {/* <Overview organization={organization} profile={baseString} /> */}
            </div>
        </div>
      </EmailNotificationsContext.Provider>
    </AccountInformationContext.Provider>
  );
};

export default withRouter(UserDashboard);
