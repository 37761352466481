import {useState, useEffect} from 'react';

export const tryCatchServerError = (handler: (message: string) => void, defaultHandler?: (err: any) => void ) => {
  return async (err: any) => {
    console.error(err);
    try {
      // try to be smart with how we handle this
      if (err && ((err.status === 409) || (err.response && err.response.status === 409))) {
        // this is a planned issue - show server message back to user
        const result = err instanceof Response ? await err.json() : err.response.data;
        console.log(result);
        handler(result.message);
      } else {
        if (defaultHandler) {
          defaultHandler(err);
        }
      }
    } catch {
      // default when handling an exception
      if (defaultHandler) {
        defaultHandler(err);
      }
    }
  }
};

export function useDebounce(value: any, delay: any) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value] 
  );

  return debouncedValue;
  
}
export function getSortIfDescendingFirst(event: {sortField: string, sortOrder: number}, sortField: string) {
  // we are sorting on a new column - force to be desc (-1)
  return event.sortField !== sortField ? -1 : event.sortOrder;
}