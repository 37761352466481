/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef } from "react";
import "./ContractTableScreen.css";
import UserContext from "../../services/UserContext";

import { Button } from "primereact/button";
import "date-fns";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import { ContractsResourceServer } from "../../configs/resources";
import getWindowDimensions from "../../services/dimensions";
import { TableProperties } from "exceljs";

import "../DataTable.css";
import Chip from "../../components/Chip";
import "datejs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import { Checkbox } from "primereact/checkbox";

import { getDateString } from "../../services/formatter";
import GrowlContext from "../../services/growlContext";
import {
  tryCatchServerError,
  getSortIfDescendingFirst,
  useDebounce,
} from "../../services/functions";
import { ProgressSpinner } from "primereact/progressspinner";
import { parse } from "query-string";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { Popover } from "../../components/Popover/Popover";
import { Button as PButton } from "primereact/components/button/Button";
import {
  buildExcelTable,
  generateExcel,
  getChunksFromString,
  MaxCharsAllowed,
  genEmptyRow,
} from "../../services/exporter";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";

import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import * as ActionButtons from "../../components/DataTable/ActionButtons";
import {
  MaximizeGrid,
  MinimizeGrid,
  NavigateToButton,
} from "../../components/DataTable/ActionButtons";
import { useEnterListener } from "../../hooks/useEnterListener";
import IconTooltip from "../../components/IconTooltip";
import Swal from "sweetalert2";

import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import axios from "axios";
import { saveAs } from "file-saver";
import { fetchDataNewEnvironment } from "../../services/dropdownOptionListProvider";
import CustomFilterSearchSelect from "../../components/CustomSearchSelect/CustomFilterSearchSelect";
import { removeHtmlTags } from "../../services/dataParser";
import plusButton from "../../assets/icons/v2/icon-11.png";
import { OverlayPanel } from "primereact/overlaypanel";
import styled from "@emotion/styled";
import { MultiSelect } from "primereact/multiselect";
import { useHistory } from "react-router-dom";

const icons = {
  location: require("../../assets/icons/v2/icon_location.png")?.default,
  helmet: require("../../assets/icons/v2/icon_helmet.png")?.default,
  number: require("../../assets/icons/v2/icon_number.png")?.default,
  file: require("../../assets/icons/v2/icon_file.png")?.default,
  eligible: require("../../assets/icons/v2/icon_checklist.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
  implementor: require("../../assets/icons/Implementer_icon.png")?.default,
  zip: require("../../assets/icons/v2/icon_zip.png")?.default,
};

interface PointOfContact {
  phone: string;
  fullName: string;
  email: string;
  fax: string;
}
type PipelineDto = {
  id: number;
  name: string;
};
type PipelineForecasts = {
  id: number;
  name: string;
  businessForcast: [];
};

interface TableRecord {
  contractOpportunityBase_Id: number;
  id: string;
  notes: string;
  customColumn: string;
  isMyList: boolean;
  version: number | null;
  title: string;
  solicitationNumber: string;
  agencyName: string;
  type: string;
  setAside: string | null;
  placeOfPerformance: string | null;
  country: string;
  description: string;
  primeOrIncumbent: string;
  postedDateIn: string | null;
  solicitationDeadlineResponseIn: string | null;
  classificationCode: string;
  naicsCodes: string;
  lastUpdated: Date;
  modifiedDateIn: string | null;
  pointOfContactOne: PointOfContact | null;
  pointOfContactTwo: PointOfContact | null;
  contractorAwardedName: string | null;
  contractorAwardedDate: Date | null;
  contractorAwardedAmount: string | null;
  awardNumber: string | null;
}

interface ExpandedRecord extends TableRecord {}
interface FileRecord {
  //There is no FileDescription to provide
  name: string;
  createdOn: string | null;
}

const OverlayerWindow = styled.div({
  zIndex: 10000,
  top: "1px !important",
  right: "0px",
});
const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};
const isNumeric = (str: any) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const getDownloadUrlFromId = (id: string) => `https://sam.gov/opp/${id}/view`;

const RecordExpandedView = ({
  record,
  fileKeyword,
}: {
  record: TableRecord;
  fileKeyword?: string;
}) => {
  const styles = useGridStyles();

  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const [data, setData] = React.useState(Array<ExpandedRecord>());
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState(Array<FileRecord>());
  const [matchingFiles, setMatchingFiles] = React.useState(Array<string>());

  React.useEffect(() => {
    setLoading(true);
    const request = createAuthenticatedRequest(context);

    const url = `/api/contract/details/${record.contractOpportunityBase_Id}${
      fileKeyword ? `?fileKeyword=${fileKeyword}` : ""
    }`;
    axios
      .get(getFullUrl(url, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        var r: {
          record: ExpandedRecord;
          files: FileRecord[];
          matchingFiles: string[];
        }[] = response.data;

        const files = r
          .map((f) => f.files)
          .reduce((prev, curr) => [...prev, ...curr], [])
          .sort((a, b) =>
            new Date(a.createdOn || "9999-01-01") <
            new Date(b.createdOn || "9999-01-01")
              ? 1
              : -1
          );
        const records = r
          .map((f) => f.record)
          .sort((a, b) => {
            if (a.postedDateIn === null) return -1;
            if (b.postedDateIn === null) return 1;
            return a.postedDateIn > b.postedDateIn ? 1 : -1;
          });
        const matchingFiles = r
          .map((f) => f.matchingFiles)
          .reduce((prev, curr) => [...prev, ...curr], []);

        setData(records);
        setFiles(files);
        setMatchingFiles(matchingFiles);
        setLoading(false);
      })
      .catch(
        tryCatchServerError(() => {
          growl.current.show({
            severity: "error",
            summary: "Error getting details",
            detail: `Error getting Contract+ details for ${record.id}`,
          });
          setLoading(false);
        })
      );
  }, []);

  const ActionsButtonColumn = ({ record }: { record: TableRecord }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NavigateToButton
          openPage={() => {
            window.open(getDownloadUrlFromId(record.id), "_blank");
          }}
        />
      </div>
    );
  };

  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");

  const descriptionColumnBody = useCallback(
    (r: TableRecord) => (
      <>
        <Popover
          dialogHeader={dialogHeader}
          isVisible={
            dialogVisible ===
            (r.postedDateIn ? r.postedDateIn.toString() : "date")
          }
          dialogText={dialogText}
          setMultiDialogVisible={setDialogVisible}
          multi={true}
        />
        <p
          className="contacts_description"
          data-title={r.title}
          data-full-abstract={r.description}
          dangerouslySetInnerHTML={{
            __html:
              r.description && r.description.length > 100
                ? `${removeHtmlTags(r.description).substring(0, 100)}...`
                : r.description,
          }}
          onClick={() => {
            {
              console.log("AAAAAAAAAAAAAAAAAAAAA", r.postedDateIn?.toString());
            }
            setDialogHeader(r.title);
            setDialogText(r.description);
            setDialogVisible(
              r.postedDateIn ? r.postedDateIn.toString() : "date"
            );
          }}
        ></p>
      </>
    ),
    []
  );

  if (loading) {
    return <ProgressSpinner></ProgressSpinner>;
  }

  return (
    <div
      className="p-grid p-dir-col"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        textAlign: "left",
        margin: 15,
      }}
    >
      <h4>
        Files
        {files.length > 0 ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              // href="#"
              onClick={() => {
                axios
                  .post(
                    getFullUrl(
                      `/api/contract/details/${record.contractOpportunityBase_Id}/getzip`,
                      { useDedicatedEnvironment: true }
                    ),
                    {},
                    {
                      ...createAuthenticatedRequest(context),
                      responseType: "blob",
                    }
                  )
                  .then(async (response) => {
                    let fileName = `export-${new Date().valueOf()}.zip`;
                    try {
                      fileName = response.headers["content-disposition"]
                        .split(";")[1]
                        .replace("filename=", "")
                        .replaceAll('"', "")
                        .trim();
                    } catch {
                      /* NO-OP */
                    }

                    // in deployed version the response is still getting converted back
                    // to b64 so need to un-load that. if running web server locally the
                    // response is already decoded so can directly save response.data instead
                    // of this BS
                    // const decoded = atob(await response.data.text());
                    // const array = new Uint8Array(decoded.length);
                    // for (let i = 0; i < decoded.length; i++) {
                    //   array[i] = decoded.charCodeAt(i);
                    // }

                    // saveAs(
                    //   new Blob([array], { type: "application/zip" }),
                    //   fileName
                    // );

                    saveAs(new Blob([response.data]), fileName);
                  })
                  .catch((error) => {
                    console.log(error);
                    alert("error fetching data");
                  });
              }}
            >
              <img
                src={icons.zip}
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  ...styles.addRemoveButtonStyle,
                }}
              />
              Download all attachments
            </a>
          </>
        ) : (
          <span className="no-attachments">
            No attachments have been added to this opportunity
          </span>
        )}
      </h4>

      <div style={{ width: 1024 }} className="extended-contact-table">
        <DataTable value={files}>
          {GetCustomColumnBody<FileRecord>(
            styles,
            300,
            (x) => (
              <a
                href="#"
                style={{ paddingLeft: 15 }}
                onClick={(e: any) => {
                  e.preventDefault();
                  window.open(
                    `${ContractsResourceServer}${encodeURIComponent(x.name)}`
                  );
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: 20 }}>
                  {matchingFiles.find((r) => r === x.name) ? "* " : ""}
                </span>
                {x.name}
              </a>
            ),
            "Name",
            "name"
          )}
          {GetCustomColumnBody<FileRecord>(
            styles,
            300,
            (x) => formatDate(x.createdOn, DateFormat.dd_MMMM_yyyy_HH_mm),
            "Added Date/Time at UTC",
            "createdOn"
          )}
        </DataTable>
      </div>

      <h4>Record History</h4>

      <DataTable
        value={data}
        scrollHeight="600px"
        scrollable={true}
        className="extended-contact-table"
      >
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => (
            <ActionsButtonColumn record={x} />
          ),
          "Actions",
          "id",
          { sortable: false }
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          450,
          (x) => x.title,
          "Title",
          "title"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          100,
          (x) => x.version,
          "Version",
          "version"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.modifiedDateIn,
          "Modified Date",
          "modifiedDateIn"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.solicitationNumber,
          "Solicitation Number",
          "solicitationNumber"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          250,
          (x) => x.agencyName,
          "Department/Agency",
          "agencyName"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.type,
          "Contract Opportunity Type",
          "type"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.country,
          "Country/Office",
          "country"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          250,
          (x) => x.setAside,
          "Set Aside",
          "setAside"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          250,
          (x) => x.placeOfPerformance,
          "Place of Performance",
          "placeOfPerformance"
        )}
        {GetCustomColumnShortened<ExpandedRecord>(
          styles,
          450,
          descriptionColumnBody,
          (x) => x.title,
          "Description",
          150,
          true,
          "description"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          450,
          (x) => x.primeOrIncumbent,
          "Likely Bidding Primes or Incumbent",
          "primeOrIncumbent",
          { additionalHeaderStyles: styles.primeColumnStyle }
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.postedDateIn,
          "Original Publish Date",
          "postedDateIn"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.solicitationDeadlineResponseIn,
          "Solicitation Response Deadline",
          "solicitationDeadlineResponseIn"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.classificationCode,
          "Product Service Code",
          "classificationCode"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.naicsCodes,
          "NAICS",
          "naicsCodes"
        )}
        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          150,
          (x) => x.lastUpdated,
          "Last Updated",
          "solicitlastUpdatedationNumber"
        )}

        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          350,
          (x) => {
            const p = x.pointOfContactOne || {
              fullName: "",
              email: "",
              phone: "",
              fax: "",
            };
            return (
              <div className="p-grid p-dir-col p-justify-center">
                <div className="p-col">
                  <div>{p.fullName}</div>
                  <div>{p.email}</div>
                  {p.phone && <div>{`Phone: ${p.phone}`}</div>}
                  {p.fax && <div>{`Fax: ${p.fax}`}</div>}
                </div>
              </div>
            );
          },
          "Contact Info",
          "pointOfContactOne"
        )}

        {GetCustomColumnBody<ExpandedRecord>(
          styles,
          350,
          (x) => {
            const p = x.pointOfContactTwo || {
              fullName: "",
              email: "",
              phone: "",
              fax: "",
            };
            return (
              <div className="p-grid p-dir-col p-justify-center">
                <div className="p-col">
                  <div>{p.fullName}</div>
                  <div>{p.email}</div>
                  {p.phone && <div>{`Phone: ${p.phone}`}</div>}
                  {p.fax && <div>{`Fax: ${p.fax}`}</div>}
                </div>
              </div>
            );
          },
          "Secondary Contact Info",
          "pointOfContactTwo"
        )}
      </DataTable>
    </div>
  );
};

function ContractsTableScreen() {
  const context = React.useContext(UserContext);
  const styles = useGridStyles();

  // Page info
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initial prarams
  let onlyMyRecordsArgument = false;
  if (location && location.search) {
    onlyMyRecordsArgument =
      (parse(location.search).myContracts as string) === "true" || false;
  }

  // Initialize date to today-3 months
  let todayMinusMonths = Date.today().addMonths(-6);

  // Grid required properties
  const [isLoading, setIsLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortField, setSortField] = React.useState("modifiedDateIn");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [lastUpdated, setLastUpdated] = React.useState("");
  const [data, setData] = React.useState(Array<TableRecord>());
  const [colunmType, setColumnType] = React.useState("");
  //Available Options
  const [countriesOptions, setCountriesOptions] = React.useState(
    Array<string>()
  );
  const [setAsidesOptions, setSetAsidesOptions] = React.useState(
    Array<{ label: string; value: string }>()
  );
  const [opportunityTypesOptions, setOpportunityTypesOptions] = React.useState(
    Array<{ label: string; value: string }>()
  );

  const [fcountry, fsetAside, fopportunityType] = [
    "country",
    "setAside",
    "opportunityType",
  ];

  React.useEffect(() => {
    let baseUrl = "/api/contract/dropdownoptions/";
    let countriesUrl = baseUrl + fcountry;
    let setAsideUrl = baseUrl + fsetAside;
    let opportunityTypesUrl = baseUrl + fopportunityType;

    let baseMessage = "error fetching available ";
    let countriesMessage = baseMessage + "countries";
    let setAsideMessage = baseMessage + "set asides";
    let opportunityTypesMessage = baseMessage + "opportunity types";

    fetchDataNewEnvironment(
      countriesUrl,
      setCountriesOptions,
      context,
      countriesMessage,
      true
    );
    fetchDataNewEnvironment(
      setAsideUrl,
      setSetAsidesOptions,
      context,
      setAsideMessage,
      true
    );
    fetchDataNewEnvironment(
      opportunityTypesUrl,
      setOpportunityTypesOptions,
      context,
      opportunityTypesMessage,
      true
    );
  }, []);

  const [tempCountryNamesFilter, setTempCountryNamesFilter] =
    React.useState("");
  const [tempSetAsideFilter, setTempSetAsideFilter] = React.useState("");
  const [tempOpportunityTypeFilter, setTempOpportunityTypeFilter] =
    React.useState("");
  const [tempCountryNamesOptions, setTempCountryNamesOptions] = React.useState(
    Array<string>()
  );
  const [tempSetAsideOptions, setTempSetAsideOptions] = React.useState(
    Array<string>()
  );
  const [tempOpportunityTypeOptions, setTempOpportunityTypeOptions] =
    React.useState(Array<string>());

  function setTempFilteredOptions(filter: string, filterOptionName: string) {
    let apiUrl = "/api/contract/dropdownoptions/" + filterOptionName;
    let queryString = new URLSearchParams();
    queryString.set("filter", filter);
    let url = `${apiUrl}?${queryString.toString()}`;

    let errorMessage = "Error fetching filtered options";

    let setMethod;
    switch (filterOptionName) {
      case fcountry:
        setMethod = setTempCountryNamesOptions;
        break;
      case fsetAside:
        setMethod = setTempSetAsideOptions;
        break;
      case fopportunityType:
        setMethod = setTempOpportunityTypeOptions;
        break;
    }

    fetchDataNewEnvironment(url, setMethod, context, errorMessage, true);
  }

  React.useEffect(() => {
    if (tempSetAsideFilter)
      setTempFilteredOptions(tempSetAsideFilter, fsetAside);
  }, [tempSetAsideFilter]);

  React.useEffect(() => {
    if (tempCountryNamesFilter)
      setTempFilteredOptions(tempCountryNamesFilter, fcountry);
  }, [tempCountryNamesFilter]);

  React.useEffect(() => {
    if (tempOpportunityTypeFilter)
      setTempFilteredOptions(tempOpportunityTypeFilter, fopportunityType);
  }, [tempOpportunityTypeFilter]);

  // Filters
  const [showAwards, setShowAwards] = React.useState(false);
  const [solicitationNumber, setSolicitationNumber] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [fileKeyword, setFileKeyword] = React.useState("");
  const [onlyMyList, setOnlyMyList] = React.useState(onlyMyRecordsArgument);
  const [includeUsaid, setIncludeUsaid] = React.useState(true);
  const [includeMcc, setIncludeMcc] = React.useState(false);
  const [includeCdc, setIncludeCdc] = React.useState(false);
  const [includeStateDept, setIncludeStateDept] = React.useState(false);
  const [country, setCountry] = React.useState(Array<string>());
  const [opportunityType, setOpportunityType] = React.useState(Array<string>());
  const [setAside, setSetAside] = React.useState(Array<string>());
  const [fileName, setFileName] = React.useState("");
  const [updatedDateFilter, setUpdatedDateFilter] = React.useState<Date | null>(
    todayMinusMonths
  );
  const [updatedDateFilterError, setUpdatedDateFilterError] =
    React.useState(false);
  const [filePopUp, setFilePopUp] = React.useState(false);

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: number; note: string }>()
  );
  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: number; customColumn: string }>()
  );

  const [customColumnName, setCustomColumnName] = React.useState("");
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const [query, setQuery] = React.useState<URLSearchParams>();

  const depList = [
    showAwards,
    solicitationNumber,
    keyword,
    fileKeyword,
    onlyMyList,
    includeUsaid,
    includeMcc,
    includeCdc,
    includeStateDept,
    country,
    opportunityType,
    setAside,
    fileName,
    updatedDateFilter,
    rowsPerPage,
    sortField,
    sortOrder,
    first,
    totalRecords,
  ];

  const queryCallback = React.useCallback(
    () =>
      queryData({
        first,
        rows: rowsPerPage,
        sortField,
        sortOrder,
      }),
    depList
  );
  useEnterListener(queryCallback);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
      setColumnType(container.id);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setColumnType("");
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);
    const url = "/api/contract";
    const queryString = new URLSearchParams();

    if (opportunityType.length) {
      queryString.set("opportunityType", opportunityType.join("|"));
    }

    if (solicitationNumber) {
      queryString.set("solicitationNumber", solicitationNumber);
    }

    if (onlyMyList) {
      queryString.set("onlyMyList", onlyMyList.toString());
    }

    if (includeUsaid) {
      queryString.set("includeUsaid", includeUsaid.toString());
    }
    if (includeMcc) {
      queryString.set("includeMcc", includeMcc.toString());
    }
    if (includeCdc) {
      queryString.set("includeCdc", includeCdc.toString());
    }
    if (includeStateDept) {
      queryString.set("includeStateDept", includeStateDept.toString());
    }

    if (setAside.length) {
      queryString.set("setAsides", setAside.join("|"));
    }

    if (keyword) {
      queryString.set("filter", keyword);
    }

    if (fileKeyword) {
      queryString.set("fileKeyword", fileKeyword);
    }

    if (country.length) {
      queryString.set("country", country.join("|"));
    }

    if (fileName) {
      queryString.set("fileName", fileName);
    }

    if (updatedDateFilter && !updatedDateFilterError) {
      queryString.set(
        "updatedBy",
        updatedDateFilter.toISOString().substring(0, 10)
      );
    }

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    if (showAwards) {
      queryString.set("showAwards", showAwards.toString());
    }

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc, attributes } = response.data;
        setTotalRecords(totalItems);

        setCustomColumnName(attributes ? attributes.CustomColumnName : "");
        // Set top records
        const d = data as Array<TableRecord>;
        d.forEach((r) => {
          if (!r.notes) {
            r.notes = "";
          }
        });
        setData(d);
        setOriginalNotes(
          d.map((r) => ({ id: r.contractOpportunityBase_Id, note: r.notes }))
        );
        setOriginalColumns(
          d.map((r) => ({
            id: r.contractOpportunityBase_Id,
            customColumn: r.customColumn,
          }))
        );
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!countriesOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Country"
                setSelectedOption={setCountry}
                allOptions={
                  tempCountryNamesFilter
                    ? tempCountryNamesOptions
                    : countriesOptions
                }
                disabled={isLoading || !countriesOptions}
                setFilter={setTempCountryNamesFilter}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div
            className="p-col-fixed icon-col"
            style={{ position: "relative" }}
            onMouseEnter={() => setFilePopUp(true)}
            onMouseLeave={() => setFilePopUp(false)}
          >
            <img src={icons.file} alt="search" style={styles.iconFile} />
            {filePopUp && (
              <div className="file-pop-up">
                {"Search file names of historical attachments. " +
                  "Clear the date filter below to search all records in the database. " +
                  'Try "IEE" or "EMMP" for environmental reports. ' +
                  'Or, "LCP" or "Compensation" for local compensation plans. ' +
                  "You can also filter to only search by select Missions."}
              </div>
            )}
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="fileName"
              type="search"
              value={fileName}
              onChange={(e: any) => setFileName((e.target as any).value)}
              placeholder="File Name"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.number} alt="search" style={styles.icon} />
          </div>
          <div
            className="p-col"
            style={{ textAlign: "left", display: "block" }}
          >
            <InputText
              style={styles.filters.inputBox}
              id="solicitationNumber"
              type="search"
              value={solicitationNumber}
              onChange={(e: any) =>
                setSolicitationNumber((e.target as any).value)
              }
              placeholder="Solicitation Number"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!setAsidesOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Set Aside"
                setSelectedOption={setSetAside}
                allOptions={
                  tempSetAsideFilter ? tempSetAsideOptions : setAsidesOptions
                }
                disabled={isLoading || !setAsidesOptions}
                setFilter={setTempSetAsideFilter}
                getOptionValue={(r: any) => r.value}
                getOptionLabel={(r: any) => r.label}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!opportunityTypesOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Opportunity Types"
                setSelectedOption={setOpportunityType}
                allOptions={
                  tempOpportunityTypeFilter
                    ? tempOpportunityTypeOptions
                    : opportunityTypesOptions
                }
                disabled={isLoading || !opportunityTypesOptions}
                setFilter={setTempOpportunityTypeFilter}
                getOptionValue={(r: any) => r.value}
                getOptionLabel={(r: any) => r.label}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword Search Project Data"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="fileKeyword"
              type="search"
              value={fileKeyword}
              onChange={(e: any) => setFileKeyword((e.target as any).value)}
              placeholder="Keyword Search In-Document Text"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show contracts updated since{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              onError={(e) => setUpdatedDateFilterError(!!e)}
              variant="inline"
              format="MM/dd/yyyy"
              value={updatedDateFilter}
              onChange={(date) => setUpdatedDateFilter(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <Button
              style={styles.filters.searchButton}
              onClick={() => {
                if (showAwards) {
                  setSortField("contractorAwardedDate");
                  queryData({
                    first,
                    rows: rowsPerPage,
                    sortField: "contractorAwardedDate",
                    sortOrder,
                  });
                } else {
                  queryData({
                    first,
                    rows: rowsPerPage,
                    sortField,
                    sortOrder,
                  });
                }
              }}
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<TableRecord[]> => {
    var base = "/api/contract";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<TableRecord>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid" style={styles.pageHeader}>
        <div className="p-col-12" style={{ ...styles.pageHeader }}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Contracts+
        </div>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Panel
          style={styles.filters.filterBox}
          collapsed={isCollapsed}
          onToggle={(e) => setIsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              search={() => {
                if (showAwards) {
                  setSortField("contractorAwardedDate");
                  queryData({
                    first,
                    rows: rowsPerPage,
                    sortField: "contractorAwardedDate",
                    sortOrder,
                  });
                } else {
                  queryData({ first, rows: rowsPerPage, sortField, sortOrder });
                }
              }}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              expandedTitle={
                <div
                  className="p-grid"
                  style={{ ...styles.filters.fontHeader }}
                >
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb1"
                      value="USAID"
                      onChange={(e) => setIncludeUsaid(e.checked)}
                      checked={includeUsaid}
                    />
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      USAID
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-2"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb2"
                      value="MCC"
                      onChange={(e) => setIncludeMcc(e.checked)}
                      checked={includeMcc}
                    />
                    <label
                      htmlFor="cb2"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      MCC
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-3"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb3"
                      value="State Dept"
                      onChange={(e) => setIncludeStateDept(e.checked)}
                      checked={includeStateDept}
                    />
                    <label
                      htmlFor="cb3"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      State Dept
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-3"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb4"
                      value="CDC"
                      onChange={(e) => setIncludeCdc(e.checked)}
                      checked={includeCdc}
                    />
                    <label
                      htmlFor="cb4"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      CDC
                    </label>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-4"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="swa"
                      value="showAwardsOnly"
                      onChange={(e) => setShowAwards(e.checked)}
                      checked={showAwards}
                    />
                    <label
                      htmlFor="swa"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      Show Awards Only
                    </label>
                  </div>
                </div>
              }
            />
          }
        >
          {renderSearch()}
        </Panel>
      </MuiPickersUtilsProvider>

      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />

      <ContractsDataTable
        gridWidth={gridWidth}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={lastUpdated}
        originalNotes={originalNotes}
        setOriginalNotes={setOriginalNotes}
        originalColumns={originalColumns}
        setOriginalColumns={setOriginalColumns}
        customColumnName={customColumnName}
        setCustomColumnName={setCustomColumnName}
        queryData={queryData}
        fileKeyword={fileKeyword}
        getExcelData={getExcelData}
      />
    </div>
  );
}

type ContractTableProps = {
  isLoading: boolean;
  isPipelineScreen?: boolean;

  // Filtering/sorting/querying
  rowsPerPage: number;
  pipelineId?: number;
  setRowsPerPage: React.Dispatch<number>;

  sortField: string;
  setSortField: React.Dispatch<string>;
  sortOrder: number;
  setSortOrder: React.Dispatch<number>;
  first: number;

  totalRecords: number;
  setTotalRecords: React.Dispatch<number>;

  // Grid state
  data: Array<TableRecord>;
  setData: React.Dispatch<Array<TableRecord>>;
  lastUpdated: string;
  gridWidth: number;

  // Notes info
  originalNotes: Array<{ id: number; note: string }>;
  setOriginalNotes: React.Dispatch<Array<{ id: number; note: string }>>;

  // Columns info
  originalColumns: Array<{ id: number; customColumn: string }>;
  setOriginalColumns: React.Dispatch<
    Array<{ id: number; customColumn: string }>
  >;

  // Column name
  customColumnName: string;
  setCustomColumnName: React.Dispatch<string>;

  isMyContracts?: boolean;
  isMySuggestions?: boolean;
  decrement?: () => void;

  queryData: (event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) => void;

  // needed for global keyword search
  keyword?: string;
  setKeyword?: React.Dispatch<string>;

  // needed for file keyword search to show in expanded view
  fileKeyword?: string;

  getExcelData?: () => Promise<TableRecord[]>;
  isRemove?: boolean;
};

const ContractsDataTable: React.FunctionComponent<ContractTableProps> = ({
  isLoading,
  isPipelineScreen,
  pipelineId,

  rowsPerPage,
  setRowsPerPage,

  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  first,
  totalRecords,
  setTotalRecords,

  data,
  setData,
  lastUpdated,
  gridWidth,

  originalNotes,
  setOriginalNotes,

  originalColumns,
  setOriginalColumns,

  customColumnName,
  setCustomColumnName,

  isMyContracts,
  isMySuggestions,
  decrement,

  queryData,

  // needed for global keyword search
  keyword,
  setKeyword,

  // needed for file keyword search to show in expanded view
  fileKeyword,

  getExcelData,
  isRemove,
}) => {
  // Contexts
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);

  // Hooks
  const styles = useGridStyles();

  // Grid state
  const [expandedRows, setExpandedRows] = React.useState(Array<TableRecord>());
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const [notesCollapsed, setNotesCollapsed] = React.useState(true);
  const [customColumnCollapsed, setCustomColumnCollapsed] =
    React.useState(true);

  const history = useHistory();
  const path = "/contracts";

  // Handlers
  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  const [disableExcel, setDisableExcel] = React.useState(false);
  const [pipeline, setPipelines] = React.useState<PipelineDto[]>(
    Array<PipelineDto>()
  );
  const [pipelineForcasts, setPipelineforcasts] = React.useState<
    PipelineForecasts[]
  >(Array<PipelineForecasts>());
  const popupMenu = useRef<OverlayPanel>(null);
  const [rowData, setRowData] = React.useState<TableRecord>();
  const [selectedPipelines, setSelectedPipelines] = React.useState<
    PipelineDto[]
  >([]);
  const [rerender, setRerender] = React.useState(false);
  const [serverRecord, setServerRecord] = React.useState<PipelineDto[]>([]);

  const loadPipelines = () => {
    axios
      .get(
        getFullUrl("/api/pipeline", { useDedicatedEnvironment: true }),
        createRequestWithAuthHeaders(context)
      )
      .then(async (res) => {
        const mypipelines = res.data.filter(
          (pipeline) => pipeline.pipelineUserRole === "Admin"
        );
        setPipelines(mypipelines);
        const records = await Promise.all(
          res.data.map(async (x: PipelineDto) => {
            const sa = await axios.get(
              getFullUrl(`/api/pipeline/${x.id}/contract`, {
                useDedicatedEnvironment: true,
              }),
              createAuthenticatedRequest(context)
            );

            const data = sa.data.data;

            return {
              id: x.id,
              name: x.name,
              businessForcast: data,
            };
          })
        );

        const r: PipelineForecasts[] = (records as PipelineForecasts[]).map(
          (x) => ({
            id: x.id,
            name: x.name,
            businessForcast: x.businessForcast,
          })
        );

        setPipelineforcasts(r);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    //  load pipeline data
    loadPipelines();
  }, [rerender]);

  const addToMyPipeline = (
    record: TableRecord,
    e: React.SyntheticEvent<Element, Event>
  ) => {
    const p = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: TableRecord) => u.id.includes(record.id))
    );
    setSelectedPipelines(p);
    setServerRecord(p);

    // set row data
    setRowData(record);

    if (popupMenu && popupMenu.current) {
      popupMenu.current.toggle(e);
    }
  };
  const selectPipeline = useCallback(
    async (pipelinesTobeAdded: PipelineDto[]) => {
      // setAllPipelines(pipelinesTobeAdded)
      setSelectedPipelines(pipelinesTobeAdded);
    },
    []
  );

  const closeModal = () => {
    setSelectedPipelines([]);
    popupMenu.current?.hide();
  };

  const unTrack = async (
    pipelineToRemove: PipelineDto,
    pipelines: PipelineDto[]
  ) => {
    const request = createAuthenticatedRequest(context);

    if (!rowData) {
      return;
    }

    const sendToServer = async () => {
      return axios
        .delete(
          getFullUrl(
            `/api/pipeline/${pipelineToRemove.id}/contract/${rowData.id}`,
            { useDedicatedEnvironment: true }
          ),
          request
        )
        .then(() => {
          const updatedPipelines = pipelines.filter(
            (r) => r.id !== pipelineToRemove.id
          );
          setSelectedPipelines(updatedPipelines);
          // loadPipelines()
        })
        .catch((message) => {
          console.error(message);
          growl.current.show({
            severity: "error",
            summary: "Error removing pipeline from the opportunity",
          });
        });
    };

    await sendToServer();
  };

  const TrackOpportunity = () => {
    const request = createAuthenticatedRequest(context);
    if (!rowData) {
      return;
    }
    const updatedSelectedPipelines = selectedPipelines.filter(
      (sa) => !serverRecord.some((r) => sa.id === r.id)
    );
    updatedSelectedPipelines.map(async (sa) => {
      await axios
        .post(
          getFullUrl(`/api/pipeline/${sa.id}/contract/${rowData.id}`, {
            useDedicatedEnvironment: true,
          }),
          null,
          request
        )
        .then((res) => {
          growl.current.show({
            severity: "success",
            summary: `Opportunity updated, with ${sa.name} pipeline`,
          });
          popupMenu.current?.hide();
          setRerender(!rerender); // chain  this on use-effect
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: sam.gov</div>
        <div>{lastUpdated}</div>
      </div>

      <div
        className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader"
        style={{ display: "flex" }}
      >
        {isMyContracts && setKeyword && (
          <div className="search-bar">
            <InputText
              type="search"
              style={{ ...styles.keywordSearch }}
              value={keyword}
              onChange={(e) => setKeyword!((e.target as any).value)}
              placeholder="Global Search"
            />
          </div>
        )}
        <div
          style={{
            font: "14px Open Sans",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconTooltip
            tooltipText={
              "Opportunities are automatically sorted by “Last Updated Date,” so you can quickly identify changes and when they were made."
            }
            className="tooltip-fixed"
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Contracts+",
                `Aidkonekt_contracts_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (data: TableRecord[]): TableProperties => {
    const columns = [
      { name: "Link" },
      { name: "Department/Agency" },
      { name: "Title" },
      { name: "Country/Office" },
      { name: "Description" },
      { name: "Contract Opportunity Type" },
      { name: "Contractor Awarded Name" },
      { name: "Awarded Date" },
      { name: "Contract Award Number" },
      { name: "Awarded Amount" },
      { name: "Solicitation Number" },
      { name: "Notes (*)" },
      { name: "Custom Column" },
      { name: "Original Publish Date" },
      { name: "Offer Due Date" },
      { name: "Last Updated Date" },
      { name: "Set Aside" },
      { name: "Product Service Code" },
      { name: "NAICS" },
      { name: "Place of Performance" },
      { name: "Contact Info" },
      { name: "Secondary Contact Info" },
    ];

    let rows: any[][] = [];

    if (data.length === 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        const chunkDescription = getChunksFromString(
          record.description,
          MaxCharsAllowed
        );

        rows.push([
          getDownloadUrlFromId(record.id),
          record.agencyName,
          record.title,
          record.country,
          chunkDescription[0] || "",
          record.type,
          record.contractorAwardedName,
          record.contractorAwardedDate
            ? record.contractorAwardedDate.toString(DateFormat.dd_MMMM_yyyy)
            : "",
          record.awardNumber,
          isNumeric(record.contractorAwardedAmount)
            ? currencyFormatter.format(
                parseFloat(record.contractorAwardedAmount || "")
              )
            : record.contractorAwardedAmount,
          record.solicitationNumber,
          !isMySuggestions ? record.notes : "",
          formatDate(record.postedDateIn, DateFormat.dd_MMMM_yyyy),
          formatDate(
            record.solicitationDeadlineResponseIn,
            DateFormat.dd_MMMM_yyyy
          ),
          formatDate(record.modifiedDateIn, DateFormat.dd_MMMM_yyyy),
          record.setAside,
          record.classificationCode,
          record.naicsCodes,
          record.placeOfPerformance,
          record.pointOfContactOne
            ? `${record.pointOfContactOne.fullName}; ${
                record.pointOfContactOne.email
              }${
                record.pointOfContactOne.phone
                  ? `; ${record.pointOfContactOne.phone}`
                  : ""
              }${
                record.pointOfContactOne.fax
                  ? `; ${record.pointOfContactOne.fax}`
                  : ""
              }`
            : "",
          record.pointOfContactTwo
            ? `${record.pointOfContactTwo.fullName}; ${
                record.pointOfContactTwo.email
              }${
                record.pointOfContactTwo.phone
                  ? `; ${record.pointOfContactTwo.phone}`
                  : ""
              }${
                record.pointOfContactTwo.fax
                  ? `; ${record.pointOfContactTwo.fax}`
                  : ""
              }`
            : "",
        ]);

        for (let j = 1; j < chunkDescription.length; j++) {
          let emptyRow = genEmptyRow(columns.length);
          emptyRow[4] = chunkDescription[j];
          rows.push(emptyRow);
        }
      }
    }

    return buildExcelTable("GrantsExport", columns, rows);
  };

  const NotesEditor = (props: any) => {
    const { rowIndex } = props;
    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].notes = value;
      setData(updatedData);
    }

    return (
      <textarea
        autoFocus
        value={data[rowIndex].notes}
        className="notesTextarea"
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const ColumnEditor = (props: any) => {
    const { rowIndex } = props;
    function onEditorValueChange(value: string) {
      let updatedData = [...data];
      updatedData[rowIndex].customColumn = value;
      setData(updatedData);
    }

    return (
      <InputText
        type="text"
        value={data[rowIndex].customColumn || ""}
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const cancelNote = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: TableRecord } = o;
    const baseNote =
      (
        originalNotes.find(
          (r) => r.id === rowData.contractOpportunityBase_Id
        ) || {}
      ).note || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].notes = baseNote;
    setData(updatedData);
  };

  const cancelCustomColumn = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: TableRecord } = o;
    const baseColumn =
      (
        originalColumns.find(
          (r) => r.id === rowData.contractOpportunityBase_Id
        ) || {}
      ).customColumn || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const submitNote = (o: any) => {
    const { rowData }: { rowIndex: number; rowData: TableRecord } =
      o.columnProps;

    // If the note has not changed, don't re-save
    const baseNote = originalNotes.find(
      (r) => r.id === rowData.contractOpportunityBase_Id
    );
    const isNoteUnmodified = baseNote && baseNote.note === rowData.notes;
    if (isNoteUnmodified) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl("/api/contract", { useDedicatedEnvironment: true }),
        { Id: rowData.id, note: rowData.notes },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // Update in-memory notes to new note
        if (baseNote) {
          const updatedNotes = [...originalNotes];
          const updatedNote = updatedNotes.find((r) => r.id === baseNote.id);
          if (updatedNote) updatedNote.note = rowData.notes;
          setOriginalNotes(updatedNotes);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Note Updated",
            detail: `Finished updating notes for '${rowData.solicitationNumber}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelNote(o);
        })
      );
  };

  const cancelColumn = (o: any) => {
    const { rowIndex, rowData }: { rowIndex: number; rowData: TableRecord } = o;
    const baseColumn =
      (
        originalColumns.find(
          (r) => r.id === rowData.contractOpportunityBase_Id
        ) || {}
      ).customColumn || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const submitColumn = (o: any) => {
    const { rowData }: { rowIndex: number; rowData: TableRecord } =
      o.columnProps;

    // If the note has not changed, don't re-save
    const baseColumn = originalColumns.find(
      (r) => r.id === rowData.contractOpportunityBase_Id
    );

    const isColumnUnmodified =
      baseColumn && baseColumn.customColumn === rowData.customColumn;
    if (isColumnUnmodified) {
      return;
    }

    // Send update
    axios
      .post(
        getFullUrl("/api/contract/customColumn", {
          useDedicatedEnvironment: true,
        }),
        { Id: rowData.id, customColumn: rowData.customColumn },
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // Update in-memory notes to new note
        if (baseColumn) {
          const updatedColumns = [...originalColumns];
          const updatedColumn = updatedColumns.find(
            (r) => r.id === baseColumn.id
          );
          if (updatedColumn) updatedColumn.customColumn = rowData.customColumn;
          setOriginalColumns(updatedColumns);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Custom Column Updated",
            detail: `Finished updating custom column for '${rowData.solicitationNumber}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelCustomColumn(o);
        })
      );
  };

  const addRecordToMyList = (record: TableRecord) => {
    axios
      .post(
        getFullUrl(`/api/contract/mylist/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        null,
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        // re-set my list status for all items with this id
        const newData = [...data];
        newData
          .filter((r: any) => r.id === record.id)
          .forEach((r: TableRecord) => (r.isMyList = true));
        setData(newData);
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added contract '${record.solicitationNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const removeRecordFromMyList = (record: TableRecord) => {
    let request = createAuthenticatedRequest(context);

    axios
      .delete(
        getFullUrl(`/api/pipeline/${pipelineId}/contract/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then(() => {
        // re-set my list status for all items with this id
        // const newData = [...data];
        const newData = [...data.filter((r) => r.id !== record.id)];
        // newData
        //   .filter((r: any) => r.id === record.id)
        //   .forEach((r: TableRecord) => (r.isMyList = false));
        setData(newData);
        setTotalRecords(newData.length);
        growl.current.show({
          severity: "success",
          summary: "Removed",
          detail: `Removed '${record.solicitationNumber}' from my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const addMySuggestion = (record: TableRecord) => {
    axios
      .post(
        getFullUrl(`/api/contract/mylist/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        null,
        createRequestWithAuthHeaders(context)
      )
      .then(() => {
        debugger;
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        if (decrement) decrement();
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added contract '${record.solicitationNumber}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  React.useEffect(() => {
    if (isRemove) {
      removeAllMySuggestions();
    }
  }, [isRemove]);

  const removeAllMySuggestions = () => {
    axios
      .delete(
        getFullUrl(
          pipelineId
            ? `/api/pipeline/${pipelineId}/contract/suggestion`
            : `/api/contract/mysuggestions`,
          {
            useDedicatedEnvironment: true,
          }
        ),
        createAuthenticatedRequest(context)
      )
      .then(() => {
        // remove this item from current list
        setTotalRecords(0);
        setRowsPerPage(0);
        setData([]);
        if (decrement) decrement();
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const removeMySuggestion = (record: TableRecord) => {
    axios
      .delete(
        getFullUrl(`/api/contract/mysuggestions/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        setData(newData);
        if (decrement) decrement();
        growl.current.show({
          severity: "success",
          summary: "Ignored",
          detail: `Ignored contract '${record.solicitationNumber}'`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          })
        )
      );
  };

  const ActionsButtonColumn = ({ record }: { record: TableRecord }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <NavigateToButton
          openPage={() => {
            window.open(getDownloadUrlFromId(record.id), "_blank");
          }}
        />
      </div>
    );
  };

  const ActionsButtonColumnPipeline = ({ record }: { record: TableRecord }) => {
    if (!isMySuggestions) {
      // const add = (
      //   <ActionButtons.AddToMyListButton
      //     add={() => addRecordToMyList(record)}
      //   />
      // );
      const remove = (
        <ActionButtons.RemoveFromMyListButton
          remove={() => removeRecordFromMyList(record)}
        />
      );

      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NavigateToButton
            openPage={() => {
              window.open(getDownloadUrlFromId(record.id), "_blank");
            }}
          />
          {/* {record.isMyList ? remove : add} */}
          {remove}
        </div>
      );
    } else {
      const add = (
        <ActionButtons.AddToMyListButton add={() => addMySuggestion(record)} />
      );
      const remove = (
        <ActionButtons.RemoveFromMyListButton
          remove={() => removeMySuggestion(record)}
        />
      );

      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NavigateToButton
            openPage={() => {
              window.open(getDownloadUrlFromId(record.id), "_blank");
            }}
          />
          {!isMyContracts && add}
          {remove}
        </div>
      );
    }
  };

  const showEditCustomColumnName = async () => {
    const { value: formValues } = await Swal.fire({
      title: `Edit Column Name`,
      html: `<label htmlFor="columnName">Date</label>
          <input id="columnName" class="swal2-input" value="${customColumnName}"></input>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [(document.getElementById("columnName") as any).value];
      },
    });

    if (!formValues || !formValues.length) return;

    const [newColumnName] = formValues;
    await axios
      .put(
        getFullUrl("/api/utility/columnName", {
          useDedicatedEnvironment: true,
        }),
        { columnName: newColumnName, type: "contract" },
        createAuthenticatedRequest(context)
      )
      .then(() => {
        setCustomColumnName(newColumnName);
      })
      .catch((e) => {
        console.error("error setting contract custom column name", e);
        growl.current.show({
          severity: "error",
          summary: "Error setting column name",
        });
      });
  };

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");
  const [colunmType, setColumnType] = React.useState("");

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".contacts_description")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible("Contracts");
      setColumnType(container.id);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setColumnType("");
    };
  }, []);

  const descriptionColumnBody = (r: TableRecord) => (
    <p
      className="contacts_description"
      data-title={r.solicitationNumber}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.solicitationNumber);
        setDialogText(r.description);
        setDialogVisible("Contracts");
      }}
    ></p>
  );

  const pipelineRowTemplate = (r: any) => {
    const p = pipelineForcasts.filter((a) =>
      a.businessForcast.some((u: TableRecord) => u.id.includes(r.id))
    );
    const pipelines: any = [];
    for (let i = 0; i < p.length; i++) {
      pipelines.push(p[i].name);
    }
    const title = `Added to ${p.length} Pipelines`;

    return (
      <>
        <div>
          <span
            className="pipeline-actions"
            onClick={(e: React.SyntheticEvent<Element, Event>) =>
              addToMyPipeline(r, e)
            }
          >
            <img className="pipeline-button" src={plusButton} alt="" />
            <p
              className="contacts_description"
              data-full-abstract={pipelines}
              data-title={title}
              id="pipeline-popover"
              style={{
                textDecoration: "underline",
                paddingLeft: "4px",
                fontWeight: 400,
              }}
              onClick={(e) => {
                e.stopPropagation(); // stop propagation during the bubbling event.
                setDialogHeader(title);
                setDialogText(pipelines);
                setDialogVisible("Contracts");
                setColumnType("pipeline-popover");
              }}
            >
              {p.length == 0 ? `Add to Pipeline` : `${p.length} Pipelines`}
            </p>
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible === "Contracts"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
        colunmType={colunmType}
      />
      <OverlayerWindow>
        <OverlayPanel
          ref={popupMenu}
          id="contracts"
          style={{ width: "390px", display: "flex !important" }}
          className="contract-panel"
        >
          <div style={{ width: "335px", height: "auto" }}>
            <div className="side-panel-header-forecast">
              Add opportunity to Pipeline
              <i
                onClick={closeModal}
                className="pi pi-times mr-2"
                style={{ fontSize: 20, cursor: "pointer" }}
              ></i>
            </div>
            <div className="side-panel-content">
              <div className="p-grid">
                <div
                  className="p-col-12"
                  style={{ display: "grid" }}
                  id="contract-input"
                >
                  <label
                    className="field-titles-forecast"
                    htmlFor=" Opportunity"
                  >
                    Opportunity Title
                  </label>
                  <InputText
                    style={{ border: "none", fontWeight: 700 }}
                    placeholder="Opportunity"
                    className="field-titles-forecast "
                    defaultValue={rowData ? rowData.title : ""}
                  />
                </div>
                <div className="p-col-7" style={{ marginTop: "-16px" }}>
                  <label className="field-titles-forecast" htmlFor="country">
                    Contry/Office
                  </label>
                  <InputText
                    style={{ border: "none", fontWeight: 700 }}
                    type="text"
                    placeholder="country"
                    className="field-titles-forecast "
                    defaultValue={rowData ? rowData.country : ""}
                  />
                </div>
                <div className="p-col-7" style={{ marginTop: "-16px" }}>
                  <label
                    className="field-titles-forecast"
                    htmlFor="Created date"
                  >
                    Created at
                  </label>
                  <InputText
                    style={{ border: "none", fontWeight: 700 }}
                    type="text"
                    placeholder="Created date"
                    className="field-titles-forecast "
                    defaultValue={formatDate(
                      rowData?.postedDateIn as string,
                      DateFormat.dd_MMMM_yyyy
                    )?.toString()}
                  />
                </div>
                <div className="p-col-12" style={{ marginTop: "-16px" }}>
                  <label className="field-titles-forecast" htmlFor="email">
                    Pipelines
                  </label>
                  <MultiSelect
                    optionLabel="name"
                    filter={true}
                    style={customStyle.multiSelect}
                    placeholder={
                      selectedPipelines.length + " Pipeline(s) Selected"
                    }
                    fixedPlaceholder
                    value={selectedPipelines}
                    options={pipeline}
                    // itemTemplate={userTemplate}
                    onChange={(e) => selectPipeline(e.value)}
                  />

                  <div style={customStyle.chipsContainer}>
                    {selectedPipelines.map((sa, i) => (
                      <Chip
                        key={i}
                        label={sa.name}
                        onIconClick={() => unTrack(sa, selectedPipelines)}
                      />
                    ))}
                  </div>
                  {/* <Dropdown optionLabel="name" options={pipeline} className='field-inputs'  placeholder="3 Pipelines Selected " /> */}
                </div>
                <div
                  className="p-col-12"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    onClick={closeModal}
                    style={{ marginRight: "6px" }}
                    className="modal-button"
                    label="Cancel"
                  />
                  <Button
                    onClick={TrackOpportunity}
                    className="modal-button"
                    label="Save"
                  />
                </div>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </OverlayerWindow>
      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 60}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        header={tableHeader}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={(d: TableRecord) => (
          <RecordExpandedView record={d} fileKeyword={fileKeyword} />
        )}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
      >
        {!isMySuggestions && (
          <Column
            headerStyle={styles.headerStyle(40)}
            style={styles.columnStyle(40)}
            expander
          />
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) =>
            isPipelineScreen ? (
              <ActionsButtonColumnPipeline record={x} />
            ) : (
              <ActionsButtonColumn record={x} />
            ),
          "Actions",
          "id",
          { sortable: false }
        )}
        {isMyContracts && (
          <Column
            field={notesCollapsed ? "" : "notes"}
            header={
              <div>
                {notesCollapsed && (
                  <div>
                    <Button
                      icon="pi pi-chevron-right"
                      onClick={() => setNotesCollapsed(false)}
                    />
                  </div>
                )}
                {!notesCollapsed && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ alignSelf: "center", marginRight: 10 }}>
                      Notes
                    </div>
                    <Button
                      icon="pi pi-chevron-left"
                      onClick={() => setNotesCollapsed(true)}
                    />
                  </div>
                )}
              </div>
            }
            headerStyle={{
              ...styles.headerStyle(notesCollapsed ? 50 : 350),
              ...styles.primeColumnStyle,
            }}
            style={styles.columnStyle(notesCollapsed ? 50 : 350)}
            editor={notesCollapsed ? undefined : NotesEditor}
            sortable={true}
            onEditorSubmit={submitNote}
            onEditorCancel={cancelNote}
          />
        )}
        {isMyContracts && (
          <Column
            field={customColumnCollapsed ? "" : "customColumn"}
            header={
              <div>
                {customColumnCollapsed && (
                  <div>
                    <Button
                      icon="pi pi-chevron-right"
                      onClick={() => setCustomColumnCollapsed(false)}
                    />
                  </div>
                )}
                {!customColumnCollapsed && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ alignSelf: "center", marginRight: 10 }}>
                      {customColumnName}
                    </div>
                    <Button
                      icon="pi pi-chevron-left"
                      style={{ marginRight: 10 }}
                      onClick={() => setCustomColumnCollapsed(true)}
                    />
                    <Button
                      icon="pi pi-pencil"
                      onClick={showEditCustomColumnName}
                    />
                  </div>
                )}
              </div>
            }
            headerStyle={{
              ...styles.headerStyle(customColumnCollapsed ? 50 : 350),
              ...styles.primeColumnStyle,
            }}
            style={styles.columnStyle(customColumnCollapsed ? 50 : 350)}
            editor={customColumnCollapsed ? undefined : ColumnEditor}
            sortable={true}
            onEditorSubmit={submitColumn}
            onEditorCancel={cancelColumn}
          />
        )}

        {/* pipelines */}

        {history.location.pathname === path ? (
          <Column
            header="Pipeline"
            field="id"
            body={pipelineRowTemplate}
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(100)}
          />
        ) : null}
        {/* {GetCustomColumnShortened<TableRecord>(
              styles,
              180,
              pipelineRowTemplate,
              (x) => x.id,
              "Pipelines",
              100,
              true,
              "id"
            )} */}

        {GetCustomColumnBody<TableRecord>(
          styles,
          350,
          (x) => x.agencyName,
          "Department/Agency",
          "agencyName"
        )}

        {GetCustomColumnShortened<TableRecord>(
          styles,
          450,
          (x) => x.title,
          (x) => x.title,
          "Title",
          100,
          true,
          "title"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.country,
          "Country/Office",
          "country"
        )}
        {GetCustomColumnShortened<TableRecord>(
          styles,
          550,
          descriptionColumnBody,
          (x) => x.title,
          "Description",
          100,
          true,
          "description"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.type,
          "Contract Opportunity Type",
          "type"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.contractorAwardedName,
          "Contractor Awarded Name",
          "contractorAwardedName"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.contractorAwardedDate,
          "Awarded Date",
          "contractorAwardedDate"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.awardNumber,
          "Contract Award Number",
          "awardNumber"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) =>
            isNumeric(x.contractorAwardedAmount)
              ? currencyFormatter.format(
                  parseFloat(x.contractorAwardedAmount || "")
                )
              : x.contractorAwardedAmount,
          "Awarded Amount",
          "contractorAwardedAmount"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.solicitationNumber,
          "Solicitation Number",
          "solicitationNumber"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => formatDate(x.postedDateIn, DateFormat.dd_MMMM_yyyy),
          "Original Publish Date",
          "postedDateIn"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) =>
            formatDate(
              x.solicitationDeadlineResponseIn,
              DateFormat.dd_MMMM_yyyy
            ),
          "Offer Due Date",
          "solicitationDeadlineResponseIn"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => formatDate(x.modifiedDateIn, DateFormat.dd_MMMM_yyyy),
          "Last Updated Date",
          "modifiedDateIn"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.setAside,
          "Set Aside",
          "setAside"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.classificationCode,
          "Product Service Code",
          "classificationCode"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          150,
          (x) => x.naicsCodes,
          "NAICS",
          "naicsCodes"
        )}
        {GetCustomColumnBody<TableRecord>(
          styles,
          250,
          (x) => x.placeOfPerformance,
          "Place of Performance",
          "placeOfPerformance"
        )}

        {GetCustomColumnBody<TableRecord>(
          styles,
          350,
          (x) => {
            const p = x.pointOfContactOne || {
              fullName: "",
              email: "",
              phone: "",
              fax: "",
            };
            return (
              <div className="p-grid p-dir-col p-justify-center">
                <div className="p-col">
                  <div>{p.fullName}</div>
                  <div>{p.email}</div>
                  {p.phone && <div>{`Phone: ${p.phone}`}</div>}
                  {p.fax && <div>{`Fax: ${p.fax}`}</div>}
                </div>
              </div>
            );
          },
          "Contact Info",
          "pointOfContactOne"
        )}

        {GetCustomColumnBody<TableRecord>(
          styles,
          350,
          (x) => {
            const p = x.pointOfContactTwo || {
              fullName: "",
              email: "",
              phone: "",
              fax: "",
            };
            return (
              <div className="p-grid p-dir-col p-justify-center">
                <div className="p-col">
                  <div>{p.fullName}</div>
                  <div>{p.email}</div>
                  {p.phone && <div>{`Phone: ${p.phone}`}</div>}
                  {p.fax && <div>{`Fax: ${p.fax}`}</div>}
                </div>
              </div>
            );
          },
          "Secondary Contact Info",
          "pointOfContactTwo"
        )}
      </DataTable>
    </div>
  );
};

export default ContractsTableScreen;

export const ContractsTableOnly: React.FunctionComponent<{
  id: number;
  isPipelineScreen?: boolean;
}> = ({ id, isPipelineScreen }) => {
  const context = React.useContext(UserContext);
  const history = useHistory();
  const path = history.location.pathname;
  const [baseUrl, setBaseUrl] = React.useState("");
  // Page info
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [isLoading, setIsLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortField, setSortField] = React.useState("modifiedDateIn");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");

  const [lastUpdated, setLastUpdated] = React.useState("");
  const [data, setData] = React.useState(Array<TableRecord>());

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: number; note: string }>()
  );

  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: number; customColumn: string }>()
  );

  const [customColumnName, setCustomColumnName] = React.useState("");

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  const debouncedSearch = useDebounce(keyword, 750);
  React.useEffect(() => {
    queryData({
      first,
      rows: rowsPerPage,
      sortField,
      sortOrder,
      keyword,
    });
  }, [debouncedSearch]);

  const [query, setQuery] = React.useState<URLSearchParams>();

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
    keyword?: string;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);
    let url = "/api/contract";
    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/contract`;
        setBaseUrl(url);
        break;
      case `/mybids`:
        setBaseUrl(url);
        break;
      default:
        break;
    }
    const queryString = new URLSearchParams();

    queryString.set("onlyMyList", true.toString());
    queryString.set("includeUsaid", true.toString());
    queryString.set("includeMcc", true.toString());
    queryString.set("includeOthers", true.toString());
    queryString.set("includeStateDept", true.toString());

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    const filterToUse = event.keyword !== undefined ? event.keyword : keyword;
    if (filterToUse) {
      queryString.set("filter", filterToUse);
    }

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { totalItems, data, lastUpdatedUtc, attributes } = response.data;
        setTotalRecords(totalItems);
        setCustomColumnName(attributes ? attributes.CustomColumnName : "");
        // Set top records
        const d = data as Array<TableRecord>;
        d.forEach((r) => {
          if (!r.notes) {
            r.notes = "";
          }
        });
        setData(d);
        setOriginalNotes(
          d.map((r) => ({ id: r.contractOpportunityBase_Id, note: r.notes }))
        );
        setOriginalColumns(
          d.map((r) => ({
            id: r.contractOpportunityBase_Id,
            customColumn: r.customColumn,
          }))
        );

        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
        setIsLoading(false);
      });
  }

  var getExcelData = async (): Promise<TableRecord[]> => {
    var base = baseUrl;
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<TableRecord>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        alert("error fetching data");
      });
    return result;
  };
  return (
    <div className="p-grid p-dir-col">
      <ContractsDataTable
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        gridWidth={gridWidth}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={lastUpdated}
        originalNotes={originalNotes}
        setOriginalNotes={setOriginalNotes}
        originalColumns={originalColumns}
        setOriginalColumns={setOriginalColumns}
        customColumnName={customColumnName}
        setCustomColumnName={setCustomColumnName}
        queryData={queryData}
        isMyContracts={true}
        keyword={keyword}
        setKeyword={setKeyword}
        getExcelData={getExcelData}
        isPipelineScreen={isPipelineScreen}
        pipelineId={id}
      />
    </div>
  );
};

export const MySuggestionsContracts: React.FunctionComponent<{
  decrement: () => void;
  isRemove: boolean;
  pipelineId: number;
}> = ({ decrement, isRemove, pipelineId }) => {
  const history = useHistory();
  const context = React.useContext(UserContext);
  const path = history.location.pathname;
  const [baseUrl, setBaseUrl] = React.useState("");
  // Page info
  const [gridWidth, setGridWidth] = React.useState(0);

  React.useEffect(() => {
    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [isLoading, setIsLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortField, setSortField] = React.useState("modifiedDateIn");
  const [sortOrder, setSortOrder] = React.useState(-1);
  const [first, setFirst] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [data, setData] = React.useState(Array<TableRecord>());

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const [query, setQuery] = React.useState<URLSearchParams>();

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  // Load data on page load
  React.useEffect(() => {
    queryData({ first, rows: rowsPerPage, sortField, sortOrder });
  }, []);

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    setIsLoading(true);
    //  default base url
    let url = "/api/contract/mysuggestions";
    switch (path) {
      case `/pipeline/${pipelineId}`:
        url = `/api/pipeline/${pipelineId}/contract/suggestion`;
        setBaseUrl(url);
        break;
      case `/mybids`:
        setBaseUrl(url);
        break;
      default:
        break;
    }

    const queryString = new URLSearchParams();

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());
    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { totalItems, data } = response.data;
        setTotalRecords(totalItems);

        // Set top records
        const d = data as Array<TableRecord>;
        setData(d);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  var getExcelData = async (): Promise<TableRecord[]> => {
    var base = baseUrl;
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    var result = Array<TableRecord>();
    await axios
      .get(
        getFullUrl(qu, { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);

        alert("Error fetching data here");
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col">
      <ContractsDataTable
        pipelineId={pipelineId}
        isLoading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        sortField={sortField}
        setSortField={setSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        gridWidth={gridWidth}
        first={first}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        data={data}
        setData={setData}
        lastUpdated={""}
        originalNotes={[]}
        setOriginalNotes={(r) => {}}
        originalColumns={[]}
        setOriginalColumns={(r) => {}}
        customColumnName={""}
        setCustomColumnName={(r) => {}}
        queryData={queryData}
        isMySuggestions={true}
        decrement={decrement}
        getExcelData={getExcelData}
        isRemove={isRemove}
      />
    </div>
  );
};
