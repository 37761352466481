import React from "react";
import Button from "@material-ui/core/Button";
import "date-fns";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import getWindowDimensions from "../../services/dimensions";
import { useGridStyles } from "../../hooks/useGridStyles";
import { Panel } from "primereact/panel";
import "../DataTable.css";
import "./Data.css";
import {
  GetCustomColumnShortened,
  GetCustomColumnBody,
} from "../../components/DataTable/Columns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { DataTable } from "primereact/datatable";
import { Button as PButton } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Popover } from "../../components/Popover/Popover";

import { getDateString } from "../../services/formatter";

import {
  generateExcel,
  buildExcelTable,
  genEmptyRow,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import {
  getSortIfDescendingFirst,
  useDebounce,
} from "../../services/functions";
import { DateFormat, formatDate } from "../../utils/conversion/date-converters";
import { PanelHeader } from "../../components/DataTable/PanelHeader";
import { useEnterListener } from "../../hooks/useEnterListener";
import {
  MaximizeGrid,
  MinimizeGrid,
  NavigateToButton,
} from "../../components/DataTable/ActionButtons";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { Checkbox } from "primereact/checkbox";
import axios from "axios";
import { fetchDataNewEnvironment } from "../../services/dropdownOptionListProvider";
import { ProgressSpinner } from "primereact/progressspinner";
import UserContext from "../../services/UserContext";
import CustomFilterSearchSelect from "../../components/CustomSearchSelect/CustomFilterSearchSelect";

const icons = {
  location: require("../../assets/icons/v2/icon_location.png")?.default,
  helmet: require("../../assets/icons/v2/icon_helmet.png")?.default,
  number: require("../../assets/icons/v2/icon_number.png")?.default,
  file: require("../../assets/icons/v2/icon_file.png")?.default,
  eligible: require("../../assets/icons/v2/icon_checklist.png")?.default,
  keyword: require("../../assets/icons/v2/icon-06.png")?.default,
  calendar: require("../../assets/icons/v2/icon_calendar.png")?.default,
};

const getDownloadUrlFromId = (id: string) =>
  `https://konektid-dec-resources.s3.amazonaws.com/${id.replace(/-/g, "")}.pdf`;

// Initialize date to today-5 years
let todayMinusYears = new Date();
todayMinusYears.setFullYear(todayMinusYears.getFullYear() - 5);

interface Record {
  rowId: number;
  country: string;
  title: string;
  documentType: string;
  abstract: string;
  publicationDate: string;
  authoringOrganization: string;
  personalAuthors: string;
  technicalSectors: string;
  uniqueId: string;
  contractGrantNumber: string;
  contractGrantNumberEvaluated: string;
}

const getExportableDataTable = (data: Record[]): TableProperties => {
  const columns = [
    { name: "Link" },
    { name: "Country" },
    { name: "Title" },
    { name: "Document Type" },
    { name: "Publication Date" },
    { name: "Authoring Organization" },
    { name: "Authors" },
    { name: "Technical Sector" },
    { name: "Abstract" },
    { name: "Contract Grant Number" },
    { name: "Contract Grant Number Evaluated" },
  ];

  let rows: any[][] = [];

  if (data.length == 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];

      rows.push([
        getDownloadUrlFromId(record.uniqueId),
        record.country,
        record.title,
        record.documentType,
        record.publicationDate ? record.publicationDate.substring(0, 10) : "",
        record.authoringOrganization,
        record.personalAuthors
          ? record.personalAuthors.replace(/ \|\| /g, "; ")
          : "",
        record.technicalSectors
          ? record.technicalSectors.replace(/ \|\| /g, "; ")
          : "",
        record.abstract,
        record.contractGrantNumber,
        record.contractGrantNumberEvaluated,
      ]);
    }
  }

  return buildExcelTable("DataExport", columns, rows);
};

const removeTagsFromRecords = (records: Record[]): Record[] => {
  let recordsWithoutTags: Record[] = [];

  records.forEach((r) => {
    let recordWithoutTags = Object.assign({}, r);
    (
      Object.keys(recordWithoutTags) as Array<keyof typeof recordWithoutTags>
    ).forEach((key) => {
      const value = recordWithoutTags[key];
      if (typeof value === "string") {
        recordWithoutTags = {
          ...recordWithoutTags,
          [key]: removeTagsFromField(value),
        };
      }
    });
    recordsWithoutTags.push(recordWithoutTags);
  });

  return recordsWithoutTags;
};

const removeTagsFromField = (fieldData: string): string =>
  fieldData.replace(/(<([^>]+)>)/gi, "");

function DecDataView() {
  const context = React.useContext(UserContext);

  // Stylings
  const [gridWidth, setGridWidth] = React.useState(0);
  React.useEffect(() => {
    function handleResize() {
      const { width, headerWidth } = getWindowDimensions();
      if (width !== null && headerWidth != null) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Grid required properties
  const [data, setData] = React.useState(Array<Record>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("publicationDate");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  //Available Options
  const [decCountryNamesOptions, setDecCountryNamesOptions] = React.useState(
    Array<string>()
  );
  const [decTechnicalSectorsOptions, setDecTechnicalSectorsOptions] =
    React.useState(Array<string>());
  const [decAuthoringOrgsOptions, setDecAuthoringOrgsOptions] = React.useState(
    Array<string>()
  );

  const [country, techSector, authOrg] = [
    "country",
    "technicalSector",
    "authoringOrg",
  ];

  React.useEffect(() => {
    let apiUrl = "/api/dec/dropdownoptions/";
    let decCountryNamesUrl = apiUrl + country;
    let decTechnicalSectorsUrl = apiUrl + techSector;
    let decAuthoringOrgsUrl = apiUrl + authOrg;

    let baseMessage = "error fetching available ";
    let decCountryNamesMessage = baseMessage + "dec country names";
    let decTechnicalSectorsMessage = baseMessage + "dec technical sectors";
    let decAuthoringOrgsMessage = baseMessage + "dec authoring organizations";

    fetchDataNewEnvironment(
      decCountryNamesUrl,
      setDecCountryNamesOptions,
      context,
      decCountryNamesMessage,
      true
    );
    fetchDataNewEnvironment(
      decTechnicalSectorsUrl,
      setDecTechnicalSectorsOptions,
      context,
      decTechnicalSectorsMessage,
      true
    );
    fetchDataNewEnvironment(
      decAuthoringOrgsUrl,
      setDecAuthoringOrgsOptions,
      context,
      decAuthoringOrgsMessage,
      true
    );
  }, []);

  const [tempCountryNamesFilter, setTempCountryNamesFilter] =
    React.useState("");
  const [tempTechnicalSectorsFilter, setTempTechnicalSectorsFilter] =
    React.useState("");
  const [tempAuthoringOrgsFilter, setTempAuthoringOrgsFilter] =
    React.useState("");

  const [tempCountryNamesOptions, setTempCountryNamesOptions] = React.useState(
    Array<string>()
  );
  const [tempTechnicalSectorsOptions, setTempTechnicalSectorsOptions] =
    React.useState(Array<string>());
  const [tempAuthoringOrgsOptions, setTempAuthoringOrgsOptions] =
    React.useState(Array<string>());

  function setTempFilteredOptions(filter: string, filterOptionName: string) {
    let apiUrl = "/api/dec/dropdownoptions/" + filterOptionName;
    let queryString = new URLSearchParams();
    queryString.set("filter", filter);
    let url = `${apiUrl}?${queryString.toString()}`;

    let errorMessage = "Error fetching filtered options";

    let setMethod;
    switch (filterOptionName) {
      case country:
        setMethod = setTempCountryNamesOptions;
        break;
      case techSector:
        setMethod = setTempTechnicalSectorsOptions;
        break;
      case authOrg:
        setMethod = setTempAuthoringOrgsOptions;
        break;
    }

    fetchDataNewEnvironment(url, setMethod, context, errorMessage, true);
  }

  React.useEffect(() => {
    if (tempTechnicalSectorsFilter)
      setTempFilteredOptions(tempTechnicalSectorsFilter, techSector);
  }, [tempTechnicalSectorsFilter]);

  React.useEffect(() => {
    if (tempCountryNamesFilter)
      setTempFilteredOptions(tempCountryNamesFilter, country);
  }, [tempCountryNamesFilter]);

  React.useEffect(() => {
    if (tempAuthoringOrgsFilter)
      setTempFilteredOptions(tempAuthoringOrgsFilter, authOrg);
  }, [tempAuthoringOrgsFilter]);

  // Filters
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState(
    Array<string>()
  );
  const [selectedTechnicalSector, setTechnicalSector] = React.useState(
    Array<string>()
  );
  const [authoringOrg, setAuthoringOrg] = React.useState(Array<string>());
  const [closingStartDate, setClosingStartDate] = React.useState<Date | null>(
    todayMinusYears
  );
  const [updatedStartingDateFilterError, setUpdatedStartedDateFilterError] = React.useState(false);
  const [updatedClosingDateFilterError, setUpdatedClosingDateFilterError] = React.useState(false);
  const [closingEndDate, setClosingEndDate] = React.useState<Date | null>(null);
  const [keyword, setKeyword] = React.useState("");
  const [fileKeyword, setFileKeyword] = React.useState("");
  const [onlyAttachments, setOnlyAttachments] = React.useState(true);

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  const styles = useGridStyles();

  const [query, setQuery] = React.useState<URLSearchParams>();

  const depList = [
    selectedCountryFilter,
    selectedTechnicalSector,
    authoringOrg,
    closingStartDate,
    closingEndDate,
    keyword,
    fileKeyword,
    onlyAttachments,
    rowsPerPage,
    sortField,
    sortOrder,
    first,
    totalRecords,
  ];

  const queryCallback = React.useCallback(
    () =>
      queryData({
        first,
        rows: rowsPerPage,
        sortField,
        sortOrder,
      }),
    depList
  );
  useEnterListener(queryCallback);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (!event.target.matches(".abstract")) return;

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");
      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    const newSortOrder = getSortIfDescendingFirst(event, sortField);
    setSortField(event.sortField);
    setSortOrder(newSortOrder);
    queryData({
      first,
      rows: rowsPerPage,
      sortField: event.sortField,
      sortOrder: newSortOrder,
    });
  }

  function queryData(event: {
    first: number;
    rows: number;
    sortField: string;
    sortOrder: number;
  }) {
    setRowsPerPage(event.rows);

    let request = createAuthenticatedRequest(context);

    setIsLoading(true);
    const url = "/api/dec";
    const queryString = new URLSearchParams();

    if (selectedCountryFilter.length) {
      queryString.set("country", selectedCountryFilter.join("|"));
    }

    if (selectedTechnicalSector.length) {
      queryString.set("sector", selectedTechnicalSector.join("|"));
    }

    if (authoringOrg.length) {
      queryString.set("authoringOrg", authoringOrg.join("|"));
    }

    if (keyword) {
      queryString.set("filter", keyword);
    }

    if (fileKeyword) {
      queryString.set("fileKeyword", fileKeyword);
    }

    if (onlyAttachments)
      queryString.set("onlyAttachments", onlyAttachments.toString());

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("pageSize", event.rows.toString());

    queryString.set("sortField", event.sortField || sortField);
    queryString.set("sortOrder", (event.sortOrder || sortOrder).toString());

    if (closingStartDate && !updatedStartingDateFilterError) {
      queryString.set(
        "startDate",
        closingStartDate.toISOString().substring(0, 10)
      );
    }

    if (closingEndDate && !updatedClosingDateFilterError) {
      queryString.set("endDate", closingEndDate.toISOString().substring(0, 10));
    }

    setQuery(queryString);

    axios
      .get(
        getFullUrl(`${url}?${queryString.toString()}`, {
          useDedicatedEnvironment: true,
        }),
        request
      )
      .then((response) => {
        const { pageIndex, totalItems, pageSize, data, lastUpdatedUtc } =
          response.data;
        setTotalRecords(totalItems);
        setData(data ? removeTagsFromRecords(data) : data);
        setLastUpdated(
          lastUpdatedUtc
            ? `Last updated ${getDateString(new Date(lastUpdatedUtc + "Z"))}`
            : ""
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  const dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);

  const exportFunction = ({ data, field }: any) => {
    switch (field) {
      case "publicationDate":
        return data ? data.substring(0, 10) : data;
      case "personalAuthors":
        return data ? data.replace(/ \|\| /g, "; ") : data;
      case "technicalSectors":
        return data ? data.replace(/ \|\| /g, "; ") : data;
      case "uniqueId":
        return getDownloadUrlFromId(data);
      default:
        return data;
    }
  };

  const [disableExcel, setDisableExcel] = React.useState(false);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col-12 p-md-8 p-lg-8 topLeftColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div>Source: USAID Development Experience Clearinghouse</div>
        <div>{lastUpdated}</div>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-justify-end buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            iconPos="left"
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "Data+",
                `Aidkonekt_data_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data
                )
              ).then(() => {
                setDisableExcel(false);
              });
            }}
            disabled={disableExcel}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false)
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              ...styles.tableHeaderButton,
            }}
          />
        </div>
      </div>
    </div>
  );

  function RenderArrays({ data }: { data: string }) {
    if (data) {
      const arr = [...new Set(data.split(" || "))].join("; ");
      return <div>{arr}</div>;
    } else {
      return <></>;
    }
  }

  function PdfTemplate(record: any) {
    return (
      <div>
        {record.fileSyncStatus === "Synced" && (
          <NavigateToButton
            openPage={() => {
              window.open(getDownloadUrlFromId(record.uniqueId), "_blank");
            }}
          />
        )}
      </div>
    );
  }

  var renderSearch = () => {
    return (
      <div>
        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.location} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!decCountryNamesOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Country"
                setSelectedOption={setSelectedCountryFilter}
                allOptions={
                  tempCountryNamesFilter
                    ? tempCountryNamesOptions
                    : decCountryNamesOptions
                }
                disabled={isLoading || !decCountryNamesOptions}
                setFilter={setTempCountryNamesFilter}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.helmet} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!decTechnicalSectorsOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Technical Sector"
                allOptions={
                  tempTechnicalSectorsFilter
                    ? tempTechnicalSectorsOptions
                    : decTechnicalSectorsOptions
                }
                disabled={isLoading || !decTechnicalSectorsOptions}
                setFilter={setTempTechnicalSectorsFilter}
                setSelectedOption={setTechnicalSector}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.eligible} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            {!!decAuthoringOrgsOptions.length ? (
              <CustomFilterSearchSelect
                placeholder="Authoring Organization"
                setSelectedOption={setAuthoringOrg}
                allOptions={
                  tempAuthoringOrgsFilter
                    ? tempAuthoringOrgsOptions
                    : decAuthoringOrgsOptions
                }
                disabled={isLoading || !decAuthoringOrgsOptions}
                setFilter={setTempAuthoringOrgsFilter}
              />
            ) : (
              <ProgressSpinner style={{ height: 28 }}></ProgressSpinner>
            )}
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="keyword"
              type="search"
              value={keyword}
              onChange={(e: any) => setKeyword((e.target as any).value)}
              placeholder="Keyword Search Project Data"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.keyword} alt="search" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left" }}>
            <InputText
              style={styles.filters.inputBox}
              id="fileKeyword"
              type="search"
              value={fileKeyword}
              onChange={(e: any) => setFileKeyword((e.target as any).value)}
              placeholder="Keyword Search In-Document Text"
            />
          </div>
        </div>

        <div
          className="p-grid p-align-center"
          style={styles.filters.notTopFilterRow}
        >
          <div className="p-col-fixed icon-col">
            <img src={icons.calendar} alt="map" style={styles.icon} />
          </div>
          <div className="p-col" style={{ textAlign: "left", display: "flex" }}>
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              Show records with a publication date between{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={closingStartDate}
              onError={(e) => setUpdatedStartedDateFilterError(!!e)}
              onChange={(date) => setClosingStartDate(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
            <label
              style={{
                alignSelf: "center",
                ...styles.filters.inputTextLabel,
              }}
            >
              {" "}
              and{" "}
            </label>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              value={closingEndDate}
              onError={(e) => setUpdatedClosingDateFilterError(!!e)}
              onChange={(date) => setClosingEndDate(date)}
              style={{ marginLeft: 10 }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="p-grid" style={styles.filters.notTopFilterRow}>
          <div className="p-col" style={styles.filters.searchButtonContainer}>
            <Button
              style={styles.filters.searchButton}
              onClick={() =>
                queryData({ first, rows: rowsPerPage, sortField, sortOrder })
              }
            >
              <div style={styles.filters.searchButtonText}>Search</div>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  var getExcelData = async (): Promise<Record[]> => {
    var base = "/api/dec";
    var q = query;
    if (q) q.set("pageSize", "1000");
    var qu = q ? base + "?" + q.toString() : base;
    let request = createAuthenticatedRequest(context);
    var result = Array<Record>();
    await axios
      .get(getFullUrl(qu, { useDedicatedEnvironment: true }), request)
      .then((response) => {
        result = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="p-col-12" style={styles.pageHeader}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Data
        </div>

        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          Welcome to the streamlined search of USAID reports, evaluations,
          assessments, and other key documents and data sources. This search
          allows you to access more than 140,000 USAID documents, organize based
          on your country, technical sector, and partner preferences, and access
          the PDF documents directly through AidKonekt (when available). Click
          on the PDF icon on the far right to access the document directly,
          without having to go through another search engine. This is a link
          that you can share internally with your team. When you export a
          search, the link to the PDF files will be included in the export, so
          you and your colleagues can access directly without having to even
          sign back in to AidKonekt.
        </div>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Panel
          style={styles.filters.filterBox}
          collapsed={isCollapsed}
          onToggle={(e) => setIsCollapsed(e.value)}
          toggleable
          expandIcon=""
          collapseIcon=""
          header={
            <PanelHeader
              search={() =>
                queryData({ first, rows: rowsPerPage, sortField, sortOrder })
              }
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              expandedTitle={
                <div
                  className="p-grid"
                  style={{ ...styles.filters.fontHeader }}
                >
                  <div
                    className="p-col-12 p-md-4"
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      inputId="cb1"
                      value="onlyAttachments"
                      onChange={(e) => setOnlyAttachments(e.checked)}
                      checked={onlyAttachments}
                    />
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                      style={styles.filters.inputTextLabel}
                    >
                      Only show results with attachments
                    </label>
                  </div>
                </div>
              }
            />
          }
        >
          {renderSearch()}
        </Panel>
      </MuiPickersUtilsProvider>

      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />

      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        ref={dt}
        header={tableHeader}
        exportFunction={exportFunction}
        exportFilename={`Aidkonekt_data_plus_${new Date().getFullYear()}`}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
      >
        {GetCustomColumnBody<Record>(
          styles,
          75,
          PdfTemplate,
          "Link",
          "uniqueId",
          { sortable: false }
        )}
        {GetCustomColumnBody<Record>(
          styles,
          200,
          (x) => (
            <RenderArrays data={x.country} />
          ),
          "Country",
          "country"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          550,
          (x) => x.title,
          "Title",
          "title"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          225,
          (x) => x.documentType,
          "Document Type",
          "documentType"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          150,
          (x) => formatDate(x.publicationDate, DateFormat.dd_MMMM_yyyy),
          "Publication Date",
          "publicationDate"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          350,
          (x) => (
            <RenderArrays data={x.authoringOrganization} />
          ),
          "Authoring Organization",
          "authoringOrganization"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          350,
          (x) => (
            <RenderArrays data={x.personalAuthors} />
          ),
          "Authors",
          "personalAuthors"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          350,
          (x) => (
            <RenderArrays data={x.technicalSectors} />
          ),
          "Technical Sector(s)",
          "technicalSectors"
        )}
        {GetCustomColumnShortened<Record>(
          styles,
          550,
          (x) => x.abstract,
          (x) => x.title,
          "Abstract",
          150,
          true,
          "abstract"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          225,
          (x) => x.contractGrantNumber,
          "Contract Grant Number",
          "contractGrantNumber"
        )}
        {GetCustomColumnBody<Record>(
          styles,
          225,
          (x) => x.contractGrantNumberEvaluated,
          "Contract Grant Number Evaluated",
          "contractGrantNumberEvaluated"
        )}
      </DataTable>
    </div>
  );
}

export default DecDataView;
