import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react";
import apiConfigs, {
  ApiName,
  createAuthenticatedRequest,
} from "../../configs/axios-export.custom";
import { tryCatchServerError } from "../../services/functions";
import GrowlContext from "../../services/growlContext";
import UserContext from "../../services/UserContext";
import "./WeeklyForecast.css";

const icons = {
  logo: require("../../assets/AidkonektLogoLarge.png")?.default,
  bullet: require("../../assets/icons/v2/icon_Globe.png")?.default,
};

const FromApiReport = () => {
  const [loading, setLoading] = React.useState(false);
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const [content, setContent] = React.useState("");
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    if (hasLoaded) return;

    setLoading(true);

    const currentUrl = new URL(window.location.toString());

    const asOf = currentUrl.searchParams.get("asOf");
    const start = currentUrl.searchParams.get("start");
    const api = currentUrl.searchParams.get("apiKey");
    const user = currentUrl.searchParams.get("userOverride");

    const queryString = new URLSearchParams();
    if (asOf) queryString.set("asOf", asOf);
    if (api) queryString.set("apiKey", api);
    if (user) queryString.set("userOverride", user);
    if (start) queryString.set("start", start);

    const request = createAuthenticatedRequest(context, {
      additionalHeaders: {
        ["Content-Type"]: "text/html; charset=utf-8",
      },
    });
    if (!request) return;

    setHasLoaded(true);

    const config = apiConfigs.API.endpoints.find((r) => r.name === ApiName);
    if (!config) {
      return;
    }

    const urlPrefix = config.endpoint;
    const url = `${urlPrefix}/WeeklyForecastReport?${queryString.toString()}`;

    fetch(url, {
      method: "get",
      ...(request as any),
    })
      .then((r) => r.text())
      .then((r) => {
        console.log(r);
        setContent(r);
        setLoading(false);
      })
      .catch(
        tryCatchServerError((m) => {
          growl.current.show({
            severity: "error",
            summary: "Error getting report",
            detail: `Error getting Weekly report: ${m}`,
          });
          setLoading(false);
        })
      );
  }, [context]);

  if (loading) {
    return <ProgressSpinner />;
  }

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default FromApiReport;
