import React, { useEffect, useState } from "react";

import "./App.css";
import Router from "./components/Navigation/Router";
import UserContext, {
  IdToken,
  ServerOrgRoles,
  User,
} from "./services/UserContext";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";
import "./screens/DataTable.css";
import "./assets/themes/nova-light/theme.css";

import { Toast as Growl } from "primereact/toast";
import GrowlContext from "./services/growlContext";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getFullUrl } from "./configs/axios-export.custom";
import axios from "axios";
import {
  parseToken,
  SignInResponseModel,
} from "./components/Authentication/models";

const theme = createMuiTheme({
  typography: {
    // useNextVariants: true,
    fontFamily: "Montserrat",
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 700,
      color: "#187AB5",
      fontSize: 45,
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 700,
      color: "#187AB5",
      fontSize: 40,
    },
    subtitle1: {
      fontFamily: "Helvetica Neue Italic",
      color: "#6C6C6C",
      fontSize: 25,
    },
    h6: {
      fontFamily: "Montserrat, sans-serif",
      color: "#187AB5",
      fontSize: 16,
      fontWeight: "bold",
    },
  },
});

const callRefresh = async (
  currentRefreshToken: string | null,
  currentUser: User,
  setCurrentRefreshToken: React.Dispatch<React.SetStateAction<string | null>>,
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>,
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let token = currentRefreshToken
    ? currentRefreshToken
    : currentUser.refreshToken
    ? currentUser.refreshToken
    : window.localStorage.getItem("refreshToken");
  await axios
    .post<SignInResponseModel>(
      getFullUrl("/api/auth/refreshToken", { useDedicatedEnvironment: true }),
      {
        Token: token,
      }
    )
    .then(async (resp) => {
      var { user, groups, konektidToken } = resp.data;
      var parsedIdToken = parseToken(user.idToken);
      var refreshToken = user.refreshToken ? user.refreshToken : token;
      setCurrentRefreshToken(refreshToken);
      setCurrentUser({
        accessToken: user.accessToken,
        jwt: user.idToken,
        idToken: user.idToken,
        parsedIdToken: parsedIdToken ?? undefined,
        refreshToken: refreshToken!,
        expiresIn: user.expiresIn,
        groups,
        konektidToken,
      });
      setIsLoaded(true);
    });
};

const App = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {}, [currentUser]);
  const [currentRefreshToken, setCurrentRefreshToken] = useState(
    window.localStorage.getItem("refreshToken")
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const growl = React.useRef();

  const updateCurrentUser = async (user) => {
    if (user) {
      setCurrentRefreshToken(user.refreshToken);
      setCurrentUser(user);
      setIsLoaded(true);
      return;
    }

    try {
      await callRefresh(
        currentRefreshToken,
        currentUser!,
        setCurrentRefreshToken,
        setCurrentUser,
        setIsLoaded
      );
    } catch (err) {
      setCurrentUser({
        accessToken: "",
        jwt: "",
        idToken: undefined,
        parsedIdToken: undefined,
        refreshToken: "",
        expiresIn: 0,
        groups: [],
        konektidToken: undefined,
      });
      setIsLoaded(true);
    }
  };

  const refreshUserToken = async (reloadPage) => {
    await callRefresh(
      currentRefreshToken,
      currentUser!,
      setCurrentRefreshToken,
      setCurrentUser,
      setIsLoaded
    ).then(() => {
      if (reloadPage) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    window.localStorage.setItem("refreshToken", currentRefreshToken!);
  }, [currentRefreshToken]);

  return (
    <GrowlContext.Provider value={growl as any}>
      <Growl ref={(el) => (growl.current = el as any)} />
      <UserContext.Provider
        value={{
          user: currentUser as any,
          updateCurrentUser: updateCurrentUser,
          isLoaded: isLoaded,
          refreshUserToken,
        }}
      >
        <ThemeProvider theme={theme}>
          <div className="App">
            <Router currentUser={currentUser} />
          </div>
        </ThemeProvider>
      </UserContext.Provider>
    </GrowlContext.Provider>
  );
};

export default App;
