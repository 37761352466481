import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import DashboardSection from "./DashboardSection";
import Update, { UpdateType } from "../Update";
import { css } from "glamor";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ThemeContext from "../../configs/theme";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext, {
  isUserAdminOrSemiAdminWith,
  ServerOrgRoles,
} from "../../services/UserContext";
import GrowlContext from "../../services/growlContext";
import SectionHeader from "./SectionHeader";
import IconTooltip from "../IconTooltip";
import axios, { AxiosRequestConfig } from "axios";
import { showRichTextEditorComponent } from "../ShowRichTextEditor";

const useStyles: () => { [key: string]: React.CSSProperties } = () => {
  const theme = React.useContext(ThemeContext);

  return {
    header: {
      font: theme.v2Fonts.mediumHeader,
    },
    buttonContainer: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "flex-end",
    },
    tooltip: {
      font: theme.v2Fonts.context,
      verticalAlign: "middle",
    },
  };
};

const AidkonektUpdates: FunctionComponent = () => {
  const userContext = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const styles = useStyles();

  const [updates, setUpdates] = useState<UpdateType[]>([]);

  const [keyword, setKeyword] = useState("");

  const fetchUpdatesAsync = useCallback(() => {
    const request = createAuthenticatedRequest(userContext);
    axios
      .get(
        getFullUrl("/api/user/update", { useDedicatedEnvironment: true }),
        request
      )
      .then((response) => {
        type AdminUpdateResponse = { id: number; message: string };
        const updates: AdminUpdateResponse[] = response.data;
        setUpdates(updates);
      })
      .catch((e) => {
        console.error(e);
        growl.current.show({
          severity: "error",
          summary: "Unable to load user profile",
        });
      });
  }, []);

  useEffect(fetchUpdatesAsync, [userContext]);

  const addUpdate = useCallback((updateText: string, updates: UpdateType[]) => {
    axios
      .post(
        getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
        { message: updateText },
        createRequestWithAuthHeaders(userContext)
      )
      .then((response) => {
        const newUpdate = { ...response.data };
        setUpdates([newUpdate, ...updates]);
      })
      .catch((e) => {
        console.error(e);
        growl.current.show({
          severity: "error",
          summary: "Error saving new update",
          detail: e,
        });
      });
  }, []);

  const deleteUpdate = useCallback(
    (updateId: number) => {
      const request = {
        ...createRequestWithAuthHeaders(userContext),
        data: { id: updateId },
      };
      axios
        .delete(
          getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
          request
        )
        .then(() => {
          const updatedUpdates = updates.filter(
            (update) => update.id !== updateId
          );
          setUpdates(updatedUpdates);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error deleting update",
            detail: JSON.stringify(e),
          });
        });
    },
    [updates]
  );

  const editUpdate = useCallback(
    (updateId: number, editedUpdateText: string) => {
      axios
        .put(
          getFullUrl("/api/admin/update", { useDedicatedEnvironment: true }),
          {
            id: updateId,
            message: editedUpdateText,
          },
          createAuthenticatedRequest(userContext)
        )
        .then(() => {
          const updatedUpdates = updates.map((update) =>
            update.id === updateId
              ? { ...update, message: editedUpdateText }
              : update
          );
          setUpdates(updatedUpdates);
        })
        .catch((e) => {
          console.error(e);
          growl.current.show({
            severity: "error",
            summary: "Error editing update",
            detail: e,
          });
        });
    },
    [updates]
  );

  const canEditUpdates = isUserAdminOrSemiAdminWith(
    userContext,
    ServerOrgRoles.EditUserNotifications
  );

  const showNewUpdateMessage = React.useCallback(
    async (updates: UpdateType[]) => {
      const { isConfirmed, components } = await showRichTextEditorComponent({
        name: "Create New Update",
        components: [
          {
            name: "New Update",
            startingText: "",
          },
        ],
      });

      if (isConfirmed) {
        addUpdate(components[0], updates);
      }
    },
    []
  );

  return (
    <DashboardSection style={{ height: "73vh", overflowY: "scroll" }}>
      <SectionHeader text={"USAID Market and AidKonekt Updates"}>
        <span {...css(styles.tooltip)}>
          <IconTooltip
            tooltipText={
              "We share relevant updates from USAID and key USAID market partners here. Many of these come from the several USAID newsletter that we monitor for you. This is a great place for our paid subscribers to share their own updates relevant to USAID. Please message us if you have any updates that you would like us to share here."
            }
          />
        </span>
      </SectionHeader>
      {canEditUpdates && (
        <>
          <div {...css(styles.buttonContainer)}>
            <Button
              icon="pi pi-plus"
              label="Add Update"
              className="aid-blu-btn"
              onClick={() => showNewUpdateMessage(updates)}
            />
          </div>
        </>
      )}
      <div>
        <InputText
          placeholder="Search..."
          value={keyword}
          style={{
            margin: "10px 0",
            borderRadius: "30px",
            width: "100%",
          }}
          onChange={(r) => setKeyword((r.target as any).value)}
        />
      </div>
      {updates
        .filter((r) => r.message.match(new RegExp(keyword, "i")))
        .map((update) => (
          <Update
            key={update.id}
            update={update}
            canEditUpdates={canEditUpdates}
            editUpdate={editUpdate}
            deleteUpdate={deleteUpdate}
          />
        ))}
    </DashboardSection>
  );
};

export default AidkonektUpdates;
