import React from "react";
import ReactSelect from "react-select";
import ThemeContext from "../../configs/theme";
import "./CustomSearchSelect.css";
import { debounce } from "lodash";

export const AddAllOptionText = "Add all matching results";

const CustomFilterSearchSelect = (params: any) => {
  const {
    setSelectedOption,
    allOptions,
    placeholder,
    disabled,
    getOptionLabel,
    getOptionValue,
    getOptionsFromArray,
    setFilter,
  } = params;
  const theme = React.useContext(ThemeContext);

  const [isLoading, setIsLoading] = React.useState(false)
  const [search, setSearch] = React.useState('')

  const getOptionsFromStringArray = (arr: string[]) => {
    const results = arr.map((m: string) => ({
      label: m,
      value: m,
    }));

    const addAllIndex = results.findIndex((x) => x.label === AddAllOptionText);

    // if add all is in group and more than one record showing, add option to front
    if (addAllIndex > 0 && results.length > 1) {
      results.splice(addAllIndex, 1);
      if (search) {
        results.unshift({
          label: AddAllOptionText,
          value: AddAllOptionText
        });
      }
    }

    return results;
  };

  React.useEffect(() => {
    setOptions([...getAllOptions(allOptions)]);
  }, [allOptions]);

  const getAllOptions = getOptionsFromArray || getOptionsFromStringArray;

  const [options, setOptions] = React.useState([...getAllOptions(allOptions)]);
  const [value, setValue] = React.useState(Array< { label: string; value: string }>());

  const sectorFilterHandler = React.useCallback(
    debounce((text) => setFilter(text), 1000),
    []
  );

  const sectorFilterLoaderHandler = React.useCallback(
    debounce(() => setIsLoading(false), 2000),
    []
  );

  const selectStyles = {
    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
    option: (provided: any, state: any) => ({
      ...provided,
      font: theme.v2Fonts.input,
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      whiteSpace: "break-spaces",
    }),
  };

  const ContainsFilter = (
    option: { data: { label: string } },
    searchText: string
  ) => {
    let isMatch = option.data.label
      .toLowerCase()
      .includes(searchText.toLowerCase());

    // Override if add all
    if (option.data.label === AddAllOptionText) {
      isMatch = true;
    }

    return isMatch;
  };

  return (
    <ReactSelect
      isLoading={isLoading}
      hideSelectedOptions={true}
      isDisabled={disabled}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder || "Select..."}
      styles={selectStyles}
      isMulti={true}
      onInputChange={(text) => {
        setIsLoading(true)
        setSearch(text)
        sectorFilterHandler(text);
        sectorFilterLoaderHandler()
      }}
      className="select-class"
      value={value}
      onChange={(x) => {
        let localX = x ? [...x] : [];
        if (
          x &&
          x.find((r: { label: string }) => r.label === AddAllOptionText)
        ) {
          // Need to set x to all options minus "add all" then call default behavior
          localX = [...value,...options.filter((r) => r.label !== AddAllOptionText && r.label.toLowerCase().includes(search.toLowerCase()))];
        }

        setValue(localX);
        setSelectedOption(
          localX
            ? localX.map((m: { label: string; value: string }) => m.value)
            : []
        );
      }}
      options={options}
      formatOptionLabel={formatOptionLabel}
      filterOption={ContainsFilter}
    />
  );
};
const s1: React.CSSProperties | undefined = {};
const s2: React.CSSProperties | undefined = { fontStyle: "italic" };
const formatOptionLabel = (o: any) => (
  <div style={{ display: "flex", font: "16px Montserrat" }}>
    <div style={o.label === AddAllOptionText ? s2 : s1}>{o.label}</div>
  </div>
);
export default CustomFilterSearchSelect;
