import React from 'react';
import './QAArchives.css'
import { useStyles } from "../../hooks/useStyles";
import { qalist } from "./qa-list";

const QAArchives: React.FC = (): JSX.Element => {
    const styles = useStyles()

    return (
        <div className="resources-screen">
            <section className="header">
                <div className="header-title">
                    <img src={styles.aidkonektIcon} style={styles.icon} alt="Aidkonekt" className="formIcon"/>
                    <span>Q&A Archives</span>
                </div>
                <span className="header-description">
                    The Agency hosts a quarterly webinar to allow partners the opportunity to hear their questions
                    answered by expert
                </span>
            </section>

            <section className="qa-list-section">
                <div className="country-list">
                    {qalist.map((item) => (
                        <React.Fragment key={item.title}>
                            <h3 className="list-title">{item.title}</h3>
                            {item.main && <span className="list-subtitle">{item.main}</span>}
                            <span className="list-description">{item.description}</span>
                            <ul>
                                {item.archives.map((archive) => (
                                    <li key={archive.title} className="list-item" onClick={() => window.open(archive.link, '_blank')}>{archive.title} <span>[{archive.type}]</span></li>
                                ))}
                            </ul>
                        </React.Fragment>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default QAArchives
