import * as React from "react";
import "./SubscriptionsScreen.css";
import { Button } from "primereact/button";
import {
  subscriptionGroups,
  subscriptionTypes,
} from "../../configs/subscriptionTypes";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext from "../../services/UserContext";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import aidkonektIcon from "../../assets/AidKonectLogo.png";

const rowStyle = {
  borderTop: "1px solid #979797",
  padding: "10px 20px 10px 20px",
  fontSize: "16px",
};

const SubscriptionsScreen = () => {
  const context = React.useContext(UserContext);

  const request = createAuthenticatedRequest(context);
  const [isLoading, setIsLoading] = React.useState(true);

  const [currentSubscription, setCurrentSubscription] = React.useState(
    subscriptionGroups.free
  );

  const addSubscription = async (sub: subscriptionTypes) => {
    axios
      .post(
        getFullUrl("/api/Payment/submitsubscription", {
          useDedicatedEnvironment: true,
        }),
        { SubscriptionType: sub },
        createRequestWithAuthHeaders(context)
      )
      .then((r) => {
        if (r.data && r.data.noAction) {
          alert(`No action taken - ${r.data.reason}`);
          return;
        }

        // go to manage billing so user can see subs
        const { url } = r.data;
        window.location = url;
      })
      .catch((e) => alert(e));
  };

  return isLoading ? (
    <ProgressSpinner />
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        border: "solid #187AB5",
        borderRadius: "68px",
      }}
    >
      <div style={{ height: "30px" }}>
        <h1>
          Upgrade
          <span style={{ marginLeft: "10px", marginRight: "10px" }}>
            <img src={aidkonektIcon} alt="logo" />
          </span>
          Plan
        </h1>
      </div>
      <div>
        <div
          className="p-grid"
          style={{ padding: "60px 30px 30px 30px", fontWeight: "bold" }}
        >
          <div className="p-col-4" style={{ paddingRight: "30px" }}>
            <div
              className="p-grid p-dir-col"
              style={{
                borderStyle: "solid",
                borderRadius: "25px",
                borderColor: "rgba(0,0,0,0.1)",
                color: "#979797",
                paddingBottom: "30px",
              }}
            >
              <div style={{ marginBottom: "20px", fontWeight: "bolder" }}>
                <div style={{ fontSize: "35px", paddingTop: "20px" }}>Free</div>
                <div>$0 / month</div>
                <div>1 user</div>
              </div>
              <div style={rowStyle}>Search Business Forecast Opportunities</div>
              <div style={rowStyle}>
                See changes to Business Forecast in last 24 hours
              </div>
            </div>
          </div>
          <div className="p-col-4" style={{ paddingRight: "30px" }}>
            <div
              className="p-grid p-dir-col"
              style={{
                borderStyle: "solid",
                borderRadius: "25px",
                borderColor: "#2EA600",
                paddingBottom: "30px",
              }}
            >
              <div style={{ marginBottom: "20px", fontWeight: "bolder" }}>
                <div
                  style={{
                    color: "#2EA600",
                    fontSize: "35px",
                    paddingTop: "20px",
                  }}
                >
                  Plus
                </div>
                <div style={{ color: "#2EA600" }}>$X / month</div>
                <div style={{ color: "#2EA600" }}>Up to 5 users</div>
              </div>
              <div style={rowStyle}>Search Business Forecast Opportunities</div>
              <div style={rowStyle}>
                See changes to Business Forecast for the last 60 days
              </div>
              <div style={rowStyle}>
                Create Partner Profile and contact other partners
              </div>
              <div style={rowStyle}>
                Search Environmental Assessment updates
              </div>
              <div style={rowStyle}>
                See changes to Environmental Assessments
              </div>
              <div style={rowStyle}>Search closing USAID projects</div>
              <div style={rowStyle}>Email notifications - Customizable</div>
              <div style={rowStyle}>See which Primes are likely bidding</div>
              <div style={rowStyle}>
                Export up to three Bid Strategy sheets per month
              </div>
              <div style={rowStyle}>Export 1 Competitor Sheet per month</div>
            </div>
            <Button
              onClick={() => {
                if (currentSubscription === subscriptionGroups.plus) {
                  return;
                }
                addSubscription(subscriptionTypes.plusMonthly);
              }}
              tooltip={
                currentSubscription === subscriptionGroups.plus
                  ? "Current Plan"
                  : "Upgrade"
              }
              tooltipOptions={{ position: "top" }}
              label={
                currentSubscription === subscriptionGroups.plus
                  ? "Current Plan"
                  : "Upgrade"
              }
              style={{
                borderRadius: "65px",
                position: "relative",
                bottom: "24px",
                backgroundColor: "#2EA600",
                color: "white",
                fontSize: "24px",
                fontWeight: "bolder",
                height: 50,
              }}
            />
          </div>
          <div className="p-col-4">
            <div
              className="p-grid p-dir-col"
              style={{
                borderStyle: "solid",
                borderRadius: "25px",
                borderColor: "#FCB017",
                paddingBottom: "30px",
              }}
            >
              <div style={{ marginBottom: "20px", fontWeight: "bolder" }}>
                <div
                  style={{
                    color: "#FCB017",
                    fontSize: "35px",
                    paddingTop: "20px",
                  }}
                >
                  Pro
                </div>
                <div style={{ color: "#FCB017" }}>$X / month</div>
                <div style={{ color: "#FCB017" }}>Up to 50 users</div>
              </div>
              <div style={rowStyle}>Search Business Forecast Opportunities</div>
              <div style={rowStyle}>
                See changes to Business Forecast for the last 60 days
              </div>
              <div style={rowStyle}>
                Create Partner Profile and contact other partners
              </div>
              <div style={rowStyle}>
                Search Environmental Assessment updates
              </div>
              <div style={rowStyle}>
                See changes to Environmental Assessments
              </div>
              <div style={rowStyle}>Search closing USAID projects</div>
              <div style={rowStyle}>Email notifications - Customizable</div>
              <div style={rowStyle}>See which Primes are likely bidding</div>
              <div style={rowStyle}>Export unlimited Bid Strategy sheets</div>
              <div style={rowStyle}>Export unlimited Competitor Sheets</div>
            </div>
            <Button
              onClick={() => {
                if (currentSubscription === subscriptionGroups.pro) {
                  return;
                }
                addSubscription(subscriptionTypes.proMonthly);
              }}
              tooltip={
                currentSubscription === subscriptionGroups.pro
                  ? "Current Plan"
                  : "Upgrade"
              }
              tooltipOptions={{ position: "top" }}
              label={
                currentSubscription === subscriptionGroups.pro
                  ? "Current Plan"
                  : "Upgrade"
              }
              style={{
                borderRadius: "65px",
                position: "relative",
                bottom: "24px",
                backgroundColor: "#FCB017",
                color: "white",
                fontSize: "24px",
                fontWeight: "bolder",
                height: 50,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsScreen;
