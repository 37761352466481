import React from "react";
import { css } from 'glamor';
import { CSSProperties } from "react";
import ThemeContext from "../../configs/theme";

const useStyle = () => {
    const theme = React.useContext(ThemeContext);

    return {
        profileSection: {
            border: "5px solid",
            borderColor: theme.v2.lightGrey,
            borderRadius: "30px",
            padding: "10px 20px",
        }
    };
};

interface ProfileProps {
    children: any, 
    style?: CSSProperties
}

const DashboardSection = (p: ProfileProps) => (
    <div {...css(useStyle().profileSection)} style={p.style ? p.style : {}}>
        { p.children }
    </div>
);

export default DashboardSection;
