import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import "./FAQ.css";
import { useGridStyles } from "../../hooks/useGridStyles";
import {
  ApiName,
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
} from "../../services/UserContext";
import axios, { AxiosRequestConfig } from "axios";

export interface FAQTab {
  id: number;
  section: string;
  title: string;
  link: string;
  text: string;
}

export interface FAQ {
  id: number;
  title: string;
  tabs: FAQTab[];
}

const FAQ: React.FC = (): JSX.Element => {
  const user = React.useContext(UserContext);
  const [FAQs, setFAQs] = useState<FAQ[]>([]);
  const [inputError, setInputError] = useState<
    { titleError: boolean; linkError: boolean }[]
  >([]);
  const [inputTabValues, setInputTabValues] = useState<{ value: string }[]>([]);
  const [inputLinkValues, setInputLinkValues] = useState<{ value: string }[]>(
    []
  );

  const styles = useGridStyles();
  const context = useContext(UserContext);

  const fetchFAQs = async () => {
    const request = createAuthenticatedRequest(user);
    await axios
      .get(getFullUrl("/api/faq", { useDedicatedEnvironment: true }), request)
      .then((response) => {
        var data = response.data;
        setInputTabValues(data.map(() => ({ value: "" })));
        setInputLinkValues(data.map(() => ({ value: "" })));
        setInputError(
          data.map(() => ({ titleError: false, linkError: false }))
        );
        setFAQs(data);
      });
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setInputError(FAQs.map(() => ({ titleError: false, linkError: false })));
    const value = event.target.value;
    const filteredInputs = inputTabValues.map((item, itemIndex) =>
      itemIndex === index ? { value } : { ...item }
    );
    setInputTabValues(filteredInputs);
  };

  const handleInputLinkChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setInputError(FAQs.map(() => ({ titleError: false, linkError: false })));
    const value = event.target.value;
    const filteredInputs = inputLinkValues.map((item, itemIndex) =>
      itemIndex === index ? { value } : { ...item }
    );
    setInputLinkValues(filteredInputs);
  };

  const addPage = async (index: number) => {
    const item = FAQs[index];
    const title = inputTabValues[index].value;
    const link = inputLinkValues[index].value
      .replace(/ /g, "-")
      .toLocaleLowerCase();

    if (title === "") {
      const filteredInputs = inputError.map((item, itemIndex) =>
        itemIndex === index
          ? { titleError: true, linkError: false }
          : { ...item }
      );
      setInputError(filteredInputs);
      return;
    }

    if (link === "") {
      const filteredInputs = inputError.map((item, itemIndex) =>
        itemIndex === index
          ? { titleError: false, linkError: true }
          : { ...item }
      );
      setInputError(filteredInputs);
      return;
    }

    await axios
      .post(
        getFullUrl("/api/faq", { useDedicatedEnvironment: true }),
        { sectionId: item.id, title, link },
        createRequestWithAuthHeaders(user)
      )
      .then((resp) => {
        var r: FAQTab = resp.data;
        const filteredFAQs: FAQ[] = FAQs.map((item, itemIndex) =>
          index === itemIndex
            ? {
                ...item,
                tabs: item.tabs.concat({
                  id: r.id,
                  section: FAQs[index].title,
                  title: r.title,
                  link: r.link,
                  text: "",
                }),
              }
            : { ...item }
        );
        setFAQs(filteredFAQs);
        setInputTabValues((prevState) =>
          prevState.map((item, itemIndex) =>
            itemIndex === index ? { value: "" } : { ...item }
          )
        );
        setInputLinkValues((prevState) =>
          prevState.map((item, itemIndex) =>
            itemIndex === index ? { value: "" } : { ...item }
          )
        );
      });
  };

  const removePage = async (itemId: number, tabId: number) => {
    let request: AxiosRequestConfig = {
      ...createRequestWithAuthHeaders(user),
      data: {
        id: tabId,
      },
    };

    await axios
      .delete(
        getFullUrl("/api/faq", { useDedicatedEnvironment: true }),
        request
      )
      .then(() => {
        const filteredFAQs: FAQ[] = FAQs.map((item) =>
          item.id === itemId
            ? {
                ...item,
                tabs: item.tabs.filter((tab) => tab.id !== tabId),
              }
            : { ...item }
        );
        setFAQs(filteredFAQs);
      });
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin) || doesUserHaveRole(context, ServerOrgRoles.semiAdmin);

  return (
    <div className="faq-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>FAQ and Knowledge Base</span>
        </div>
        <span className="header-description">
          A knowledge base is a published collection of documentation that
          typically includes answers to frequently asked questions, how-to
          guides, and troubleshooting instructions. Its purpose is to make it
          easy for people to find solutions to their problems without having to
          ask for help.
        </span>
      </section>

      <section className="faqs-container">
        {FAQs.map((item, index) => (
          <div className="faq-block" key={item.id}>
            <h3 className="faq-block-title">{item.title}</h3>
            <ul className="faq-block-content">
              {item.tabs.map((tab) => (
                <li className="tab" key={tab.id}>
                  <a href={"/faq/" + tab.link}>{tab.title}</a>
                  {isAdmin && (
                    <div
                      className="tab-remove"
                      onClick={() => removePage(item.id, tab.id)}
                    >
                      x
                    </div>
                  )}
                </li>
              ))}
              {isAdmin && (
                <li className="tab create">
                  <div className="tab-inputs-container">
                    <input
                      type="text"
                      name={`input-${index}`}
                      className={
                        !inputError[index].titleError
                          ? "tab-input"
                          : "tab-input error"
                      }
                      value={inputTabValues[index].value}
                      onChange={(event) => handleInputChange(event, index)}
                    />
                    <div className="tab-link-container">
                      <span className="tab-link-helper">link:</span>
                      <input
                        type="text"
                        name={`input-link-${index}`}
                        className={
                          !inputError[index].linkError
                            ? "tab-link-input"
                            : "tab-link-input error"
                        }
                        value={inputLinkValues[index].value}
                        onChange={(event) =>
                          handleInputLinkChange(event, index)
                        }
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => addPage(index)}
                    className="tab-button"
                  >
                    Add Page
                  </button>
                </li>
              )}
            </ul>
          </div>
        ))}
      </section>
    </div>
  );
};

export default FAQ;
