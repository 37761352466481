export enum DateFormat {
  dd_MMMM_yyyy = "dd MMMM yyyy",
  dd_MMMM_yyyy_HH_mm = "dd MMMM yyyy HH:mm:ss",
}

export const formatDate = (
  dateString: Date | string | null,
  format: string
): string | null => {
  if (dateString) {
    if ((dateString as any).toISOString) {
      return (dateString as Date).toString(format);
    } else {
      const date = new Date(dateString);
      // sanity check - if min date "0001-01-01T00:00:00" then return N/A
      if (date && date.toISOString().startsWith("0")) {
        return "N/A";
      } else if (date) {
        return date.toString(format);
      }
    }
  }

  return dateString ? dateString.toString() : "";
};
