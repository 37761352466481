import React, { FC, FunctionComponent, useCallback, useState } from "react";
import DashboardSection from "../DashboardSection";
import SectionHeader from "../SectionHeader";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../Chip";
import "./MarketPreferences.css";
import { useSharedStyles } from "../useSharedStyles";
import { countries, technicalSectors } from "./market_preference_configs";
import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import UserContext from "../../../services/UserContext";
import GrowlContext from "../../../services/growlContext";
import axios from "axios";
import styled from "@emotion/styled";
import { textAlign } from "@mui/system";

interface Item {
  name: string;
  code: string;
}

const styles = {
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  multiSelectContainer: {
    margin: "5px 0px",
  },
};
const CustomLabel = styled.label({
  fontSize: "15px",
  textAlign: "left",
});
enum PreferenceTypes {
  country = 1,
  technicalSector = 2,
}

type MarketPreference = {
  preferenceType: PreferenceTypes;
  name: string;
};
type UserProfileType = {
  firstName: string;
  lastName: string;
  email: string;
};
interface Props {
  GetSelectedCountries: ({}) => void;
  GetSelectedSectors: ({}) => void;
  marketPreferences: any;
  pipelineId: number;
}

const MarketPreferencesBeta: FC<Props> = ({
  GetSelectedCountries,
  GetSelectedSectors,
  marketPreferences,
  pipelineId,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedCountries, setSelectedCountries] = useState<Item[]>([]);
  const [selectedTechnicalSectors, setSelectedTechnicalSectors] = useState<
    Item[]
  >([]);
  const sharedStyles = useSharedStyles();
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);

  React.useEffect(() => {
    var r: Array<MarketPreference> = marketPreferences;

    const seCountries = r
      .filter((x) => x.preferenceType === PreferenceTypes.country)
      .map((x) => countries.find((r) => r.name === x.name))
      .filter((x) => x) as Array<Item>;

    setSelectedCountries(seCountries);

    const seSectors = r
      .filter((x) => x.preferenceType === PreferenceTypes.technicalSector)
      .map((x) => technicalSectors.find((r) => r.name === x.name))
      .filter((x) => x) as Array<Item>;
    setSelectedTechnicalSectors(seSectors);
  }, []);

  const selectCountries = useCallback(async (countriesToAdd: Item[]) => {
    let body = countriesToAdd.map((r) => ({
      preferenceType: PreferenceTypes.country,
      name: r.name,
    }));
    GetSelectedCountries(body);
    setSelectedCountries(countriesToAdd);
  }, []);

  const unSelectCountry = useCallback(
    async (countryToRemove: Item, selectedCountries: Item[]) => {
      const updatedCountries = selectedCountries.filter(
        (country) => country.name !== countryToRemove.name
      );
      const body = [
        {
          PreferenceType: PreferenceTypes.country.toString(),
          Name: countryToRemove.name,
          MethodType: "Delete",
        },
      ];
      const deleteFromServer = () => {
        return axios
          .post(
            getFullUrl(`/api/pipeline/${pipelineId}/marketpreference`, {
              useDedicatedEnvironment: true,
            }),
            body,
            header
          )
          .then(() => {
            setSelectedCountries(updatedCountries);
            GetSelectedCountries(
              updatedCountries.map((r) => ({
                preferenceType: PreferenceTypes.country,
                name: r.name,
              }))
            );
          })
          .catch((message) => {
            console.error(message);
            growl.current.show({
              severity: "error",
              summary: "Error removing Country",
            });
          });
      };

      await deleteFromServer();
    },
    []
  );

  const selectSectors = useCallback((sectorsToAdd: Item[]) => {
    let body = sectorsToAdd.map((r) => ({
      preferenceType: PreferenceTypes.technicalSector,
      name: r.name,
    }));
    GetSelectedSectors(body);
    setSelectedTechnicalSectors(sectorsToAdd);
  }, []);

  const unSelectTechnicalSector = useCallback(
    async (techSectorToRemove: Item, selectedTechnicalSectors: Item[]) => {
      const updatedSectors = selectedTechnicalSectors.filter(
        (techSector) => techSector.name !== techSectorToRemove.name
      );
      const body = [
        {
          PreferenceType: PreferenceTypes.technicalSector.toString(),
          Name: techSectorToRemove.name,
          MethodType: "Delete",
        },
      ];

      const deleteFromServer = () => {
        return axios
          .post(
            getFullUrl(`/api/pipeline/${pipelineId}/marketpreference`, {
              useDedicatedEnvironment: true,
            }),
            body,
            header
          )
          .then(() => {
            setSelectedTechnicalSectors(updatedSectors);
            GetSelectedSectors([
              {
                preferenceType: PreferenceTypes.technicalSector.toString(),
                name: techSectorToRemove.name,
              },
            ]);
            GetSelectedSectors(
              updatedSectors.map((r) => ({
                preferenceType: PreferenceTypes.technicalSector,
                name: r.name,
              }))
            );
          })
          .catch((message) => {
            console.error(message);
            growl.current.show({
              severity: "error",
              summary: "Error removing sector",
            });
          });
      };

      await deleteFromServer();
    },
    []
  );

  return (
    <>
      <div
        style={styles.multiSelectContainer}
        className="dashboard-market-preferences"
      ></div>
      <div
        style={styles.multiSelectContainer}
        className="dashboard-market-preferences"
      >
        <CustomLabel className="field-titles" htmlFor="regions">
          Regions and Countries
        </CustomLabel>
        <MultiSelect
          optionLabel="name"
          filter={true}
          style={styles.multiSelect}
          placeholder=" Regions and Countries"
          fixedPlaceholder
          value={selectedCountries}
          options={countries}
          onChange={(e) => selectCountries(e.value)}
        />
        <div style={styles.chipsContainer}>
          {selectedCountries
            .sort((c1, c2) => c1.name.localeCompare(c2.name))
            .map((country) => (
              <Chip
                key={country.name}
                label={country.name}
                onIconClick={() => unSelectCountry(country, selectedCountries)}
              />
            ))}
        </div>
      </div>
      <div
        style={styles.multiSelectContainer}
        className="dashboard-market-preferences"
      >
        <MultiSelect
          optionLabel="name"
          filter={true}
          style={styles.multiSelect}
          placeholder="Technical Sectors"
          fixedPlaceholder
          value={selectedTechnicalSectors}
          options={technicalSectors}
          onChange={(e) => selectSectors(e.value)}
        />
        <div style={styles.chipsContainer}>
          {selectedTechnicalSectors
            .sort((s1, s2) => s1.name.localeCompare(s2.name))
            .map((technicalSector) => (
              <Chip
                key={technicalSector.name}
                label={technicalSector.name}
                onIconClick={() =>
                  unSelectTechnicalSector(
                    technicalSector,
                    selectedTechnicalSectors
                  )
                }
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default MarketPreferencesBeta;
