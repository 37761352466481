import React, { FunctionComponent } from "react";
import { css } from "glamor";

import DashboardSection from "./DashboardSection";
import ThemeContext from "../../configs/theme";
import { Button } from "primereact/button";
import SectionHeader from "./SectionHeader";
import { AskAQuestionUrl, goToUrlInNewTab } from "../../configs/resources";

const useStyles = () => {
  const theme = React.useContext(ThemeContext);

  return {
    header: {
      font: theme.v2Fonts.contextHeader,
      fontSize: "1.25em",
      textAlign: "left"
    },
    textBlock: {
      font: theme.v2Fonts.context,
      margin: "10px 0 5px",
      textAlign: "left"
    },
    submitFeedbackButton: {
      borderRadius: 65,
      font: theme.v2Fonts.link,
      backgroundColor: theme.v2.darkYellow,
      color: "white"
    }
  };
};

const UserFeedback: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <DashboardSection style={{ height: '17.5vh', marginTop:"10px"}}>
      <SectionHeader text={"User Feedback"} />
      <p {...css(styles.textBlock)}>
        We want AidKonekt to be the one stop shop for all your USAID Business
        Development.
      </p>
      <p {...css(styles.textBlock)}>Are we missing something?</p>
      <Button
        label="Submit Feedback"
        className="aid-button"
        onClick={goToUrlInNewTab(AskAQuestionUrl)}
        style={styles.submitFeedbackButton}
      />
    </DashboardSection>
  );
};

export default UserFeedback;
