import { Weekday } from "../../../../utils/constants/time-constants";
import { NotificationRecipient } from "../../../../utils/constants/notification-recipients";

export enum Type {
    SET_WEEKLY_EMAIL_ENABLED = "SET_WEEKLY_EMAIL_ENABLED",
    SAVE_WEEKLY_EMAIL_ENABLED = "SAVE_WEEKLY_EMAIL_ENABLED",

    SET_WEEKLY_EMAIL_TIME = "SET_WEEKLY_EMAIL_TIME",
    SAVE_WEEKLY_EMAIL_TIME = "SAVE_WEEKLY_EMAIL_TIME",

    SET_WEEKLY_EMAIL_DAY = "SET_WEEKLY_EMAIL_DAY",
    SAVE_WEEKLY_EMAIL_DAY = "SAVE_WEEKLY_EMAIL_DAY",

    SET_WEEKLY_EMAIL_RECIPIENT = "SET_WEEKLY_EMAIL_RECIPIENT",
    SAVE_WEEKLY_EMAIL_RECIPIENT = "SAVE_WEEKLY_EMAIL_RECIPIENT",

    SET_DAILY_EMAIL_ENABLED = "SET_DAILY_EMAIL_ENABLED",
    SAVE_DAILY_EMAIL_ENABLED = "SAVE_DAILY_EMAIL_ENABLED",

    SET_DAILY_EMAIL_TIME = "SET_DAILY_EMAIL_TIME",
    SAVE_DAILY_EMAIL_TIME = "SAVE_DAILY_EMAIL_TIME",

    SET_DAILY_EMAIL_RECIPIENT = "SET_DAILY_EMAIL_RECIPIENT",
    SAVE_DAILY_EMAIL_RECIPIENT = "SAVE_DAILY_EMAIL_RECIPIENT",

    SET_FORECAST_ALERT_ENABLED = "SET_FORECAST_ALERT_ENABLED",
    SAVE_FORECAST_ALERT_ENABLED = "SAVE_FORECAST_ALERT_ENABLED",

    SET_FORECAST_ALERT_RECIPIENT = "SET_FORECAST_ALERT_RECIPIENT",
    SAVE_FORECAST_ALERT_RECIPIENT = "SAVE_FORECAST_ALERT_RECIPIENT"
}

type SetWeeklyEmailEnabledAction = { type: Type.SET_WEEKLY_EMAIL_ENABLED, value: boolean }
type SaveWeeklyEmailEnabledAction = { type: Type.SAVE_WEEKLY_EMAIL_ENABLED }
type SetWeeklyEmailTimeAction = { type: Type.SET_WEEKLY_EMAIL_TIME, value: string }
type SaveWeeklyEmailTimeAction = { type: Type.SAVE_WEEKLY_EMAIL_TIME }
type SetWeeklyEmailDayAction = { type: Type.SET_WEEKLY_EMAIL_DAY, value: Weekday }
type SaveWeeklyEmailDayAction = { type: Type.SAVE_WEEKLY_EMAIL_DAY }
type SetWeeklyEmailRecipientAction = { type: Type.SET_WEEKLY_EMAIL_RECIPIENT, value: NotificationRecipient }
type SaveWeeklyEmailRecipientAction = { type: Type.SAVE_WEEKLY_EMAIL_RECIPIENT }
type SetDailyEmailEnabledAction = { type: Type.SET_DAILY_EMAIL_ENABLED, value: boolean }
type SaveDailyEmailEnabledAction = { type: Type.SAVE_DAILY_EMAIL_ENABLED }
type SetDailyEmailTimeAction = { type: Type.SET_DAILY_EMAIL_TIME, value: string }
type SaveDailyEmailTimeAction = { type: Type.SAVE_DAILY_EMAIL_TIME }
type SetDailyEmailRecipientAction = { type: Type.SET_DAILY_EMAIL_RECIPIENT, value: NotificationRecipient }
type SaveDailyEmailRecipientAction = { type: Type.SAVE_DAILY_EMAIL_RECIPIENT }
type SetForecastAlertEnabledAction = { type: Type.SET_FORECAST_ALERT_ENABLED, value: boolean }
type SaveForecastAlertEnabledAction = { type: Type.SAVE_FORECAST_ALERT_ENABLED }
type SetForecastAlertRecipientAction = { type: Type.SET_FORECAST_ALERT_RECIPIENT, value: NotificationRecipient }
type SaveForecastAlertRecipientAction = { type: Type.SAVE_FORECAST_ALERT_RECIPIENT }

export const setWeeklyEmailEnabled = (enabled: boolean): SetWeeklyEmailEnabledAction => ({
    type: Type.SET_WEEKLY_EMAIL_ENABLED,
    value: enabled
});

export const saveWeeklyEmailEnabled = (): SaveWeeklyEmailEnabledAction => ({
    type: Type.SAVE_WEEKLY_EMAIL_ENABLED
});

export const setWeeklyEmailTime = (time: string): SetWeeklyEmailTimeAction => ({
    type: Type.SET_WEEKLY_EMAIL_TIME,
    value: time
});

export const saveWeeklyEmailTime = (): SaveWeeklyEmailTimeAction => ({
    type: Type.SAVE_WEEKLY_EMAIL_TIME
});

export const setWeeklyEmailDay = (weekday: Weekday): SetWeeklyEmailDayAction => ({
    type: Type.SET_WEEKLY_EMAIL_DAY,
    value: weekday
});

export const saveWeeklyEmailDay = (): SaveWeeklyEmailDayAction => ({
    type: Type.SAVE_WEEKLY_EMAIL_DAY
});

export const setWeeklyEmailRecipient = (recipient: NotificationRecipient): SetWeeklyEmailRecipientAction => ({
    type: Type.SET_WEEKLY_EMAIL_RECIPIENT,
    value: recipient
});

export const saveWeeklyEmailRecipient = (): SaveWeeklyEmailRecipientAction => ({
    type: Type.SAVE_WEEKLY_EMAIL_RECIPIENT
});

export const setDailyEmailEnabled = (enabled: boolean): SetDailyEmailEnabledAction => ({
    type: Type.SET_DAILY_EMAIL_ENABLED,
    value: enabled
});

export const saveDailyEmailEnabled = (): SaveDailyEmailEnabledAction => ({
    type: Type.SAVE_DAILY_EMAIL_ENABLED
});

export const setDailyEmailTime = (time: string): SetDailyEmailTimeAction => ({
    type: Type.SET_DAILY_EMAIL_TIME,
    value: time
});

export const saveDailyEmailTime = (): SaveDailyEmailTimeAction => ({
    type: Type.SAVE_DAILY_EMAIL_TIME
});

export const setDailyEmailRecipient = (recipient: NotificationRecipient): SetDailyEmailRecipientAction => ({
    type: Type.SET_DAILY_EMAIL_RECIPIENT,
    value: recipient
});

export const saveDailyEmailRecipient = (): SaveDailyEmailRecipientAction => ({
    type: Type.SAVE_DAILY_EMAIL_RECIPIENT
});

export const setForecastAlertEnabled = (enabled: boolean): SetForecastAlertEnabledAction => ({
    type: Type.SET_FORECAST_ALERT_ENABLED,
    value: enabled
});

export const saveForecastAlertEnabled = (): SaveForecastAlertEnabledAction => ({
    type: Type.SAVE_FORECAST_ALERT_ENABLED
});

export const setForecastAlertRecipient = (recipient: NotificationRecipient): SetForecastAlertRecipientAction => ({
    type: Type.SET_FORECAST_ALERT_RECIPIENT,
    value: recipient
});

export const saveForecastAlertRecipient = (): SaveForecastAlertRecipientAction => ({
    type: Type.SAVE_FORECAST_ALERT_RECIPIENT
});

export type Action = SetWeeklyEmailEnabledAction
                   | SaveWeeklyEmailEnabledAction
                   | SetWeeklyEmailTimeAction
                   | SaveWeeklyEmailTimeAction
                   | SetWeeklyEmailDayAction
                   | SaveWeeklyEmailDayAction
                   | SetWeeklyEmailRecipientAction
                   | SaveWeeklyEmailRecipientAction
                   | SetDailyEmailEnabledAction
                   | SaveDailyEmailEnabledAction
                   | SetDailyEmailTimeAction
                   | SaveDailyEmailTimeAction
                   | SetDailyEmailRecipientAction
                   | SaveDailyEmailRecipientAction
                   | SetForecastAlertEnabledAction
                   | SaveForecastAlertEnabledAction
                   | SetForecastAlertRecipientAction
                   | SaveForecastAlertRecipientAction
