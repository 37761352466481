import React from 'react'
import './authentication.css'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import {useStyles} from '../../hooks/useStyles';

function Authenticator() {

  const [errorMessage, setErrorMessage] = React.useState<string|null>();
  const [currentState, setCurrentState] = React.useState('showSignIn');

  const styles = useStyles();

  React.useEffect(() => {
    setErrorMessage(null);
  }, [currentState]);

  return (
    <div style={{...styles.formContainerStyle, maxHeight: 520}}>
        { currentState === 'showSignIn'  && <SignIn updateErrorMessage={setErrorMessage} errorMessage={errorMessage} />  }
        { currentState === 'showSignUp' && <SignUp updateErrorMessage={setErrorMessage} switchState={setCurrentState} errorMessage={errorMessage} /> }
        { currentState === 'showForgotPassword' && <ForgotPassword switchState={setCurrentState} updateErrorMessage={setErrorMessage} errorMessage={errorMessage} /> }
        <div style={styles.buttonContainer}>
          {
            currentState === 'showSignIn' ? (
              <div style={styles.linkContainer}>
                <p onClick={() => setCurrentState('showSignUp')} style={styles.toggle} className="auth-link">Need an account? Sign Up</p>
                <p onClick={() => setCurrentState('showForgotPassword')} style={styles.toggle} className="auth-link">Forgot your password?</p>
              </div>
                ) : (
              <div style={styles.linkContainer}>
                <p
                  style={styles.toggle}
                  className="auth-link"
                  onClick={() => setCurrentState('showSignIn')}
                >Already have an account? Sign In</p>
              </div>
            )
          }
        </div>
      </div>
  )
}

export default Authenticator
