import React from "react"
import {Ripple} from 'primereact/ripple';
import {Button} from 'primereact/button';
import {useGridStyles} from '../../hooks/useGridStyles';

const icons = {
    arrowUp: require('../../assets/icons/v2/icon_up_arrow.png')?.default,
    arrowDown: require('../../assets/icons/v2/icon_down_arrow.png')?.default
  };

export const PanelHeader: React.FunctionComponent<{
    title?: string,
    expandedTitle?: JSX.Element,
    search?: () => any|void, 
    isCollapsed: boolean, 
    setIsCollapsed: (x: boolean) => void,
    expanderIcon?: JSX.Element,
    collapserIcon?: JSX.Element
}> = ({title, search, isCollapsed, setIsCollapsed, expanderIcon, collapserIcon, expandedTitle}) => {
    const styles = useGridStyles();

    const expander = expanderIcon || <img src={icons.arrowDown} style={styles.icon}></img>;
    const collapser = collapserIcon || <img src={icons.arrowUp} style={styles.icon}></img>;

    return (
        <div className="p-grid p-align-center" style={{minHeight: 65}}>
            <div className="p-col-10" onClick={(e) => {
                if (e.target 
                    && (e.target as any).type !== "checkbox" 
                    && !(e.target as any).classList.contains("p-checkbox-box") 
                    && !(e.target as any).parentElement.classList.contains("p-checkbox-box")
                    && !(e.target as any).classList.contains("p-radiobutton-box")) {
                    setIsCollapsed(!isCollapsed);
                }
            }} style={{cursor: "pointer"}}>
                {
                    title && (
                        <div className="p-grid" style={styles.filters.notTopFilterRow} >
                            <div className="p-col" style={{
                                ...styles.filters.searchButtonContainer,
                                font: styles.theme.v2Fonts.smediumHeader,
                                color: "black"
                            }}>
                                {title}
                            </div>
                        </div>
                    )     
                }
                {
                    expandedTitle && !isCollapsed && (
                        <div className="p-grid" style={styles.filters.notTopFilterRow} >
                            <div className="p-col" style={{
                                ...styles.filters.searchButtonContainer
                            }}>
                                {expandedTitle}
                            </div>
                        </div>
                    )     
                }
                {
                    isCollapsed && search && (
                        <div className="p-grid" style={styles.filters.notTopFilterRow} >
                            <div className="p-col" style={styles.filters.searchButtonContainer}>
                            <Button style={styles.filters.searchButton} onClick={search}> 
                                <div style={styles.filters.searchButtonText}>Search</div>
                            </Button>
                            </div>
                        </div>
                    )                      
                }
            </div>
            <div className="p-col-2" style={{textAlign: "right", cursor: "pointer"}} onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed && (
                <button className="p-panel-header-icon p-panel-toggler p-link" 
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    aria-expanded={!isCollapsed} 
                    role="tab">
                    {expander}
                    <Ripple />
                </button>
                )}
                {!isCollapsed && (
                <button className="p-panel-header-icon p-panel-toggler p-link" 
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    aria-expanded={!isCollapsed} 
                    role="tab">
                    {collapser}
                </button>
                )}
            </div>
        </div>
    );
}