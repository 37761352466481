import React, { FunctionComponent, useContext, useState } from "react";
import ThemeContext from "../../../configs/theme";
import { DateFormat, formatDate } from "../../../utils/conversion/date-converters";
import { Button } from "primereact/button";
import { EventType, sharedStyles } from "./EventsCalendar";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

const useStyles = () => {
    const theme = useContext(ThemeContext);

    return {
        header: {
            font: theme.v2Fonts.smallHeader,
            whiteSpace: "nowrap" as "nowrap"
        }
    };
};

export const useHeaderRow = () => {
    const styles = useStyles();

    return (
        <tr style={styles.header}>
            <th>Date</th>
            <th>Event Name</th>
            <th>Organization</th>
            <th>RSVP Link</th>
        </tr>
    );
};

interface CalendarRowProps {
    event: EventType,
    canEditEvents: boolean,
    editEvent: (eventId: number, editedEvent: EventType) => void,
    deleteEvent: (eventId: number) => void
}

const CalendarRow: FunctionComponent<CalendarRowProps> = ({
    event,
    canEditEvents,
    editEvent,
    deleteEvent
}) => {

    const [editedEvent, setEditedEvent] = useState<EventType>(event);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const toggleEditing = () => {
        setIsEditing(! isEditing);
        setEditedEvent(event);
    };

    const handleSave = () => {
        setIsEditing(false);
        editEvent(event.id, editedEvent);
    };

    return (
        <tr>
            {
                isEditing ?
                    <>
                        <td>
                            <Calendar placeholder="mm/dd/yyyy"
                                      dateFormat="mm/dd/yy"
                                      value={new Date(editedEvent.date)}
                                      onChange={e => setEditedEvent({
                                          ...editedEvent,
                                          date: (e.value as Date).toISOString()})
                                      } />
                        </td>
                        <td>
                            <InputText placeholder="Event Name"
                                       style={sharedStyles.inlineInput}
                                       value={editedEvent.name}
                                       onChange={e => setEditedEvent({
                                           ...editedEvent,
                                           name: e.currentTarget.value
                                       })} />
                        </td>
                        <td>
                            <InputText placeholder="Organization"
                                       style={sharedStyles.inlineInput}
                                       value={editedEvent.organization}
                                       onChange={e => setEditedEvent({
                                           ...editedEvent,
                                           organization: e.currentTarget.value
                                       })} />
                        </td>
                        <td>
                            <InputText placeholder="RSVP Link"
                                       style={sharedStyles.inlineInput}
                                       value={editedEvent.url}
                                       onChange={e => setEditedEvent({
                                           ...editedEvent,
                                           url: e.currentTarget.value
                                       })} />
                        </td>
                    </>
                    :
                    <>
                        <td style={sharedStyles.cell}>{formatDate(editedEvent.date, DateFormat.dd_MMMM_yyyy)}</td>
                        <td style={sharedStyles.cell}>{editedEvent.name}</td>
                        <td style={sharedStyles.cell}>{editedEvent.organization}</td>
                        <td style={sharedStyles.cell}><a href={editedEvent.url} target="_blank">Link</a></td>
                    </>
            }
            {canEditEvents &&
                <td style={{ ...sharedStyles.cell, ...sharedStyles.controls }}>
                    {isEditing ?
                        <>
                            <Button icon={"pi pi-save"}
                                    className="p-button-rounded"
                                    style={sharedStyles.iconButton}
                                    tooltip={"Save Event"} tooltipOptions={{ position: "top" }}
                                    onClick={() => handleSave()} />
                            <Button icon={"pi pi-times"}
                                    className="p-button-rounded"
                                    style={sharedStyles.iconButton}
                                    tooltip={"Cancel"} tooltipOptions={{ position: "top" }}
                                    onClick={() => toggleEditing()} />
                        </>
                        :
                        <>
                            <Button icon={"pi pi-pencil"}
                                    className="p-button-rounded"
                                    style={sharedStyles.iconButton}
                                    tooltip={"Edit Event"} tooltipOptions={{ position: "top" }}
                                    onClick={() => toggleEditing()} />
                            <Button icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger"
                                    style={sharedStyles.iconButton}
                                    tooltip="Delete Event" tooltipOptions={{ position: "top" }}
                                    onClick={() => deleteEvent(event.id)} />
                        </>
                    }
                </td>
            }
        </tr>
    );
};

export default CalendarRow;
