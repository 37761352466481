import { useContext, useEffect } from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import StickyBox from "react-sticky-box";
import UserContext, {
  doesUserHaveRole,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import NavBar, { Header } from "./Header";

import Authenticator from "../Authentication/Authenticator";
import Forecast from "../../screens/Forecast/Forecast";
import Pipeline from "../../screens/Pipelines/Pipeline";
import FAQ from "../../screens/FAQ/FAQ";
import FAQArticle from "../../screens/FAQ/FAQArticle/FAQArticle";
import LandingPage from "../Landing/Landing";
import ConfirmationEmail from "../Authentication/ConfirmationEmail";
import AdminScreen from "../../screens/AdminScreen/AdminScreen";
import Dashboard from "../../screens/Dashboard";
import Data from "../../screens/Data/Data";
import PaymentScreen from "../../screens/PaymentScreen/PaymentScreen";
import SubscriptionsScreen from "../../screens/SubscriptionsScreen/SubscriptionsScreen";
import { MyItems } from "../../screens/MyItems/MyItems";
import UserManagementScreen from "../../screens/UserManagementScreen/UserManagementScreen";
import GrantsTableScreen from "../../screens/GrantsTableScreen/GrantsTableScreen";
import ContractsTableScreen from "../../screens/ContractTableScreen/ContractTableScreen";
import WeeklyForecast from "../../screens/WeeklyForecast/WeeklyForecast";
import USAidScreen from "../../screens/USAidScreen/USAid";
import UpgradePlan from "../../screens/UpgradePlan/UpgradePlan";
import AidKonektResources from "../../screens/AidKonektResources/AidKonektResources";
import Resources from "../../screens/Resources/Resources";
import Webinars from "../../screens/Webinars/Webinars";
import CDCSRepository from "../../screens/CDCSRepository/CDCSRepository";
import LCPArchives from "../../screens/LCPArchives/LCPArchives";
import IEEArchives from "../../screens/IEEArchives/IEEArchives";
import QAArchives from "../../screens/QAArchives/QAArchives";
import Footer from "../footer";
import useIsAuthenticated from "../../services/authCheck";
import { ProgressSpinner } from "primereact/progressspinner";
import ProjectDetails from "../../screens/ProjectDetails/ProjectDetails";
import UserDashboard from "../../screens/UserDashboard";
import UserMenu from "../Dashboard/DashboardManagement/Users/UserMenu";
import OrganizationDashboard from "../../screens/OrganizationDashboard";
import PipelineDetail from "../../screens/Pipelines/PipelineFragments/PipelineDetail";

function PrivateRouteComponent({ history, component: Component, children }) {
  const context = useContext(UserContext);
  const { isLoaded } = context;
  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    context.updateCurrentUser();
    const unlisten = history.listen(() => {
      context.updateCurrentUser();
    });
    return unlisten;
  }, []);

  return (
    <Route
      {...children}
      render={(props) => {
        if (!isLoaded) {
          return <ProgressSpinner />;
        }

        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
            }}
          />
        );
      }}
    />
  );
}

function RoleSpecificRouteComponent({
  history,
  component: Component,
  children,
  roles,
}) {
  const context = useContext(UserContext);

  const isRole = roles.find((r) => doesUserHaveRole(context, r)) ? true : false;

  const { isLoaded } = context;

  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    context.updateCurrentUser();
    const unlisten = history.listen(() => {
      context.updateCurrentUser();
    });
    return unlisten;
  }, []);

  return (
    <Route
      {...children}
      render={(props) => {
        if (!isLoaded) {
          return <ProgressSpinner />;
        }

        if (!isAuthenticated) {
          return <Redirect to={{ pathname: "/auth" }} />;
        }

        return isRole ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/profile" }} />
        );
      }}
    />
  );
}

const RoleSpecificRoute = withRouter(RoleSpecificRouteComponent);
const PrivateRoute = withRouter(PrivateRouteComponent);

const BasicRouter = () => {
  const currentRoute = useLocation();
  const [getIsAuthenticated] = useIsAuthenticated();

  const getIsLanding = () =>
    currentRoute.pathname.includes("/usaid-biz-dev") ||
    currentRoute.pathname === "/project-details";

  const isAuthenticated = getIsAuthenticated();
  const isLanding = getIsLanding();

  const wrapperStyle = {
    main: !isLanding ? "p-grid main-container" : "",
    subMain: !isLanding ? "p-col p-grid p-justify-center content-block" : "",
  };

  return (
    <>
      {!isLanding && (
        <div className="p-grid">
          <div className="p-col-12" style={{ textAlign: "left" }}>
            <Header />
            <div style={{ float: "right" }}>
              {/* To do: return another item instead of empty div */}

              {isAuthenticated ? <UserMenu /> : <div></div>}
            </div>
          </div>
        </div>
      )}
      <div className={wrapperStyle.main}>
        {isAuthenticated && !isLanding && (
          <div className="p-col-fixed" style={{ width: 175, marginRight: 40 }}>
            <StickyBox offsetTop={20}>
              <NavBar />
            </StickyBox>
          </div>
        )}
        <div className={wrapperStyle.subMain}>
          <Switch>
            {isAuthenticated ? (
              <Route
                path="/auth"
                exact
                render={() => <Redirect to={{ pathname: "/" }} />}
              />
            ) : (
              <Route path="/auth" exact component={Authenticator} />
            )}
            {/* <Route path="/usaid-biz-dev" exact component={LandingPage} />
            <Route path="/usaid-biz-dev/aidex-brussels" exact component={LandingPage} />
            <Route path="/usaid-biz-dev/germany" exact component={LandingPage} /> */}
            <Route path="/verify-email" exact component={ConfirmationEmail} />
            <PrivateRoute path="/weeklyreport" component={WeeklyForecast} />
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute
              path="/admin-panel"
              exact
              roles={[ServerOrgRoles.admin]}
              component={AdminScreen}
            />
            <PrivateRoute path="/forecast" exact component={Forecast} />
            <PrivateRoute path="/pipeline" exact component={Pipeline} />
            <PrivateRoute
              path="/aidkonekt-resources"
              exact
              component={AidKonektResources}
            />
            <PrivateRoute path="/resources" exact component={Resources} />
            <PrivateRoute path="/webinars" exact component={Webinars} />
            <PrivateRoute
              path="/userdashboard"
              exact
              component={UserDashboard}
            />

            <PrivateRoute
              path="/organization"
              exact
              component={OrganizationDashboard}
            />
            <PrivateRoute
              path="/cdcs-repository"
              exact
              component={CDCSRepository}
            />
            <PrivateRoute path="/lcp-archives" exact component={LCPArchives} />
            <PrivateRoute path="/iee-archives" exact component={IEEArchives} />
            <PrivateRoute path="/qa-archives" exact component={QAArchives} />
            <PrivateRoute path="/faq" exact component={FAQ} />
            <PrivateRoute path="/faq/" component={FAQArticle} />
            <RoleSpecificRoute
              path="/decdata"
              component={Data}
              roles={UserRoleGroups.proOrHigher}
            />
            <RoleSpecificRoute
              path="/payment"
              component={PaymentScreen}
              roles={UserRoleGroups.plusOrHigher}
            />
            <PrivateRoute
              path="/subscriptions"
              component={SubscriptionsScreen}
              roles={UserRoleGroups.plusOrHigher}
            />
            <RoleSpecificRoute
              path="/mybids"
              component={MyItems}
              roles={UserRoleGroups.plusOrHigher}
            />

            <RoleSpecificRoute
              path="/users"
              roles={[ServerOrgRoles.admin]}
              component={UserManagementScreen}
            />
            <RoleSpecificRoute
              path="/grants"
              roles={UserRoleGroups.plusOrHigher}
              component={GrantsTableScreen}
            />
            <RoleSpecificRoute
              path="/contracts"
              roles={UserRoleGroups.plusOrHigher}
              component={ContractsTableScreen}
            />
            <RoleSpecificRoute
              path="/projects"
              roles={UserRoleGroups.proOrHigher}
              component={USAidScreen}
            />
            <RoleSpecificRoute
              path="/project-details"
              roles={UserRoleGroups.proOrHigher}
              component={ProjectDetails}
            />

            <RoleSpecificRoute
              path="/organization"
              roles={UserRoleGroups.plusOrHigher}
              component={OrganizationDashboard}
            />

            <RoleSpecificRoute
              path="/pipeline/:id"
              roles={UserRoleGroups.organizationAdmins}
              component={PipelineDetail}
            />

            <PrivateRoute path="/tiers" component={UpgradePlan} />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
        </div>
      </div>
      {!isLanding && <Footer />}
      {/* {!isLanding && <SubmitFeedback />} */}
      {/* {isAuthenticated && !isLanding && <StartedGuide />} */}
    </>
  );
};

const Routes = ({ currentUser }) => {
  return (
    <Router>
      <Switch>
        {/* <Route path="/weeklyreport" render={(props) => <WeeklyForecast {...props} />} /> */}
        <Route component={BasicRouter} />
      </Switch>
    </Router>
  );
};

export default Routes;
