import React from "react";
import "./AidKonektResources.css";
import { Link } from "react-router-dom";
import { useGridStyles } from "../../hooks/useGridStyles";

const AidKonektResources: React.FC = (): JSX.Element => {
  const styles = useGridStyles();

  return (
    <div className="resources-screen">
      <section className="header">
        <div className="header-title">
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          />
          <span>USAID Resources</span>
        </div>
        <span className="header-subtitle">
          Curated Key Resources for working with USAID
        </span>
        <span className="header-description">
          As a company, we have more than half a decade of experience supporting
          our clients’ USAID proposal development, and collectively, our core
          network of professionals has centuries of experience in all aspects of
          working with USAID, from proposals to implementation to closeout and
          follow-on activities. With this depth and breadth of experience
          working with USAID, we would like to share the following resources. We
          hope you find them useful as you and your team partner with USAID to
          implement their important work.
        </span>
      </section>

      <section className="resources-cards-block">
        <div className="cards-column">
          <div className="card-container">
            <h3 className="card-title">
              USAID Best Practices and General Information
            </h3>
            <ul className="card-list-content">
              <li>- Top 25 USAID Prime Implementers 2017-2019</li>
              <li>- Subcontracting Best Practices White Paper</li>
              <li>- Proposal Development Best Practices White Paper</li>
            </ul>
            <Link className="card-btn-yellow" to="/resources">
              Resources
            </Link>
          </div>
          <div className="card-container">
            <h3 className="card-title">Konektid International Webinars</h3>
            <ul className="card-list-content">
              <li>– USAID’s Response to COVID-19</li>
              <li>– Introduction to USAID partnering</li>
              <li>– USAID under the Biden Administration</li>
            </ul>
            <Link className="card-btn-yellow" to="/webinars">
              Webinars
            </Link>
          </div>
          <div className="card-container">
            <h3 className="card-title">
              USAID Country Development Cooperation Strategy Repository
            </h3>
            <div className="card-content">
              <span>
                A Country Development Cooperation Strategy (CDCS) defines how a
                Mission will further the goal of ending the need for foreign
                assistance in a given country by articulating how and where it
                will build self-reliance with the country partner.
              </span>
            </div>
            <Link className="card-btn-yellow" to="/cdcs-repository">
              CDCS Repository
            </Link>
          </div>
        </div>
        <div className="cards-column">
          <div className="card-container">
            <h3 className="card-title">USG Local Compensation Plan Archive</h3>
            <div className="card-content">
              <span>
                Local Compensation Plans are essential to US Government
                Contracting budgets and proposals but can be difficult to come
                by. We have complied a database of LCP’s we have on hand and
                will continue to build our database.
              </span>
            </div>
            <Link className="card-btn-yellow" to="/lcp-archives">
              LCP Archive
            </Link>
          </div>
          <div className="card-container">
            <h3 className="card-title">
              USAID Business Forecast Quarterly Information Call
            </h3>
            <div className="card-content">
              <span>
                USAID holds an information sharing call regarding the Business
                Forecast quarterly to answer implementer questions and share
                additional information on procurements. Access the Quarterly
                call information in our archive.
              </span>
            </div>
            <Link className="card-btn-yellow" to="/qa-archives">
              Q&A Archive
            </Link>
          </div>
          <div className="card-container">
            <h3 className="card-title">
              Initial Environmental Examination Archive
            </h3>
            <div className="card-content">
              {/* <span>Coming soon!</span> */}
            </div>
            <Link className="card-btn-yellow" to="/iee-archives">
              IEE Archive
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AidKonektResources;
