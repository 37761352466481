import styled from "@emotion/styled";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import React, { FC, useCallback } from "react";
import MarketPreferencesBeta from "../../../components/Dashboard/MarketPreferences/MarketPreferencesBeta";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../../services/UserContext";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import GrowlContext from "../../../services/growlContext";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../../components/Chip";
import { TabPanel, TabView } from "primereact/tabview";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import DebouncedButton from "./DebouncedButton";
import { useHistory } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { pipeline } from "stream";
const Divider = styled.hr({
  width: "100%",
  border: "1px solid #D9D9D9",
});
const CustomLabel = styled.label({
  fontSize: "15px",
});
const styles = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
  chipsContainer: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap" as "wrap",
  },
};
type User = {
  publicUserId: string;
  firstName: string;
  lastName: string;
  userRole: string;
  pipelineUserRole: string;
  email: string;
};

type PipelineDto = {
  id: number;
  name: string;
  description: string;
  businessForecastCustomColumnName: string;
  contractCustomColumnName: string;
  grantCustomColumnName: string;
  pipelineUserRole: string;
  createdOnUtc: string;
  users: User[];
  marketPreferences: any;
  grantCount: number;
  contractCount: number;
  forecastCount: number;
  totalOpportunities: number;
};

type Prop = {
  pipelines: PipelineDto;
  toggleEdits: boolean;
  toggleViewMode: boolean;
  divRef: React.RefObject<OverlayPanel>;
};
enum PreferenceTypes {
  country = 1,
  technicalSector = 2,
}

interface Item {
  Name: string;
  code: string;
}
type MarketPreference = {
  preferenceType: number;
  name: string;
};

const Sidebar: FC<Prop> = ({
  pipelines,
  toggleEdits,
  toggleViewMode,
  divRef,
}) => {
  const [title, setTitle] = React.useState(pipelines.name);
  const [desc, setDesc] = React.useState(pipelines.description);
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const header = createAuthenticatedRequest(context);
  const history = useHistory();
  const [companyUsers, setCompanyUsers] = React.useState<User[]>([]);
  const [selected, setSelected] = React.useState({});
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const { user } = context;
  const { users } = pipelines;
  const [pipelineMembers, setPipelineUser] = React.useState<User[]>(users);
  const [selectedCountries, setSelectedCountires] = React.useState<
    MarketPreference[]
  >(Array<MarketPreference>());
  const [selectedSectors, setSelectedSectors] = React.useState<
    MarketPreference[]
  >(Array<MarketPreference>());

  const GetSelectedCountries = (data: any) => {
    setSelectedCountires(data);
  };
  const GetSelectedSectors = (data: any) => {
    setSelectedSectors(data);
  };

  React.useEffect(() => {
    const country = pipelines.marketPreferences.filter(
      (obj: MarketPreference) => {
        return obj.preferenceType === 1;
      }
    );
    setSelectedCountires(country);
    const sectors = pipelines.marketPreferences.filter(
      (obj: MarketPreference) => {
        return obj.preferenceType !== 1;
      }
    );
    setSelectedSectors(sectors);
  }, []);

  React.useEffect(() => {
    axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context)
      )
      .then((response) => {
        const { data } = response;
        const email = user.parsedIdToken?.["cognito:username"].split("|");
        if (email) {
          var id = email[0] + "@" + email[1];
          const orgUsers = data.filter((r: User) => r.email !== id);
          setCompanyUsers(getDifference(orgUsers, pipelineMembers));
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  }, []);

  const editPipeline = () => {
    const pipelineUsers = pipelineMembers.map((sa) => {
      if (Object.keys(selected).length === 0) {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role: "Member",
        };
      } else {
        return {
          publicUserId: sa.publicUserId.toUpperCase(),
          role: selected[sa.firstName],
        };
      }
    });

    if (title === "") {
      growl.current.show({
        severity: "error",
        summary: "The title is required",
      });
      return;
    }
    axios
      .put(
        getFullUrl(`/api/pipeline/${pipelines.id}`, {
          useDedicatedEnvironment: true,
        }),
        {
          Name: title,
          Description: desc,
          BusinessForecastCustomColumnName: "Internal ID",
          ContractCustomColumnName: "Internal ID",
          GrantCustomColumnName: "Internal ID",
          Users: pipelineUsers,
        },
        header
      )
      .then((res) => {
        let marketPref: MarketPreference[] =
          selectedCountries.concat(selectedSectors);
        const row = {} as PipelineDto;
        row.name = title;
        row.description = desc;
        row.pipelineUserRole = pipelines.pipelineUserRole;
        row.id = pipelines.id;
        row.users = pipelineMembers;
        row.marketPreferences = marketPref;

        //  update  pills
        setPipelineUser(row.users);

        //  reset location state
        if (history.location.state) {
          let state = row;
          history.replace({ ...history.location, state });
        }

        addSelectedCountries(pipelines.id, "Add");
        addSelectedSectors(pipelines.id, "Add");

        growl.current.show({
          severity: "success",
          summary: "Pipeline has been edited",
        });
        // close the overlay
        divRef.current?.hide();
      })
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving edits",
        });
      });
  };

  const selectUsers = useCallback(async (usersToAdd: User[]) => {
    setPipelineUser(usersToAdd);
  }, []);

  const userTemplate = (option) => {
    return (
      <div key={option.publicUserId}>
        {option.firstName} {option.lastName}
      </div>
    );
  };
  const RemoveUser = (userToRemove: User, Users: User[]) => {
    const updatedUsers = Users.filter(
      (user) => user.publicUserId !== userToRemove.publicUserId
    );
    setPipelineUser(updatedUsers);
    // if user  is already in options list
    // return else add the removed user to the option list

    if (companyUsers.includes(userToRemove)) {
      return;
    } else {
      setCompanyUsers([userToRemove, ...companyUsers]);
    }
  };
  const addSelectedCountries = (id: number, method: string) => {
    let body = selectedCountries.map((r) => {
      return {
        PreferenceType: PreferenceTypes.country.toString(),
        Name: r.name,
        MethodType: method,
      };
    });

    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        body,
        header
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving country",
        });
      });
  };
  const addSelectedSectors = (id: number, method: string) => {
    let body = selectedSectors.map((r) => ({
      PreferenceType: PreferenceTypes.technicalSector.toString(),
      Name: r.name,
      MethodType: method,
    }));

    axios
      .post(
        getFullUrl(`/api/pipeline/${id}/marketpreference`, {
          useDedicatedEnvironment: true,
        }),
        body,
        header
      )
      .then((res) => {})
      .catch((message) => {
        console.error(message);
        growl.current.show({
          severity: "error",
          summary: "Error saving sector",
        });
      });
  };
  const canEditData = pipelineMembers.length !== users.length;
  function getDifference(c1: User[], c2: User[]) {
    return c1.filter((sa) => {
      return !c2.some((r) => {
        return sa.publicUserId === r.publicUserId.toLowerCase();
      });
    });
  }
  return (
    <>
      <TabView>
        <TabPanel header="Details">
          <div className="p-grid text-labels">
            <div className="p-col-12" style={{ textAlign: "left" }}>
              <div className="p-col-12">
                <CustomLabel htmlFor="Title">Title*</CustomLabel>
                <InputText
                  placeholder="Title"
                  className="field-inputs"
                  defaultValue={title}
                  // value={}
                  onChange={(e) => {
                    e.preventDefault();
                    setTitle((e.target as HTMLInputElement).value);
                  }}
                />
              </div>
              <div className="p-col-12">
                <CustomLabel className="field-titles" htmlFor="Message">
                  Description
                </CustomLabel>
                <InputTextarea
                  defaultValue={desc || pipelines.description}
                  placeholder=""
                  rows={5}
                  cols={40}
                  onChange={(e) =>
                    setDesc((e.target as HTMLInputElement).value)
                  }
                />
              </div>

              {isFree ? null : (
                <div className="p-col-12">
                  <CustomLabel className="field-titles" htmlFor="users">
                    Share With
                  </CustomLabel>
                  <MultiSelect
                    optionLabel="firstName"
                    filter={true}
                    style={styles.multiSelect}
                    placeholder=" share with"
                    fixedPlaceholder
                    value={pipelineMembers}
                    options={companyUsers}
                    itemTemplate={userTemplate}
                    onChange={(e) => selectUsers(e.value)}
                  />

                  <div style={styles.chipsContainer}>
                    {pipelineMembers
                      .filter((x) => x.pipelineUserRole !== "Admin")
                      .map((sa, i) => (
                        <Chip
                          key={i}
                          label={sa.firstName + " " + sa.lastName}
                          onIconClick={() => RemoveUser(sa, pipelineMembers)}
                        />
                      ))}
                  </div>
                </div>
              )}
              <div className="p-col-12">
                <MarketPreferencesBeta
                  pipelineId={pipelines.id}
                  GetSelectedCountries={GetSelectedCountries}
                  GetSelectedSectors={GetSelectedSectors}
                  marketPreferences={pipelines.marketPreferences}
                />
              </div>
              <div className="p-col-12">
                <div className="pipeline-save-button">
                  <DebouncedButton
                    disabled={!canEditData}
                    as={Button}
                    delay={5000}
                    className="modal-button"
                    label="Save"
                    onClick={editPipeline}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Privacy" contentStyle={{ textAlign: "left" }}>
          <div className="field-radiobutton text-labels">
            <RadioButton
              style={{}}
              inputId="private"
              name="private"
              checked={users.length <= 1}
              value="private"
            />
            <label
              style={{ paddingLeft: "5px", fontWeight: 600 }}
              htmlFor="private"
            >
              Private
            </label>
            <p className="radio-button-info">
              Used for personal pipeline opportunities tracking
            </p>
          </div>
          <div className="field-radiobutton text-labels">
            <RadioButton
              inputId="public"
              name="public"
              checked={users.length > 1}
              value="public"
            />
            <label
              style={{ fontSize: "16px", paddingLeft: "5px", fontWeight: 600 }}
              htmlFor="private"
            >
              Shared
            </label>
            <p className="radio-button-info">
              {" "}
              Multiple users can manage pipeline opportunities
            </p>
          </div>
          <div className="pipeline-user-label">
            <label
              style={{ fontSize: "16px", marginTop: "10px" }}
              className="field-titles"
              htmlFor="pipeline members"
            >
              Pipeline Members
            </label>
            <div className="shared-with-total">
              {"Shared with " +
                pipelineMembers.length +
                " Member" +
                (pipelineMembers.length > 2 ? "s" : "")}
            </div>
          </div>
          <div className="p-grid text-labels">
            {React.Children.toArray(
              pipelineMembers.map((sa: User) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="pipeline-members">
                      {sa.pipelineUserRole === "Admin" && (
                        <p style={{ width: "200px" }}>
                          <strong>
                            {sa.firstName} {sa.lastName}
                          </strong>{" "}
                          (You){" "}
                        </p>
                      )}
                      {sa.pipelineUserRole !== "Admin" && (
                        <p>
                          <strong>
                            {sa.firstName} {sa.lastName}
                          </strong>
                        </p>
                      )}
                    </div>
                    <div className="pipeline-members">
                      {sa.pipelineUserRole === "Admin" && (
                        <p style={{ textAlign: "end" }}>
                          <strong>Owner</strong>
                        </p>
                      )}
                      {sa.pipelineUserRole !== "Admin" && (
                        <div
                          style={{ minWidth: "174px", textAlign: "center" }}
                          className="shared-with-total"
                        >
                          {sa.pipelineUserRole === "Admin"
                            ? "Can Manage"
                            : "View Only"}
                        </div>
                      )}
                    </div>
                    {sa.pipelineUserRole === "Admin" ? null : (
                      <div
                        onClick={() => RemoveUser(sa, pipelineMembers)}
                        className="member-close-button"
                      >
                        X
                      </div>
                    )}
                  </div>
                  <Divider />
                </>
              ))
            )}
            <div className="p-col-12">
              <div className="pipeline-save-button">
                <DebouncedButton
                  disabled={!canEditData}
                  as={Button}
                  delay={5000}
                  className="modal-button"
                  label="Save"
                  onClick={editPipeline}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

export default Sidebar;
