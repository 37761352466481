import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import './PaymentScreen.css';
import {InputText} from 'primereact/inputtext'; 

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = () => {
  const [error, setError] = React.useState<string|null>();
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event: any) => {
    if (!elements || !stripe) {
        return;
    }

    event.preventDefault();
    const card: any = elements.getElement(CardElement);

    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      stripeTokenHandler(result.token);
    }
  };

  return (
    <div className="p-fluid">
        <div className="p-field">
            <label htmlFor="firstname1">Firstname</label>
            <InputText id="firstname1" type="text"/>
        </div>
        <div className="p-field">
            <label htmlFor="lastname1">Lastname</label>
            <InputText id="lastname1" type="text"/>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="p-field" style={{marginBottom: 10}}>
            <label htmlFor="card-element">Credit or debit card</label>           
            <CardElement
              id="card-element"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange} />
          </div>
          <div className="card-errors" role="alert">{error}</div>
          <button type="submit">Submit Payment</button>
        </form>
    </div>
  );
}

export default CheckoutForm;

// POST the token ID to your backend.
async function stripeTokenHandler(token: any) {
  const response = await fetch('/charge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: token.id})
  });

  return response.json();
}