import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { rowsPerPageOptionsStandard } from "../../../utils/constants/gridsOptions";
import { GetCustomColumnBody } from "../../../components/DataTable/Columns";
import { useGridStyles } from "../../../hooks/useGridStyles";

export interface ProjectTransactionRecord {
    id: string;
    name: string;
    date: Date;
    amount: string;
    description: string;
}

const ProjectTransactionsTable: React.FC<{ tableData: ProjectTransactionRecord[] | undefined }> = ({tableData}) => {
    const styles = useGridStyles();
    let dt = React.useRef<DataTable>(null);

    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [sortField, setSortField] = useState('expirationDate')
    const [sortOrder, setSortOrder] = useState(-1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [data, setData] = useState(Array<ProjectTransactionRecord>())

    const tableHeader = (
        <div className="project-transaction-table-header">
            <span className="project-transaction-table-header-item">Total Count of Sub-Award Transactions: XXX</span>
            <span className="project-transaction-table-header-item">Total Amount of Sub-Awards: $X.XX Billion</span>
            <span className="project-transaction-table-header-item">Percent of Prime Award Obligated Amount: XXX.X%</span>
        </div>
    )

    useEffect(() => {
        if (tableData) {
            setData(tableData)
        }
    }, [tableData])

    return (
        <DataTable
            ref={dt}
            value={data}
            style={{ marginTop: 20, width: '100%' }}
            paginator={true}
            rows={rowsPerPage}
            totalRecords={totalRecords}
            lazy={true}
            loading={isLoading}
            sortField={sortField}
            sortOrder={sortOrder}
            scrollable={true}
            scrollHeight="400px"
            header={tableHeader}
            rowsPerPageOptions={rowsPerPageOptionsStandard}
        >
            {GetCustomColumnBody<ProjectTransactionRecord>(styles, 300, x => x.id, 'Sub-Award ID','id', {sortable: false})}
            {GetCustomColumnBody<ProjectTransactionRecord>(styles, 150, x => x.name, 'Recipient Name','name', {sortable: false})}
            {GetCustomColumnBody<ProjectTransactionRecord>(styles, 150, x => `${x.date.getDate()}/${x.date.getMonth()+1}/${x.date.getFullYear()}`, 'Action Date','date', {sortable: false})}
            {GetCustomColumnBody<ProjectTransactionRecord>(styles, 300, x => x.amount, 'Amount','amount', {sortable: false})}
            {GetCustomColumnBody<ProjectTransactionRecord>(styles, 400, x => x.description, 'Description','description', {sortable: false})}
        </DataTable>
    )
}

export default ProjectTransactionsTable
