import React, { FunctionComponent } from "react";
import ThemeContext from "../../configs/theme";
import AidkonektIcon from "../../assets/icons/v2/icon_Globe.png";
import IconTooltip from "../IconTooltip";

interface SectionHeaderProps {
  text: string;
  icon?: boolean;
  icontext?: string;
}

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  text,
  icon,
  icontext,
}) => {
  const theme = React.useContext(ThemeContext);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={AidkonektIcon}
        alt="Aidkonekt Icon"
        style={{ height: "25px", width: "auto" }}
      />{" "}
      &nbsp;
      <span style={{ font: theme.v2Fonts.mediumHeader, textAlign: "left" }}>
        {text}{" "}
        <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
          {icon && <IconTooltip tooltipText={icontext ? icontext : ""} />}
        </span>
      </span>
    </div>
  );
};

export const SubHeaders: FunctionComponent<SectionHeaderProps> = ({
  text,
  icon,
  icontext,
}) => {
  const theme = React.useContext(ThemeContext);

  return (
    <div style={{ display: "flex", alignItems: "center", marginLeft: 6 }}>
      <img
        src={AidkonektIcon}
        alt="Aidkonekt Icon"
        style={{ height: "17px", width: "auto" }}
      />{" "}
      &nbsp;
      <span style={{ fontSize:"18px",fontWeight:700, textAlign: "left" }}>
        {text}{" "}
        <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
          {icon && <IconTooltip tooltipText={icontext ? icontext : ""} />}
        </span>
      </span>
    </div>
  );
};
export default SectionHeader;
