import React from "react";
import { useStyles } from "../../hooks/useStyles";
import { onEnter } from "../../services/onEnterKey";
import { getFullUrl } from "../../configs/axios-export.custom";
import axios from "axios";

type ForgotPasswordParams = {
  updateErrorMessage: React.Dispatch<string | null>;
  switchState: React.Dispatch<string>;
  errorMessage: string | null | undefined;
};

function ForgotPassword({
  updateErrorMessage,
  switchState,
  errorMessage,
}: ForgotPasswordParams) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [screen, setScreen] = React.useState<
    "email" | "confirmationCode" | "password"
  >("email");
  // const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const styles = useStyles();

  const enterPassword = () => {
    if (!confirmationCode) {
      updateErrorMessage("Please enter your confirmation code.");
      return;
    }

    updateErrorMessage(null);
    setScreen("password");
  };

  const forgotPassword = () => {
    if (!username) {
      updateErrorMessage("Please enter a username to continue");
      return;
    }

    updateErrorMessage(null);

    const request = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        getFullUrl("/api/auth/resetpassword", {
          useDedicatedEnvironment: true,
        }),
        { email: username },
        request
      )
      .then(() => {
        setScreen("confirmationCode");
      })
      .catch((err) => {
        if (err && err.code === "UserNotFoundException") {
          // continue anyway
          setScreen("confirmationCode");
          return;
        }
        console.log("error: ", err);
        if (err && err.code === "LimitExceededException") {
          updateErrorMessage(
            "Attempt limit exceeded, please try after some time."
          );
        }
      });
  };

  const forgotPasswordSubmit = () => {
    if (password !== passwordConfirm) {
      updateErrorMessage(
        "Your password and confirmation password do not match"
      );
      return;
    }

    const confirmRequest = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        getFullUrl("/api/auth/confirmpasswordreset", {
          useDedicatedEnvironment: true,
        }),
        {
          email: username,
          password: password,
          authCode: confirmationCode,
        },
        confirmRequest
      )
      .then(() => {
        alert("successfully changed password!");
        switchState("showSignIn");
      })
      .catch((err) => {
        console.log("error resetting password:", err);
        if (err && err.message) {
          updateErrorMessage(err.message);
        }
      });
  };

  return (
    <div style={styles.innerContainer}>
      <div style={styles.header}>
        <img
          src={styles.aidkonektIcon}
          style={styles.icon}
          alt="Aidkonekt"
          className="formIcon"
        ></img>
        Forgot Password
      </div>
      <div style={styles.errorDiv}>
        <p>{errorMessage}</p>
      </div>
      {screen === "email" && (
        <>
          <label htmlFor="username" style={styles.inputLabel}>
            Email / Username
          </label>
          <input
            id="username"
            value={username}
            onKeyUp={onEnter(forgotPassword)}
            onChange={(evt) => {
              setUsername(evt.target.value);
            }}
            style={styles.inputStyle}
          />
          <div
            style={styles.buttonStyle}
            className="auth-btn"
            onClick={forgotPassword}
          >
            <p style={styles.buttonTextStyle}>Get Authentication Code</p>
          </div>
        </>
      )}
      {screen === "confirmationCode" && (
        <>
          <label htmlFor="confirmation" style={styles.inputLabel}>
            Confirmation Code
          </label>
          <input
            id="confirmation"
            onChange={(evt) => setConfirmationCode(evt.target.value)}
            onKeyUp={onEnter(enterPassword)}
            style={styles.inputStyle}
          />

          <div
            style={styles.buttonStyle}
            onClick={() => setScreen("email")}
            className="aid-blu-btn"
          >
            <p style={styles.buttonTextStyle}>Go Back</p>
          </div>
          <div
            style={styles.buttonStyle}
            onClick={enterPassword}
            className="aid-blu-btn"
          >
            <p style={styles.buttonTextStyle}>Enter Confirmation Code</p>
          </div>
        </>
      )}
      {screen === "password" && (
        <>
          <label htmlFor="newpassword" style={styles.inputLabel}>
            New Password
          </label>
          <input
            id="newpassword"
            onChange={(evt) => setPassword(evt.target.value)}
            style={styles.inputStyle}
            type="password"
          />

          <label htmlFor="passwordConfirm" style={styles.inputLabel}>
            Confirm New Password
          </label>
          <input
            id="passwordConfirm"
            onChange={(evt) => setPasswordConfirm(evt.target.value)}
            onKeyUp={onEnter(forgotPasswordSubmit)}
            style={styles.inputStyle}
            type="password"
          />

          <div
            style={styles.buttonStyle}
            onClick={() => setScreen("confirmationCode")}
            className="aid-blu-btn"
          >
            <p style={styles.buttonTextStyle}>Go Back</p>
          </div>
          <div
            style={styles.buttonStyle}
            onClick={forgotPasswordSubmit}
            className="aid-blu-btn"
          >
            <p style={styles.buttonTextStyle}>Reset Password</p>
          </div>
        </>
      )}
    </div>
  );
}

export default ForgotPassword;
