export const RemoveTags = (str: string)=>{

    const  hasHTMLTags = (str: string)=>{
      let a = document.createElement('div');
      if(str === null){
        return false;
      }else{
        a.innerHTML=str;
        for (let sa = a.childNodes, i: number = sa.length; i--;) {
            if(sa[i].nodeType == 1){
              return true;
            } else{
              return false;
            }
        }
      }
    }
    const removeTags = (str:string) =>{
  
      if(hasHTMLTags(str)){
        
        var temp_string = str.replace(/(<([^>]+)>)/ig, ' ')
  
        return temp_string.replace(/&nbsp;/g, '')
      }
    }
    return removeTags(str);
  }