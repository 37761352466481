import React from 'react'

export type Theme = {
    primaryFont: string,
    colors: {
        primary: string,
        secondary: string,
        accent: string,
        primaryFaded: string,
        accentFaded: string,
        offwhite: string
    },
    v2: {
        lightYellow: string,
        darkYellow: string,
        lightBlue: string,
        darkBlue: string,
        lightGrey: string,
        darkGrey: string,
        darkestGrey: string,
        black: string,
        green: string,
        accentFaded: string
    },
    v2Fonts: {
        largeHeader: string,
        mediumHeader: string,
        smediumHeader: string,
        smallHeader: string,
        menuSelected: string,
        menuRootNode: string,
        menu: string,
        gridData: string,
        inputHeader: string,
        input: string,
        contextHeader: string,
        context: string,
        link: string
    }
}

const defaultTheme: Theme = {
    primaryFont: 'Montserrat',
    colors: {
        primary: '#187AB5',
        secondary: 'rgba(24, 122, 181, 1)',
        accent: '#FCB017',
        primaryFaded: 'rgba(24, 122, 181, 1)',
        accentFaded: 'rgba(252, 176, 23, 0.5)',
        offwhite: 'rgba(255, 255, 255, 0.1)'
    },
    v2: {
        lightYellow: 'rgb(250,234,203)',
        darkYellow: 'rgb(238,173,36)',
        lightBlue: 'rgb(203,227,242)',
        darkBlue: 'rgb(50,126,184)',
        lightGrey: 'rgb(217,217,217)',
        darkGrey: 'rgb(151,151,151)',
        darkestGrey: 'rgb(96,95,95)',
        black: 'rgb(0,0,0)',
        green: 'rgb(106,195,69)',
        accentFaded: 'rgba(252, 176, 23, 0.5)'
    },
    v2Fonts: {
        largeHeader: '800 28px Montserrat',
        mediumHeader: '800 24px Montserrat',
        smediumHeader: '800 20px Montserrat',
        smallHeader: 'bold 16px Montserrat',
        menuSelected: '800 16px Montserrat',
        menuRootNode: '800 16px Montserrat',
        gridData: '14px Montserrat',
        menu: '16px Montserrat',
        inputHeader: 'bold 16px Montserrat',
        input: '16px Montserrat',
        contextHeader: 'bold 14px Open Sans',
        context: '14px Open Sans',
        link: '14px Open Sans'
    }
};

const ThemeContext = React.createContext<Theme>(defaultTheme);
export default ThemeContext;

export {defaultTheme};