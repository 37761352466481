import React, {
  useEffect,
  useRef
} from "react";
import { withRouter } from "react-router-dom";
import Team from "../components/Dashboard/DashboardManagement/Organization/Team";
import OrganizationInformation from "../components/Dashboard/DashboardManagement/Organization/OrganizationInformation";
import styled from "@emotion/styled";
import UserContext, { doesUserHaveRole, isUserInRoles, ServerOrgRoles, UserRoleGroups } from "../services/UserContext";
import Subscription from "../components/Dashboard/DashboardManagement/Organization/Subscription";

const Div = styled.div({
    border:"5px solid #D9D9D9",
    borderRadius:"35px",
    height:"74px",
    boxSizing:"border-box",
    display:"flex",
    '& > button':{
      background:"#FAEACD",
      borderRadius:"26.5px",
      margin:"10px",
      height:"40px",
      padding:"8px 24px",
      border:"none",
      left:"0px",
      ':focus':{
        background:"#ECAC37",
        border:"none",
        color:"#FFFFFF"
      }
    }

})

const OrganizationDashboard = () => {
  // Context
  const context = React.useContext(UserContext)
  const [showTeams,setShowTeams] = React.useState(false);
  const [showOrgSettings, setShowOrgsettings] = React.useState(true);
  const [subscriptions, setShowSubs] = React.useState(false)
  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin);
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isProOrPlusAdmin = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const defaultButton  = useRef<HTMLButtonElement>(null);
  useEffect(()=>{
    if(defaultButton && defaultButton.current){
      defaultButton.current.focus()
    }
  },[])

  const setSelectedTab = (type: "orgSettings" | "teams" | "subscriptions") => {

    switch (type) {
      case "orgSettings":
        setShowOrgsettings(true);
        setShowTeams(false);
        setShowSubs(false);
        break;
      case "teams":
        setShowTeams(true);
        setShowOrgsettings(false);
        setShowSubs(false);  
        break;
      case "subscriptions":
        setShowSubs(true);setShowOrgsettings(false);setShowTeams(false) 
        break;
    }
  }

  return (
    <>{!isFree?
      <div className="p-col p-grid p-justify-center content-block">
          <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
            <Div>
              <button ref={defaultButton} onClick={() => setSelectedTab("orgSettings")} ><strong>Organization Settings</strong></button>
               {isAdmin || isProOrPlusAdmin ? <button  onClick={() => setSelectedTab("teams")} style={{width:"90px"}}><strong>Team</strong></button> :null} 
              <button  onClick={() => setSelectedTab("subscriptions")}><strong>Subscription</strong></button>
            </Div>
          </div>
          <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
              <Div style={{display:"block",alignItems:"center",padding:"20px",minHeight:"80vh", height:"auto"}} >
                  
                    <div>
                     {isAdmin || isProOrPlusAdmin ? 
                      <div>
                         {showTeams ?  <Team /> : null}
                      </div>: null}
                      {showOrgSettings ?  <OrganizationInformation /> : null}
                      {subscriptions ? <Subscription /> : null}
                    </div>
              </Div>    
          </div>
      </div>
        : null}
      </>               
  );
  
};

export default withRouter(OrganizationDashboard);
